import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getUser = gql`
  query getUser {
    user: me {
      id
      username
      email
      roles
    }
  }
`;

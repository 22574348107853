import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../BaseTooltip';
import ChartTooltipOverlay from './ChartTooltipOverlay';

import './ChartTooltip.scss';

const ChartTooltip = ({
  data,
  color,
  children,
  overlayClassName,
  placement = 'top',
}) => {
  const { header, value, valueLabel, percentageValue } = data;
  const overlay = (
    <ChartTooltipOverlay
      header={header}
      value={value}
      valueLabel={valueLabel}
      percentageValue={percentageValue}
      color={color}
    />
  );

  const normalizedPlacement =
    placement === 'topRight' ? 'chartTopRight' : placement;

  return (
    <Tooltip
      placement={normalizedPlacement}
      overlay={overlay}
      overlayClassName={overlayClassName}
    >
      {children}
    </Tooltip>
  );
};

ChartTooltip.defaultProps = {
  overlayClassName: 'chart',
};

ChartTooltip.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    valueLabel: PropTypes.string.isRequired,
    percentageValue: PropTypes.number.isRequired,
  }),
  color: PropTypes.string,
  children: PropTypes.node,
  overlayClassName: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'topRight',
    'topLightLeft',
    'topLightRight',
    'topLeft',
  ]),
};

export default ChartTooltip;

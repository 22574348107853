import { handleActions } from 'redux-actions';
import {
  SET_ONBOARDING,
  NEXT_STEP,
  END_ONBOARDING,
  RESET_STEPS,
} from '../constants/OnboardingConstants';

const ACTION_HANDLERS = {
  [SET_ONBOARDING]: (state) => ({
    ...state,
    onboardingActive: true,
    currentStep: 0,
  }),
  [NEXT_STEP]: (state, action) => ({
    ...state,
    currentStep: action.payload.nextStep,
  }),
  [END_ONBOARDING]: (state) => ({
    ...state,
    onboardingActive: false,
  }),
  [RESET_STEPS]: (state) => ({
    ...state,
    currentStep: 0,
  }),
};

const initialState = {
  onboardingActive: false,
  currentStep: null,
};

export default handleActions(ACTION_HANDLERS, initialState);

import React from 'react';
import PropTypes from 'prop-types';

import './RoundButton.scss';

const RoundButton = ({ iconClass, onClickFn }) => {
  const props = { className: iconClass };

  if (onClickFn) {
    props.onClick = onClickFn;
  }

  return (
    <div className='RoundButton row middle-xs center-xs'>
      {/* eslint-disable-next-line react/jsx-props-no-spreading -- @refactor whole file */}
      <i {...props} />
    </div>
  );
};

RoundButton.defaultProps = {
  onClickFn: null,
  iconClass: null,
};

RoundButton.propTypes = {
  iconClass: PropTypes.string,
  onClickFn: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.func]),
};

export default RoundButton;

import { handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
import {
  UPDATE_PEOPLE_NUMBER,
  SET_DEMOGRAPHIC_DATA,
  SET_DEMOGRAPHIC_LOADING,
  UPDATE_DEMOGRAPHIC_ITEM_HEIGHT,
  SET_DEMOGRAPHIC_DEFAULT_DATA,
} from '../constants/DemographicConstants';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UPDATE_PEOPLE_NUMBER]: (state, action) => ({
    ...state,
    demographicData: action.payload,
  }),
  [SET_DEMOGRAPHIC_DATA]: (state, action) => ({
    ...state,
    demographicData: action.payload,
  }),
  [SET_DEMOGRAPHIC_LOADING]: (state, action) => ({
    ...state,
    listLoading: action.payload,
  }),
  [UPDATE_DEMOGRAPHIC_ITEM_HEIGHT]: (state, action) => ({
    ...state,
    demographicLegendHeights: action.payload,
  }),
  [SET_DEMOGRAPHIC_DEFAULT_DATA]: (state, action) => ({
    ...state,
    demographicDefaultData: action.payload,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  demographicData: null,
  demographicDefaultData: null,
  demographicGraphData: {
    panelItems: [],
    byGenderAndAgeGroupPopulation: {
      male: {},
      female: {},
      labels: [],
      placeholderData: {},
    },
    byNationalityAndAgeGroupPopulation: {
      saudi: {},
      nonSaudi: {},
      labels: [],
      placeholderData: {},
    },
  },
  demographicLegendHeights: {},
  listLoading: true,
};

export default handleActions(ACTION_HANDLERS, initialState);

export const EASTERN_REGION_NAME = 'eastern-region';

export const TOOLTIP_COLOR_MAPPINGS = {
  '#00F6C9': 'green',
  '#4a90e2': 'blue',
  '#DFDF6B': 'yellow',
  '#C37DC5': 'purple',
};

export const MAP_TYPE_ID = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
} as const;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isMobileOS } from '../../helpers/ResponsiveHelper';
import './FancyScroll.scss';

const FancyScroll = ({ cssClass, children }) => {
  const cssClasses = classNames(
    {
      'fancy-scroll--mobile': isMobileOS(),
      'fancy-scroll--desktop': !isMobileOS(),
    },
    cssClass,
  );

  return <div className={cssClasses}>{children}</div>;
};

FancyScroll.propTypes = {
  children: PropTypes.node,
  cssClass: PropTypes.string,
};

export default FancyScroll;

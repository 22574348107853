import React from 'react';
import { uniqueId } from 'lodash'
import { I18n } from 'react-redux-i18n';

import FooterNav from './FooterNav/FooterNav'
import { isMobileScreen } from '../../helpers/ResponsiveHelper'
import { getA2IAllReportsLink } from '../../helpers/AppHelper'
import CONFIG from '../../config/config'

import zaddMobileLogo from '../../assets/footer/mobile-zadd-logo.svg';
import hrdfLogo from '../../assets/footer/hrdf-logo.svg';
import stampCertificate from '../../assets/footer/stamp-certificate.png';
import xIcon from '../../assets/footer/x-icon.svg';
import igIcon from '../../assets/footer/ig-icon.svg';

import './Footer.scss';

const Footer = () => {
  const isMobile = isMobileScreen();

  const navItems = [
    {
      href: CONFIG.a2iLink,
      translationKey: 'footer.navigation.mainPage',
      keyId: uniqueId('footer-nav-'),
      isActive: () => false
    },
    {
      href: '.',
      translationKey: 'footer.navigation.interactiveMap',
      keyId: uniqueId('footer-nav-'),
      isActive: (path) => path.startsWith('/eastern-region') || path.startsWith('/riyadh-region')
    },
    {
      href: getA2IAllReportsLink('all'),
      translationKey: 'footer.navigation.reports',
      keyId: uniqueId('footer-nav-'),
      isActive: () => false
    },
  ]

  return <footer className='Footer'>
    <div className='Footer__content'>
      <div className='Footer__content__section'>
        <div className='logo-container'>
          <img src={zaddMobileLogo} alt='' />
        </div>
        <div>
          <FooterNav items={navItems} />
        </div>
      </div>
      {isMobile ? (
        <div className='Footer__content__section--mobile'>
          <div className='pages-container'>
            <div className='pages-container__links'>
              <a href='https://910ths.sa/%D8%B4%D8%B1%D9%88%D8%B7-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85/'>{I18n.t('footer.pages.termsAndConditions')}</a>
              <a href='https://910ths.sa/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9/'>{I18n.t('footer.pages.privacyPolicy')}</a>
            </div>
          </div>
          <div className='links-container'>
            <div className='links-container__logo'>
              <span className='links-container__logo__text'>{I18n.t('footer.initiativeBy')}</span>
              <img src={hrdfLogo} alt='' />
            </div>
            <div className='links-container__icons'>
              <a href='https://www.instagram.com/910ths'>
                <img src={igIcon} alt='9/10ths Instagram Profile' />
              </a>
              <a href='https://x.com/910ths'>
                <img src={xIcon} alt='9/10ths X Profile' />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className='Footer__content__section'>
          <div className='logo-container'>
            <span className='logo-container__text'>{I18n.t('footer.initiativeBy')}</span>
            <img src={hrdfLogo} alt='' />
          </div>
          <div className='links-container'>
            <div className='links-container__links'>
              <a href='https://910ths.sa/%D8%B4%D8%B1%D9%88%D8%B7-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85/'>{I18n.t('footer.pages.termsAndConditions')}</a>
              <a href='https://910ths.sa/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9/'>{I18n.t('footer.pages.privacyPolicy')}</a>
            </div>
            <div className='links-container__icons'>
              <a href='https://www.instagram.com/910ths'>
                <img src={igIcon} alt='9/10ths Instagram Profile' />
              </a>
              <a href='https://x.com/910ths'>
                <img src={xIcon} alt='9/10ths X Profile' />
              </a>
            </div>
          </div>
        </div>
      )}
      <div className='Footer__content__banner'>
        <a href='http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/254'>
          <img src={stampCertificate} alt='Digital stamp certificate' />
        </a>
      </div>
    </div>
  </footer>;
}

export default Footer;

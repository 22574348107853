const businessChart = {
  in: 'في ',
  theBusinessesIn: 'نقاط الاهتمام في {{areaName}}',
  noBusinessFound: ' لا توجد أعمال تجارية في هذه المنطقة',
  businesses: 'عدد الأعمال التجارية',
  businessTooltipLabel: 'عدد نقاط الاهتمام (النسبة)',
  noDataProvided:
    'معلومات الأعمال التجارية في هذه المنطقة غير متوفرة. الرجاء اختيار منطقة أخرى.',
  description: {
    noSectors: {
      businessNumber: 'عدد نقاط الاهتمام',
      panelItem: 'نسبة نقاط الاهتمام المنتمية إلى {{isicName}}',
      tooltip: 'النسبة من الأعمال التجارية في كافة القطاعات',
    },
    oneSector: {
      panelTitle: 'نقاط الاهتمام المنتمية إلى القطاع المحدد في {{areaName}}',
      businessNumber: 'عدد نقاط الاهتمام',
      panelItem: 'النسبة من إجمالي نقاط الاهتمام في كافة القطاعات',
      ranking: 'الترتيب بين القطاعات الأخرى من ناحية عدد الأعمال التجارية',
      tooltip: 'النسبة من الأعمال التجارية في {{isicName}}',
    },
    oneSubsector: {
      panelTitle:
        'نقاط الاهتمام المنتمية إلى القطاع الفرعي المحدد في {{areaName}}',
      businessNumber: 'عدد نقاط الاهتمام',
      panelItem: 'النسبة من إجمالي نقاط الاهتمام في {{parentIsicName}}',
      ranking:
        'الترتيب بين القطاعات الفرعية الأخرى المنتمية إلى {{isicName}} من ناحية عدد الأعمال التجارية',
      tooltip: 'النسبة من الأعمال التجارية في {{isicName}}',
    },
    moreThanOneTag: {
      panelTitle: {
        twoTags: 'نقاط الاهتمام المنتمية إلى القطاعين المحددين في {{areaName}}',
        threeTags:
          'نقاط الاهتمام المنتمية إلى القطاعات المحددة في {{areaName}}',
      },
      businessNumber: 'عدد نقاط الاهتمام',
      panelItem: {
        twoSectors: 'النسبة من إجمالي نقاط الاهتمام في القطاعين المحددين',
        twoSubsector: 'النسبة من إجمالي نقاط الاهتمام في القطاعين المحددين',
        threeSectors: 'النسبة من إجمالي نقاط الاهتمام في القطاعات المحددة',
        threeSubsector: 'النسبة من إجمالي نقاط الاهتمام في القطاعات المحددة',
      },
      tooltip: 'النسبة من الأعمال التجارية في القطاعات المحددة',
    },
    pdf: {
      panelItemDescription: 'نسبة نقاط الاهتمام المنتمية إلى',
    },
  },
};

export default businessChart;

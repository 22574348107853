const saveModal = {
  modalHeader: 'احصل على رابط المشاركة',
  modalMessage: 'انسخ الرابط لحفظ المرشحات للمستقبل أو لمشاركتها مع زملائك.',
  inactiveButtonMessage: 'نسخ الرابط',
  activeButtonMessage: 'تم نسخ الرابط',
  footerMessage:
    'يمكنك الآن لصق الرابط وإضافته إلى مواقعك المفضلة أو إرساله مباشرةً إلى زملائك .',
};

export default saveModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import RenderPdfTooltip from '../RenderPdfTooltip/RenderPdfTooltip';
import PercentageValue from '../../../components/PercentageValue';
import NumberValueLabel from '../../../components/NumberValueLabel';

import './RenderPdfSpendingTooltip.scss';

const tooltipText = {
  tooltipCurrency: 'incomeChart.currencyRiyal',
};

const RenderPdfSpendingTooltip = ({ item, tooltipData }) => {
  const totalSpending = tooltipData
    .map((spendingData) => spendingData.value)
    .reduce((totalValue, spendingValue) => totalValue + spendingValue);

  return (
    <RenderPdfTooltip>
      <div className='render-pdf-spending-tooltip'>
        <h3 className='render-pdf-spending-tooltip__header'>
          <Translate value={`spendingChart.categories.${item.subIndicator}`} />
        </h3>
        <div className='render-pdf-spending-tooltip__underline' />
        <p className='render-pdf-spending-tooltip__value'>
          <NumberValueLabel number={item.value} />
          <span className='render-pdf-spending-tooltip__currency'>
            <Translate value={tooltipText.tooltipCurrency} />
          </span>
          <span className='render-pdf-spending-tooltip__percentage'>
            <PercentageValue
              number={((item.value / totalSpending) * 100).toFixed(1)}
            />
          </span>
        </p>
      </div>
    </RenderPdfTooltip>
  );
};

export default RenderPdfSpendingTooltip;

RenderPdfSpendingTooltip.propTypes = {
  item: PropTypes.object,
  tooltipData: PropTypes.array,
};

import { createSelector } from '@reduxjs/toolkit';
import BusinessChartDataHelper from '../helpers/BusinessChartDataHelper';
import {
  getChosenSectors,
  getColoredNonZeroSectorList,
  getColoredSectorList,
} from './sector.selector';

export const getBusiness = (state) => state.business;
export const isBusinessLoading = createSelector(
  getBusiness,
  (business) => business.listLoading,
);
export const getVisibleBusinesesses = createSelector(
  getBusiness,
  (business) => business.businesses.size,
);
export const getBusinessItems = createSelector(
  getBusiness,
  (business) => business.businesses,
);
export const getAllSABusinesses = createSelector(
  getBusiness,
  (business) => business.businessesFromSA,
);
export const getAllBusisessesFromArea = createSelector(
  getBusiness,
  (business) => business.businessesFromCurrentArea,
);

export const getBusinessChartData = createSelector(
  getAllSABusinesses,
  getVisibleBusinesesses,
  getAllBusisessesFromArea,
  getChosenSectors,
  getColoredSectorList,
  getColoredNonZeroSectorList,
  (
    allSABusinesses,
    visibleBusinesses,
    allBusinessesFromArea,
    tags,
    sectorList,
    nonZeroSectorList,
  ) => {
    const taggedSectors = [];
    const taggedSubsectors = [];

    tags.forEach((tag) => {
      if (tag.root && tag.root.id) {
        const [sector] = sectorList.filter(
          (currentSector) => currentSector.id === tag.root.id,
        );
        if (sector) {
          const [subsector] = sector.children.items.filter(
            (currentSubsector) => currentSubsector.id === tag.id,
          );

          taggedSubsectors.push(subsector);
        }
      } else {
        const [sector] = sectorList.filter(
          (currentSector) => currentSector.id === tag.id,
        );

        taggedSectors.push(sector);
      }
    });

    const barChartHelper = new BusinessChartDataHelper(
      sectorList,
      nonZeroSectorList,
      taggedSectors,
      taggedSubsectors,
      visibleBusinesses,
      allSABusinesses,
      allBusinessesFromArea,
    );

    // No businesses found
    if (!sectorList.length) {
      return barChartHelper.getDefault();
    }

    // One sector case
    if (taggedSectors.length === 1 && !taggedSubsectors.length) {
      return barChartHelper.getOneSectorData();
    }

    // One subsector case
    if (!taggedSectors.length && taggedSubsectors.length === 1) {
      return barChartHelper.getOneSubsectorData();
    }

    // Two or more - sectors or subsectors
    if (
      (taggedSectors.length >= 1 && taggedSubsectors.length >= 1) ||
      (taggedSectors.length > 1 && !taggedSubsectors.length) ||
      (taggedSubsectors.length > 1 && !taggedSectors.length)
    ) {
      return barChartHelper.getMoreThanOneTagData();
    }

    return barChartHelper.getDefault();
  },
);

import React from 'react';
import classNames from 'classnames';
import { browserHistory } from 'react-router';

import Breadcrumbs from '../../modules/Sidebar/components/Breadcrumbs';
import SectorsTab from '../../modules/Sidebar/components/SectorsTab';
import BusinessTab, {
  BusinessTabTitle,
} from '../../modules/Sidebar/components/BusinessTab';
import DemographicTab from '../../modules/Sidebar/components/DemographicTab';
import CloseSidebarButton from '../../modules/Sidebar/components/CloseSidebarButton';
import SidebarItem from '../../modules/Sidebar/components/SidebarItem';
import OnboardingContainer from '../../modules/Onboarding/containers/OnboardingContainer';
import SidebarCumulatedActions from '../../modules/Sidebar/containers/actions';

import DownloadReportButton from '../../components/DownloadReportButton/DownloadReportButton';
import FounderLogo from '../../components/FounderLogo';
import RegionChooser from '../../components/RegionChooser';
import SaveModalWrapper from '../components/SaveModalWrapper';

import { AppPaths } from '../../helpers/globals/Constans';
import { isMobileDevice } from '../../helpers/ResponsiveHelper';
import { getChosenRegionData, getMinZoomLevel } from '../../helpers/MapHelper';
import { Region } from '../../types';

import {
  changeBodySidebarClass,
  getSidebarItemPathname,
  isActiveSidebarItem,
  checkPath,
} from './utils';

import { DOWNLOAD_REPORT_ID, GET_BUSINESSES_DELAY_TIME } from './constants';
import { downloadTranslations } from './translations';

let sidebarDetails: HTMLDivElement | null = null;

export interface SidebarLayoutProps {
  google: object; // @UPDATE-OBJECT-TYPE
  children: JSX.Element;
  breadcrumbs: object; // @UPDATE-OBJECT-TYPE;
  chosenRegion: Region;
  tags: Array<object>; // @UPDATE-OBJECT-TYPE;
  businessesNumber?: number;
  demographicData?: {
    totalPopulation: number;
  };
  actions: typeof SidebarCumulatedActions;
}

export interface SidebarLayoutState {
  isActive: boolean;
}

export class SidebarLayout extends React.Component<
  SidebarLayoutProps,
  SidebarLayoutState
> {
  getBusinessesDelay?: ReturnType<typeof setTimeout>;

  browserHistoryUnlisten: ReturnType<typeof browserHistory.listen> | null =
    null;

  constructor(props: SidebarLayoutProps) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {
    this.checkSidebarPath();

    this.browserHistoryUnlisten = browserHistory.listen(() =>
      this.checkSidebarPath(),
    );
  }

  componentDidUpdate(
    prevProps: SidebarLayoutProps,
    prevState: SidebarLayoutState,
  ) {
    const { isActive } = this.state;
    const {
      actions: { getBusinesses },
    } = this.props;

    if (isActive !== prevState.isActive && !isActive) {
      this.clearGetBusinessesDelay();

      this.getBusinessesDelay = setTimeout(
        getBusinesses,
        GET_BUSINESSES_DELAY_TIME,
      );
    }
  }

  componentWillUnmount() {
    if (this.browserHistoryUnlisten) {
      this.browserHistoryUnlisten();
    }

    this.browserHistoryUnlisten = null;

    this.clearGetBusinessesDelay();
  }

  setSidebar = (isActive: boolean) => {
    if (isActive) {
      changeBodySidebarClass('add');
      if (isMobileDevice()) {
        this.toggleSidebar(isActive);
      } else {
        setTimeout(() => this.toggleSidebar(isActive), 300);
      }
      return;
    }

    changeBodySidebarClass('remove');
    this.toggleSidebar(isActive);

    if (isMobileDevice() && sidebarDetails) {
      sidebarDetails.scrollTo(0, 0);
    }
    // PROBALBLY ANIMATION BLINKS
    // setTimeout(() => changeRoutePath({ value, chosenRegion, route }), 300);
  };

  resetToRegion = (chosenRegion: Region) => {
    const {
      actions: { setCurrentLocation, setMapZoom },
    } = this.props;
    const { chosenRegionCenter } = getChosenRegionData(chosenRegion);

    setCurrentLocation(chosenRegionCenter);
    setMapZoom(getMinZoomLevel(chosenRegion));
  };

  clearGetBusinessesDelay() {
    if (this.getBusinessesDelay) {
      clearTimeout(this.getBusinessesDelay);
    }
  }

  checkSidebarPath() {
    const isSidebarPath = checkPath();

    this.toggleSidebar(isSidebarPath);

    changeBodySidebarClass(isSidebarPath ? 'add' : 'remove');
  }

  toggleSidebar(isActive: boolean) {
    this.setState({ isActive });
  }

  render() {
    const {
      actions,
      children,
      google,
      breadcrumbs,
      tags,
      businessesNumber,
      demographicData,
      chosenRegion,
    } = this.props;

    const { isActive } = this.state;

    const classes = classNames('sidebar__container', {
      open: isActive,
    });

    const totalPopulation = demographicData?.totalPopulation ?? 0;

    const getSetSidebarFunction = (appPathname: string) => () =>
      this.setSidebar(!isActiveSidebarItem(appPathname));

    return (
      <aside className={classes}>
        <div className='sidebar__wrapper'>
          <OnboardingContainer section='sidebar' />
          <div
            className='sidebar__details'
            ref={(ref) => {
              sidebarDetails = ref;
            }}
          >
            <div className='sidebar__content'>
              {children}
              <CloseSidebarButton
                sidebarControlCallback={this.setSidebar}
                region={chosenRegion}
              />
            </div>
          </div>
          <div className='sidebar__column'>
            <div className='sidebar__column-row'>
              <div
                id='onboarding-step-breadcrumbs'
                className='sidebar__item-wrapper sidebar__item--breadcrumbs sidebar__item-wrapper--separated sidebar__space-top--s3 sidebar__space-bottom--s3'
              >
                <div className='sidebar__space-bottom--s3'>
                  <RegionChooser
                    chosenRegion={chosenRegion}
                    setChosenRegion={actions.setChosenRegion}
                    clearTags={actions.clearTags}
                    resetToRegion={this.resetToRegion}
                  />
                </div>
                {chosenRegion && (
                  <Breadcrumbs
                    google={google}
                    breadcrumbs={breadcrumbs}
                    updateViewport={actions.updateViewport}
                    chosenRegion={chosenRegion}
                    resetToRegion={this.resetToRegion}
                  />
                )}
              </div>
              <SidebarItem
                onboardingStep='sectors'
                className='sidebar__item--sectors sidebar__space-top--s3 sidebar__space-bottom--s3'
                isActive={isActiveSidebarItem(AppPaths.SECTORS)}
                onClick={getSetSidebarFunction(AppPaths.SECTORS)}
                pathname={getSidebarItemPathname(
                  AppPaths.SECTORS,
                  chosenRegion,
                )}
              >
                <SectorsTab tags={tags} removeSector={actions.removeSector} />
              </SidebarItem>
            </div>

            <div className='sidebar__column-row'>
              <div className='sidebar__item-layout sidebar__space-top--s3 sidebar__space-bottom--s2'>
                <BusinessTabTitle />
              </div>
              <SidebarItem
                onboardingStep='business'
                className='sidebar__item--business'
                disabled={businessesNumber === 0}
                isActive={isActiveSidebarItem(AppPaths.BUSINESSES)}
                onClick={getSetSidebarFunction(AppPaths.BUSINESSES)}
                pathname={getSidebarItemPathname(
                  AppPaths.BUSINESSES,
                  chosenRegion,
                )}
              >
                <BusinessTab businessesNumber={businessesNumber} />
              </SidebarItem>
              <SidebarItem
                onboardingStep='demographic'
                className='sidebar__item--demographic'
                disabled={totalPopulation === 0}
                isActive={isActiveSidebarItem(AppPaths.DEMOGRAPHIC)}
                onClick={getSetSidebarFunction(AppPaths.DEMOGRAPHIC)}
                pathname={getSidebarItemPathname(
                  AppPaths.DEMOGRAPHIC,
                  chosenRegion,
                )}
              >
                <DemographicTab populationCount={totalPopulation} />
              </SidebarItem>
            </div>

            <div className='sidebar__column-row'>
              <div
                id='onboarding-step-download'
                className='sidebar__item-wrapper sidebar__space-top--s3 sidebar__space-bottom--s3'
              >
                <SaveModalWrapper />
                <div className='SidebarItem-download sidebar__space-top--s2'>
                  <DownloadReportButton
                    chosenRegion={chosenRegion}
                    modalButtonTranslations={downloadTranslations}
                    buttonId={DOWNLOAD_REPORT_ID}
                  />
                </div>
              </div>
            </div>

            {chosenRegion && (
              <div className='sidebar__item-layout sidebar__item-logo sidebar__space-bottom--s4'>
                <FounderLogo chosenRegion={chosenRegion} />
              </div>
            )}
          </div>
        </div>
      </aside>
    );
  }
}

export default SidebarLayout;

import {
  GENDER_AGE_TABLE,
  NATIONALITY_AGE_TABLE,
  RIYADH_GROUPS_TABLE,
  getEasternGroupsTable,
  SPENDING_DATA_TABLE,
  BUSINESS_TABLE,
} from './tablesConfig';
import {
  flatAndMerge,
  mergeAndFlatGroups,
  normalizeGroups,
  mapSector,
} from './utils.ts';
import {
  translateGroupPopulation,
  translateGroup,
  translateSpendingData,
} from './translateChartData';

export const RIYADH_GROUPS = {
  tableConfig: RIYADH_GROUPS_TABLE,
  normalize: (data) => normalizeGroups(data, 'facts'),
  process: (groups) => mergeAndFlatGroups(groups),
  translate: translateGroup,
};

export const EASTERN_GROUPS = {
  tableConfig: getEasternGroupsTable,
  normalize: (data) => normalizeGroups(data, 'facts'),
};

export const BY_GENDER_AND_AGE_GROUP_POPULATION = {
  tableConfig: GENDER_AGE_TABLE,
  normalize: (data) => normalizeGroups(data, 'data'),
  process: (data) => flatAndMerge(data, ['female', 'male']),
  translate: translateGroupPopulation,
};

export const BY_NATIONALITY_AND_AGE_GROUP_POPULATION = {
  tableConfig: NATIONALITY_AGE_TABLE,
  normalize: (data) => normalizeGroups(data, 'data'),
  process: (data) => flatAndMerge(data, ['saudi', 'nonSaudi']),
  translate: translateGroupPopulation,
};

export const SPENDING_DATA = {
  tableConfig: SPENDING_DATA_TABLE,
  translate: translateSpendingData,
  process: (data) => data.facts,
};

export const BUSINESS_LIST = {
  tableConfig: BUSINESS_TABLE,
  normalize: (data) => data.flatMap(mapSector),
};

import React from 'react';
import PropTypes from 'prop-types';

import './RenderPdfTooltipContainer.scss';

const RenderPdfTooltipContainer = ({ children }) => (
  <div className='render-pdf-tooltip-container'>{children}</div>
);

export default RenderPdfTooltipContainer;

RenderPdfTooltipContainer.propTypes = {
  children: PropTypes.array,
};

import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const app = {
  placeholder: 'Enter a value',
  backToMap: 'Back to map',
  supportedBy: 'Supported by:',
  mapTooltip: {
    district: '{{districtName}}, ',
    address: '{{areaName}} {{zipCode}}',
  },
  header: {
    homepage: 'Homepage',
    map: 'Map',
    reports: 'Reports',
    allReports: {
      desktop: 'All reports',
      mobile: 'All reports',
    },
    sector: {
      desktop: 'Sector reports',
      mobile: 'Sector reports',
    },
    product: {
      desktop: 'Product reports',
      mobile: 'Product reports',
    },
    area: {
      desktop: 'Area reports',
      mobile: 'Area reports',
    },
    services: {
      title: '910ths Services'
    }
  },
  popup: {
    heading: 'Pilot launch',
    content:
      'We are currently updating business information in Riyadh. The map will include business locations and demographic and economic characteristics at the Kingdom level in the future',
    button: 'Access to the map',
  },
  regions: {
    [riyadhRegionString]: 'Central Region',
    [easternRegionString]: 'Eastern Region',
  },
  easternRegionTooltip:
    'With the exception of Al-Ahsa and Hafar Al-Batin governorates',
};

export default app;

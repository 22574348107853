import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import RegionDropdown from './RegionDropdown';
import RegionDropdownToggle from './RegionDropdownToggle';
import './RegionChooser.scss';
import { addRegionToUrl, scrollToMap } from '../../helpers/AppHelper';
import { easternRegionString } from '../../helpers/globals/Constans.ts';
import EasternRegionTooltip from './EasternRegionToolip/EasternRegionTooltip';

const SELECT_TRANSLATION_KEY = 'app.popup.heading';
const REGION_TRANSLATION_PATH = 'app.regions.';

const RegionChooser = ({
  setChosenRegion,
  chosenRegion,
  resetToRegion,
  clearTags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleChooseRegion = useCallback(
    (region) => {
      if (region === chosenRegion) {
        resetToRegion(region);
      } else {
        addRegionToUrl(region);
        setChosenRegion(region);
        clearTags();
      }
      toggleDropdown();
      scrollToMap();
    },
    [isOpen, chosenRegion],
  );

  const dropdownLabel = chosenRegion
    ? I18n.t(`${REGION_TRANSLATION_PATH}${chosenRegion}`)
    : I18n.t(SELECT_TRANSLATION_KEY);

  const ref = useRef(null);

  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (isOpen) {
        toggleDropdown();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div ref={ref} className='RegionChooser'>
      {chosenRegion === easternRegionString && <EasternRegionTooltip />}
      <RegionDropdownToggle
        toggleDropdown={toggleDropdown}
        dropdownLabel={dropdownLabel}
      />
      {isOpen && (
        <RegionDropdown
          handleChooseRegion={handleChooseRegion}
          chosenRegion={chosenRegion}
        />
      )}
    </div>
  );
};

RegionChooser.propTypes = {
  setChosenRegion: PropTypes.func.isRequired,
  clearTags: PropTypes.func.isRequired,
  resetToRegion: PropTypes.func.isRequired,
  chosenRegion: PropTypes.string,
};

export default RegionChooser;

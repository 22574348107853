const csvReport = {
  name: 'Name',
  size: 'Size',
  nationality: 'Nationality',
  age_group: 'Age group',
  group: 'Group',
  area_name: 'Area name',
  value: 'Value',
  gender: 'Gender',
  population_density_value: 'Population density value',
  population_growth_rate_value: 'Population growth rate value',
  household_size_value: 'Household size value',
  household_growth_rate_value: 'Household growth rate value',
  household_income_value: 'Household income value',
  household_savings_value: 'Household savings value',
  household_spending_value: 'Household spending value',
  indicator: 'Indicator',
};

export default csvReport;

import React from 'react';
import PropTypes from 'prop-types';

import BarItem from './BarItem';
import BarItemSubsectorsWrapper from './BarItemSubsectorsWrapper';
import BarLabel from './BarLabel';

const BarItemList = ({
  barChartDataType,
  width,
  items,
  scaleX,
  guides,
  enableAnimation,
}) => {
  if (width === 0) {
    return null;
  }

  const noSectorType = 'noSectors';
  const moreThanOneTagType = 'moreThanOneTag';
  const noSectorsTagged = barChartDataType === noSectorType;
  const isMoreThanOneTag = barChartDataType === moreThanOneTagType;
  const [barItem] = items;
  const name = barItem && barItem.root ? barItem.root.name : '';

  return (
    <div className='chart__bar-items chart__bar-items--business'>
      {!isMoreThanOneTag ? (
        <>
          {!noSectorsTagged && (
            <BarLabel
              name={name}
              isSubsector={false}
              additionalClass='bar__label--sector-name'
            />
          )}
          {items.map((item) => (
            <BarItem
              width={width}
              key={item.id}
              barWidth={scaleX(item.size)}
              item={item}
              guides={guides}
              barChartDataType={barChartDataType}
              enableAnimation={enableAnimation}
            />
          ))}
        </>
      ) : (
        items.map((item) => (
          <BarItemSubsectorsWrapper
            width={width}
            scaleX={scaleX}
            key={item.id}
            item={item}
            guides={guides}
            barChartDataType={barChartDataType}
            enableAnimation={enableAnimation}
          />
        ))
      )}
    </div>
  );
};

BarItemList.propTypes = {
  barChartDataType: PropTypes.string,
  guides: PropTypes.number.isRequired,
  items: PropTypes.array,
  scaleX: PropTypes.func.isRequired,
  width: PropTypes.number,
  enableAnimation: PropTypes.bool,
};

export default BarItemList;

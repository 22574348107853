import React from 'react';
import PropTypes from 'prop-types';
import indicator from '../../../assets/indicator.svg';

const EasternRegionIndicator = ({ toggleShowEasternRegionTooltip }) => (
  <div
    className='EasternRegionIndicator'
    onClick={toggleShowEasternRegionTooltip}
  >
    <img src={indicator} alt='indicator' />
  </div>
);

EasternRegionIndicator.propTypes = {
  toggleShowEasternRegionTooltip: PropTypes.func.isRequired,
};

export default EasternRegionIndicator;

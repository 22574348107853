import { PanelItemTypes } from './globals/Constans.ts';
import { convertToPercentage } from './BarChartHelper';

function preparePanelItemValue(panelParams, type) {
  let params = {};

  const { size, totalNumber, demographicValue, subsectorWithinSectorCase } =
    panelParams;

  if (type === PanelItemTypes.percentageValue) {
    params = {
      size,
      totalNumber,
      percentageValue: convertToPercentage(size, totalNumber),
      subsectorWithinSectorCase,
    };
  } else if (type === PanelItemTypes.number) {
    params = {
      size,
    };
  } else if (type === PanelItemTypes.demographicValue) {
    params = {
      demographicValue,
    };
  }

  return {
    type,
    params,
  };
}

function preparePanelItemTranslations({ value, params = {}, subtitle = null }) {
  return {
    title: {
      value,
      params,
    },
    subtitle,
  };
}

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export function preparePanelItem(panelParams, type) {
  const value = preparePanelItemValue(panelParams.value, type);

  const translation = preparePanelItemTranslations(panelParams.translation);

  return {
    value,
    translation,
    ...panelParams.otherProperties,
  };
}

import React from 'react';
import { Translate } from 'react-redux-i18n';
import preloaderIcon from '../../assets/download/preloader.gif';

const LoadingModalContent = () => {
  const modalText = {
    pendingHeader: 'downloadModal.pendingHeader',
    pendingMessage: 'downloadModal.pendingMessage',
  };

  return (
    <>
      <h4 className='DownloadModalStatus__header'>
        <Translate value={modalText.pendingHeader} />
      </h4>
      <p className='DownloadModalStatus__message'>
        <Translate value={modalText.pendingMessage} />
      </p>
      <img
        className='DownloadModalStatus__preloader'
        src={preloaderIcon}
        alt='preloader'
      />
    </>
  );
};

export default LoadingModalContent;

import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-client-preset';
import { graphQLUri } from './constants';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: graphQLUri,
    credentials: 'include',
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default apolloClient;

import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';

const AxisY = ({ guideLines, singleAxisItem, maxAxisXItem, width, cssKey }) => {
  const renderedGuideLines = guideLines
    .filter((line) => line !== guideLines.length - 1)
    .map((line) => {
      const value = round(singleAxisItem * line);

      const axisYSpace = (value / maxAxisXItem) * width;

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(axisYSpace)) {
        return null;
      }

      return (
        <div
          key={line}
          className='simple-axis-y__item'
          style={{ [cssKey]: axisYSpace }}
        />
      );
    });

  return <div className='simple-axis-y'>{renderedGuideLines}</div>;
};

AxisY.propTypes = {
  guideLines: PropTypes.array.isRequired,
  singleAxisItem: PropTypes.number.isRequired,
  maxAxisXItem: PropTypes.number.isRequired,
  cssKey: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default AxisY;

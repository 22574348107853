import { mapStateToPropsForBusinessBarChartComponent } from '../../modules/Businesses/containers/BusinessBarChartContainer';
import { mapStateToPropsForDemographicBarChartComponent } from '../../modules/Demographic/containers/DemographicBarChartContainer';

export const getBarChartComponentData = (pointsOfInterestStoreData, limit) => {
  const barChartComponentData = mapStateToPropsForBusinessBarChartComponent(
    pointsOfInterestStoreData,
  );

  return {
    ...barChartComponentData,
    businessChartData: {
      ...barChartComponentData.businessChartData,
      businessList: barChartComponentData.businessChartData.businessList.slice(
        0,
        limit || barChartComponentData.businessChartData.businessList.length,
      ),
    },
  };
};

export const getDemographicBarChartComponentData = (demographicStoreData) => {
  const demographicBarChartComponentData =
    mapStateToPropsForDemographicBarChartComponent(demographicStoreData);
  const { demographicGraphData, demographicLegendHeights } =
    demographicStoreData.demographic;

  return {
    ...demographicBarChartComponentData,
    demographicGraphData,
    demographicLegendHeights,
    spendingData: demographicStoreData.map.spendingData.spendingData,
    loading: false,
    groups: demographicStoreData.groups,
  };
};

export const getDistricName = (breadcrumbs) => {
  if (
    breadcrumbs.firstLine.label === 'breadcrumbs.centralRegionName' &&
    breadcrumbs.thirdLine.label !== 'breadcrumbs.selectDistrict'
  ) {
    return breadcrumbs.thirdLine.label;
  }

  if (
    breadcrumbs.firstLine.label !== 'breadcrumbs.centralRegionName' &&
    breadcrumbs.secondLine.label !== 'breadcrumbs.selectGovernorate'
  ) {
    return breadcrumbs.secondLine.label;
  }

  return null;
};

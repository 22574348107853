import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import { scaleLinear, scaleLog } from 'd3-scale';

import classNames from 'classnames';
import ChartTooltipOverlay from '../../../../components/Tooltip/ChartTooltip/ChartTooltipOverlay';

import './Item.scss';
import {
  BUBBLE_MARGIN,
  DEMOGRAPHIC_TOOLTIP_WIDTH,
} from '../../../Demographic/constants/IncomeChartHelpers';
import MonetaryValue from '../../../../components/MonetaryValue';
import { getTooltipPositionValues } from './itemHelpers';
import { getScreenBreakpoint } from '../../../../helpers/ResponsiveHelper';

import Bubble from './Bubble';
import BubbleChartValue from './BubbleChartValue';

const DEFAULT_ITEM_WIDTH = 420;
const GROUPED_ITEM_WIDTH = 300;
const GROUPED_ITEM_WIDTH_REDUCED = 260;

const getGroupedItemWidth = () =>
  getScreenBreakpoint() === 'md'
    ? GROUPED_ITEM_WIDTH_REDUCED
    : GROUPED_ITEM_WIDTH;
const getSvgWidth = (grouped) =>
  grouped ? getGroupedItemWidth() : DEFAULT_ITEM_WIDTH;

const extraDataNode = ({ selectedBubble, extraData }) => {
  const income = extraData?.bubbles.find(
    (item) => item.id === selectedBubble.id,
  ).income;

  return (
    income && (
      <div className='Item__extraData'>
        (<span>{I18n.t('demographicChart.averageSavings')}: </span>
        <MonetaryValue
          className='Item__extraData-value'
          value={income}
          fractionDigits={1}
        />
        )
      </div>
    )
  );
};

class Item extends React.Component {
  constructor(props) {
    super(props);
    const { grouped, fillWidth } = props;

    this.state = {
      hovered: false,
      selectedBubble: {},
      bubblePosition: 0,
      svgWidth: fillWidth ? '100%' : getSvgWidth(grouped),
    };
  }

  componentDidMount() {
    const { grouped, fillWidth } = this.props;

    window.addEventListener('resize', () => {
      this.setState({
        svgWidth: fillWidth ? '100%' : getSvgWidth(grouped),
      });
    });
  }

  handleBubbleHover = (bubble) => {
    this.setState({
      bubblePosition: bubble.value,
      selectedBubble: bubble,
      hovered: true,
    });
  };

  handleBubbleHoverEnd = () => {
    this.setState({ hovered: false });
  };

  render() {
    const {
      isEmpty,
      label,
      icon,
      color,
      centerIcon,
      bubbles,
      hideRanking,
      grouped,
      minIncome,
      maxIncome,
      valueSuffix,
      extraData,
      showValue,
      fillWidth,
    } = this.props;

    const { hovered, selectedBubble, bubblePosition, svgWidth } = this.state;

    const startRange = BUBBLE_MARGIN;
    const endRange = svgWidth - BUBBLE_MARGIN;

    const xScale = (grouped ? scaleLinear() : scaleLog())
      .domain([maxIncome, minIncome])
      .range(fillWidth ? ['0%', '100%'] : [startRange, endRange]);

    const tooltipPosition = getTooltipPositionValues({
      bubblePosition,
      endRange,
    }).content;

    const tooltip = hovered && (
      <div
        style={{
          left: `${tooltipPosition}px`,
          width: DEMOGRAPHIC_TOOLTIP_WIDTH,
        }}
        className='rc-tooltip base-tooltip Item__tooltip rc-tooltip-placement-top'
      >
        <ChartTooltipOverlay
          income={selectedBubble.income}
          header={selectedBubble.districtName}
          valueLabel={selectedBubble.valueLabel}
          position={selectedBubble.position}
          numBubbles={selectedBubble.numBubbles}
          hideRanking={hideRanking}
          color={color}
          valueSuffix={valueSuffix}
          arrowLeft={
            getTooltipPositionValues({ bubblePosition, endRange }).arrow
          }
        >
          {extraDataNode({ selectedBubble, extraData })}
        </ChartTooltipOverlay>
      </div>
    );

    const dataNotAvailableMessage = (
      <Translate value='incomeChart.notAvailable' />
    );

    const bubblesCopy = [...bubbles];
    const primaryBubble = isEmpty ? null : bubblesCopy.pop();

    return (
      <div
        className={classNames('Item', {
          'Item--grouped': grouped,
          'Item--spread': showValue,
        })}
      >
        <div
          className={classNames('Item__label', {
            'Item__label--grouped': grouped,
          })}
        >
          <div className='Item__icon'>
            <i
              className={classNames(`geo-icon-${icon}`, {
                'Item__icon--center-align': centerIcon,
              })}
            />{' '}
          </div>
          <Translate value={`demographicChart.${label}`} />
        </div>
        <div
          id={label}
          className='Item__points'
          style={{ width: fillWidth ? '100%' : svgWidth }}
        >
          <svg width='100%' height={30}>
            <line x1='0' y1='15' x2='100%' y2='15' stroke='#919ba7' />
            <g fill={color} opacity={0.25}>
              {bubblesCopy.map((bubble) => (
                <Bubble
                  bubble={{ ...bubble, value: xScale(bubble.income) }}
                  handleBubbleHover={this.handleBubbleHover}
                  handleBubbleHoverEnd={this.handleBubbleHoverEnd}
                  radius={8}
                  key={bubble.id}
                />
              ))}
            </g>
            {primaryBubble && (
              <Bubble
                bubble={{
                  ...primaryBubble,
                  value: xScale(primaryBubble.income),
                }}
                handleBubbleHover={this.handleBubbleHover}
                handleBubbleHoverEnd={this.handleBubbleHoverEnd}
                radius={12.5}
                color={color}
                key={primaryBubble.id}
                showLabel={showValue}
                valueSuffix={valueSuffix}
              />
            )}
          </svg>
          {tooltip}
        </div>
        {isEmpty && (
          <div style={{ color }} className='Item__position'>
            {dataNotAvailableMessage}
          </div>
        )}
        {grouped && (
          <div className='Item__caption'>
            <BubbleChartValue income={minIncome} valueSuffix={valueSuffix} />
            <BubbleChartValue income={maxIncome} valueSuffix={valueSuffix} />
          </div>
        )}
      </div>
    );
  }
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  bubbles: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  hideRanking: PropTypes.bool,
  centerIcon: PropTypes.bool,
  grouped: PropTypes.bool,
  minIncome: PropTypes.number,
  maxIncome: PropTypes.number,
  valueSuffix: PropTypes.string,
  extraData: PropTypes.object,
  showValue: PropTypes.bool,
  fillWidth: PropTypes.bool,
};

export default Item;

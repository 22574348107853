export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_MAP = 'SET_MAP';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_MAP_SHAPE = 'SET_MAP_SHAPE';
export const SET_CLUSTER_LEVEL = 'SET_CLUSTER_LEVEL';
export const SAVE_HIGHEST_CLUSTER_LABEL = 'SAVE_HIGHEST_CLUSTER_LABEL';
export const SET_MAP_RANGE = 'SET_MAP_RANGE';
export const RUN_MAP_EXTENSIONS = 'RUN_MAP_EXTENSIONS';
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';
export const SET_BREADCRUMB_ITEMS = 'SET_BREADCRUMB_ITEMS';
export const SET_BREADCRUMB_CONNECTED_DATA = 'SET_BREADCRUMB_CONNECTED_DATA';
export const SET_BREADCRUMBS_IN_SIDEBAR = 'SET_BREADCRUMBS_IN_SIDEBAR';
export const SET_AREAS = 'SET_AREAS';
export const SET_AREA_SHAPE = 'SET_AREA_SHAPE';
export const CREATE_PLACES_SERVICE = 'CREATE_PLACES_SERVICE';
export const SET_MARKER_CONSTRUCTOR = 'SET_MARKER_CONSTRUCTOR';
export const SET_BUSINESSES_ON_MAP = 'SET_BUSINESSES_ON_MAP';
export const SET_INFOWINDOW_CONSTRUCTOR = 'SET_INFOWINDOW_CONSTRUCTOR';
export const SET_INFOWINDOW_ON_MAP = 'SET_INFOWINDOW_ON_MAP';
export const SET_INFOWINDOW_ON_MAP_PENDING = 'SET_INFOWINDOW_ON_MAP_PENDING';
export const SET_INFOWINDOW_CONTENT_TEXT = 'SET_INFOWINDOW_CONTENT_TEXT';
export const COPY_BUSINESS_DETAILS_INTO_CLIPBOARD =
  'COPY_BUSINESS_DETAILS_INTO_CLIPBOARD';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const SET_USER_POSITION = 'SET_USER_POSITION';
export const SET_MAP_OPTIONS = 'SET_MAP_OPTIONS';
export const SET_MAP_SEARCH = 'SET_MAP_SEARCH';
export const UPDATE_CLUSTER_SHOW_TYPE = 'UPDATE_CLUSTER_SHOW_TYPE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_USER_AS_VISITED = 'SET_USER_AS_VISITED';
export const SET_USER = 'SET_USER';
export const SET_CHOSEN_REGION = 'SET_CHOSEN_REGION';
export const SET_MAP_TYPE = 'SET_MAP_TYPE';

import { cloneDeep, filter, round, sumBy } from 'lodash';

import { isArabicDirection } from './AppHelper';
import { PanelItemTypes } from './globals/Constans.ts';

function computeItemsSum(items, iteratee) {
  return sumBy(
    filter(items, ({ isChildSubsector }) => !isChildSubsector),
    iteratee,
  );
}

export function roundPanelItemToGetProperSum(panelItems) {
  const iteratee = (item) => item.value.params.percentageValue;

  const filteredPanelItems = panelItems.filter(
    (item) =>
      PanelItemTypes.percentageValue === item.value.type &&
      !item.value.params.subsectorWithinSectorCase,
  );

  const panelItemsWithChildSubsector = filter(
    filteredPanelItems,
    ({ isChildSubsector }) => isChildSubsector,
  );

  const isChildSubsectorCase = !!panelItemsWithChildSubsector.length;

  const sum = computeItemsSum(filteredPanelItems, iteratee);

  const panelItemsSum = sumBy(filteredPanelItems, iteratee);

  if (sum && sum >= 99.9 && sum !== 100) {
    filteredPanelItems[0].value.params.percentageValue -= round(sum - 100, 3);
    round(filteredPanelItems[0].value.params.percentageValue, 3);
  }

  if (filteredPanelItems.length < 3) {
    return panelItems;
  }

  if (isChildSubsectorCase && panelItemsSum !== 100) {
    if (panelItemsSum < 100) {
      const [
        {
          isic: {
            root: { id: rootId },
          },
        },
      ] = panelItemsWithChildSubsector;
      const index = filteredPanelItems.findIndex(
        (item) => item.isic.id === rootId,
      );
      const itemToRound =
        filteredPanelItems[index].value.params.percentageValue;

      filteredPanelItems[index].value.params.percentageValue =
        itemToRound + (100 - panelItemsSum);

      const [info] = cloneDeep(panelItems);

      return [info, ...filteredPanelItems];
    }
  }

  if (
    filteredPanelItems.filter(
      (item) => item && item.value.params.percentageValue === 33.3,
    ).length !== 3
  ) {
    return panelItems;
  }

  const [info, first, second, third] = cloneDeep(panelItems);

  if (first && second && third) {
    first.value.params.percentageValue = 33.4;
    second.value.params.percentageValue = 33.3;
    third.value.params.percentageValue = 33.3;
  }

  return [info, first, second, third];
}

function getPrecision(number) {
  if (number < 0.01) {
    return 3;
  }

  if (number < 0.1) {
    return 2;
  }

  return 1;
}

function roundTooltipPercentageValues(businessItems, precision) {
  return businessItems.map((item) => {
    const percentageValue = round(
      item.tooltip.percentageValue,
      precision || getPrecision(item.tooltip.percentageValue),
    );

    return {
      ...item,
      tooltip: {
        ...item.tooltip,
        percentageValue,
      },
    };
  });
}

export function roundTooltipValueToGetProperSum(businessItems) {
  const filteredBusinessItems = businessItems.filter((item) => !item.skipInSum);

  const sum = computeItemsSum(
    filteredBusinessItems,
    (item) => item.tooltip.percentageValue,
  );

  if (sum && sum >= 99.9 && sum !== 100) {
    filteredBusinessItems[0].tooltip.percentageValue -= round(sum - 100, 3);
    round(filteredBusinessItems[0].tooltip.percentageValue, 3);
  }

  if (filteredBusinessItems.length < 3) {
    return roundTooltipPercentageValues(businessItems, 3);
  }

  if (
    filteredBusinessItems.filter(
      (item) => item && item.tooltip.percentageValue === 33.3,
    ).length !== 3
  ) {
    return roundTooltipPercentageValues(businessItems, 3);
  }

  const [first, second, third] = cloneDeep(filteredBusinessItems);

  first.tooltip.percentageValue = 33.4;
  second.tooltip.percentageValue = 33.3;
  third.tooltip.percentageValue = 33.3;

  return roundTooltipPercentageValues([first, second, third], 3);
}

export function convertToPercentage(number, totalNumber) {
  if (totalNumber === 0 || number === 0 || number > totalNumber) {
    return 0;
  }

  const percentageValue = (number / totalNumber) * 100;

  const precision = getPrecision(percentageValue);

  return round(percentageValue, precision);
}

export function getAreaName(breadcrumbs) {
  const { firstLine, secondLine, thirdLine } = breadcrumbs;

  if (thirdLine && !(thirdLine.label === 'breadcrumbs.selectDistrict')) {
    return thirdLine.label;
  }

  if (secondLine.clickable) {
    return secondLine.label;
  }

  return firstLine.label;
}

export function createAxisStyles(rtlStyles, value) {
  const property = isArabicDirection() ? rtlStyles[1] : rtlStyles[0];

  const styles = {
    [property]: value,
  };

  return styles;
}

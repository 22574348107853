import { browserHistory } from 'react-router';

import { AppPaths } from '../../helpers/globals/Constans';
import { Region } from '../../types/index';

type ClassListMethod = keyof Pick<DOMTokenList, 'add' | 'remove'>;

export const changeBodySidebarClass = (method: ClassListMethod) => {
  document.body.classList[method]('sidebar-scroll-only');
  document.documentElement.classList[method]('sidebar-scroll-only');
};

export const isActiveSidebarItem = (appPathname: string) => {
  const { pathname } = browserHistory.getCurrentLocation();

  return pathname.includes(appPathname);
};

export const getSidebarItemPathname = (
  appPathname: string,
  chosenRegion: Region,
) =>
  isActiveSidebarItem(appPathname)
    ? `/${chosenRegion}`
    : `/${chosenRegion}${appPathname}`;

export const checkPath = () => {
  const path = browserHistory.getCurrentLocation().pathname;

  const validPaths = [
    AppPaths.SECTORS,
    AppPaths.BUSINESSES,
    AppPaths.DEMOGRAPHIC,
    AppPaths.RENDER_PDF,
  ];

  return new RegExp(validPaths.join('|')).test(path);
};

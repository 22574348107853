import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import RenderPdfTooltipContainer from '../RenderPdfTooltipContainer/RenderPdfTooltipContainer';
import RenderPdfDemographicTooltip from '../RenderPdfDemographicTooltip/RenderPdfDemographicTooltip';

const tooltipText = {
  male: 'demographicChart.male',
  female: 'demographicChart.female',
  saudi: 'demographicChart.saudi',
  nonSaudi: 'demographicChart.nonSaudi',
};

const RenderPdfDemographicTooltipContainer = ({ tooltipData }) => {
  const maleTotal = tooltipData.male?.total;
  const femaleTotal = tooltipData.female?.total;
  const genderTotal = maleTotal + femaleTotal;
  const malePercent =
    parseFloat(((maleTotal / genderTotal) * 100).toFixed(1)) || 0;
  const femalePercent =
    parseFloat(((femaleTotal / genderTotal) * 100).toFixed(1)) || 0;

  const saudiTotal = tooltipData.saudi?.total;
  const nonSaudiTotal = tooltipData.nonSaudi?.total;
  const nationalityTotal = saudiTotal + nonSaudiTotal;
  const saudiPercent =
    parseFloat(((saudiTotal / nationalityTotal) * 100).toFixed(1)) || 0;
  const nonSaudiPercent =
    parseFloat(((nonSaudiTotal / nationalityTotal) * 100).toFixed(1)) || 0;

  return (
    <RenderPdfTooltipContainer>
      {'male' in tooltipData ? (
        <>
          <RenderPdfDemographicTooltip
            color='blue'
            header={malePercent}
            number={maleTotal}
          >
            <Translate value={tooltipText.male} />
          </RenderPdfDemographicTooltip>
          <RenderPdfDemographicTooltip
            color='pink'
            header={femalePercent}
            number={femaleTotal}
          >
            <Translate value={tooltipText.female} />
          </RenderPdfDemographicTooltip>
        </>
      ) : (
        <>
          <RenderPdfDemographicTooltip
            color='green'
            header={saudiPercent}
            number={saudiTotal}
          >
            <Translate value={tooltipText.saudi} />
          </RenderPdfDemographicTooltip>
          <RenderPdfDemographicTooltip
            color='yellow'
            header={nonSaudiPercent}
            number={nonSaudiTotal}
          >
            <Translate value={tooltipText.nonSaudi} />
          </RenderPdfDemographicTooltip>
        </>
      )}
    </RenderPdfTooltipContainer>
  );
};

export default RenderPdfDemographicTooltipContainer;

RenderPdfDemographicTooltipContainer.propTypes = {
  tooltipData: PropTypes.object,
};

export const haveLatLngChange = (
  centerLat,
  centerLng,
  currentCenterLat,
  currentCenterLng,
) =>
  ![centerLat, centerLng].includes(undefined) &&
  (centerLat !== currentCenterLat || centerLng !== currentCenterLng);

export const hasMapTypeIdChange = (mapTypeId, currentMapTypeId) =>
  mapTypeId !== undefined && mapTypeId !== currentMapTypeId;

export const haveTagsChange = (tags, currentTags) =>
  tags.length !== currentTags.length ||
  tags.some(
    ({ id }) =>
      !currentTags.some(({ id: currentTagId }) => id === currentTagId),
  );

import React from 'react';
import PropTypes from 'prop-types';

class MapMarkers extends React.Component {
  componentDidMount() {
    const { drawMarkers } = this.props;

    drawMarkers();
  }

  shouldComponentUpdate(prevProps) {
    const { businesses } = this.props;

    return prevProps.businesses !== businesses;
  }

  componentDidUpdate() {
    const { drawMarkers } = this.props;

    drawMarkers();
  }

  render() {
    return <div />;
  }
}

MapMarkers.propTypes = {
  businesses: PropTypes.object,
  drawMarkers: PropTypes.func,
};

export default MapMarkers;

import React from 'react';
import { Translate } from 'react-redux-i18n';

import Tab from '../Tab/Tab';

const businessTabTranslations = {
  businesses: 'businessTab.businesses',
  foundInSelectedSectors: 'businessTab.foundInSelectedSectors',
};

export const BusinessTabTitle = () => (
  <div className='business-title'>
    <h3 className='text'>
      <Translate value={businessTabTranslations.foundInSelectedSectors} />
    </h3>
  </div>
);

export interface BusinessTabProps {
  businessesNumber?: number;
}

class BusinessTab extends React.Component<BusinessTabProps> {
  shouldComponentUpdate({ businessesNumber }: BusinessTabProps) {
    return !Number.isNaN(businessesNumber);
  }

  render() {
    const { businessesNumber } = this.props;

    return (
      <Tab
        className='sidebar__badge--business'
        value={businessesNumber || '-'}
        labelTranslation={businessTabTranslations.businesses}
        iconName='geo-icon-business'
      />
    );
  }
}

export default BusinessTab;

import { pick } from 'lodash';

import store from '../../store';
import { getDemographicGraphData } from '../../selectors/demographic.selector';

const DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT = 35.5;
const DEFAULT_PAGE_NUMBER = 7;
const RIYADH_PAGE_NUMBER = 6;

const DEFAULT_EASTERN_DEMOGRAPHIC_LEGEND_HEIGHTS = {
  '60+': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '50-59': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '40-49': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '30-39': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '20-29': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '10-19': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '0-9': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
};

const DEFAULT_CENTRAL_DEMOGRAPHIC_LEGEND_HEIGHTS = {
  ...DEFAULT_EASTERN_DEMOGRAPHIC_LEGEND_HEIGHTS,
  '80+': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '70-79': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
  '60-69': DEFAULT_DEMOGRAPHIC_LEGEND_HEIGHT,
};

const getPageNumber = (chosenRegion, areas) => {
  const isLessPages = chosenRegion === 'riyadh-region' || areas[0].length > 15;

  return isLessPages ? RIYADH_PAGE_NUMBER : DEFAULT_PAGE_NUMBER;
};

const getDefaultDemographicLegendHeights = (chosenRegion) => {
  switch (chosenRegion) {
    case 'easter-region':
      return DEFAULT_EASTERN_DEMOGRAPHIC_LEGEND_HEIGHTS;
    case 'riyadh-region':
    default:
      return DEFAULT_CENTRAL_DEMOGRAPHIC_LEGEND_HEIGHTS;
  }
};

const getChartsData = () => {
  const state = store.getState();
  const { groups } = state.map;

  const updatedDemographicGraphData = getDemographicGraphData(state);

  const {
    business,
    sector,
    demographic: {
      demographicData,
      demographicDefaultData,
      demographicLegendHeights,
    },
    map: {
      breadcrumbItems,
      breadcrumbsInSidebar,
      chosenRegion,
      areas,
      areaShapes,
      spendingData,
    },
  } = state;

  const pointsOfInterestStoreData = {
    business,
    sector,
    map: {
      breadcrumbItems,
      breadcrumbsInSidebar,
    },
  };

  const demographicStoreData = {
    demographic: {
      demographicData,
      demographicDefaultData,
      demographicLegendHeights,
      demographicGraphData: updatedDemographicGraphData,
    },
    groups,
    map: {
      breadcrumbItems,
      breadcrumbsInSidebar,
      chosenRegion,
      areas,
      areaShapes: pick(areaShapes, ['id', 'name', 'type']),
      spendingData,
    },
  };

  const pages = getPageNumber(chosenRegion, areas);

  if (Object.keys(demographicLegendHeights).length === 0) {
    demographicStoreData.demographic.demographicLegendHeights =
      getDefaultDemographicLegendHeights(chosenRegion);
  }

  return {
    pages,
    chosenRegion,
    data: {
      pointsOfInterestStoreData,
      demographicStoreData,
    },
  };
};

export default getChartsData;

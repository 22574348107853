import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import AutocompleteItem from './AutocompleteItemComponent';

const AutocompleteList = ({
  elements,
  activeIndex,
  noResults,
  noResultsTranslation,
  onClickItem,
  darkVersion = false,
}) =>
  elements.length > 0 && (
    <div className={classNames('autocomplete__list', { dark: darkVersion })}>
      {elements.map((element, index) => (
        <AutocompleteItem
          // eslint-disable-next-line
          key={index}
          index={index}
          activeIndex={activeIndex}
          element={element}
          noResults={noResults}
          noResultsTranslation={noResultsTranslation}
          onClickItem={onClickItem}
        />
      ))}
    </div>
  );

AutocompleteList.propTypes = {
  noResults: PropTypes.bool.isRequired,
  noResultsTranslation: PropTypes.string,
  activeIndex: PropTypes.number.isRequired,
  elements: PropTypes.array,
  onClickItem: PropTypes.func,
  darkVersion: PropTypes.bool,
};

export default AutocompleteList;

import React from 'react';
import classNames from 'classnames';

import LinkWithPersistSearch from '../../../../components/LinkWithPersistSearch/LinkWithPersistSearch';
import arrowIconSrc from '../../../../assets/arrow.svg';

import './SidebarItem.scss';

interface SidebarItemProps {
  children: JSX.Element;
  onboardingStep: string;
  pathname: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
}

const SidebarItem = ({
  children,
  onboardingStep,
  pathname,
  onClick = () => {},
  isActive = false,
  disabled = false,
  className = '',
}: SidebarItemProps) => (
  <div
    id={onboardingStep && `onboarding-step-${onboardingStep}`}
    className={classNames(
      'sidebar__item-wrapper',
      'sidebar__item-wrapper--separated',
      {
        'sidebar__item-wrapper--active': isActive,
        'sidebar__item-wrapper--disabled': disabled,
      },
      className,
    )}
  >
    <LinkWithPersistSearch
      to={{ pathname }}
      onClick={onClick}
      className='SidebarItem sidebar__badge'
    >
      <div className='SidebarItem__icon-container'>
        <img src={arrowIconSrc} aria-hidden='true' alt='' />
      </div>
      <div className='SidebarItem__content'>{children}</div>
    </LinkWithPersistSearch>
  </div>
);

export default SidebarItem;

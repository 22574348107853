export const getClusterQuery = `
  query getClusterQuery (
    $minLat: Float!,
    $maxLat: Float!,
    $minLng: Float!,
    $maxLng: Float!,
    $clusterType: String!,
    $areas: [String]!,
  ) {
    businesses {
      aggregationByArea(
        locationBoundingBox: {
          minimalLatitude: $minLat
          maximalLatitude: $maxLat
          minimalLongitude: $minLng
          maximalLongitude: $maxLng
        }
        areaAggregation: {
          areaClusterTypes: [$clusterType]
        }
      ) {
        areaBuckets {
          area {
            id
            location {
              latitude
              longitude
            }
          }
          size
        }
      }
      size(
        areas: $areas
      )
    }
  }
`;

export const getClusterWithTagsQuery = `
  query getClusterWithTagsQuery (
    $minLat: Float!,
    $maxLat: Float!,
    $minLng: Float!,
    $maxLng: Float!,
    $clusterType: String!,
    $areas: [String]!
    $isics: [String]!
  ) {
    businesses {
      aggregationByAreaAndIsic(
        locationBoundingBox: {
          minimalLatitude: $minLat
          maximalLatitude: $maxLat
          minimalLongitude: $minLng
          maximalLongitude: $maxLng
        }
        areaAggregation: {
          areaClusterTypes: [$clusterType]
        },
        isics: $isics
      ) {
        areaBuckets {
          area {
            id
            location {
              latitude
              longitude
            }
          }
          isicBuckets {
            size
            isic {
              id
              root {
                id
              }
            }
          }
        size
        }
      }

      size(
        areas: $areas,
        isics: $isics,
      )
    }
  }
`;

const getIsicBuckets = `
  isicBuckets {
    size
    isic {
      id
      root {
        id
      }
    }
  }
`;

export const getGeoHashClusters = ({ withTags } = {}) => `
  query getGeoHashClusters (
    $minLat: Float!
    $maxLat: Float!
    $minLng: Float!
    $maxLng: Float!
    $areas: [String]!
    ${withTags ? '$isics: [String]!' : ''}
    $precision: Int!
  ) {
    businesses {
      otherClusters: aggregationByGeohashGrid${withTags ? 'AndIsic' : ''}(
        locationBoundingBox: {
          minimalLatitude: $minLat
          maximalLatitude: $maxLat
          minimalLongitude: $minLng
          maximalLongitude: $maxLng
        }
        excludedAreas: $areas
        precision: $precision
        ${withTags ? 'isics: $isics' : ''}
      ) {
       geohashGridBuckets {
          id: geohash
          location {
            latitude
            longitude
          }
          ${withTags ? getIsicBuckets : ''}
          size
        }
      }
      selectedClusters: aggregationByGeohashGrid${withTags ? 'AndIsic' : ''}(
        areas: $areas
        locationBoundingBox: {
            minimalLatitude: $minLat
            maximalLatitude: $maxLat
            minimalLongitude: $minLng
            maximalLongitude: $maxLng
        }
        ${withTags ? 'isics: $isics' : ''}
        precision: $precision
      ) {
        geohashGridBuckets {
          id: geohash
          location {
            latitude
            longitude
          }
          ${withTags ? getIsicBuckets : ''}
          size
        }
      }
      size(
        areas: $areas
        ${withTags ? 'isics: $isics' : ''}
      )
    }
  }
`;

import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import {
  setMapZoom,
  setModalMessage,
  setUserPosition,
  toggleModalVisibility,
} from '../actions/MapActions';
import {
  getChosenRegion,
  getMapModal,
  getMapOptions,
  isRegionLimitationEnabled,
} from '../../../selectors/map.selector';
import MapExtensions from '../components/MapExtensions';
import GoogleApi from '../../../store/globalReducers/Google/GoogleApi';

const actions = {
  toggleModalVisibility,
  setModalMessage,
  setUserPosition,
  setMapZoom,
};

const mapStateToProps = (state) => ({
  map: GoogleApi().map,
  google: GoogleApi().google,
  mapOptions: getMapOptions(state),
  modal: getMapModal(state),
  isRegionLimitationEnabled: isRegionLimitationEnabled(state),
  chosenRegion: getChosenRegion(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapExtensions);

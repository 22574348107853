import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

const AutocompleteItemContent = ({
  noResults,
  noResultsTranslation,
  element,
}) => {
  if (noResults) {
    return (
      <span className='autocomplete__list-item-main'>
        <Translate value={noResultsTranslation} />
      </span>
    );
  }

  const { mainText, secondaryText } = element;

  return (
    <div className='autocomplete__list-item-wrapper'>
      <div className='autocomplete__list-item-main-text'>
        <span className='autocomplete__list-item-main'>{mainText}</span>
        {secondaryText && (
          <span className='autocomplete__list-item-secondary'>
            &nbsp;
            {secondaryText}
          </span>
        )}
      </div>
    </div>
  );
};

AutocompleteItemContent.propTypes = {
  noResults: PropTypes.bool.isRequired,
  noResultsTranslation: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired,
};

export default AutocompleteItemContent;

import React from 'react';
import { Translate } from 'react-redux-i18n';

import maintenanceIcon from '../../assets/maintenance_page/maintenance-icon.svg';
import zaddLogo from '../../assets/maintenance_page/zadd-logo-white.svg';
import ninetenthsLogo from '../../assets/maintenance_page/910thsLogo.svg';

import './MaintenancePage.scss';
import CONFIG from '../../config/config';

const MaintenancePage = () => (
  <div className='maintenanceContainer'>
    <img className='zaddLogo' src={zaddLogo} alt='' />
    <img className='maintenanceIcon' src={maintenanceIcon} alt='' />
    <h1 className='header'>
      <Translate value='maintenancePage.header' />
    </h1>
    <p className='description'>
      <Translate value='maintenancePage.description' />
    </p>
    <p className='callToAction'>
      <Translate value='maintenancePage.callToAction' />
    </p>
    <a href={CONFIG.ninetenthsUrl} target='_blank' rel='noreferrer'>
      <img className='ninetenthsLogo' src={ninetenthsLogo} alt='' />
    </a>
  </div>
);

export default MaintenancePage;

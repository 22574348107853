const sectors = {
  sectorsHeader: 'يرجى اختيار القطاعات الرئيسية أو الفرعية',
  sectorTitle: 'قطاعات رئيسية',
  subsectorTitle: 'قطاعات فرعية',
  tagsRemaining: 'المتبقي: {{number}}',
  searchPlaceholder: 'نوع القطاع الرئيسي/ القطاع الفرعي',
  noSectorsFoundMessage: 'لا توجد نتائج تتوافق مع بحثك في {{districtName}}',
  outsideOfDistrictNoSectorsFoundMessage: 'لا توجد نتائج تتوافق مع بحثك',
};

export default sectors;

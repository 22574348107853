import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';

import BarItemList from './BarItemList';
import AxisY from './AxisY';
import AxisX from './AxisX';

class HorizontalBarChart extends React.PureComponent {
  render() {
    const {
      items,
      guides,
      barChartDataType,
      contentRect,
      measureRef,
      enableAnimation,
    } = this.props;

    const width = contentRect.bounds.width || 0;

    const sizes = items.reduce((aggregated, sector) => {
      const subsectors = sector.children;

      const subsectorSizes = [];

      if (subsectors && subsectors.items) {
        subsectors.items.reduce((subsectorSizeElements, subsector) => {
          const { size } = subsector;

          subsectorSizeElements.push(size);

          return subsectorSizeElements;
        }, subsectorSizes);
      }

      return aggregated.concat([...subsectorSizes, sector.size]);
    }, []);

    const maxSize = items.length === 0 ? 0 : Math.max(...sizes);

    const guideNumberOnAxis = guides + 1;

    const scaleX = scaleLinear()
      .range([0, width])
      .domain([0, maxSize])
      .nice(guideNumberOnAxis);

    const [, scaleMax] = scaleX.domain();

    const ticks =
      scaleMax > guideNumberOnAxis
        ? scaleX.ticks(guideNumberOnAxis)
        : scaleX.domain();

    const pointsOnAxis = ticks
      .map(scaleX.tickFormat(guideNumberOnAxis, 'd'))
      .filter((tick, index, arr) => arr.indexOf(tick) === index)
      .map((tick) => ({ position: scaleX(+tick), value: Number(tick) }));

    return (
      <div ref={measureRef} className='chart__container'>
        <div className='chart__content'>
          <BarItemList
            width={width}
            guides={guides}
            scaleX={scaleX}
            items={items}
            barChartDataType={barChartDataType}
            enableAnimation={enableAnimation}
          />
          <AxisY points={pointsOnAxis} />
          <AxisX points={pointsOnAxis} />
        </div>
      </div>
    );
  }
}

HorizontalBarChart.propTypes = {
  guides: PropTypes.number.isRequired,
  barChartDataType: PropTypes.string,
  contentRect: PropTypes.object.isRequired,
  measureRef: PropTypes.func.isRequired,
  items: PropTypes.array,
  enableAnimation: PropTypes.bool,
};

export default HorizontalBarChart;

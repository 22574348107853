import React from 'react';
import ReactDOM from 'react-dom';

import setInfoWindowOnMap from '../../actions/setInfoWindowOnMapAction';
import redrawCurrentMarkers from '../../actions/redrawCurrentMarkersAction';
import GoogleServices from '../../../../store/globalReducers/Google/GoogleServices';

import { Colors } from '../../../../helpers/globals/Constans.ts';
import { isAdmin } from '../../../../helpers/UserHelper';
import ClusterNumberValueLabel from '../../../../components/Map/ClusterNumberValueLabel/ClusterNumberValueLabel';

import './marker.scss';
import { getBusinessMarkerBackground, updateMarkerView } from './utils';

let drawIterator = 0;

/* eslint-disable prefer-destructuring, no-multi-assign */
function MarkerConstructor(googleMaps) {
  function Marker(
    coords,
    map,
    color,
    isCluster,
    clusterOptions,
    tags,
    numberOfMarkersToDraw,
    businessId,
    store,
  ) {
    this.coords = coords;
    this.businessId = businessId;

    this.isCluster = isCluster;
    this.clusterOptions = clusterOptions;
    this.map = map;
    this.tags = tags;
    this.numberOfMarkersToDraw = numberOfMarkersToDraw;
    this.store = store;

    this.setMap(map);
    this.color = color;
  }

  Marker.prototype = new googleMaps.OverlayView();

  Marker.prototype.draw = function updatedDraw() {
    let businessMarker = this.businessMarker;
    const clusterOptions = this.clusterOptions;
    let borderColor = Colors.blue.value;

    if (!this.businessMarker) {
      const mapTypeId = this.map.getMapTypeId();

      const businessMarkerClassName = this.isCluster ? 'cluster' : 'marker';

      businessMarker = this.businessMarker = document.createElement('div');
      businessMarker.tabIndex = 0;
      businessMarker.id = this.businessId;
      businessMarker.className = `${businessMarkerClassName} ${mapTypeId}`;

      // sometimes the cluster size is undefined, quick fix for rendering NaN in the marker
      if (this.isCluster) {
        ReactDOM.render(
          <ClusterNumberValueLabel
            clusterSize={clusterOptions.size}
            number={clusterOptions.label}
          />,
          businessMarker,
        );

        businessMarker = updateMarkerView(
          businessMarker,
          clusterOptions.size,
          clusterOptions.tagsLength,
          clusterOptions.markersIndex,
        );
      }

      if (this.color) {
        const { ROADMAP } = googleMaps.MapTypeId;

        const isRoadmapType = mapTypeId === ROADMAP;

        const { isCluster, color } = this;

        businessMarker.style.backgroundColor = getBusinessMarkerBackground(
          isCluster,
          isRoadmapType,
          color,
        );
      }

      if (!this.isCluster) {
        businessMarker.addEventListener('click', (event) => {
          event.stopPropagation();
          const state = this.store.getState();
          const InfoWindow = GoogleServices.infoWindowConstructor;
          const infoWindowOnMap = state.map.infoWindowOnMap;
          const infoWindowPending = state.map.infoWindowPending;

          if (this.color) {
            borderColor = this.color.value;
          }

          if (infoWindowPending || !InfoWindow) return;

          if (infoWindowOnMap && infoWindowOnMap.id === this.businessId) {
            this.store.dispatch(setInfoWindowOnMap(null));

            return;
          }

          const infoWindowOptions = {
            coords: this.coords,
            isAdmin: isAdmin(state),
          };

          const infoWindowInstance = new InfoWindow(
            this.map,
            infoWindowOptions,
            this.businessId,
            borderColor,
            this.store,
          );

          this.store.dispatch(setInfoWindowOnMap(infoWindowInstance));
        });
      }

      if (this.isCluster) {
        businessMarker.addEventListener('click', (event) => {
          event.stopPropagation();
          this.map.setZoom(this.map.getZoom() + 1);
          this.map.panTo(this.coords);
        });
      }

      const panes = this.getPanes();
      panes.overlayImage.appendChild(businessMarker);
    }

    const convertLatLangIntoPixel = this.getProjection().fromLatLngToDivPixel(
      this.coords,
    );

    if (convertLatLangIntoPixel) {
      businessMarker.style.left = `${convertLatLangIntoPixel.x}px`;
      businessMarker.style.top = `${convertLatLangIntoPixel.y}px`;
    }

    drawIterator += 1;

    // Run action when every marker will be drawn on the map
    if (drawIterator === this.numberOfMarkersToDraw) {
      drawIterator = 0;

      this.store.dispatch(redrawCurrentMarkers());
    }
  };

  Marker.prototype.setColor = function setColor(color) {
    this.color = color;
  };

  return Marker;
}

export default MarkerConstructor;

import React from 'react';
import PropTypes from 'prop-types';

import { isMobileDevice } from '../../../helpers/ResponsiveHelper';

import Tooltip from '../Tooltip';

import builtinPlacements from './builtinPlacements';
import TooltipContent from './TooltipContent';
import './BreadcrumbsTooltip.scss';

class BreadcrumbsTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate({ breadcrumb: prevBreadcrumb }) {
    const { breadcrumb } = this.props;
    const breadcrumbUpdated = breadcrumb !== prevBreadcrumb;

    if (breadcrumbUpdated && breadcrumb !== 'breadcrumbs.selectDistrict') {
      this.setState({ isOpen: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  toggleVisibility = () => {
    const { breadcrumb } = this.props;
    const { isOpen } = this.state;
    const shouldShowTooltip =
      breadcrumb === 'breadcrumbs.selectDistrict' ||
      breadcrumb === 'breadcrumbs.selectGovernorate';

    if (!shouldShowTooltip) return;

    if (!isOpen) {
      this.timer = setTimeout(() => this.setState({ isOpen: false }), 10000);
    } else if (this.timer) {
      clearTimeout(this.timer);
    }

    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { children, breadcrumb: breadcrumbLabel, ...restProps } = this.props;
    const { isOpen } = this.state;
    const rightPlacementName =
      window.innerHeight <= 900 ? 'breadcrumbsLightRight' : 'breadcrumbsRight';

    return (
      <Tooltip
        overlay={
          <TooltipContent
            closeTooltip={this.toggleVisibility}
            breadcrumbLabel={breadcrumbLabel}
          />
        }
        popupVisible={isOpen}
        onVisibleChange={this.toggleVisibility}
        trigger={['click']}
        placement={
          isMobileDevice() ? 'breadcrumbsBottomRight' : rightPlacementName
        }
        customPlacements={builtinPlacements}
        overlayClassName='breadcrumbs__tooltip'
        destroyPopupOnHide
        // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
        {...restProps}
      >
        {children}
      </Tooltip>
    );
  }
}

BreadcrumbsTooltip.propTypes = {
  breadcrumb: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BreadcrumbsTooltip;

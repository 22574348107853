const saveModal = {
  modalHeader: 'Get sharable link',
  modalMessage:
    'Copy link to save filters for the future or to share with your colleagues.',
  inactiveButtonMessage: 'Copy link',
  activeButtonMessage: 'Link copied',
  footerMessage:
    'Now you can paste a link and add it to your bookmarks or send directly to your coworkers.',
};

export default saveModal;

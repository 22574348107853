import React from 'react';
import PropTypes from 'prop-types';

import './RenderPdfTooltip.scss';

const RenderPdfTooltip = ({ children }) => (
  <div className='render-pdf-tooltip'>{children}</div>
);

export default RenderPdfTooltip;

RenderPdfTooltip.propTypes = {
  children: PropTypes.object,
};

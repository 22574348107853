import { get, mapValues } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { TableConfig, ListItem, Sector } from './types';

export const objectsListToTable = <T extends ListItem>(
  list: T[],
  tableConfig: TableConfig,
) => {
  if (list.length === 0) {
    return null;
  }

  const { columns, name } = tableConfig;

  const header = columns.map(({ nameTranslateKey }) =>
    I18n.t(nameTranslateKey),
  );

  const rawList = list.map((obj) =>
    columns.map(({ valuePath }) => get(obj, valuePath)),
  );

  return { name, data: [header, ...rawList] };
};

export const normalizeGroups = <K, T extends Record<string, K>>(
  groups: T,
  field: keyof K,
) => mapValues(groups, (value) => value[field]);

type ItemRecord<Item> = Record<string, Item>;

export const objectToFlatList = <Item>(data: ItemRecord<Item>) =>
  Object.keys(data).map((key) => ({ key, ...data[key] }));

export const flatAndMerge = <Item, T extends Record<string, ItemRecord<Item>>>(
  data: T,
  fields: (keyof T)[],
) => fields.flatMap((field) => objectToFlatList(data[field]));

export const mergeAndFlatGroups = <Item>(groups: ItemRecord<Item[]>) =>
  Object.keys(groups).flatMap((key) =>
    groups[key].map((item) => ({ key, ...item })),
  );

export const mapSector = (sector: Sector): Sector | Sector[] => {
  if (!sector.children?.items) {
    return sector;
  }

  return sector.children.items.flatMap(mapSector);
};

import React, { useState, useCallback } from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import PlainTooltipContent from '../../Tooltip/PlainTooltip/PlainTooltipContent';
import { isMobileDevice } from '../../../helpers/ResponsiveHelper';
import builtinPlacements from '../../Tooltip/BreadcrumbsTooltip/builtinPlacements';
import EasternRegionIndicator from './EasternRegionIndicator';
import './EasternRegionTooltip.scss';

const easternRegionTooltip = {
  label: 'app.easternRegionTooltip',
};

const EasternRegionTooltip = () => {
  const [showEasternRegionTooltip, setShowEasternRegionTooltip] =
    useState(false);
  const toggleShowEasternRegionTooltip = useCallback(
    () => setShowEasternRegionTooltip(!showEasternRegionTooltip),
    [showEasternRegionTooltip],
  );

  return (
    <Tooltip
      overlay={
        <PlainTooltipContent
          closeTooltip={toggleShowEasternRegionTooltip}
          breadcrumbLabel={easternRegionTooltip.label}
        />
      }
      popupVisible={showEasternRegionTooltip}
      onPopupVisibleChange={toggleShowEasternRegionTooltip}
      placement={isMobileDevice() ? 'easternBottomRight' : 'easternRight'}
      customPlacements={builtinPlacements}
      overlayClassName='breadcrumbs__tooltip eastern__tooltip'
      destroyPopupOnHide
      trigger={['click']}
    >
      <EasternRegionIndicator
        toggleShowEasternRegionTooltip={toggleShowEasternRegionTooltip}
      />
    </Tooltip>
  );
};

export default EasternRegionTooltip;

import { useEffect } from 'react';
import { ApiPaths } from '../../helpers/globals/Constans';
import { DownloadStatusType } from './types.d';
import successIcon from '../../assets/download/success.svg';
import errorIcon from '../../assets/download/warning.svg';

const triggerDownload = (url: string, fileName: string, isPdf: boolean) => {
  let link: HTMLAnchorElement | null = document.createElement('a');

  if (!isPdf) {
    link.setAttribute('download', fileName);
  }

  link.href = url;
  document.body.append(link);
  link.click();
  link.remove();
  link = null;
};

export const downloadFile = (
  responseData: BlobPart,
  type: string,
  fileName: string,
): void => {
  const blob = new Blob([responseData], { type });
  const downloadUrl = URL.createObjectURL(blob);
  triggerDownload(downloadUrl, fileName, false);
};

export const downloadPdf = (id: string) => {
  const url = `${ApiPaths.DOWNLOAD}/${id}`;
  triggerDownload(url, id, true);
};

export const downloadStatuses: Record<DownloadStatusType, DownloadStatusType> =
  {
    DOWNLOADING: 'DOWNLOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    IDLE: 'IDLE',
  };

// on IOS icons have been not loaded
export const usePreloadStatusIconsForIOS = () => {
  useEffect(() => {
    new Image().src = successIcon;
    new Image().src = errorIcon;
  }, []);
};

import React from 'react';
import { debounce } from 'lodash';

import './DownloadModalButton.scss';

const preventDoubleCLickDebounceTime = 500;

interface DownloadModalButtonProps {
  id: string;
  buttonText: string;
  buttonIcon: string;
  onClick: () => void;
}

const DownloadModalButton = ({
  id,
  buttonText,
  buttonIcon,
  onClick,
}: DownloadModalButtonProps) => (
  <button
    type='button'
    id={id}
    className='download-modal-button'
    onClick={debounce(onClick, preventDoubleCLickDebounceTime, {
      leading: true,
      trailing: false,
    })}
  >
    <span className='download-modal-button__text'>{buttonText}</span>
    <img
      className='download-modal-button__icon'
      src={buttonIcon}
      alt={buttonIcon}
    />
  </button>
);

export default DownloadModalButton;

import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'react-redux-i18n';
import { formatNumberValue } from '../../../../components/FormattedValue/FormattedValue';
import { prepareNumberShortcutLabel } from '../../../../components/ShortcutValueLabel/ShortcutValueLabel';
import incomeChart from '../../../../translations/ar/translations/incomeChart';

const Bubble = ({
  bubble,
  radius,
  color,
  showLabel,
  valueSuffix,
  handleBubbleHoverEnd,
  handleBubbleHover,
}) => {
  const valueLabelTranslateKey = prepareNumberShortcutLabel(bubble.income);
  const labelNumberValue = formatNumberValue(bubble.income, 10000);

  const getBubbleLabel = (value) =>
    `${value.toLocaleString('en', { maximumFractionDigits: 1 })} ${
      valueLabelTranslateKey ? I18n.t(valueLabelTranslateKey) : ''
    } ${valueSuffix ? I18n.t(valueSuffix) : incomeChart.currencyRiyal}`;

  return (
    <g>
      <circle
        key={bubble.id}
        onMouseLeave={handleBubbleHoverEnd}
        onMouseEnter={(event) => handleBubbleHover(bubble, event)}
        cy={15}
        cx={bubble.value}
        r={radius}
        fill={color}
      />
      {showLabel && (
        <text textAnchor='middle' fill={color} y={50} x={bubble.value}>
          {getBubbleLabel(labelNumberValue)}
        </text>
      )}
    </g>
  );
};

Bubble.propTypes = {
  bubble: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    income: PropTypes.number,
  }).isRequired,
  handleBubbleHover: PropTypes.func,
  handleBubbleHoverEnd: PropTypes.func,
  radius: PropTypes.number.isRequired,
  color: PropTypes.string,
  showLabel: PropTypes.bool,
  valueSuffix: PropTypes.string,
};

export default Bubble;

import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const demographicTab = {
  billion: 'مليار',
  million: 'مليون',
  thousand: 'ألف',
  people: 'شخص',
  noData: 'بيانات السكان غير متوفرة',
  dataInfo: {
    [riyadhRegionString]: 'المصدر: الهيئة الملكية لمدينة الرياض، 2016',
    [easternRegionString]: 'المصدر: أمانة المنطقة الشرقية، 2020',
  },
};

export default demographicTab;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { Translate } from 'react-redux-i18n';

import Autocomplete from '../../../components/Autocomplete/AutocompleteComponent';

const sectorsTranslations = {
  sectorsHeader: 'sectors.sectorsHeader',
  tagsRemaining: 'sectors.tagsRemaining',
  placeholder: 'sectors.searchPlaceholder',
};

const MAX_REMAINING_SIZE = 3;

export const SectorsList = ({ tags, queryCallback, query, chosenRegion }) => {
  const [shouldResetQuery, setShouldResetQuery] = useState(false);
  useEffect(() => setShouldResetQuery(true), [chosenRegion]);
  const onResetQueryCallback = useCallback(() => setShouldResetQuery(false));

  const remaining = MAX_REMAINING_SIZE - tags.length;

  return (
    <div className='sectors-details__search'>
      <h2 className='heading-4'>
        <Translate value={sectorsTranslations.sectorsHeader} />
      </h2>
      <div className='sectors-details__search-container'>
        <Autocomplete
          defaultOpen
          placeholder={sectorsTranslations.placeholder}
          preventToggle
          onChangeCallback={debounce(500, queryCallback)}
          query={query}
          isSectorSearch
          shouldResetQuery={shouldResetQuery}
          onResetQueryCallback={onResetQueryCallback}
        >
          <Translate
            className='sectors-details__remaining visible--on-desktop'
            value={sectorsTranslations.tagsRemaining}
            number={remaining}
          />
        </Autocomplete>

        <Translate
          className='sectors-details__remaining visible--on-mobile'
          value={sectorsTranslations.tagsRemaining}
          number={remaining}
        />
      </div>
    </div>
  );
};

SectorsList.propTypes = {
  queryCallback: PropTypes.func,
  tags: PropTypes.array,
  query: PropTypes.string,
  chosenRegion: PropTypes.string,
};

export default SectorsList;

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export function getElementByIdWhenRendered(elementId, interval) {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      const element = document.getElementById(elementId);
      if (element !== null) {
        resolve(element);
        clearInterval(intervalId);
      }
    }, interval);
  });
}

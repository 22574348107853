import config from './config.json';

const port = process.env.API_NGINX_PORT ? `:${process.env.API_NGINX_PORT}` : '';
const url = process.env.API_URL ? `${process.env.API_URL}` : `${config.api}`;
const a2iLink = process.env.A2I_URL
  ? `${process.env.A2I_URL}`
  : `${config.a2iLink}`;
const sentryEnvironment = process.env.SENTRY_ENVIRONMENT
  ? `${process.env.SENTRY_ENVIRONMENT}`
  : `${config.sentryEnvironment}`;
const sentryDsn = process.env.SENTRY_DSN
  ? `${process.env.SENTRY_DSN}`
  : `${config.sentryDsn}`;
const googleMapsKey = process.env.GOOGLE_KEY
  ? `${process.env.GOOGLE_KEY}`
  : `${config.GAPI_KEY}`;
const ninetenthsUrl = process.env.NINETENTHS_URL
  ? `${process.env.NINETENTHS_URL}`
  : `${config.ninetenthsUrl}`;

const isHotjarEnabled = process.env.IS_HOTJAR_ENABLED
  ? !!Number(process.env.IS_HOTJAR_ENABLED)
  : config.hotjar.enabled;
const hjid = process.env.HJID ? `${process.env.HJID}` : `${config.hotjar.hjid}`;
const hjsv = process.env.HJSV ? `${process.env.HJSV}` : `${config.hotjar.hjsv}`;

const isMaintenanceMode = process.env.IS_MAINTENANCE_MODE
  ? !!Number(process.env.IS_MAINTENANCE_MODE)
  : config.isMaintenanceMode;

const CONFIG = {
  dev: config.dev,
  HMR: config.HMR,
  debugTree: config.debugTree,
  api: `${url}${port}`,
  language: config.appLanguage.trim(),
  GoogleMapsKey: googleMapsKey,
  enableShapes: config.enableShapes,
  enableReduxLogger: config.enableReduxLogger,
  locales: config.locales,
  zeroAreaCase: config.zeroAreaCase,
  isRegionLimitationEnabled: config.isRegionLimitationEnabled,
  hotjar: config.hotjar
    ? {
        enabled: isHotjarEnabled,
        hjid,
        hjsv,
      }
    : {},
  searchOptions: config.searchOptions,
  a2iLink,
  sentryDsn,
  sentryEnvironment,
  isMaintenanceMode,
  ninetenthsUrl,
};

export default CONFIG;

const errorMessage = {
  limitForSelectedSectors:
    'بإمكانك تحديد 3 خيارات فقط من القطاعات الرئيسية و/ أو القطاعات الفرعية',
  noBusinessesRegistered:
    'لا توجد شركات مسجلة تحت القطاع الرئيسي/ القطاع الفرعي المختار',
  outOfRegion:
    'لم يتم العثور على موقعك في المنطقة المحددة. يرجى تحديد منطقتك من الخيارات في أعلى يسار الشاشة، إن توفرت.',
  geolocationOff:
    'لا يمكننا تحديد موقعك، يرجى السماح للمتصفح بالوصول للموقع أو استخدام بروتوكول HTTPS',
};

export default errorMessage;

import React from 'react';
import PropTypes from 'prop-types';

import RenderPdfTooltip from '../RenderPdfTooltip/RenderPdfTooltip';
import NumberValueLabel from '../../../components/NumberValueLabel';

import './RenderPdfDemographicTooltip.scss';

const RenderPdfDemographicTooltip = ({ color, header, number, children }) => (
  <RenderPdfTooltip>
    <div
      className={`render-pdf-demographic-tooltip render-pdf-demographic-tooltip--${color}`}
    >
      <h2
        className={`render-pdf-demographic-tooltip__header render-pdf-demographic-tooltip__header--${color}`}
      >
        <span>%</span>
        {header}
      </h2>
      <p className='render-pdf-demographic-tooltip__paragraph'>{children}</p>
      <p className='render-pdf-demographic-tooltip__number'>
        <NumberValueLabel number={number} />
      </p>
    </div>
  </RenderPdfTooltip>
);

export default RenderPdfDemographicTooltip;

RenderPdfDemographicTooltip.propTypes = {
  color: PropTypes.string,
  header: PropTypes.number,
  number: PropTypes.number,
  children: PropTypes.object,
};

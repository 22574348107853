import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { withContentRect } from 'react-measure';

import HorizontalBarChart from '../../Charts/components/HorizontalBarChart';
import Panel from '../../../components/Panel';
import MapLoader from '../../Map/components/LoadingMap/Loader';
import { BusinessChartTypes } from '../../../helpers/globals/Constans.ts';
import NoDataWrapper from '../../../components/NoDataWrapper';
import { StickyScrollWalker } from '../../../components/StickyScrollWalker/StickyScrollWalker';

function getOnlyNumericalBusinessValue(businessChartData, businessesNumber) {
  if (!isUndefined(businessChartData.overwrittenSize)) {
    return businessChartData.overwrittenSize;
  }

  if (!isUndefined(businessChartData.mainSector.size)) {
    return businessChartData.mainSector.size;
  }

  return businessesNumber;
}

function getBusinessChartTypeValueAndParams(type, panelsWithNonZeroValue) {
  let value;
  let params;

  switch (type) {
    case BusinessChartTypes.noSectors:
      value = 'businessChart.theBusinessesIn';
      break;
    case BusinessChartTypes.oneSector:
      value = 'businessChart.description.oneSector.panelTitle';
      params = {
        isicName: panelsWithNonZeroValue[1].translation.title.params.isicName,
      };
      break;
    case BusinessChartTypes.oneSubsector:
      value = 'businessChart.description.oneSubsector.panelTitle';
      params = {
        isicName: panelsWithNonZeroValue[1].translation.title.params.isicName,
      };
      break;
    case BusinessChartTypes.moreThanOneTag:
      value = `businessChart.description.moreThanOneTag.panelTitle.${
        panelsWithNonZeroValue.length === 3 ? 'twoTags' : 'threeTags'
      }`;
      break;
    default:
      value = 'businessChart.theBusinessesIn';
  }

  return {
    value,
    params,
  };
}

const BusinessBarChartComponent = ({
  businessChartData,
  businessesNumber,
  listLoading,
  breadcrumbs,
  displayType,
  enableAnimation,
}) => {
  const { businessList, panelItems, businessChartType } = businessChartData;

  const businessNumberInArea = getOnlyNumericalBusinessValue(
    businessChartData,
    businessesNumber,
  );

  const zeroBusinesses = businessNumberInArea === 0;

  if (listLoading || (!zeroBusinesses && panelItems.length === 1)) {
    return <MapLoader />;
  }

  const panelsWithNonZeroValue =
    businessNumberInArea > 0
      ? panelItems.filter((item) => item.value.params.size !== 0)
      : panelItems;

  const { value, params } = getBusinessChartTypeValueAndParams(
    businessChartType,
    panelsWithNonZeroValue,
  );

  const Chart = withContentRect('bounds')(({ measureRef, contentRect }) => (
    <HorizontalBarChart
      measureRef={measureRef}
      contentRect={contentRect}
      items={businessList}
      barChartDataType={businessChartType}
      guides={3}
      enableAnimation={enableAnimation}
    />
  ));

  if (displayType === 'panel') {
    return (
      <Panel
        breadcrumbs={breadcrumbs}
        panelItems={panelItems}
        value={value}
        params={params}
        pdfSectorTitles
        enableAnimation={enableAnimation}
      />
    );
  }

  if (displayType === 'chart') {
    return <Chart />;
  }

  return (
    <div className='business-section__content'>
      <Panel
        breadcrumbs={breadcrumbs}
        panelItems={panelItems}
        value={value}
        params={params}
        enableAnimation={enableAnimation}
      />
      {zeroBusinesses && <NoDataWrapper text='businessChart.noDataProvided' />}
      <div
        className='chart chart--simple-chart'
        style={{ opacity: zeroBusinesses ? 0.2 : 1 }}
      >
        <Chart />
      </div>
    </div>
  );
};

BusinessBarChartComponent.propTypes = {
  listLoading: PropTypes.bool.isRequired,
  businessesNumber: PropTypes.number.isRequired,
  breadcrumbs: PropTypes.object.isRequired,
  businessChartData: PropTypes.shape({
    businessList: PropTypes.array,
    businessChartType: PropTypes.string,
    overwrittenSize: PropTypes.number,
    mainSector: PropTypes.shape({
      size: PropTypes.number,
    }),
    panelItems: PropTypes.array,
  }),
  displayType: PropTypes.oneOf(['panel', 'chart', 'full']),
  enableAnimation: PropTypes.bool,
};

BusinessBarChartComponent.defaultProps = {
  displayType: 'full',
};

export default StickyScrollWalker(BusinessBarChartComponent, {
  cssClass:
    'business-section__scroll-container fancy-scroll--desktop--business',
});

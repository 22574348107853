import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const mapAutocomplete = {
  searchPlaceholder: {
    [riyadhRegionString]: 'Insert the district name',
    [easternRegionString]: 'Please select a governorate or district',
  },
  noResultsMessage: 'No search results found',
  searchCoordinates: 'Coordinates',
  name: '{{name}}, {{secondaryText}}',
  coordinates: '{{lat}},{{lng}}',
};

export default mapAutocomplete;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';

import FancyScroll from '../../../components/StickyScrollWalker/FancyScroll';

import Sector from './Sector';

const isSectorActive = (sector, activeSectorId, searchPhrase) => {
  if (sector.id === activeSectorId) {
    return true;
  }

  if (searchPhrase === '') {
    return false;
  }

  if (sector.children.items.length !== 0) {
    return true;
  }

  return false;
};

class SectorsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSectorId: '',
    };
  }

  handleCollapseSector = (id) => {
    const { activeSectorId } = this.state;

    const sectorId = id === activeSectorId ? '' : id;

    this.setState({ activeSectorId: sectorId });
  };

  render() {
    const {
      listLoading,
      sectorsList = [],
      errors,
      tags,
      clickedSectorId,
      clearSectorErrors,
      handleToggleSector,
      getSectorListItemHoverColor,
      tooltipPlacement,
      searchPhrase,
      district,
    } = this.props;

    const sectorTranslations = {
      title: 'sectors.sectorTitle',
      tagsRemaining: 'sectors.tagsRemaining',
      noSectorsFoundMessage: 'sectors.noSectorsFoundMessage',
      outsideOfDistrictNoSectorsFoundMessage:
        'sectors.outsideOfDistrictNoSectorsFoundMessage',
    };

    const sectorTitle = I18n.t(sectorTranslations.title);

    if (
      !listLoading &&
      (!sectorsList || (sectorsList && !sectorsList.length))
    ) {
      const noSectorsFoundMessage = district
        ? sectorTranslations.noSectorsFoundMessage
        : sectorTranslations.outsideOfDistrictNoSectorsFoundMessage;

      const districtAreaName = district ? district.area.name : '';

      return (
        <p className='sectors-details__no-results'>
          <Translate
            value={noSectorsFoundMessage}
            sectorTitle={sectorTitle}
            districtName={districtAreaName}
          />
        </p>
      );
    }

    const loader = (
      <div className='loader__container loader__container--lighter'>
        <div className='loader' />
      </div>
    );

    if (errors.length > 0) {
      setTimeout(clearSectorErrors, 3000);
    }

    const { activeSectorId } = this.state;

    return (
      <>
        {listLoading && loader}
        <FancyScroll cssClass='fancy-scroll sectors-details__scroll-container'>
          {sectorsList.map((sector) => (
            <Sector
              searchPhrase={searchPhrase}
              key={sector.id}
              clickedSectorId={clickedSectorId}
              sector={sector}
              isActive={isSectorActive(sector, activeSectorId, searchPhrase)}
              tooltipPlacement={tooltipPlacement}
              handleCollapseSector={this.handleCollapseSector}
              handleToggleSector={handleToggleSector}
              getSectorListItemHoverColor={getSectorListItemHoverColor}
              tags={tags}
            />
          ))}
        </FancyScroll>
      </>
    );
  }
}

SectorsListComponent.propTypes = {
  sectorTranslations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    noSectorsFoundMessage: PropTypes.string.isRequired,
  }),
  getSectorListItemHoverColor: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string.isRequired,
  clickedSectorId: PropTypes.string,
  tags: PropTypes.array,
  errors: PropTypes.array,
  handleToggleSector: PropTypes.func,
  sectorsList: PropTypes.array,
  listLoading: PropTypes.bool,
  clearSectorErrors: PropTypes.func,
  district: PropTypes.shape({
    area: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default SectorsListComponent;

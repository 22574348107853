import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import chevron from '../../../assets/chevron.svg';
import CONFIG from '../../../config/config';

const HeaderSubmenu = ({
  children,
  submenuTitle,
  onClick,
  forwardedId,
  forwardedRef,
  clickedItem,
}) => {
  const clickedItemId = clickedItem && clickedItem.current.id;

  return (
    <div id={forwardedId} ref={forwardedRef} className='header-menu-position'>
      <a
        href={CONFIG.a2iLink}
        className='header-toggle-link'
        onClick={(event) => onClick(event, forwardedRef)}
      >
        <Translate value={submenuTitle} />
        <img src={chevron} alt='' />
      </a>

      <div
        className={classNames('header-submenu', {
          active: forwardedId === clickedItemId,
        })}
      >
        {children}
      </div>
    </div>
  );
};

HeaderSubmenu.propTypes = {
  children: PropTypes.node,
  submenuTitle: PropTypes.string,
  onClick: PropTypes.func,
  forwardedId: PropTypes.string,
  forwardedRef: PropTypes.object,
  clickedItem: PropTypes.object,
};

export default HeaderSubmenu;

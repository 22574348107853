import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import classNames from 'classnames';

import AutocompleteList from './AutocompleteListComponent';
import AutocompleteSearch from './AutocompleteSearchComponent';

const DARK_VERSION = true;

export class AutocompleteComponent extends React.Component {
  constructor(props) {
    super(props);

    this.index = 0;

    this.state = {
      query: '',
      index: this.index,
      focused: props.preventToggle || false,
      clicked: props.preventToggle || props.defaultOpen,
      chosenItem: null,
    };

    if (props.googleCloseListener) {
      this.setGoogleCloseListener();
    }
  }

  componentWillMount() {
    const searchId = uniqueId('search-');
    const { query } = this.props;
    let initialStateObject = { searchId };

    if (query && query.length) {
      initialStateObject = {
        ...initialStateObject,
        query,
      };
    }

    this.setState(initialStateObject);
  }

  componentDidUpdate({ shouldResetQuery: prevShouldResetQuery }) {
    const { shouldResetQuery, onResetQueryCallback } = this.props;
    if (shouldResetQuery && !prevShouldResetQuery) {
      this.resetQuery();
      onResetQueryCallback();
    }
  }

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = () => {
    this.setState({
      focused: false,
    });
  };

  onKeyDown = (e, chosenItem) => {
    const { elements } = this.props;

    const index = {
      min: 0,
      max: 4,
    };

    const buttons = {
      enter: 13,
      arrowUp: 40,
      arrowDown: 38,
    };

    if (!elements.length) {
      if (e.keyCode === buttons.enter && chosenItem !== null) {
        this.onClickItem(chosenItem);
      }

      return;
    }

    const firstItem = document.getElementById(
      `autocomplete-item-${this.index}`,
    );

    // eslint-disable-next-line
    switch (e.keyCode) {
      case buttons.enter:
        if (chosenItem !== null) {
          this.onClickItem(chosenItem);

          return;
        }

        if (firstItem) {
          firstItem.click();
        }

        break;
      case buttons.arrowUp:
        e.preventDefault();
        if (this.index < index.max) {
          this.index += 1;
        } else {
          this.index = index.min;
        }
        break;
      case buttons.arrowDown:
        e.preventDefault();
        if (this.index > index.min) {
          this.index -= 1;
        } else {
          this.index = index.max;
        }
        break;
    }

    this.setState({
      index: this.index,
    });
  };

  onChange = (e) => {
    const { onChangeCallback } = this.props;
    const { value } = e.target;

    this.index = 0;

    this.setState({
      query: value,
      index: this.index,
      chosenItem: null,
    });

    if (onChangeCallback) {
      onChangeCallback(value);
    }
  };

  onClickItem = (element) => {
    const { onClickItemCallback } = this.props;

    onClickItemCallback(element).then((item) => {
      const { name: query } = item;

      this.setState({
        query,
        chosenItem: item,
      });
    });
  };

  setGoogleCloseListener() {
    const { googleMaps, map } = this.props;

    googleMaps.event.addListener(map, 'click', this.closeSearch);
    googleMaps.event.addListener(map, 'drag', this.closeSearch);
  }

  closeSearch = () => {
    const { query } = this.state;

    if (!query.length) {
      this.setState({
        clicked: false,
      });
    }
  };

  resetQuery = (query = '') => {
    const { onChangeCallback } = this.props;

    this.setState({
      query,
      chosenItem: null,
    });

    if (onChangeCallback) {
      onChangeCallback(query);
    }
  };

  toggleSearch = () => {
    const { preventToggle } = this.props;

    if (preventToggle) {
      return;
    }

    const { clicked, searchId } = this.state;

    const isClicked = !clicked;

    this.setState({
      clicked: isClicked,
    });

    const elementToFocus = document.getElementById(searchId);

    if (isClicked && elementToFocus) {
      elementToFocus.focus();
    }
  };

  render() {
    const { index, clicked, focused, query, searchId, chosenItem } = this.state;
    const {
      elements,
      children,
      placeholder,
      noResultsTranslation,
      noResults,
      isSectorSearch,
    } = this.props;

    const classes = classNames('autocomplete', {
      open: clicked,
      focused,
      typed: !!query.length,
      loaded: !!elements.length,
      dark: DARK_VERSION,
    });
    return (
      <div id='onboarding-step-search' className={classes}>
        <AutocompleteSearch
          toggleSearch={this.toggleSearch}
          resetQuery={this.resetQuery}
          query={query}
          chosenItem={chosenItem}
          searchId={searchId}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          placeholder={placeholder}
          isSectorSearch={isSectorSearch}
        >
          {children}
        </AutocompleteSearch>

        <AutocompleteList
          darkVersion={DARK_VERSION}
          noResultsTranslation={noResultsTranslation}
          elements={elements}
          activeIndex={index}
          noResults={noResults}
          onClickItem={this.onClickItem}
        />
      </div>
    );
  }
}

AutocompleteComponent.propTypes = {
  noResults: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  noResultsTranslation: PropTypes.string,
  preventToggle: PropTypes.bool,
  googleCloseListener: PropTypes.bool,
  elements: PropTypes.array,
  onChangeCallback: PropTypes.func,
  onClickItemCallback: PropTypes.func,
  googleMaps: PropTypes.object,
  map: PropTypes.object,
  query: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.object,
  shouldResetQuery: PropTypes.bool,
  onResetQueryCallback: PropTypes.func,
  isSectorSearch: PropTypes.bool,
  // focused: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool])
};

AutocompleteComponent.defaultProps = {
  elements: [],
  noResults: false,
  defaultOpen: true,
  placeholder: 'app.placeholder',
};

export default AutocompleteComponent;

import {
  setChosenRegion,
  setCurrentLocation,
  setMapZoom,
  updateViewport,
} from '../../Map/actions/MapActions';
import { getBusinesses } from '../../Businesses/actions/BusinessActions';
import { clearTags, removeSector } from '../../Sectors/reducers/SectorSlice';

export default {
  updateViewport,
  setCurrentLocation,
  removeSector,
  setMapZoom,
  setChosenRegion,
  clearTags,
  getBusinesses,
};

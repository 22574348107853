import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { createAxisStyles } from '../../../../helpers/BarChartHelper';
import NumberValueLabel from '../../../../components/NumberValueLabel';

const computeAxisElementPosition = (index, last, position, width) => {
  if (index === 0) {
    return 0;
  }

  if (index === last) {
    return position - width;
  }

  return position - width / 2;
};

function createStyles({ position }, contentRect, index, length) {
  const last = length - 1;

  const rtlStyles = ['left', 'right'];

  const { width = 0 } = contentRect.bounds;

  const value = computeAxisElementPosition(index, last, position, width);

  return createAxisStyles(rtlStyles, value);
}

const AxisX = ({ points = [] }) => (
  <div className='axis-x axis-x--shadow sticky paragraph-3--dark-gray'>
    {points.map((point, index) => (
      <Measure
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        bounds
      >
        {({ measureRef, contentRect }) => (
          <div
            className='axis-x__point'
            style={createStyles(point, contentRect, index, points.length)}
            ref={measureRef}
          >
            <NumberValueLabel number={point.value} />
          </div>
        )}
      </Measure>
    ))}
  </div>
);

AxisX.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      width: PropTypes.number,
    }),
  ),
};

export default AxisX;

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

import Button from '../Button';
import SimpleModal from '../SimpleModal/SimpleModal.tsx';

import './SaveModal.scss';

const BUTTON_DEACTIVATION_TIME = 3000;

const saveModalTranslate = {
  modalHeader: 'saveModal.modalHeader',
  modalMessage: 'saveModal.modalMessage',
  inactiveButtonMessage: 'saveModal.inactiveButtonMessage',
  activeButtonMessage: 'saveModal.activeButtonMessage',
  footerMessage: 'saveModal.footerMessage',
};

const addSourceToURL = (url) => {
  const currentURL = new URL(url);

  currentURL.searchParams.set('source', 'modal');

  return currentURL.toString();
};

const SaveModal = ({
  isOpen,
  isCopyingBlocked,
  setIsCopyingBlocked,
  onClose,
  location,
}) => {
  const [inputText, setInputText] = useState('');

  const delayRef = useRef(null);

  const buttonTextTranslation = isCopyingBlocked
    ? saveModalTranslate.activeButtonMessage
    : saveModalTranslate.inactiveButtonMessage;

  const copyInputText = () => {
    clearTimeout(delayRef.current);

    // Safari 13.1: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
    navigator.clipboard.writeText(inputText);

    setIsCopyingBlocked(true);

    delayRef.current = setTimeout(() => {
      setIsCopyingBlocked(false);
    }, BUTTON_DEACTIVATION_TIME);
  };

  useEffect(() => {
    setInputText(addSourceToURL(window.location.href));
  }, [location]);

  useEffect(() => () => clearTimeout(delayRef.current), []);

  return (
    <SimpleModal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <h4 className='SaveModal__header'>
        <Translate value={saveModalTranslate.modalHeader} />
      </h4>
      <Translate
        value={saveModalTranslate.modalMessage}
        className='SaveModal__message'
      />
      <input value={inputText} className='SaveModal__input' readOnly />
      <div className='SaveModal__row'>
        <Button
          className={classNames(
            'SaveModal__button',
            isCopyingBlocked ? 'SaveModal__button--active' : '',
          )}
          modifier='turqoise'
          type='full'
          text={buttonTextTranslation}
          onClick={copyInputText}
        />
        {isCopyingBlocked && (
          <Translate
            value={saveModalTranslate.footerMessage}
            className='SaveModal__footer'
          />
        )}
      </div>
    </SimpleModal>
  );
};

export default withRouter(SaveModal);

SaveModal.propTypes = {
  isOpen: PropTypes.bool,
  isCopyingBlocked: PropTypes.bool,
  setIsCopyingBlocked: PropTypes.func,
  onClose: PropTypes.func,
  location: PropTypes.object,
};

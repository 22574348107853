const map = {
  markers: {
    name: 'الاسم',
    address: 'العنوان',
    subSectorLabel: 'قطاعات فرعية',
  },
  contextMenu: {
    copy: {
      text: 'نسخ معلومات المنشأة',
    },
  },
};

export default map;

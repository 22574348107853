import { MAP_TYPE_ID as MAP_TYPES } from '../../Map/constants/MapConstants.ts';

const ID_COLOR_SEPARATOR = ':';
const SECTOR_KEY = 'sector';
const MAX_SECTORS_IN_URL = 3;
const NUMERIC_MAP_OPTIONS_KEYS = ['zoom', 'centerLat', 'centerLng'];
const MAP_TYPE_ID = 'mapTypeId';
const MAP_PARAMS = [...NUMERIC_MAP_OPTIONS_KEYS, MAP_TYPE_ID];

const getParsedSectors = (params) => {
  const sectors = params.getAll(SECTOR_KEY);
  return sectors
    .map((sector) => sector.split(ID_COLOR_SEPARATOR))
    .splice(0, MAX_SECTORS_IN_URL);
};

const parseNumberValue = (stringValue) => {
  const value = stringValue !== null ? Number(stringValue) : undefined;

  return Number.isNaN(value) ? undefined : value;
};

const parseMapTypeId = (value) =>
  Object.values(MAP_TYPES).includes(value) ? value : undefined;

const getParsedMapOptions = (params) => {
  const parsedNumericMapOptions = NUMERIC_MAP_OPTIONS_KEYS.reduce(
    (options, optionKey) => ({
      ...options,
      [optionKey]: parseNumberValue(params.get(optionKey)),
    }),
    {},
  );

  const parsedMapOptions = {
    ...parsedNumericMapOptions,
    [MAP_TYPE_ID]: parseMapTypeId(params.get(MAP_TYPE_ID)),
  };

  // remove `undefined` values from options
  Object.keys(parsedMapOptions).forEach((optionKey) => {
    if (parsedMapOptions[optionKey] === undefined) {
      delete parsedMapOptions[optionKey];
    }
  });

  return parsedMapOptions;
};

export const parseFilterSearchParamsURL = (currentSearchParams) => {
  const params = new URLSearchParams(currentSearchParams);

  return {
    ...getParsedMapOptions(params),
    sectors: getParsedSectors(params),
  };
};

export const doesFilterSearchParamsURLExist = (currentSearchParams) => {
  const parsedFilterSearchParamsURL =
    parseFilterSearchParamsURL(currentSearchParams);

  return (
    parsedFilterSearchParamsURL.sectors.length > 0 ||
    parsedFilterSearchParamsURL.zoom !== undefined ||
    parsedFilterSearchParamsURL.centerLat !== undefined ||
    parsedFilterSearchParamsURL.centerLng !== undefined ||
    parsedFilterSearchParamsURL.mapTypeId !== undefined
  );
};

export const buildFilterSearchParamsURL = ({ sectors, ...restParams }) => {
  const serializedSectors = sectors.map(
    ([id, color]) => `${id}${ID_COLOR_SEPARATOR}${color}`,
  );

  const params = new URLSearchParams();

  serializedSectors.forEach((sectorValue) => {
    params.append(SECTOR_KEY, sectorValue);
  });

  MAP_PARAMS.forEach((mapOptionKey) => {
    if (restParams[mapOptionKey] !== undefined) {
      params.append(mapOptionKey, restParams[mapOptionKey]);
    }
  });

  return `?${params}`;
};

const footer = {
  navigation: {
    mainPage: 'Main page',
    interactiveMap: 'Interactive map',
    reports: 'Reports',
  },
  pages: {
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy policy',
  },
  initiativeBy: 'Initiative by',
}

export default footer;

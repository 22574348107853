import React, { Component } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import { fireEvent } from '../../helpers/eventHelpers';
import './StickyScrollWalker.scss';
import FancyScroll from './FancyScroll';

const stickySentinelIdBase = 'sticky_sentinel-';

function addSentinels(container, className) {
  return Array.from(container.querySelectorAll('.sticky')).map((el) => {
    const sentinel = document.createElement('div');

    sentinel.classList.add('sticky_sentinel', className);
    sentinel.id = uniqueId(stickySentinelIdBase);

    return el.parentElement.appendChild(sentinel);
  });
}

function observeFooters(container) {
  const sentinels = addSentinels(container, 'sticky_sentinel--bottom');
  const stickyFooterEvent = 'sticky-footer-change';

  const observer = new IntersectionObserver(
    (records) => {
      records.forEach((record) => {
        const stickyTarget =
          record.target.parentElement.querySelector('.sticky');
        const ratio = record.intersectionRatio;

        const isStuck = ratio >= 1;

        fireEvent(isStuck, stickyTarget, container, stickyFooterEvent);
      });
    },
    {
      threshold: [1],
      root: container,
    },
  );

  sentinels.forEach((el) => observer.observe(el));
}

function observeHeaders(container) {
  const stickyHeaderChange = 'sticky-header-change';
  const observer = new IntersectionObserver(
    (records) => {
      records.forEach((record) => {
        const targetInfo = record.boundingClientRect;
        const stickyTarget =
          record.target.parentElement.querySelector('.sticky');
        const rootBoundsInfo = record.rootBounds;

        // Started sticking.
        if (targetInfo.bottom < rootBoundsInfo.top) {
          fireEvent(true, stickyTarget, container, stickyHeaderChange);
        }

        // Stopped sticking.
        if (
          targetInfo.bottom >= rootBoundsInfo.top &&
          targetInfo.bottom < rootBoundsInfo.bottom
        ) {
          fireEvent(false, stickyTarget, container, stickyHeaderChange);
        }
      });
    },
    { threshold: [0], root: container },
  );

  const sentinels = addSentinels(container, 'sticky_sentinel--top');
  sentinels.forEach((el) => observer.observe(el));
}

function notifyWhenStickyChange(container) {
  observeHeaders.bind(this)(container);
  observeFooters.bind(this)(container);
}

function handleStickEvent(e, classname) {
  const { stuck, target } = e.detail;

  if (stuck) {
    target.classList.add(classname);
  } else {
    target.classList.remove(classname);
  }
}

export const StickyScrollWalker = (EnhancedComponent, { cssClass }) =>
  class StickyScrollWalkerComponent extends Component {
    constructor() {
      super();
      this.state = {
        detail: {
          stuck: null,
          target: null,
        },
      };
    }

    componentDidMount() {
      this.mountStickySentinels();
    }

    componentDidUpdate() {
      this.mountStickySentinels();
    }

    componentWillUnmount() {
      const { fancyScroll } = this.refs;

      const container = fancyScroll.parentElement;

      container.removeEventListener(
        'sticky-footer-change',
        handleStickEvent.bind(this),
      );
      container.removeEventListener(
        'sticky-header-change',
        handleStickEvent.bind(this),
      );
    }

    mountStickySentinels() {
      const { fancyScroll } = this.refs;

      if (!fancyScroll) {
        return;
      }

      const container = fancyScroll.parentElement;

      const stickyElements = fancyScroll.querySelectorAll('.sticky');
      const sentinels = fancyScroll.querySelectorAll(
        `[id^=${stickySentinelIdBase}]`,
      );

      if (!sentinels.length && stickyElements.length) {
        notifyWhenStickyChange.bind(this)(container);

        container.addEventListener('sticky-footer-change', (e) => {
          handleStickEvent.bind(this)(e, 'axis-x--hidden-shadow');
        });

        container.addEventListener('sticky-header-change', (e) => {
          handleStickEvent.bind(this)(e, 'reset-margin');
        });
      }
    }

    render() {
      const { detail } = this.state;
      const cssClasses = classNames('fancy-scroll', cssClass);

      return (
        <FancyScroll cssClass={cssClasses}>
          <div ref='fancyScroll' className='sticky-scroll-walker'>
            {/* eslint-disable-next-line react/jsx-props-no-spreading -- HOC */}
            <EnhancedComponent {...this.props} scrolled={detail} />
          </div>
        </FancyScroll>
      );
    }
  };

export default StickyScrollWalker;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import Carousel from 'nuka-carousel';

import PanelItem from './PanelItem';
import { getAreaName } from '../../helpers/BarChartHelper';
import { isArabicDirection } from '../../helpers/AppHelper';
import { getScreenBreakpoint } from '../../helpers/ResponsiveHelper';

class Panel extends React.Component {
  componentDidMount() {
    if (getScreenBreakpoint() === 'xs') {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    }
  }

  render() {
    const {
      breadcrumbs,
      params,
      panelItems,
      value,
      pdfSectorTitles,
      enableAnimation,
    } = this.props;
    const areaName = getAreaName(breadcrumbs);
    const breakpoint = getScreenBreakpoint();

    const newParams = {
      areaName,
      ...params,
    };

    const generatePanelItemComponent = (item, index) => (
      <PanelItem
        key={index}
        index={index}
        params={item.value.params}
        valueType={item.value.type}
        iconContent={item.iconContent}
        title={item.translation.title}
        isicName={item.translation.title.params.isicName}
        pdfSectorTitles={pdfSectorTitles}
        subtitle={item.translation.subtitle}
        changedColor={item.changedColor}
        enableAnimation={enableAnimation}
      />
    );

    const initialCarouselIndex =
      isArabicDirection && breakpoint === 'xs' ? panelItems.length - 1 : 0;

    const renderedPanelItems = panelItems
      .filter((item) => item)
      .map((item, index) => generatePanelItemComponent(item, index));

    const mobilePanelItems = [...renderedPanelItems].reverse();

    return (
      <div className='panel'>
        <div className='heading-4'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading -- x-files */}
          <Translate value={value} {...newParams} />
        </div>

        <div className='panel__items panel__items--desktop start-sm'>
          {renderedPanelItems}
        </div>

        <div className='panel__items panel__items--mobile row nomargin'>
          <Carousel slideIndex={initialCarouselIndex} wrapAround>
            {mobilePanelItems}
          </Carousel>
        </div>
      </div>
    );
  }
}

Panel.propTypes = {
  breadcrumbs: PropTypes.object.isRequired,
  panelItems: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  params: PropTypes.object,
  tags: PropTypes.array,
  pdfSectorTitles: PropTypes.bool,
  enableAnimation: PropTypes.bool,
};

export default Panel;

import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Translate, I18n } from 'react-redux-i18n';

import Tooltip from '../Tooltip/Tooltip';
import Button from '../../Button';
import LinkWithPersistSearch from '../../LinkWithPersistSearch/LinkWithPersistSearch';
import {
  isMobileDevice,
  screenMode,
  isiOS,
} from '../../../helpers/ResponsiveHelper';

import './OnboardingTooltip.scss';
import { scrollToMap } from '../../../helpers/AppHelper';

const TOOTLTIP_PLACEMENTS_BY_STEP = {
  desktop: {
    default: 'right',
    bottom: ['search'],
    rightTop: ['breadcrumbs'],
    top: ['scroll'],
  },
  tablet: {
    default: 'top',
    bottom: ['search'],
  },
  phone: {
    default: 'bottomRight',
    top: ['scroll'],
    topRight: ['demographic', 'download'],
  },
};

const getDeviceType = () => {
  if (isMobileDevice(/xs/)) return 'phone';
  if (isMobileDevice(/sm/)) return 'tablet';
  return 'desktop';
};

const TooltipContent = (props) => {
  const {
    actions,
    tourSteps: { step, stepNumber, maxSteps },
  } = props;
  const isLastStep = stepNumber === maxSteps;
  const tooltipOptions = isLastStep
    ? {
        buttonText: 'onboarding.complete',
        smallText: 'onboarding.restart',
        handleMinorButton: () => {
          scrollToMap();
          actions.resetOnboarding();
        },
      }
    : {
        buttonText: 'onboarding.next',
        smallText: 'onboarding.exit',
        handleMinorButton: () => actions.endOnboarding(),
      };

  const { buttonText, smallText, handleMinorButton } = tooltipOptions;
  const handleMajorButton = () => {
    const scrollValue = getDeviceType() === 'phone' ? 800 : 100;

    if (screenMode === 'mobile' && step === 'sectors')
      window.scroll({
        top: scrollValue,
        behavior: isiOS() ? 'auto' : 'smooth',
      });
    actions.setNextStep();
  };
  const scrollId = step === 'scroll' ? '#mobile-scrolled-section' : '';
  const text = `onboarding.steps.${step}`;
  const { pathname } = browserHistory.getCurrentLocation();

  return (
    <div>
      <p className='step-counter'>{`${maxSteps}/${stepNumber}`}</p>
      <p className='tooltip-content'>
        <Translate value={text} />
      </p>
      <span className='secondary-button' onClick={handleMinorButton}>
        <Translate value={smallText} />
      </span>
      <LinkWithPersistSearch to={{ pathname, hash: scrollId }}>
        <Button text={I18n.t(buttonText)} onClick={handleMajorButton} />
      </LinkWithPersistSearch>
    </div>
  );
};

TooltipContent.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  tourSteps: PropTypes.shape({
    step: PropTypes.string,
    stepNumber: PropTypes.number,
    maxSteps: PropTypes.number,
  }).isRequired,
};

class OnboardingTooltip extends React.Component {
  deviceType = getDeviceType();

  componentDidMount() {
    const { positioning } = this.props;
    const { width, height } = positioning;

    this.setOverlayLayerSize(width, height);
  }

  setOverlayLayerSize = (width, height) => {
    document.documentElement.style.setProperty('--tooltip-width', `${width}px`);
    document.documentElement.style.setProperty(
      '--tooltip-height',
      `${height}px`,
    );
  };

  getPlacement = () => {
    const {
      tourSteps: { step },
    } = this.props;
    const placements = TOOTLTIP_PLACEMENTS_BY_STEP[this.deviceType];
    const defaultPlacement = placements.default;
    const customPlacement = Object.keys(placements)
      .filter((placement) => placements[placement].includes(step))
      .toString();

    return customPlacement || defaultPlacement;
  };

  render() {
    const { positioning, actions, tourSteps } = this.props;

    return (
      <Tooltip
        overlay={
          <TooltipContent
            actions={actions}
            tourSteps={tourSteps}
            scrollPosition={positioning.top}
          />
        }
        popupVisible
        placement={this.getPlacement()}
        overlayClassName='onboarding__tooltip'
      >
        <div className='onboarding-shadow-target' style={positioning} />
      </Tooltip>
    );
  }
}

OnboardingTooltip.propTypes = {
  positioning: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  tourSteps: PropTypes.object.isRequired,
};

export default OnboardingTooltip;

import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { checkTooltipPlacement } from '../../../helpers/AppHelper';
import { Placement } from '../../../helpers/globals/Constans.ts';
import SectorsFilters from './SectorsFilters';
import SectorsList from './SectorsList';

class SectorsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipPlacement: Placement.right,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.prepareTooltipPlacement);
    window.addEventListener('orientationchange', this.prepareTooltipPlacement);

    this.prepareTooltipPlacement();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { tooltipPlacement } = this.state;

    return (
      !isEqual(this.props, nextProps) ||
      tooltipPlacement !== nextState.tooltipPlacement
    );
  }

  componentDidUpdate(prevProps) {
    const {
      sectorSearchPhrase,
      actions: { getSectorLists },
    } = this.props;

    if (
      prevProps.sectorSearchPhrase !== sectorSearchPhrase &&
      sectorSearchPhrase.length
    ) {
      getSectorLists();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.prepareTooltipPlacement);
    window.removeEventListener(
      'orientationchange',
      this.prepareTooltipPlacement,
    );
  }

  prepareTooltipPlacement = () => {
    const tooltipPlacement = checkTooltipPlacement('right', 'topRight');

    this.setState({ tooltipPlacement });
  };

  handleToggleSector = (chosenSector) => {
    const { actions } = this.props;

    if (chosenSector.color) {
      actions.removeSector(chosenSector);
    } else {
      actions.saveSector(chosenSector);
    }
  };

  render() {
    const {
      actions,
      listLoading,
      sectorList,
      tags,
      sectorSearchPhrase,
      errors,
      clickedSector,
      district,
      chosenRegion,
    } = this.props;
    const clickedSectorId =
      clickedSector && clickedSector.id ? clickedSector.id : '';

    const { tooltipPlacement } = this.state;

    return (
      <div className='sectors-details'>
        <div className='sectors-details__container'>
          <SectorsFilters
            queryCallback={actions.filterSectors}
            query={sectorSearchPhrase}
            tags={tags}
            chosenRegion={chosenRegion}
          />
          <div className='sectors-list'>
            <div className='sectors-details__list'>
              <SectorsList
                searchPhrase={sectorSearchPhrase}
                tooltipPlacement={tooltipPlacement}
                handleToggleSector={this.handleToggleSector}
                sectorsList={sectorList}
                errors={errors}
                clickedSectorId={clickedSectorId}
                tags={tags}
                clearSectorErrors={actions.clearSectorErrors}
                getSectorListItemHoverColor={
                  actions.getSectorListItemHoverColor
                }
                listLoading={listLoading}
                district={district}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SectorsDetails.propTypes = {
  actions: PropTypes.shape({
    filterSectors: PropTypes.func,
    getSectorsSizes: PropTypes.func,
    getSectorLists: PropTypes.func,
    clearSectorErrors: PropTypes.func,
    saveSector: PropTypes.func,
    removeSector: PropTypes.func,
    getSectorListItemHoverColor: PropTypes.func,
  }),
  listLoading: PropTypes.bool.isRequired,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      color: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
      root: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    PropTypes.array,
  ]),
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf({
      message: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  clickedSector: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    root: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  sectorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      children: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            size: PropTypes.number,
            type: PropTypes.string,
            root: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  ),
  sectorSearchPhrase: PropTypes.string,
  district: PropTypes.shape({
    area: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  chosenRegion: PropTypes.string,
};

export default SectorsDetails;

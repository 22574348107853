import React from 'react';
import Tab from '../Tab/Tab';

export interface DemographicTabProps {
  populationCount: number;
}

const DemographicTab = ({ populationCount }: DemographicTabProps) => {
  const translation =
    populationCount === 0 ? 'demographicTab.noData' : 'demographicTab.people';

  return (
    <Tab
      className='sidebar__badge--demographic'
      value={populationCount || '-'}
      labelTranslation={translation}
      iconName='geo-icon-saudi'
    />
  );
};

export default DemographicTab;

import React from 'react';
import { isEqual } from 'lodash';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Tooltip from '../../../components/Tooltip/Tooltip';
import { returnTooltipContainer } from '../../../helpers/AppHelper';
import SectorsListItem from './SectorsListItem';
import errorMessages from '../../../translations/ar/translations/errorMessage';

export default class Sector extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const {
      sector,
      tags,
      tooltipPlacement,
      isActive,
      clickedSectorId,
      handleToggleSector,
      handleCollapseSector,
      getSectorListItemHoverColor,
      searchPhrase,
    } = this.props;

    const subsectors = sector.children.items
      .filter((subsector) => isActive || subsector.color)
      .map((subsector) => (
        <Tooltip
          key={subsector.id}
          placement={tooltipPlacement}
          overlay={errorMessages.limitForSelectedSectors}
          overlayClassName='tooltip red'
          getTooltipContainer={returnTooltipContainer}
          popupVisible={clickedSectorId === subsector.id}
        >
          <SectorsListItem
            name={subsector.name}
            size={subsector.size}
            color={subsector.color}
            onClick={() => {
              debounce(500, handleToggleSector(subsector, tags));
            }}
            getSectorListItemHoverColor={getSectorListItemHoverColor}
            isSubsector
          />
        </Tooltip>
      ));

    const hasSubsectorsAfterSearch = sector.children.items.length !== 0;

    return (
      <>
        <div className='sectors-details__grouped-items' id={sector.id}>
          <Tooltip
            placement={tooltipPlacement}
            overlay={errorMessages.limitForSelectedSectors}
            overlayClassName='tooltip red'
            getTooltipContainer={returnTooltipContainer}
            popupVisible={clickedSectorId === sector.id}
          >
            <SectorsListItem
              hasSubsectorsAfterSearch={hasSubsectorsAfterSearch}
              name={sector.name}
              size={sector.size}
              color={sector.color}
              id={sector.id}
              isActive={isActive}
              isDisabled={
                isActive && hasSubsectorsAfterSearch && searchPhrase !== ''
              }
              handleCollapse={() => handleCollapseSector(sector.id)}
              onClick={() => {
                debounce(500, handleToggleSector(sector, tags));
              }}
              getSectorListItemHoverColor={getSectorListItemHoverColor}
            />
          </Tooltip>
          <CSSTransition timeout={1000} in={isActive} classNames='subsectors'>
            {isActive && !hasSubsectorsAfterSearch ? (
              <div />
            ) : (
              <div>{subsectors}</div>
            )}
          </CSSTransition>
        </div>
        <hr className='sectors-details__separator' />
      </>
    );
  }
}

Sector.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleCollapseSector: PropTypes.func.isRequired,
  handleToggleSector: PropTypes.func.isRequired,
  getSectorListItemHoverColor: PropTypes.func.isRequired,
  tooltipPlacement: PropTypes.string.isRequired,
  searchPhrase: PropTypes.string.isRequired,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      color: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
      root: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    PropTypes.array,
  ]),
  clickedSectorId: PropTypes.string.isRequired,
  sector: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    root: PropTypes.shape({
      id: PropTypes.string,
    }),
    children: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

const downloadModal = {
  riyadhHeader: 'تنزيل تقرير مخصص من المنطقة الوسطى',
  easternHeader: 'تنزيل تقرير مخصص من المنطقة الشرقية',
  downloadMessage:
    'أنت على وشك تنزيل التقرير بناءً على عوامل التصفية المحددة حاليًا.',
  downloadCommentHeader: 'ما هي عوامل التصفية؟',
  downloadCommentItemOne: 'الحي المحدد داخل الخريطة',
  downloadCommentItemTwo: 'قطاعات محددة',
  downloadFooter:
    'يؤدي التنزيل بدون عوامل تصفية محددة إلى إنشاء تقرير عام للمنطقة.',
  pendingHeader: 'نحن نعمل على وثيقتك',
  pendingMessage: 'قد يستغرق هذا إلى بضع ثوان. أرجو الإنتظار...',
  successHeader: 'تم بنجاح',
  successMessage: 'لقد قمت بتنزيل الملف بنجاح',
  errorHeader: 'خطأ',
  errorMessage: 'حدث خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.',
  backToMapsButtonText: 'العودة إلى الخرائط',
  cancel: 'إلغاء',
};

export default downloadModal;

import { handleActions } from 'redux-actions';

import { isAnyOf } from '@reduxjs/toolkit';
import { isMobileDevice } from '../../../helpers/ResponsiveHelper';
import { getMinZoomLevel } from '../../../helpers/MapHelper';
import { KSABounds, mapKSACenter } from '../../../helpers/globals/Constans.ts';
import CONFIG from '../../../config/config';

// ------------------------------------
// Constants
// ------------------------------------
import {
  CREATE_PLACES_SERVICE,
  RUN_MAP_EXTENSIONS,
  SAVE_HIGHEST_CLUSTER_LABEL,
  SET_AREA_SHAPE,
  SET_AREAS,
  SET_BREADCRUMB_CONNECTED_DATA,
  SET_BREADCRUMB_ITEMS,
  SET_BREADCRUMBS_IN_SIDEBAR,
  SET_BUSINESSES_ON_MAP,
  SET_CHOSEN_REGION,
  SET_CLUSTER_LEVEL,
  SET_CURRENT_LOCATION,
  SET_INFOWINDOW_CONSTRUCTOR,
  SET_INFOWINDOW_CONTENT_TEXT,
  SET_INFOWINDOW_ON_MAP,
  SET_INFOWINDOW_ON_MAP_PENDING,
  SET_MAP_OPTIONS,
  SET_MAP_RANGE,
  SET_MAP_SEARCH,
  SET_MAP_SHAPE,
  SET_MAP_TYPE,
  SET_MAP_ZOOM,
  SET_MARKER_CONSTRUCTOR,
  SET_MODAL_CONTENT,
  SET_MODAL_VISIBILITY,
  SET_USER,
  SET_USER_AS_VISITED,
  SET_USER_POSITION,
  TOGGLE_SIDEBAR,
  UPDATE_CLUSTER_SHOW_TYPE,
  UPDATE_VIEWPORT,
} from '../constants/MapActionsConstants';
import {
  SAVE_GROUPS,
  SET_DEMOGRAPHIC_SPENDING_DATA,
} from '../../Demographic/constants/DemographicConstants';
import { MAP_TYPE_ID } from '../constants/MapConstants.ts';
import { startListening } from '../../../listenerMiddleware';
import { setBreadcrumbConnectedData } from '../actions/MapActions';
import {
  removeSector,
  saveMultipleSectors,
  saveSector,
} from '../../Sectors/reducers/SectorSlice';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TOGGLE_SIDEBAR]: (state, action) => ({
    ...state,
    openSidebar: action.openSidebar,
  }),
  [SET_MODAL_CONTENT]: (state, action) => ({
    ...state,
    modal: { ...action.payload },
  }),
  [SET_MODAL_VISIBILITY]: (state, action) => ({
    ...state,
    modal: { ...state.modal, ...action.payload },
  }),
  [SET_CURRENT_LOCATION]: (state, action) => ({
    ...state,
    mapOptions: {
      ...state.mapOptions,
      center: {
        lat: action.mapOptions.center.lat,
        lng: action.mapOptions.center.lng,
      },
    },
  }),
  [SET_USER_POSITION]: (state, action) => ({
    ...state,
    userPosition: action.payload,
  }),
  [SET_MAP_ZOOM]: (state, action) => ({
    ...state,
    mapOptions: {
      ...state.mapOptions,
      zoom: action.mapOptions.zoom,
    },
  }),
  [SET_MAP_SHAPE]: (state, action) => ({ ...state, mapShape: action.payload }),
  [SET_MAP_TYPE]: (state, action) => ({
    ...state,
    mapOptions: {
      ...state.mapOptions,
      mapTypeId: action.payload,
    },
  }),
  [SET_CLUSTER_LEVEL]: (state, action) => ({
    ...state,
    clusters: action.clusters,
  }),
  [UPDATE_CLUSTER_SHOW_TYPE]: (state, action) => ({
    ...state,
    clusters: {
      ...state.clusters,
      showType: action.payload,
    },
  }),
  [SAVE_HIGHEST_CLUSTER_LABEL]: (state, action) => ({
    ...state,
    clusters: {
      ...state.clusters,
      highestClusterLabel: action.payload,
    },
  }),
  [SET_MAP_RANGE]: (state, action) => ({ ...state, mapRange: action.mapRange }),
  [SET_BUSINESSES_ON_MAP]: (state, action) => ({
    ...state,
    markers: [...action.payload],
  }),
  [RUN_MAP_EXTENSIONS]: (state, action) => ({
    ...state,
    runMapExtensions: action.payload,
  }),
  [UPDATE_VIEWPORT]: (state, action) => ({
    ...state,
    mapOptions: {
      ...state.mapOptions,
      viewport: action.payload,
      // we introduced this hack to allow MapView component to rerender while shouldComponentUpdate in this component uses deep comparison.
      viewportComparisonFlag: !state.mapOptions.viewportComparisonFlag,
    },
  }),
  [SET_BREADCRUMB_ITEMS]: (state, action) => ({
    ...state,
    breadcrumbItems: { ...action.payload },
  }),
  [SET_BREADCRUMBS_IN_SIDEBAR]: (state, action) => ({
    ...state,
    breadcrumbsInSidebar: action.payload,
  }),
  [SET_AREAS]: (state, action) => ({ ...state, areas: action.payload }),
  [SET_AREA_SHAPE]: (state, action) => ({
    ...state,
    areaShapes: action.payload,
  }),
  [SET_BREADCRUMB_CONNECTED_DATA]: (state, action) => ({
    ...state,
    appDataLevels: action.payload,
  }),
  [CREATE_PLACES_SERVICE]: (state, action) => ({
    ...state,
    placesService: action.payload,
  }),
  [SET_MARKER_CONSTRUCTOR]: (state, action) => ({
    ...state,
    markerConstructor: action.payload,
  }),
  [SET_INFOWINDOW_CONSTRUCTOR]: (state, action) => ({
    ...state,
    infoWindowConstructor: action.payload,
  }),
  [SET_INFOWINDOW_ON_MAP]: (state, action) => ({
    ...state,
    infoWindowOnMap: action.payload,
  }),
  [SET_INFOWINDOW_ON_MAP_PENDING]: (state, action) => ({
    ...state,
    infoWindowPending: action.payload,
  }),
  [SET_INFOWINDOW_CONTENT_TEXT]: (state, action) => ({
    ...state,
    infoWindowContent: action.payload,
  }),
  [SET_MAP_OPTIONS]: (state, action) => ({
    ...state,
    mapOptions: { ...state.mapOptions, ...action.payload },
  }),
  [SET_MAP_SEARCH]: (state, action) => ({
    ...state,
    predictions: action.payload,
  }),
  [SET_USER_AS_VISITED]: (state, action) => ({
    ...state,
    hasUserVisited: action.payload,
  }),
  [SET_USER]: (state, action) => ({
    ...state,
    user: action.payload.user,
  }),
  [SET_CHOSEN_REGION]: (state, action) => ({
    ...state,
    chosenRegion: action.payload,
  }),
  [SAVE_GROUPS]: (state, action) => ({
    ...state,
    groups: action.payload,
  }),
  [SET_DEMOGRAPHIC_SPENDING_DATA]: (state, action) => ({
    ...state,
    spendingData: action.payload,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------

const breadcrumbsInSidebar = {
  firstLine: {
    label: 'breadcrumbs.defaultBreadcrumb.firstLine.label',
    clickable: false,
  },
  secondLine: {
    label: 'breadcrumbs.defaultBreadcrumb.secondLine.label',
    clickable: false,
  },
};

const getInitialUserVisitedValue = () => {
  if (window.localStorage === undefined) {
    return true;
  }

  return window.localStorage.getItem('hasUserVisited') !== null;
};

const initialState = {
  mapOptions: {
    center: !isMobileDevice() ? mapKSACenter.desktop : mapKSACenter.mobile,
    zoom: getMinZoomLevel(),
    minZoom: getMinZoomLevel(),
    gestureHandling: 'none',
    streetViewControl: false,
    zoomControl: false,
    scrollwheel: true,
    viewportComparisonFlag: false,
    mapTypeId: MAP_TYPE_ID.HYBRID,
  },
  clusters: {
    enabled: true,
    areaType: null,
    isDefaultZoomReached: true,
    showType: null,
    businessesInBounds: null,
  },
  areas: {},
  markers: [],
  markerConstructor: null,
  areaShapes: null,
  mapShapes: [],
  mapRange: {
    minLat: KSABounds.desktop.minLat,
    maxLat: KSABounds.desktop.maxLat,
    minLng: KSABounds.desktop.minLng,
    maxLng: KSABounds.desktop.maxLng,
  },
  runMapExtensions: false,
  breadcrumbItems: {},
  breadcrumbsInSidebar,
  modal: {
    isError: false,
    isOpen: false,
    text: null,
  },
  userPosition: null,
  placesService: null,
  infoWindowOnMap: null,
  infoWindowConstructor: null,
  infoWindowPending: false,
  infoWindowContent: null,
  predictions: {
    list: [],
    noResults: false,
  },
  isRegionLimitationEnabled: CONFIG.isRegionLimitationEnabled,
  hasUserVisited: getInitialUserVisitedValue(),
  chosenRegion: null,
  user: {},
  groups: null,
  spendingData: null,
};

export default handleActions(ACTION_HANDLERS, initialState);

startListening({
  matcher: isAnyOf(saveMultipleSectors, saveSector, removeSector),
  effect: (action, { dispatch }) => {
    dispatch(setBreadcrumbConnectedData());
  },
});

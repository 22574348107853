import React from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';

const TooltipContent = ({ closeTooltip, breadcrumbLabel }) => {
  const tooltipText =
    breadcrumbLabel === 'breadcrumbs.selectDistrict'
      ? 'breadcrumbs.tooltipInfoDistrict'
      : 'breadcrumbs.tooltipInfoGovernorate';

  return (
    <div className='breadcrumbs__tooltip-content'>
      <Translate value={tooltipText} />
      <div className='geo-icon-asset-26white__background' />
      <i className='geo-icon-asset-26white' onClick={closeTooltip} />
    </div>
  );
};

TooltipContent.propTypes = {
  closeTooltip: PropTypes.func.isRequired,
  breadcrumbLabel: PropTypes.string.isRequired,
};

export default TooltipContent;

import { browserHistory } from 'react-router';
import {
  parseFilterSearchParamsURL,
  buildFilterSearchParamsURL,
} from '../../modules/Sectors/components/filtersURLSearchParamsUtils';
import { mapSectorWithColor } from '../../modules/Sectors/actions/utils';

export const generateFilterSearchParamsURLBasedOnTags = (tags) => {
  const { search } = browserHistory.getCurrentLocation();

  const parsedFilterSearchParamsURL = parseFilterSearchParamsURL(search);

  const newSectors = tags.map(({ id, color: { name } }) => [id, name]);

  const newParsedFilterSearchParamsURL = {
    ...parsedFilterSearchParamsURL,
    sectors: newSectors,
  };

  return buildFilterSearchParamsURL(newParsedFilterSearchParamsURL);
};

export const getNewTags = (sectors, sectorsFlatMap) =>
  sectors
    .map(([sectorId, color]) => [sectorsFlatMap.get(sectorId), color])
    .filter(([sector]) => sector !== undefined)
    .map(mapSectorWithColor);

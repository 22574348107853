import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const BarLabel = ({ name, isSubsector, additionalClass }) => {
  const label = name.length ? name : 'No name';
  const barLabelClasses = classnames(
    `bar__label paragraph-1${isSubsector ? '--dark-gray' : '--white'}`,
    additionalClass,
  );

  return <div className={barLabelClasses}>{label}</div>;
};

BarLabel.propTypes = {
  name: PropTypes.string,
  isSubsector: PropTypes.bool,
  additionalClass: PropTypes.string,
};

BarLabel.defaultProps = {
  name: '',
  additionalClass: '',
};

export default BarLabel;

import CONFIG from '../../config/config';

const { language } = CONFIG;

export const getMarkersQuery = `query getMarkersQuery (
  $minLat: Float!,
  $maxLat: Float!,
  $minLng: Float!,
  $maxLng: Float!,
  $areas: [String]!
) {
  businesses {
    items(
      locationBoundingBox: {
        minimalLatitude: $minLat
        maximalLatitude: $maxLat
        minimalLongitude: $minLng
        maximalLongitude: $maxLng
      }
      limit: 1000
    ) {
      id
      location {
        latitude
        longitude
      }
      isic {
        id
        name(language: "${language}")
        type
        root {
          id
        }
      }
    }

    size(
      areas: $areas
    )
  }
}`;

export const getMarkersWithTagsQuery = `
  query MarkersWithTagsQuery (
      $minLat: Float!,
      $maxLat: Float!,
      $minLng: Float!,
      $maxLng: Float!,
      $areas: [String]!,
      $isics: [String]!
    ) {
    businesses {
      items(
        locationBoundingBox: {
          minimalLatitude: $minLat,
          maximalLatitude: $maxLat,
          minimalLongitude: $minLng,
          maximalLongitude: $maxLng
        },
        isics: $isics,
        limit: 1000
      ) {
        id
        location {
          latitude
          longitude
        }
        isic {
          id
          name(language: "${language}")
          type
          root {
            id
          }
        }
      }

      size(
        areas: $areas,
        isics: $isics
      )
    }
  }
`;

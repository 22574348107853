/* eslint-disable no-shadow, no-param-reassign, no-underscore-dangle */

// Snippet from https://gist.github.com/auser/1d55aa3897f15d17caf21dc39b85b663

let counter = 0;
const scriptMap = new Map();

export const ScriptCache = ((global) =>
  function ScriptCache(scripts) {
    const Cache = {};

    Cache._onLoad = (key) => (cb) => {
      const stored = scriptMap.get(key);
      if (!stored) {
        return;
      }
      stored.promise.then(() => {
        if (stored.error) {
          cb(stored.error);
        } else {
          cb(null, stored);
        }
      });
    };

    Cache._scriptTag = (key, src) => {
      if (!scriptMap.has(key)) {
        const tag = document.createElement('script');
        const promise = new Promise((resolve, reject) => {
          const body = document.getElementsByTagName('body')[0];

          tag.type = 'text/javascript';
          tag.async = true; // Load in order
          tag.defer = true;

          const cbName = `loaderCB${(counter += 1)}${Date.now()}`;

          const cleanup = () => {
            if (global[cbName] && typeof global[cbName] === 'function') {
              global[cbName] = null;
            }
          };

          const handleResult = (state) => (evt) => {
            const stored = scriptMap.get(key);
            if (state === 'loaded') {
              stored.resolved = true;
              resolve(src);
            } else if (state === 'error') {
              stored.errored = true;
              reject(evt);
            }

            cleanup();
          };

          tag.onload = handleResult('loaded');
          tag.onerror = handleResult('error');
          tag.onreadystatechange = () => {
            handleResult(tag.readyState);
          };

          // Pick off callback, if there is one
          if (src.match(/callback=CALLBACK_NAME/)) {
            // Commented, because eslint causes undefined error, while linting
            // let cb
            // src = src.replace(/(callback=)[^&]+/, `$1${cbName}`)
            // cb = window[cbName] = tag.onload
          } else {
            tag.addEventListener('load', tag.onload);
          }
          tag.addEventListener('error', tag.onerror);

          tag.src = src;
          body.appendChild(tag);
        });
        const initialState = {
          loaded: false,
          error: false,
          promise,
          tag,
        };
        scriptMap.set(key, initialState);
      }
      return scriptMap.get(key);
    };

    Object.keys(scripts).forEach((key) => {
      const script = scripts[key];
      Cache[key] = {
        tag: Cache._scriptTag(key, script),
        onLoad: Cache._onLoad(key),
      };
    });

    return Cache;
  })(window);

export default ScriptCache;

import { I18n } from 'react-redux-i18n';

import {
  areaTypes,
  breadcrumbLevels,
  businessMaxSizeInBounds,
  easternRegionString,
  riyadhRegionString,
} from './globals/Constans.ts';
import { clusterLevels } from '../types/index.ts';

function getKSALevelBreadcrumb() {
  return {
    firstLine: [
      {
        label: 'breadcrumbs.KSA',
        clickable: true,
      },
    ],
    secondLine: {
      label: 'breadcrumbs.selectRegion',
      clickable: false,
    },
  };
}

// only in Eastern Region
function getEmirateLevelBreadcrumb(emirate) {
  const { name } = emirate.area;
  return {
    firstLine: {
      label: name,
      clickable: true,
    },
    secondLine: {
      label: 'breadcrumbs.selectGovernorate',
      clickable: false,
    },
  };
}

// only in Eastern Region
function getGovernorateLevelBreadcrumb(emirate, governorate) {
  const { name: emirateLabel } = emirate.area;
  const { name: governorateLabel, boundingBox: governorateBoundingBox } =
    governorate.area;

  return {
    firstLine: {
      label: emirateLabel,
      clickable: true,
    },
    secondLine: {
      label: governorateLabel,
      boundingBox: governorateBoundingBox,
      clickable: true,
    },
    thirdLine: {
      label: 'breadcrumbs.selectDistrict',
      clickable: false,
    },
  };
}

// only in Central Region
function getCityLevelBreadcrumb(city) {
  const { id: cityAreaId, name: cityLabel } = city.area;

  return {
    firstLine: {
      label: 'breadcrumbs.centralRegionName',
      clickable: false,
    },
    secondLine: {
      label: cityLabel,
      areaId: cityAreaId,
      clickable: true,
    },
    thirdLine: {
      label: 'breadcrumbs.selectDistrict',
      clickable: false,
    },
  };
}
const getEmirateBreadcrumbLine = (emirate) => {
  if (emirate) {
    const { name: emirateLabel } = emirate.area;

    return {
      label: emirateLabel,
      clickable: true,
    };
  }

  return {
    label: 'breadcrumbs.centralRegionName',
    clickable: false,
  };
};

const getCityOrGovernorateBreadcrumbLine = ({
  chosenRegion,
  city,
  governorate,
}) => {
  if (chosenRegion === easternRegionString) {
    const { name: governorateLabel, boundingBox: governorateBoundingBox } =
      governorate.area;

    return {
      label: governorateLabel,
      boundingBox: governorateBoundingBox,
      clickable: true,
    };
  }

  if (chosenRegion === riyadhRegionString) {
    const { id: cityAreaId, name: cityLabel } = city.area;

    return {
      label: cityLabel,
      areaId: cityAreaId,
      clickable: true,
    };
  }

  return null;
};

function getDistrictLevelBreadcrumb(
  chosenRegion,
  emirate,
  governorate,
  city,
  district,
) {
  const {
    id: districtAreaId,
    name: districtLabel,
    boundingBox: districtBoundingBox,
  } = district.area;

  const districtBreadcrumb = {
    firstLine: getEmirateBreadcrumbLine(emirate),
    secondLine: getCityOrGovernorateBreadcrumbLine({
      chosenRegion,
      city,
      governorate,
    }),
    thirdLine: {
      label: districtLabel,
      boundingBox: districtBoundingBox,
      areaId: districtAreaId,
      clickable: false,
    },
  };

  return districtBreadcrumb;
}

export function getBreadcrumbConnectedAndSidebarData(
  breadcrumbItems,
  isDefaultZoomReached = false,
  businessesInBounds,
  chosenRegion,
) {
  const { emirate, governorate, city, district } = breadcrumbItems;

  // TODO rename getX functions to getXBreadcrumb function - AG-927
  if (district !== null) {
    const clusterLevel =
      businessesInBounds > businessMaxSizeInBounds
        ? clusterLevels.district
        : clusterLevels.single;
    return {
      breadcrumbConnectedData: {
        breadcrumbItem: district,
        breadcrumbLevel: breadcrumbLevels.district,
        clusterLevel,
        areaType: district.area.type,
      },
      breadcrumbsInSidebar: getDistrictLevelBreadcrumb(
        chosenRegion,
        emirate,
        governorate,
        city,
        district,
      ),
    };
  }

  if (city !== null) {
    const clusterLevel = isDefaultZoomReached
      ? clusterLevels.city
      : clusterLevels.district;
    return {
      breadcrumbConnectedData: {
        breadcrumbItem: city,
        breadcrumbLevel: breadcrumbLevels.city,
        clusterLevel,
        areaType: city.area.type,
      },
      breadcrumbsInSidebar: getCityLevelBreadcrumb(city),
    };
  }

  if (governorate !== null) {
    const clusterLevel =
      businessesInBounds > businessMaxSizeInBounds
        ? clusterLevels.governorate
        : clusterLevels.single;
    return {
      breadcrumbConnectedData: {
        breadcrumbItem: governorate,
        breadcrumbLevel: breadcrumbLevels.governorate,
        clusterLevel,
        areaType: governorate.area.type,
      },
      breadcrumbsInSidebar: getGovernorateLevelBreadcrumb(emirate, governorate),
    };
  }

  if (emirate !== null) {
    return {
      breadcrumbConnectedData: {
        breadcrumbItem: emirate,
        breadcrumbLevel: breadcrumbLevels.emirate,
        clusterLevel: clusterLevels.emirate,
        areaType: emirate.area.type,
      },
      breadcrumbsInSidebar: getEmirateLevelBreadcrumb(emirate),
    };
  }

  return {
    breadcrumbConnectedData: {
      breadcrumbLevel: breadcrumbLevels.ksa,
      areaType: 'ksa',
      clusterLevel: clusterLevels.emirate,
    },
    breadcrumbsInSidebar: getKSALevelBreadcrumb(),
  };
}

export function extendBreadcrumbs(breadcrumbs) {
  const extendedBreadcrumbs = {};

  function extendSingleArea(areaName) {
    let { [areaName]: currentArea } = breadcrumbs;

    if (currentArea) {
      let { name } = currentArea.area;

      name = I18n.t(`breadcrumbs.areaTypes.${areaName}`, { areaName: name });

      currentArea = {
        ...currentArea,
        area: { ...currentArea.area, name },
      };
    }

    return {
      name: areaName,
      extended: currentArea,
    };
  }

  areaTypes.forEach((areaName) => {
    const { name, extended } = extendSingleArea(areaName);

    extendedBreadcrumbs[name] = extended;
  });

  return extendedBreadcrumbs;
}

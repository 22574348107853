import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import ChartTooltipNumberValueLabel from '../ChartTooltip/ChartTooltipNumberValueLabel';
import './TreemapTooltip.scss';
import PercentageValue from '../../PercentageValue';
import { isMobileDevice } from '../../../helpers/ResponsiveHelper';
import BaseTooltipContent from '../BaseTooltip/BaseTooltipContent';

const MIN_HEIGHT = 115;
const X_OFFSET = 100;
const Y_OFFSET = isMobileDevice(/xs|sm|md/) ? 10 : 45;
const BORDER_OFFSET = 15;
const ARROW_OFFSET = 8;
const CURRENCY_TRANSLATION_KEY = 'incomeChart.currencyRiyal';

const getTooltipLeftValue = (positionX) => {
  if (positionX - X_OFFSET - BORDER_OFFSET < 0) {
    return BORDER_OFFSET;
  }
  if (positionX + X_OFFSET > window.innerWidth) {
    return window.innerWidth - 2 * X_OFFSET - BORDER_OFFSET;
  }
  return positionX - X_OFFSET;
};

const getArrowLeftValue = (positionX) => {
  if (positionX - X_OFFSET - BORDER_OFFSET < 0) {
    return positionX - ARROW_OFFSET - BORDER_OFFSET;
  }
  if (positionX + X_OFFSET > window.innerWidth) {
    return (
      2 * X_OFFSET -
      (window.innerWidth - positionX) -
      ARROW_OFFSET +
      BORDER_OFFSET
    );
  }
  return false;
};

const TreemapTooltip = ({
  header,
  value,
  positionX,
  positionY,
  percentageValue,
}) => {
  const [height, setHeight] = useState(MIN_HEIGHT);
  const ref = useRef(null);

  useEffect(() => {
    const newHeight = ref.current.clientHeight
      ? ref.current.clientHeight
      : MIN_HEIGHT;
    setHeight(newHeight);
  }, [header]);

  const tooltipTop = positionY - Y_OFFSET - height;
  const tooltipLeft = getTooltipLeftValue(positionX);
  const arrowLeft = getArrowLeftValue(positionX);

  return (
    <div
      style={{ left: `${tooltipLeft}px`, top: `${tooltipTop}px` }}
      className='rc-tooltip base-tooltip TreemapTooltip rc-tooltip-placement-top'
      ref={ref}
    >
      <BaseTooltipContent
        arrowStyles={arrowLeft ? { left: `${arrowLeft}px` } : {}}
      >
        <div className='chart-tooltip__content'>
          <div className='chart-tooltip-header'>
            <h4 className='chart-tooltip-header__title'>
              <Translate value={`spendingChart.categories.${header}`} />
            </h4>
          </div>
          <div className='chart-tooltip__data'>
            <div className='chart-tooltip-values'>
              <div className='chart-tooltip-value'>
                <span className='chart-tooltip-value__container'>
                  <span className='number-value-label'>
                    <span className='number-value-label__number'>
                      <ChartTooltipNumberValueLabel value={value} />
                    </span>
                    <span className='number-value-label__format'>
                      <Translate value={CURRENCY_TRANSLATION_KEY} />
                    </span>
                  </span>
                </span>
              </div>
              <div className='chart-tooltip-value--percentage'>
                <PercentageValue number={percentageValue} />
              </div>
              <div className='chart-tooltip__border-pointer'>
                <div className='chart-tooltip__border' />
                <div className='chart-tooltip__pointer' />
              </div>
            </div>
          </div>
        </div>
      </BaseTooltipContent>
    </div>
  );
};

TreemapTooltip.propTypes = {
  percentageValue: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  positionX: PropTypes.number.isRequired,
  positionY: PropTypes.number.isRequired,
};

export default TreemapTooltip;

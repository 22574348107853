import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';

import './IncomeTableItem.scss';
import { BubbleChartValue } from '../../../Charts/components/BubbleChart';

const IncomeTableItem = ({
  isEmpty,
  label,
  icon,
  centerIcon,
  income,
  color,
  valueSuffix,
}) => (
  <div className='IncomeTableItem'>
    <div className='IncomeTableItem__label'>
      {icon && (
        <div className='IncomeTableItem__icon'>
          <i
            className={classNames(`geo-icon-${icon}`, {
              'Item__icon--center-align': centerIcon,
            })}
          />{' '}
        </div>
      )}
      <Translate value={`demographicChart.${label}`} />
    </div>
    {isEmpty ? (
      <Translate
        className='IncomeTableItem__income'
        value='incomeChart.notAvailable'
      />
    ) : (
      <BubbleChartValue
        valueSuffix={valueSuffix}
        income={income}
        color={color}
      />
    )}
  </div>
);

IncomeTableItem.defaultProps = {
  centerIcon: false,
};

IncomeTableItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  centerIcon: PropTypes.bool,
  income: PropTypes.number,
  color: PropTypes.string,
  isEmpty: PropTypes.bool,
  valueSuffix: PropTypes.string,
};

export default IncomeTableItem;

import gql from 'graphql-tag';
import { DocumentNode } from 'graphql/language/ast';
import { pullAllBy } from 'lodash';
import {
  AreaBucket,
  AreaBucketDto,
  Business,
  BusinessDataShowType,
  BusinessDataShowTypes,
  BusinessDto,
  Businesses,
  BusinessesByIsicId,
  BusinessesDto,
  BusinessesResponseVariant,
  ClusterOptions,
  EnhancedClusteringResponse,
  EnhancedTag,
  IsicId,
  MarkersResponse,
  SectorType,
  SectorTypes,
  SimpleClusteringResponse,
  SimpleClusteringWithTagsResponse,
} from '../types';
import {
  getMarkersQuery,
  getMarkersWithTagsQuery,
} from '../graphql/queries/MarkersQueries';
import {
  getClusterQuery,
  getClusterWithTagsQuery,
  getGeoHashClusters,
} from '../graphql/queries/ClustersQueries';

type BusinessItems =
  | { withoutTags: AreaBucket[] }
  | { withoutTags: Business[] }
  | Record<IsicId, AreaBucket[]>
  | Record<IsicId, Business[]>;

const { sector, subsector } = SectorTypes;

const selectedPrefix = 'selected';
const otherPrefix = 'other';

export const getHighestClusterLabel = (businesses: Businesses): number => {
  const allLabels = Object.values(businesses.items).flatMap((businessItems) =>
    businessItems.map((item) => item?.label ?? 0),
  );

  return Math.max(0, ...allLabels);
};

export const getClusterShowType = (
  clusterOptions: ClusterOptions,
  withTags: boolean,
): BusinessDataShowType => {
  const { enabled: clustersEnabled, isDefaultZoomReached } = clusterOptions;

  if (clustersEnabled) {
    if (isDefaultZoomReached) {
      return withTags
        ? BusinessDataShowTypes.simpleClusteringWithTags
        : BusinessDataShowTypes.simpleClustering;
    }
    return withTags
      ? BusinessDataShowTypes.enhancedClusteringWithTags
      : BusinessDataShowTypes.enhancedClustering;
  }

  return withTags
    ? BusinessDataShowTypes.notClusteredBusinessesWithTags
    : BusinessDataShowTypes.notClusteredBusinesses;
};

const clustersShowTypeToBusinessQuery: Record<BusinessDataShowType, string> = {
  // Clusters on min zoom level
  [BusinessDataShowTypes.simpleClustering]: getClusterQuery,
  [BusinessDataShowTypes.simpleClusteringWithTags]: getClusterWithTagsQuery,
  // Clusters with big amount of businesses
  [BusinessDataShowTypes.enhancedClustering]: getGeoHashClusters(),
  [BusinessDataShowTypes.enhancedClusteringWithTags]: getGeoHashClusters({
    withTags: true,
  }),
  // Markers
  [BusinessDataShowTypes.notClusteredBusinesses]: getMarkersQuery,
  [BusinessDataShowTypes.notClusteredBusinessesWithTags]:
    getMarkersWithTagsQuery,
};

// TODO: define store state type
export const getBusinessQuery = (state: any): DocumentNode => {
  const { showType } = state.map.clusters;
  const getMatchingBusinessQuery =
    clustersShowTypeToBusinessQuery[showType as BusinessDataShowType] ??
    getMarkersQuery;

  return gql`
    ${getMatchingBusinessQuery}
  `;
};

const getIsicsByType = (
  tags: EnhancedTag[],
): Partial<Record<SectorType, IsicId[]>> =>
  tags.reduce(
    (grouped: Partial<Record<SectorType, IsicId[]>>, tag: EnhancedTag) => {
      // eslint-disable-next-line no-param-reassign -- @refactor
      grouped[tag.type] = grouped[tag.type] || [];
      grouped[tag.type]?.push(tag.id);

      return grouped;
    },
    {},
  );

const isSubsector = (type: SectorType): boolean => type === subsector;

const getIdByType = (type: SectorType, business: BusinessDto) =>
  isSubsector(type) ? business.isic.id : business.isic.root.id;

const flattenBusinesses = ({ location, ...other }: BusinessDto): Business => ({
  ...location,
  ...other,
});

const prepareDistinctBusinessesForMarkers = (
  businesses: BusinessesDto,
  tags: EnhancedTag[],
): BusinessesByIsicId => {
  const isicsBySectorType = getIsicsByType(tags);
  const businessesByIsicId: BusinessesByIsicId = {};
  const clonedBusinesses = [...businesses.items];
  const possibleTypes = [subsector, sector];

  possibleTypes.forEach((type) => {
    if (isicsBySectorType[type]) {
      isicsBySectorType[type]?.forEach((isicId) => {
        businessesByIsicId[isicId] = clonedBusinesses
          .filter((business) => getIdByType(type, business) === isicId)
          .map(flattenBusinesses);

        // Remove doubled subsectors from businesses
        pullAllBy(clonedBusinesses, businessesByIsicId[isicId], 'id');
      });
    }
  });

  return businessesByIsicId;
};

const prepareClustersFromArea = (
  areaClusters: { geohashGridBuckets: AreaBucketDto[] },
  prefix = '',
): AreaBucket[] =>
  areaClusters.geohashGridBuckets.map(({ id, location, size }) => ({
    id: `${prefix}-${id}`,
    ...location,
    label: size,
  }));

const prepareDistinctBusinessesForClustersWithTags = (
  areaClusters: { geohashGridBuckets: AreaBucketDto[] },
  prefix = '',
): Record<IsicId, AreaBucket[]> =>
  areaClusters.geohashGridBuckets.reduce(
    (aggregated: Record<IsicId, AreaBucket[]>, areaBucket) => {
      areaBucket.isicBuckets?.forEach(({ isic: { id }, size }) => {
        if (!(id in aggregated)) {
          // eslint-disable-next-line no-param-reassign -- @refactor
          aggregated[id] = [];
        }

        aggregated[id].push({
          id: `${prefix}-${areaBucket.id}-${id}`,
          tagId: id,
          ...areaBucket.location,
          label: size,
        });
      });
      return aggregated;
    },
    {},
  );

const handleEnhancedClustering = (
  businesses: EnhancedClusteringResponse,
): BusinessItems => {
  const { otherClusters, selectedClusters } = businesses;
  return {
    withoutTags: prepareClustersFromArea(otherClusters, otherPrefix).concat(
      prepareClustersFromArea(selectedClusters, selectedPrefix),
    ),
  };
};

function getDividedBusinessesById(
  tags: EnhancedTag[],
  dividedAreaBucketsBusinessesById: Record<string, AreaBucket[]>,
  dividedGeohashGridBucketsBusinessesById: Record<string, AreaBucket[]>,
) {
  return tags.reduce(
    (grouped: Record<IsicId, AreaBucket[]>, { id: isicId }) => {
      const currentAreaBuckets = dividedAreaBucketsBusinessesById[isicId] || [];
      const currentGeoHashBuckets =
        dividedGeohashGridBucketsBusinessesById[isicId] || [];

      // eslint-disable-next-line no-param-reassign -- @refactor
      grouped[isicId] = [...currentAreaBuckets, ...currentGeoHashBuckets];

      return grouped;
    },
    {},
  );
}

const handleEnhancedClusteringWithTags = (
  businesses: EnhancedClusteringResponse,
  tags: EnhancedTag[],
): BusinessItems => {
  const { otherClusters, selectedClusters } = businesses;

  const dividedBusinessesById = getDividedBusinessesById(
    tags,
    prepareDistinctBusinessesForClustersWithTags(
      selectedClusters,
      selectedPrefix,
    ),
    prepareDistinctBusinessesForClustersWithTags(otherClusters, otherPrefix),
  );

  return tags.reduce(
    (clusters: Record<IsicId, AreaBucket[]>, { id: isicId }) => {
      if (dividedBusinessesById[isicId]) {
        // eslint-disable-next-line no-param-reassign -- @refactor
        clusters[isicId] = dividedBusinessesById[isicId];
      }

      return clusters;
    },
    {},
  );
};

const handleSimpleClusteringWithTags = (
  businesses: SimpleClusteringWithTagsResponse,
  tags: EnhancedTag[],
): BusinessItems => {
  const dividedBusinessesById = prepareDistinctBusinessesForClustersWithTags({
    geohashGridBuckets: businesses.aggregationByAreaAndIsic.areaBuckets.map(
      ({ area, isicBuckets, size }) => ({
        ...area,
        isicBuckets,
        size,
      }),
    ),
  });

  return tags.reduce(
    (preparedBusinesses: Record<IsicId, Business[]>, { id: isicId }) => {
      if (dividedBusinessesById[isicId]) {
        // eslint-disable-next-line no-param-reassign
        preparedBusinesses[isicId] = dividedBusinessesById[isicId];
      }
      return preparedBusinesses;
    },
    {},
  );
};

const handleSimpleClustering = (
  businesses: SimpleClusteringResponse,
): BusinessItems => {
  // Clusters without tags
  const preparedClusters = businesses.aggregationByArea.areaBuckets.map(
    ({ area, size }) => ({
      id: area.id,
      ...area.location,
      label: size,
    }),
  );

  return {
    withoutTags: preparedClusters,
  };
};

const handleNotClusteredBusinessesWithTags = (
  businesses: MarkersResponse,
  tags: EnhancedTag[],
): BusinessItems => prepareDistinctBusinessesForMarkers(businesses, tags);

const handleNotClusteredBusinesses = (
  businesses: MarkersResponse,
): BusinessItems => ({
  withoutTags: businesses.items.map(flattenBusinesses),
});

const resolveItems = (
  showType: BusinessDataShowType,
  businesses: BusinessesResponseVariant,
  tags: EnhancedTag[],
): BusinessItems => {
  switch (showType) {
    case BusinessDataShowTypes.simpleClustering: {
      return handleSimpleClustering(businesses as SimpleClusteringResponse);
    }
    case BusinessDataShowTypes.simpleClusteringWithTags: {
      return handleSimpleClusteringWithTags(
        businesses as SimpleClusteringWithTagsResponse,
        tags,
      );
    }
    case BusinessDataShowTypes.enhancedClustering: {
      return handleEnhancedClustering(businesses as EnhancedClusteringResponse);
    }
    case BusinessDataShowTypes.enhancedClusteringWithTags: {
      return handleEnhancedClusteringWithTags(
        businesses as EnhancedClusteringResponse,
        tags,
      );
    }
    case BusinessDataShowTypes.notClusteredBusinessesWithTags: {
      return handleNotClusteredBusinessesWithTags(
        businesses as MarkersResponse,
        tags,
      );
    }
    case BusinessDataShowTypes.notClusteredBusinesses: {
      return handleNotClusteredBusinesses(businesses as MarkersResponse);
    }
    default: {
      return {};
    }
  }
};

export const getBusinessResponse = (
  state: any,
  businesses: BusinessesResponseVariant,
): {
  items: BusinessItems;
  size: number;
} => {
  const { showType } = state.map.clusters;
  const { tags } = state.sector;

  return {
    items: resolveItems(showType, businesses, tags),
    size: businesses.size,
  };
};

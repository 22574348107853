import { MAP_TYPE_ID } from '../modules/Map/constants/MapConstants';

export type Region = 'riyadh-region' | 'eastern-region';

// TODO: convert to enum
export const MapMarkerTypes = {
  marker: 'marker',
  cluster: 'cluster',
} as const;

// TODO: convert to enum
export const SectorTypes = {
  sector: 'sector',
  subsector: 'subsector',
} as const;

export type SectorType = (typeof SectorTypes)[keyof typeof SectorTypes];

export type IsicId = string;

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Isic {
  id: IsicId;
  root: { id: IsicId };
  name?: string;
  type?: SectorType;
}

export interface BusinessDto {
  id: string;
  isic: Isic;
  location: Location;
}

export interface BusinessesDto {
  items: BusinessDto[];
  size: number;
}

export interface Business extends Location {
  id: string;
  label?: number;
  tagId?: IsicId;
  isic?: Isic;
}

interface IsicBucket {
  isic: {
    id: IsicId;
    root: { id: IsicId };
  };
  size: number;
}

export interface AreaBucketDto {
  id: string;
  location: Location;
  size: number;
  isicBuckets?: IsicBucket[];
}

export interface AreaBucket extends Location {
  id: string;
  label: number;
  tagId?: IsicId;
}

export type BusinessesByIsicId = Record<IsicId, Business[]>;

export interface Businesses {
  items: Record<IsicId, Business[]>;
  size: number;
}

// TODO: convert to enum
export const clusterLevels = {
  emirate: 'emirate',
  governorate: 'governorate',
  city: 'city',
  district: 'district',
  single: 'single',
} as const;

export type ClusterLevel = (typeof clusterLevels)[keyof typeof clusterLevels];

export interface ClusterOptions {
  enabled: boolean;
  areaType: ClusterLevel;
  isDefaultZoomReached: boolean;
}

export type MapTypeId = (typeof MAP_TYPE_ID)[keyof typeof MAP_TYPE_ID];

// TODO: convert to enum
export const BusinessDataShowTypes = {
  enhancedClustering: 'enhancedClustering',
  enhancedClusteringWithTags: 'enhancedClusteringWithTags',
  simpleClustering: 'simpleClustering',
  simpleClusteringWithTags: 'simpleClusteringWithTags',
  notClusteredBusinesses: 'notClusteredBusinesses',
  notClusteredBusinessesWithTags: 'notClusteredBusinessesWithTags',
} as const;

export type BusinessDataShowType =
  (typeof BusinessDataShowTypes)[keyof typeof BusinessDataShowTypes];

interface Tag {
  id: IsicId;
  name: string;
}

export interface Color {
  name: string;
  value: string;
  labelValue: string;
  transparentValue: string;
  satelliteTransparentValue: string;
}

export interface EnhancedTag extends Tag {
  root: Tag;
  type: SectorType;
  color: Color;
}

interface BusinessesResponse {
  size: number;
}

export interface SimpleClusteringResponse extends BusinessesResponse {
  aggregationByArea: {
    areaBuckets: Array<{
      area: { id: string; location: Location };
      size: number;
    }>;
  };
}

export interface SimpleClusteringWithTagsResponse extends BusinessesResponse {
  aggregationByAreaAndIsic: {
    areaBuckets: Array<{
      area: { id: string; location: Location };
      isicBuckets: IsicBucket[];
      size: number;
    }>;
  };
}

// the same for enhancedClusteringWithTags
export interface EnhancedClusteringResponse extends BusinessesResponse {
  otherClusters: {
    geohashGridBuckets: AreaBucketDto[];
  };
  selectedClusters: {
    geohashGridBuckets: AreaBucketDto[];
  };
}

export interface MarkersResponse extends BusinessesResponse {
  items: BusinessDto[];
}

export type BusinessesResponseVariant =
  | SimpleClusteringResponse
  | MarkersResponse
  | EnhancedClusteringResponse
  | SimpleClusteringWithTagsResponse;

const businessChart = {
  in: 'in ',
  theBusinessesIn: 'The businesses in {{areaName}}',
  noBusinessFound: 'No Businesses Found in that Area',
  businesses: 'businesses',
  businessTooltipLabel: 'Number of businesses (Percentage)',
  noDataProvided:
    'Business information in this region is not available. Please select another area.',
  description: {
    noSectors: {
      businessNumber: 'businesses',
      panelItem: 'Percentage of businesses in {{isicName}}',
      tooltip: 'Percentage from businesses in all sectors',
    },
    oneSector: {
      panelTitle: 'The businesses belonging to {{isicName}} in {{areaName}}',
      businessNumber: 'businesses',
      panelItem: 'Percentage of total businesses in all sectors',
      ranking:
        'Ranking among other sectors in terms of the number of businesses it has',
      tooltip: 'Percentage from businesses in {{isicName}}',
    },
    oneSubsector: {
      panelTitle:
        'The businesses belonging to sub-sector: {{isicName}} in {{areaName}}',
      businessNumber: 'businesses',
      panelItem: 'of total businesses in {{parentIsicName}}',
      ranking:
        'Ranking among other sub-sectors in {{isicName}} in terms of the number of businesses it has',
      tooltip: 'Percentage from businesses in {{isicName}}',
    },
    moreThanOneTag: {
      panelTitle: {
        twoTags:
          'The businesses belonging to the two selected sectors in {{areaName}}',
        threeTags:
          'The businesses belonging to the selected sectors in {{areaName}}',
      },
      businessNumber: 'businesses',
      panelItem: {
        twoSectors:
          'Percentage of businesses in {{isicName}} of total businesses in the two selected sectors',
        threeSectors:
          'Percentage of businesses in {{isicName}} of total businesses in the selected sectors',
        subsector:
          'Percentage of businesses in sub-sector: {{isicName}} of total businesses ' +
          'in the two selected sectors',
      },
      tooltip: 'Percentage from businesses in the two selected sectors',
    },
  },
};

export default businessChart;

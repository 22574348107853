import React, { useEffect, useState } from 'react';
import { searchInTreeFor } from './tools';
import getChartsData from '../../helpers/charts/getChartsData';
import selectDataFromChartsForCSV from '../../helpers/csv/selectDataFromChartsForCSV';
import getCSVData from '../../helpers/csv/getCSVData';

const printData = (searchValue) => {
  // eslint-disable-next-line no-undef
  const roots = [...__REACT_DEVTOOLS_GLOBAL_HOOK__.getFiberRoots(2)];

  const root = roots.find(
    ({ containerInfo }) => containerInfo.id === 'root',
  ).current;

  const searchResults = searchInTreeFor(root, 'SidebarLayout', searchValue);

  // eslint-disable-next-line no-console
  console.log(searchResults);
};

const containerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  padding: '10px',
};

const DebugTree = () => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const onKeyUp = (e) => {
      // shortcut: Ctrl + L
      if (e.key === 'L' && e.ctrlKey) {
        printData(search);
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [search]);

  return (
    <div style={containerStyle}>
      <button
        type='button'
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log(getCSVData(selectDataFromChartsForCSV(getChartsData())));
        }}
      >
        Log pdf data
      </button>
      <button type='button' onClick={() => printData(search)}>
        Log search result
      </button>

      <input
        type='text'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};

export default DebugTree;

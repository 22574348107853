import React from 'react';
import PropTypes from 'prop-types';

import SimpleLegendItem from './SimpleLegendItem';

const PyramidLegend = ({ items }) => (
  <div className='pyramid-legend row'>
    <div className='col-xs-5'>
      <SimpleLegendItem
        additionalClass='row middle-xs reverse'
        legendItem={items[0]}
      />
    </div>
    <div className='pyramid-legend__divider col-xs-2' />
    <div className='col-xs-5'>
      <SimpleLegendItem additionalClass='row middle-xs' legendItem={items[1]} />
    </div>
  </div>
);

PyramidLegend.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PyramidLegend;

const screens = {
  desktop: 'desktop',
  mobile: 'mobile',
};

const BREAKPOINTS = [576, 768, 1024, 1200, 1366, 1680];

const getResponsiveIndex = (windowWidth) =>
  BREAKPOINTS.filter((width) => windowWidth >= width).length - 1;

export const getScreenBreakpoint = (width) => {
  switch (getResponsiveIndex(width || window.innerWidth)) {
    case 1:
      return 'sm';
    case 2:
      return 'md';
    case 3:
      return 'xl';
    case 4:
      return 'xxl';
    case 5:
      return 'xxxl';
    default:
      return 'xs';
  }
};

export const isAndroid = () => !!navigator.userAgent.match(/Android/i);
export const isBlackBerry = () => !!navigator.userAgent.match(/BlackBerry/i);
export const isiOS = () => !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
export const isOperaMini = () => !!navigator.userAgent.match(/Opera Mini/i);
export const isWindowsMobile = () => !!navigator.userAgent.match(/IEMobile/i);
export const isSafari = () =>
  navigator.userAgent.includes('Safari') &&
  !navigator.userAgent.includes('Chrome');
export const isMobileOS = () =>
  isAndroid() ||
  isBlackBerry() ||
  isiOS() ||
  isOperaMini() ||
  isWindowsMobile();

export const isMacOS = () => isSafari() && !isMobileOS();

export const isMobileDevice = (regExp = /xs|sm/) =>
  !!getScreenBreakpoint().match(regExp) && isMobileOS();

export const isMobileScreen = () => {
  const breakpoint = getScreenBreakpoint();

  return breakpoint === 'sm' || breakpoint === 'xs';
};

export const screenMode = isMobileDevice() ? screens.mobile : screens.desktop;

export const getResponsiveConstantData = (data) => data[screenMode];

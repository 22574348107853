import {
  clustersSizeObject,
  Colors,
} from '../../../helpers/globals/Constans.ts';
import {
  getClusterPixelSize,
  mapTagsColorById,
} from '../../../helpers/MarkerHelper';
import { getScreenBreakpoint } from '../../../helpers/ResponsiveHelper';
import { updateMarkerView } from '../components/Markers/utils';
import { markersStore } from './mapStore';

function recheckMarkers(
  marker,
  tag,
  tagIndex,
  tagsLength,
  clusterPixelSize,
  mappedTagsColors,
) {
  const actualSize = getScreenBreakpoint();

  const { markerOnMap } = marker;
  const clusterSize =
    clustersSizeObject[actualSize].min +
    markerOnMap.clusterOptions.label * clusterPixelSize;

  if (!markerOnMap.businessMarker) {
    return;
  }

  let { businessMarker } = markerOnMap;

  if (markerOnMap.isCluster) {
    const { clusterOptions } = markerOnMap;
    clusterOptions.size = clusterSize;

    if (clusterOptions.tagId !== tag.id) return;

    businessMarker = updateMarkerView(
      businessMarker,
      clusterSize,
      tagsLength,
      tagIndex,
    );
  }

  const { markerOptions } = markerOnMap;

  if (markerOptions) {
    const color =
      mappedTagsColors[markerOptions.isic.id] ||
      mappedTagsColors[markerOptions.isic.root.id] ||
      Colors.blue;

    markerOnMap.setColor(color);

    businessMarker.style.backgroundColor = color.transparentValue;
  }
}

export default function redrawCurrentMarkers() {
  return (dispatch, getState) => {
    const state = getState();
    const { highestClusterLabel } = state.map.clusters;
    const { markers: markersIds } = state.map;
    const { tags } = state.sector;
    const clusterPixelSize = getClusterPixelSize(highestClusterLabel);
    const mappedTagsColors = mapTagsColorById(tags);
    const actualSize = getScreenBreakpoint();

    const markers = markersStore.getMultiple(markersIds);

    if (tags.length) {
      markers.forEach((marker) => {
        for (let i = 0; i < tags.length; i++) {
          const tag = tags[i];
          const tagIndex = i;

          recheckMarkers(
            marker,
            tag,
            tagIndex,
            tags.length,
            clusterPixelSize,
            mappedTagsColors,
          );
        }
      });
    } else {
      markers.forEach((marker) => {
        recheckMarkers(
          marker,
          0,
          0,
          tags.length,
          clusterPixelSize,
          mappedTagsColors,
        );
      });
    }

    markers.forEach((marker) => {
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        const tagIndex = i;

        const { markerOnMap } = marker;
        const clusterSize =
          clustersSizeObject[actualSize].min +
          markerOnMap.clusterOptions.label * clusterPixelSize;

        if (!markerOnMap.businessMarker) return;

        let { businessMarker } = markerOnMap;

        if (markerOnMap.isCluster) {
          const { clusterOptions } = markerOnMap;
          clusterOptions.size = clusterSize;

          // eslint-disable-next-line no-continue
          if (clusterOptions.tagId !== tag.id) continue;

          businessMarker = updateMarkerView(
            businessMarker,
            clusterSize,
            tags.length,
            tagIndex,
          );
        }

        const { markerOptions } = markerOnMap;

        if (markerOptions) {
          const color =
            mappedTagsColors[markerOptions.isic.id] ||
            mappedTagsColors[markerOptions.isic.root.id] ||
            Colors.blue;

          markerOnMap.setColor(color);

          businessMarker.style.backgroundColor = color.transparentValue;
        }
      }
    });
  };
}

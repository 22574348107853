import React from 'react';
import PropTypes from 'prop-types';

import PyramidLabelItem from './PyramidLabelItem';

const PyramidLabels = ({ labels, primaryData, secondaryData, actions }) => {
  const lastItemIndex = labels.length - 1;

  return (
    <div className='pyramid-labels col-xs'>
      {labels.map((label, index) => {
        const primaryDataValue = primaryData[label.id]
          ? primaryData[label.id].value
          : 0;
        const secondaryDataValue = secondaryData[label.id]
          ? secondaryData[label.id].value
          : 0;
        const sum = primaryDataValue + secondaryDataValue;

        return (
          <PyramidLabelItem
            key={label.id}
            itemIndex={index}
            lastItemIndex={lastItemIndex}
            singleLabelItem={label}
            sum={sum}
            actions={actions}
          />
        );
      })}
    </div>
  );
};

PyramidLabels.propTypes = {
  primaryData: PropTypes.object.isRequired,
  secondaryData: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  actions: PropTypes.object,
};

export default PyramidLabels;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  getChosenSectors,
  getDefaultSectorListFlatMap,
} from '../../../selectors/sector.selector';
import { parseFilterSearchParamsURL } from '../../../modules/Sectors/components/filtersURLSearchParamsUtils';
import {
  getMapClustersOptions,
  getMapOptions,
} from '../../../selectors/map.selector';
import {
  setCurrentLocation as setCurrentLocationAction,
  setMapType as setMapTypeAction,
  setMapZoom as setMapZoomAction,
} from '../../../modules/Map/actions/MapActions';
import { saveMultipleSectors as saveMultipleSectorsAction } from '../../../modules/Sectors/reducers/SectorSlice';
import { getNewTags } from '../utils';
import useActions from '../../../store/useActions';
import { hasMapTypeIdChange, haveLatLngChange, haveTagsChange } from './utils';
import { MAP_TYPE_ID } from '../../../modules/Map/constants/MapConstants.ts';

const useIsAppReadyForApply = () => {
  const sectorsFlatMap = useSelector(getDefaultSectorListFlatMap);
  const mapClusterOptions = useSelector(getMapClustersOptions);

  const { size: flatMapSize } = sectorsFlatMap;
  const { showType } = mapClusterOptions;

  return flatMapSize > 0 && showType !== null;
};

const useApplyFilters = () => {
  const currentTags = useSelector(getChosenSectors);
  const {
    zoom: currentZoom,
    center: { lat: currentCenterLat, lng: currentCenterLng },
    mapTypeId: currentMapTypeId,
    minZoom,
  } = useSelector(getMapOptions);

  const [saveMultipleSectors, setMapZoom, setCurrentLocation, setMapType] =
    useActions([
      saveMultipleSectorsAction,
      setMapZoomAction,
      setCurrentLocationAction,
      setMapTypeAction,
    ]);

  return (tags, zoom, centerLat, centerLng, mapTypeId) => {
    const newZoom = zoom || minZoom;
    if (newZoom !== currentZoom) {
      setMapZoom(newZoom);
    }

    if (
      haveLatLngChange(centerLat, centerLng, currentCenterLat, currentCenterLng)
    ) {
      setCurrentLocation({ lat: centerLat, lng: centerLng });
    }

    const newMapTypeId = mapTypeId || MAP_TYPE_ID.HYBRID;
    if (hasMapTypeIdChange(newMapTypeId, currentMapTypeId)) {
      setMapType(newMapTypeId);
    }

    if (haveTagsChange(tags, currentTags)) {
      saveMultipleSectors(tags);
    }
  };
};

/**
 * @param {boolean} canApply
 * @param {func} onApply callback after applying
 */
const useApplyingFiltersBasedOnURL = (canApply, onApply, currentFiltersURL) => {
  const sectorsFlatMap = useSelector(getDefaultSectorListFlatMap);
  const isAppReady = useIsAppReadyForApply();

  const applyFilters = useApplyFilters();

  const setURLWithFilters = () => {
    const { search } = browserHistory.getCurrentLocation();

    const { sectors, zoom, centerLat, centerLng, mapTypeId } =
      parseFilterSearchParamsURL(search);

    const newTags = getNewTags(sectors, sectorsFlatMap);

    applyFilters(newTags, zoom, centerLat, centerLng, mapTypeId);
    onApply();
  };

  useEffect(() => {
    if (isAppReady) {
      setURLWithFilters();
    }
  }, [isAppReady, currentFiltersURL]);
};

export default useApplyingFiltersBasedOnURL;

import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';

import './FooterNav.scss';
import { I18n } from 'react-redux-i18n'
import { browserHistory } from 'react-router'

const FooterNavItem = ({ href, translationKey, isActive }) => (
    <a className='FooterNavItem' href={href}>
      <div className='FooterNavItem__content'>
        <span className='FooterNavItem__content__text'>{I18n.t(translationKey)}</span>
        <div className={classNames('FooterNavItem__content__underline', {
          active: isActive,
        })}/>
      </div>
    </a>
)

const FooterNav = ({ items }) => {
  const path = browserHistory.getCurrentLocation();

  return (
    <nav className='FooterNav'>
      {items.map((item) => (
        <FooterNavItem isActive={item.isActive(path.pathname)} key={item.keyId} href={item.href}
                       translationKey={item.translationKey}/>
      ))}
    </nav>
  )
}

FooterNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      translationKey: PropTypes.string,
      keyId: PropTypes.string,
      isActive: PropTypes.func,
    })
  ),
};

FooterNavItem.propTypes = {
  href: PropTypes.string,
  translationKey: PropTypes.string,
  isActive: PropTypes.bool,
};

FooterNavItem.defaultProps = {
  isActive: false,
}

export default FooterNav;

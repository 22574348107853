import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { uniqueId } from 'lodash';

import SimpleBarChart from './SimpleBarChart';
import PyramidLabels from './PyramidLabels';
import NoDataWrapper from '../../../../components/NoDataWrapper';
import { prepareAxisXScale } from '../../../../helpers/chartHelpers';
import FixedChartAxis from './FixedPyramidChartAxis';

const GUIDES = {
  mobile: 2,
  desktop: 4,
};

const PyramidChart = ({
  data,
  dataKeys,
  tooltip,
  overwriteHeight,
  actions,
  isMobile,
  enableAnimation = true,
}) => {
  const guideLines = isMobile ? GUIDES.mobile : GUIDES.desktop;

  const [primaryKey, secondaryKey] = dataKeys;

  let { [primaryKey]: primaryData, [secondaryKey]: secondaryData } = data;

  const { labels } = data;

  const {
    [primaryKey]: primaryPlaceholderData,
    [secondaryKey]: secondaryPlaceholderData,
  } = data.placeholderData;

  const isPopulationPositive = primaryData.total + secondaryData.total > 0;

  if (!isPopulationPositive) {
    primaryData = { ...primaryPlaceholderData };
    secondaryData = { ...secondaryPlaceholderData };
  }

  const legend = dataKeys.map((dataKey) => ({
    label: <Translate value={`demographicChart.${dataKey}`} />,
    color: data[dataKey].color,
  }));

  const maxScaleSizes = dataKeys.map((dataKey) =>
    prepareAxisXScale(data[dataKey].maxSize, guideLines, isMobile),
  );

  const overwrittenScale = Math.max(...maxScaleSizes);

  const axisXUniqueId = uniqueId('axis-x-');

  const axisXMirroredUniqueId = uniqueId('axis-x-');

  const opacity = {
    full: 1,
    grayedOut: 0.2,
  };

  return (
    <div className='chart__wrapper--pyramid row center-xs'>
      <div className='chart__container chart__container--pyramid col-xs-12'>
        <div className='chart__content'>
          <div
            className='chart__bar-items chart__bar-items--pyramid row top-xs'
            style={{
              opacity: !isPopulationPositive ? opacity.grayedOut : opacity.full,
            }}
          >
            <SimpleBarChart
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...primaryData}
              labels={labels}
              guideLines={guideLines}
              overwriteHeight={overwriteHeight}
              overwriteScale={overwrittenScale}
              tooltip={tooltip}
              enableAnimation={enableAnimation && isPopulationPositive}
              portalId={axisXMirroredUniqueId}
              mirrored
            />
            <PyramidLabels
              labels={labels}
              primaryData={primaryData.data}
              secondaryData={secondaryData.data}
              actions={actions}
            />
            <SimpleBarChart
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...secondaryData}
              labels={labels}
              guideLines={guideLines}
              overwriteHeight={overwriteHeight}
              overwriteScale={overwrittenScale}
              tooltip={tooltip}
              enableAnimation={enableAnimation && isPopulationPositive}
              portalId={axisXUniqueId}
            />
          </div>
          {!isPopulationPositive && (
            <NoDataWrapper
              text='demographicChart.noDataProvided'
              className='no-data-wrapper--demographic'
            />
          )}
          <FixedChartAxis
            axisXMirroredUniqueId={axisXMirroredUniqueId}
            axisXUniqueId={axisXUniqueId}
            legend={legend}
          />
        </div>
      </div>
    </div>
  );
};

PyramidChart.defaultProps = {
  tooltip: {
    enabled: false,
    type: 'default',
  },
};

PyramidChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    placeholderData: PropTypes.object,
  }).isRequired,
  tooltip: PropTypes.shape({
    enabled: PropTypes.bool,
    type: PropTypes.string,
  }),
  isMobile: PropTypes.bool.isRequired,
  dataKeys: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    setGraphOptions: PropTypes.func,
  }),
  overwriteHeight: PropTypes.object,
  enableAnimation: PropTypes.bool,
};

export default PyramidChart;

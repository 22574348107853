import { I18n } from 'react-redux-i18n';

export const translateGroupPopulation = ({ genderId, ...rest }) => ({
  ...rest,
  genderId: I18n.t(`demographicChart.${genderId}`),
});

export const translateGroup = ({ key, ...rest }) => ({
  ...rest,
  key: I18n.t(`demographicChart.${key}`),
});

export const translateSpendingData = ({ subIndicator, ...rest }) => ({
  ...rest,
  subIndicator: I18n.t(`spendingChart.categories.${subIndicator}`),
});

import transformDataToTables from './transformDataToTables.ts';

import {
  BUSINESS_LIST,
  BY_GENDER_AND_AGE_GROUP_POPULATION,
  BY_NATIONALITY_AND_AGE_GROUP_POPULATION,
  EASTERN_GROUPS,
  RIYADH_GROUPS,
  SPENDING_DATA,
} from './transformConfigs';

const getGroupsTransformConfig = (chosenRegion) => {
  switch (chosenRegion) {
    case 'riyadh-region':
      return RIYADH_GROUPS;
    case 'eastern-region':
    default:
      return EASTERN_GROUPS;
  }
};

const getCSVData = ({
  barChartComponentData,
  demographicBarChartComponentData,
  chosenRegion,
}) => {
  const {
    demographicGraphData: {
      byGenderAndAgeGroupPopulation,
      byNationalityAndAgeGroupPopulation,
    },
    groups,
    spendingData,
  } = demographicBarChartComponentData;

  const { businessList } = barChartComponentData.businessChartData;

  return [
    [businessList, BUSINESS_LIST],
    [byGenderAndAgeGroupPopulation, BY_GENDER_AND_AGE_GROUP_POPULATION],
    [
      byNationalityAndAgeGroupPopulation,
      BY_NATIONALITY_AND_AGE_GROUP_POPULATION,
    ],
    [groups, getGroupsTransformConfig(chosenRegion)],
    [spendingData, SPENDING_DATA],
  ]
    .flatMap(([data, config]) => transformDataToTables(data, config))
    .filter((table) => table);
};

export default getCSVData;

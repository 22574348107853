import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Tooltip from '../Tooltip';

import {
  checkTooltipTrigger,
  returnTooltipContainer,
} from '../../../helpers/AppHelper';

import './BaseTooltip.scss';

class BaseTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trigger: ['hover'],
    };
  }

  componentDidMount() {
    this.handleChangeTrigger();
    window.addEventListener('resize', this.handleChangeTrigger);
    window.addEventListener('orientationchange', this.handleChangeTrigger);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleChangeTrigger);
    window.removeEventListener('orientationchange', this.handleChangeTrigger);
  }

  handleChangeTrigger = () => {
    const trigger = [checkTooltipTrigger()];

    this.setState({ trigger });
  };

  render() {
    const { overlay, overlayClassName, placement, children } = this.props;

    const { trigger } = this.state;

    return (
      <Tooltip
        overlay={overlay}
        placement={placement}
        overlayClassName={classnames('base-tooltip', overlayClassName)}
        getTooltipContainer={returnTooltipContainer}
        destroyTooltipOnHide
        trigger={trigger}
      >
        {children}
      </Tooltip>
    );
  }
}

BaseTooltip.propTypes = {
  overlayClassName: PropTypes.string,
  overlay: PropTypes.node,
  placement: PropTypes.string,
  children: PropTypes.node,
};

export default BaseTooltip;

import React from 'react';
import PropTypes from 'prop-types';

import IncomeTableItem from '../IncomeTable/IncomeTableItem';

import iconHousehold from '../../../../assets/demographic_icons/icon-household.svg';
import iconPeople from '../../../../assets/demographic_icons/icon-people.svg';
import iconSar from '../../../../assets/demographic_icons/icon-sar.svg';
import IncomeTableGroup from '../IncomeTableGroup';
import { findByLabel } from '../../../utils';

/* eslint-disable react/jsx-props-no-spreading -- adpating elements */
const IncomeTableEastern = ({ data }) => (
  <div className='IncomeTableEastern'>
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.people'
      iconSrc={iconPeople}
    >
      <IncomeTableItem {...findByLabel(data, 'population_density')} />
      <IncomeTableItem {...findByLabel(data, 'population_growth_rate')} />
    </IncomeTableGroup>
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.household'
      iconSrc={iconHousehold}
    >
      <IncomeTableItem {...findByLabel(data, 'household_size')} />
      <IncomeTableItem {...findByLabel(data, 'household_growth_rate')} />
    </IncomeTableGroup>
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.sar'
      iconSrc={iconSar}
    >
      <IncomeTableItem {...findByLabel(data, 'household_income')} />
      <IncomeTableItem {...findByLabel(data, 'household_savings')} />
      <IncomeTableItem {...findByLabel(data, 'household_spending')} />
    </IncomeTableGroup>
  </div>
);

IncomeTableEastern.propTypes = {
  data: PropTypes.array.isRequired,
};

export default IncomeTableEastern;

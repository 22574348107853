import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import RenderPdfTooltipContainer from '../RenderPdfTooltipContainer/RenderPdfTooltipContainer';
import RenderPdfPointsTooltip from '../RenderPdfPointsTooltip/RenderPdfPointsTooltip';
import RenderPdfSpendingTooltip from '../RenderPdfSpendingTooltip/RenderPdfSpendingTooltip';

import './RenderPdfTopValuesTooltipContainer.scss';

const tooltipContainerText = {
  header: 'renderPdf.header',
};

const RenderPdfTopValuesTooltipContainer = ({
  tooltipHeader,
  tooltipData,
  totalTooltipNumber,
}) => (
  <RenderPdfTooltipContainer>
    <div className='render-pdf-top-values-tooltip-container'>
      {tooltipHeader && (
        <Translate
          className='render-pdf-top-values-tooltip-container__header'
          value={tooltipContainerText.header}
        />
      )}
      {tooltipData
        .slice(0, totalTooltipNumber)
        .map((item) =>
          tooltipHeader ? (
            <RenderPdfPointsTooltip item={item} />
          ) : (
            <RenderPdfSpendingTooltip item={item} tooltipData={tooltipData} />
          ),
        )}
    </div>
  </RenderPdfTooltipContainer>
);

export default RenderPdfTopValuesTooltipContainer;

RenderPdfTopValuesTooltipContainer.propTypes = {
  tooltipHeader: PropTypes.bool,
  tooltipData: PropTypes.array,
  totalTooltipNumber: PropTypes.number,
};

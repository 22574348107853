import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import SectorsDetails from '../components/SectorsDetails';
import {
  getChosenSectors,
  getClickedSector,
  getColoredFilteredSectorList,
  getColoredNonZeroSectorList,
  getSectorErrors,
  getSectorSearchPhrase,
  isSectorsSizesLoading,
} from '../../../selectors/sector.selector';
import {
  getBreadcrumbsItems,
  getChosenRegion,
} from '../../../selectors/map.selector';
import {
  clearTags,
  filterSectors,
  getSectorListItemHoverColor,
  getSectorLists,
  getSectorsSizes,
  removeSector,
  saveSector,
  sectorClearError,
} from '../reducers/SectorSlice';

const mapStateToProps = (state) => {
  const sectorSearchPhrase = getSectorSearchPhrase(state);

  const sectorList = sectorSearchPhrase.length
    ? getColoredFilteredSectorList(state)
    : getColoredNonZeroSectorList(state);

  const { district } = getBreadcrumbsItems(state);

  return {
    sectorList,
    sectorSearchPhrase,
    tags: getChosenSectors(state),
    listLoading: isSectorsSizesLoading(state),
    errors: getSectorErrors(state),
    clickedSector: getClickedSector(state),
    chosenRegion: getChosenRegion(state),
    district,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      saveSector,
      removeSector,
      filterSectors,
      getSectorsSizes,
      getSectorLists,
      getSectorListItemHoverColor,
      clearTags,
      sectorClearError,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectorsDetails);

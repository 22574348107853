import React from 'react';
import { Translate } from 'react-redux-i18n';
import { downloadStatuses } from './utils';
import { DownloadStatusType } from './types.d';

import successIcon from '../../assets/download/success.svg';
import errorIcon from '../../assets/download/warning.svg';

interface ResolvedModalContentProps {
  downloadStatus: DownloadStatusType;
}

const ResolvedModalContent = ({
  downloadStatus,
}: ResolvedModalContentProps) => {
  const modalText = {
    successHeader: 'downloadModal.successHeader',
    successMessage: 'downloadModal.successMessage',
    errorHeader: 'downloadModal.errorHeader',
    errorMessage: 'downloadModal.errorMessage',
  };

  return (
    <>
      <img
        className='DownloadModalStatus__icon'
        src={
          downloadStatus !== downloadStatuses.ERROR ? successIcon : errorIcon
        }
        width={48}
        height={48}
        alt={downloadStatus}
      />
      <>
        <h4 className='DownloadModalStatus__header'>
          <Translate
            value={
              downloadStatus !== downloadStatuses.ERROR
                ? modalText.successHeader
                : modalText.errorHeader
            }
          />
        </h4>
        <p className='DownloadModalStatus__message'>
          <Translate
            value={
              downloadStatus !== downloadStatuses.ERROR
                ? modalText.successMessage
                : modalText.errorMessage
            }
          />
        </p>
      </>
    </>
  );
};

export default ResolvedModalContent;

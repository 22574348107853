import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import OnboardingContainer from '../../modules/Onboarding/containers/OnboardingContainer';
import LinkWithPersistSearch from '../LinkWithPersistSearch/LinkWithPersistSearch';

import './MobileIndicator.scss';

const MobileIndicator = ({ sectionId }) => (
  <div className='mobile-indicator'>
    <OnboardingContainer section='mobileIndicator' />
    <LinkWithPersistSearch
      to={{
        pathname: `${browserHistory.getCurrentLocation().pathname}`,
        hash: sectionId,
      }}
      id='onboarding-step-scroll'
    >
      <i className='geo-icon-indicator' />
    </LinkWithPersistSearch>
  </div>
);

MobileIndicator.propTypes = {
  sectionId: PropTypes.string.isRequired,
};

export default MobileIndicator;

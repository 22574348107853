import gql from 'graphql-tag';
import CONFIG from '../../config/config';

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getAreaShape = gql`
  query getAreaShape ($areaId: String!, $epsilon: Float!) {
    area(id: $areaId) {
      id
      name(language: "${CONFIG.language}")
      type
      simplifiedShape(epsilon: $epsilon)
    }
  }
`;

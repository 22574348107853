import CONFIG from '../config/config';
import {
  ElasticAreaTypes,
  SearchResultTypePhrases,
} from './globals/Constans.ts';

export function truncateResultTypePhrase(searchPhrase) {
  if (!searchPhrase) {
    return searchPhrase;
  }

  let parsedSearchPhrase = searchPhrase;

  CONFIG.locales.forEach((locale) => {
    const phrases = SearchResultTypePhrases[locale].join('|');
    const regex = locale === 'en' ? `\\b(${phrases})\\b` : `\\B(${phrases})\\B`;

    parsedSearchPhrase = parsedSearchPhrase.replace(new RegExp(regex, 'i'), '');
  });

  return parsedSearchPhrase.trim();
}

export const getElasticAreaTypes = (searchPhrase) => {
  if (!searchPhrase) {
    return ['district', 'governorate'];
  }

  const districtRegex = `(${ElasticAreaTypes.district.join('|')})`;
  const governorateRegex = `(${ElasticAreaTypes.governorate.join('|')})`;

  if (searchPhrase.match(new RegExp(districtRegex, 'i'))) {
    return ['district'];
  }
  if (searchPhrase.match(new RegExp(governorateRegex, 'i'))) {
    return ['governorate'];
  }
  return ['district', 'governorate'];
};

import { difference } from 'lodash';
import { Colors, ColorsByPriority } from '../../../helpers/globals/Constans.ts';

const getAvailableColors = (tags) => {
  const usedColors = tags.map((tag) => tag.color?.name);
  return difference(ColorsByPriority, usedColors);
};

export const getColorForNewTag = (tags) => {
  const availableColors = getAvailableColors(tags);

  const colorName = availableColors.shift();
  return Colors[colorName];
};

export const createColorCorrectorBasedOnTags = (
  tags,
  correctColorNames,
  colors,
) => {
  const availableColors = getAvailableColors(tags);
  const usedColorNames = [];

  return ({ name } = {}) => {
    const colorName =
      name && correctColorNames.includes(name) && !usedColorNames.includes(name)
        ? name
        : availableColors.shift();

    usedColorNames.push(colorName);
    return colors[colorName];
  };
};

export const correctColorsInTags = (tags) => {
  const getCorrectedColor = createColorCorrectorBasedOnTags(
    tags,
    ColorsByPriority,
    Colors,
  );

  return tags.map(({ color, ...sectorRest }) => ({
    ...sectorRest,
    color: getCorrectedColor(color),
  }));
};

export const mapSectorWithColor = ([{ id, name, root, type }, colorName]) => ({
  id,
  name,
  root: root || { id: null },
  type,
  color: Colors[colorName],
});

import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from 'react-redux-i18n';
import { numberBreakCases } from '../../helpers/globals/Constans.ts';
import FormattedValue from '../FormattedValue';
import ShortcutValueLabel from '../ShortcutValueLabel';

const MonetaryValue = ({ value, fractionDigits = 0, className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
  <div className={className} {...props}>
    <FormattedValue
      value={+parseFloat(value).toFixed(fractionDigits)}
      bottomThousandRange={numberBreakCases.thousands.size}
    />
    <ShortcutValueLabel value={value} />
    <Translate
      value='incomeChart.currencyRiyal'
      style={{ marginRight: '5px' }}
    />
  </div>
);

MonetaryValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  fractionDigits: PropTypes.number,
  props: PropTypes.object,
};

export default MonetaryValue;

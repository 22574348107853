import React from 'react';
import PropTypes from 'prop-types';
import NumberValueLabel from '../../NumberValueLabel';

const ChartTooltipNumberValueLabel = ({ value }) => (
  <span className='chart-tooltip-value__container'>
    <NumberValueLabel number={value} />
  </span>
);

ChartTooltipNumberValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ChartTooltipNumberValueLabel;

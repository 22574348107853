const csvReport = {
  name: 'الاسم',
  size: 'الحجم',
  nationality: 'الجنسية',
  age_group: 'الفئة العمرية',
  group: 'مجموعة',
  area_name: 'اسم المنطقة',
  value: 'القيمة',
  gender: 'الجنس',
  population_density_value: 'الكثافة السكانية',
  population_growth_rate_value: 'معدل النمو السكاني',
  household_size_value: 'حجم الأسرة',
  household_growth_rate_value: 'معدل نمو الأسرة',
  household_income_value: 'دخل الأسرة',
  household_savings_value: 'المدخرات الأسرية',
  household_spending_value: 'إنفاق الأسر',
  indicator: 'المؤشر',
};

export default csvReport;

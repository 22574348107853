{
  "dev": false,
  "HMR": false,
  "debugTree": false,
  "api": "https://api.map.910ths.sa",
  "envPath": "",
  "GAPI_KEY": "Change_This_Google_Maps_Key",
  "appLanguage": "ar",
  "enableShapes": true,
  "isRegionLimitationEnabled": true,
  "enableReduxLogger": false,
  "locales": ["ar"],
  "zeroAreaCase": {
    "enabled": false,
    "areaId": "governorate-10102"
  },
  "hotjar": {
    "enabled": true,
    "hjid": "HOTJAR_ID",
    "hjsv": "HOTJAR_SNIPPET_VERSION"
  },
  "searchOptions": {
    "matchNames": true,
    "matchIds": false
  },
  "a2iLink": "https://zadd.910ths.sa",
  "sentryDsn": "",
  "sentryEnvironment": "PROD",
  "ninetenthsUrl": "https://910ths.sa"
}

import React from 'react';
import { uniqueId } from 'lodash';

import Header from './Header/Header';

import './Header.scss';
import { getScreenBreakpoint } from '../../helpers/ResponsiveHelper';
import CONFIG from '../../config/config';
import { getA2IAllReportsLink } from '../../helpers/AppHelper'

const getMenuItemTranslation = (translation, screenMode) =>
  `${translation}.${screenMode}`;

const StaticHeader = () => {
  const screenMode = getScreenBreakpoint() === 'xs' ? 'mobile' : 'desktop';

  const menuItems = [
    {
      id: uniqueId('menu-'),
      location: CONFIG.a2iLink,
      icon: 'geo-icon-homepage',
      title: 'app.header.homepage',
    },
    {
      id: uniqueId('menu-'),
      location: '.',
      icon: 'geo-icon-map',
      title: 'app.header.map',
    },
  ];

  const submenuItems = [
    {
      id: uniqueId('submenu-'),
      ref: React.createRef(),
      submenuTitle: 'app.header.reports',
      items: [
        {
          id: uniqueId('submenu-item-'),
          location: getA2IAllReportsLink('all'),
          icon: 'geo-icon-allreports',
          title: getMenuItemTranslation('app.header.allReports', screenMode),
        },
        {
          id: uniqueId('submenu-item-'),
          location: getA2IAllReportsLink('product'),
          icon: 'geo-icon-products',
          title: getMenuItemTranslation('app.header.product', screenMode),
        },
        {
          id: uniqueId('submenu-item-'),
          location: getA2IAllReportsLink('sector'),
          icon: 'geo-icon-sectors',
          title: getMenuItemTranslation('app.header.sector', screenMode),
        },
        {
          id: uniqueId('submenu-item-'),
          location: getA2IAllReportsLink('region'),
          icon: 'geo-icon-area',
          title: getMenuItemTranslation('app.header.area', screenMode),
        },
      ],
    },
  ];

  return <Header menuItems={menuItems} submenuItems={submenuItems} />;
};

export default StaticHeader;

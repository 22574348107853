import React from 'react';
import PropTypes from 'prop-types';

import BarItem from './BarItem';
import BarLabel from './BarLabel';

const BarItemSubsectorsWrapper = ({
  item,
  scaleX,
  guides,
  barChartDataType,
  width,
  enableAnimation,
}) => (
  <>
    <BarLabel
      name={item.name}
      isSubsector={false}
      additionalClass='bar__label--sector-name'
    />
    {Object.keys(item.children).length ? (
      item.children.items.map((childrenItem) => (
        <React.Fragment key={childrenItem.id}>
          <BarLabel name={childrenItem.name} isSubsector />
          <BarItem
            width={width}
            barWidth={scaleX(childrenItem.size)}
            item={childrenItem}
            guides={guides}
            barChartDataType={barChartDataType}
            isMoreThanOneTag
            enableAnimation={enableAnimation}
          />
        </React.Fragment>
      ))
    ) : (
      <BarItem
        width={width}
        barWidth={scaleX(item.size)}
        key={item.id}
        item={item}
        guides={guides}
        barChartDataType={barChartDataType}
        isMoreThanOneTag
        enableAnimation={enableAnimation}
      />
    )}
  </>
);

BarItemSubsectorsWrapper.propTypes = {
  barChartDataType: PropTypes.string,
  width: PropTypes.number.isRequired,
  guides: PropTypes.number.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    id: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.object),
    tooltip: PropTypes.shape({
      header: PropTypes.string,
      percentageValue: PropTypes.number,
      value: PropTypes.number,
      valueLabel: PropTypes.string,
    }),
  }),
  scaleX: PropTypes.func,
  enableAnimation: PropTypes.bool,
};

export default BarItemSubsectorsWrapper;

import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const demographicTab = {
  billion: 'mld',
  million: 'mln',
  thousand: 'k',
  people: 'People',
  noData: 'Population data not available',
  dataInfo: {
    [riyadhRegionString]: 'Source: Riyadh City Development Authority, 2016',
    [easternRegionString]: 'Source: Eastern Region Municipality, 2020',
  },
};

export default demographicTab;

import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { I18n } from 'react-redux-i18n';

import './MapAutocomplete.scss';
import Autocomplete from '../../../../components/Autocomplete/AutocompleteComponent';
import { prepareViewport } from '../../../../helpers/MapHelper';
import { getElementByIdWhenRendered } from '../../../../helpers/domHelpers';
import { isMobileDevice } from '../../../../helpers/ResponsiveHelper';
import {
  BusinessMarkerRefreshInterval,
  DefaultBusinessZoomLvl,
  MapElementTypes,
} from '../../../../helpers/globals/Constans.ts';

function openBusinessTooltip(businessId) {
  getElementByIdWhenRendered(businessId, BusinessMarkerRefreshInterval).then(
    (marker) => marker.click(),
  );
}

export default class MapAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      shouldResetQuery: false,
    };
  }

  componentDidMount() {
    this.loadAutocompleter();
  }

  componentDidUpdate({ chosenRegion: prevChosenRegion }) {
    const { chosenRegion } = this.props;
    if (chosenRegion !== prevChosenRegion && prevChosenRegion) {
      this.resetQuery();
    }
  }

  onChangeCallback = (value) => {
    this.setState({ query: value });
    const { actions } = this.props;
    actions.getMapElements(value);
  };

  resetQuery = () => {
    this.setState({ shouldResetQuery: true });
  };

  onResetQueryCallback = () => {
    this.setState({ shouldResetQuery: false });
  };

  showItemOnMap = (resolve, prediction) => {
    const { map, actions, google } = this.props;

    const { boundingBox, location, secondaryText } = prediction;

    let { mainText: name } = prediction;

    if (secondaryText) {
      name = I18n.t('mapAutocomplete.name', { name, secondaryText });
    }

    if (boundingBox) {
      const viewport = prepareViewport(
        MapElementTypes.boundingBox,
        boundingBox,
        google,
      );

      map.fitBounds(viewport);
    }

    if (location && !boundingBox) {
      const pointOnMap = prepareViewport(MapElementTypes.location, location);

      map.setCenter(pointOnMap);

      map.setZoom(DefaultBusinessZoomLvl);

      openBusinessTooltip(prediction.id);
    }

    actions.getMapElements();

    resolve({
      ...prediction,
      name,
    });
  };

  onClickItem = (prediction) =>
    new Promise((resolve) => {
      if (isMobileDevice()) {
        setTimeout(() => {
          this.showItemOnMap(resolve, prediction);
        }, 500);
      } else {
        this.showItemOnMap(resolve, prediction);
      }
    });

  loadAutocompleter() {
    const { map, google } = this.props;
    const googleMaps = google.maps;
    // eslint-disable-next-line react/no-string-refs
    const cardRef = this.refs.card;

    map.controls[googleMaps.ControlPosition.TOP_RIGHT].push(cardRef);

    // eslint-disable-next-line react/no-unused-class-component-methods -- public method
    this.AutocompleteService = new googleMaps.places.AutocompleteService();
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.PlacesService = new googleMaps.places.PlacesService(map);
  }

  render() {
    const {
      searchPlaceholderTranslation,
      predictions,
      noResultsTranslation,
      google,
      map,
    } = this.props;
    const { query, shouldResetQuery } = this.state;
    return (
      // eslint-disable-next-line react/no-string-refs
      <div id='pac-card' className='top' ref='card'>
        <Autocomplete
          defaultOpen
          placeholder={searchPlaceholderTranslation}
          elements={predictions.list}
          noResults={predictions.noResults}
          noResultsTranslation={noResultsTranslation}
          onChangeCallback={debounce(500, this.onChangeCallback)}
          onClickItemCallback={this.onClickItem}
          googleCloseListener
          googleMaps={google.maps}
          map={map}
          query={query}
          shouldResetQuery={shouldResetQuery}
          onResetQueryCallback={this.onResetQueryCallback}
        />
      </div>
    );
  }
}

MapAutocomplete.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  searchPlaceholderTranslation: PropTypes.string,
  noResultsTranslation: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    getMapElements: PropTypes.func,
  }),
  predictions: PropTypes.shape({
    list: PropTypes.array,
    noResults: PropTypes.bool,
  }),
  chosenRegion: PropTypes.string,
};

import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import FlashModalCloseButton from './FlashModalCloseButton';

import './flashModal.scss';

let closeModalTimeout = null;

export const closeModalAfterTimeout = (closeModalFn) => {
  const closeModalTimeoutTime = 10000;

  closeModalTimeout = setTimeout(closeModalFn, closeModalTimeoutTime);
};

export const closeModal = (closeModalFn) => {
  if (closeModalTimeout) {
    clearTimeout(closeModalTimeout);
  }

  closeModalFn();
};

const FlashModal = ({
  closeModalFn,
  isOpen,
  parentSelector,
  isError,
  children,
}) => (
  <Modal
    onAfterOpen={() => closeModalAfterTimeout(closeModalFn)}
    isOpen={isOpen}
    parentSelector={parentSelector}
    contentLabel='Flash Modal'
    overlayClassName={{
      base: 'flash-modal__container',
    }}
    className={{
      base: 'flash-modal',
      afterOpen: isError ? 'flash-modal--error' : null,
    }}
  >
    <FlashModalCloseButton onClickFn={() => closeModal(closeModalFn)} />
    {children}
  </Modal>
);

FlashModal.propTypes = {
  closeModalFn: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isError: PropTypes.bool,
  parentSelector: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FlashModal.defaultProps = {
  isOpen: false,
  isError: false,
};

export default FlashModal;

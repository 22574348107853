const incomeChart = {
  heading: {
    desktop: 'Average income',
    mobile: 'Average income',
  },
  position: 'Position: {{position}}/{{numBubbles}}',
  notAvailable: 'not available',
  monetaryValue: 'SAR {{income}}',
  currencyRiyal: 'SAR',
};

export default incomeChart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Trigger from 'rc-trigger';

import { placements } from './placements';
import Content from './Content';

import './Tooltip.scss';

class Tooltip extends Component {
  getPopupElement = () => {
    const { arrowContent, overlay, prefixCls, id } = this.props;
    return [
      <div className={`${prefixCls}-arrow`} key='arrow'>
        {arrowContent}
      </div>,
      <Content
        key='content'
        trigger={this.trigger}
        prefixCls={prefixCls}
        id={id}
        overlay={overlay}
      />,
    ];
  };

  // eslint-disable-next-line react/no-unused-class-component-methods -- used outside component
  getPopupDomNode() {
    return this.trigger.getPopupDomNode();
  }

  saveTrigger = (node) => {
    this.trigger = node;
  };

  render() {
    const {
      id,
      overlayClassName,
      trigger,
      mouseEnterDelay,
      mouseLeaveDelay,
      overlayStyle,
      prefixCls,
      children,
      onVisibleChange,
      afterVisibleChange,
      transitionName,
      animation,
      placement,
      customPlacements,
      align,
      destroyTooltipOnHide,
      defaultVisible,
      getTooltipContainer,
      arrowContent,
      overlay,
      ...triggerRestProps // popupVisible
    } = this.props;

    return (
      <Trigger
        id={id}
        popupClassName={overlayClassName}
        ref={this.saveTrigger}
        prefixCls={prefixCls}
        popup={this.getPopupElement}
        action={trigger}
        builtinPlacements={customPlacements || placements} // 'placements.js' data is being overriden by custom 'builtinPlacements' objects for other tooltips if declared in one file.
        popupPlacement={placement}
        popupAlign={align}
        getPopupContainer={getTooltipContainer}
        onPopupVisibleChange={onVisibleChange}
        afterPopupVisibleChange={afterVisibleChange}
        popupTransitionName={transitionName}
        popupAnimation={animation}
        defaultPopupVisible={defaultVisible}
        destroyPopupOnHide={destroyTooltipOnHide}
        mouseLeaveDelay={mouseLeaveDelay}
        popupStyle={overlayStyle}
        mouseEnterDelay={mouseEnterDelay}
        arrowContent={arrowContent}
        overlay={overlay}
        // eslint-disable-next-line react/jsx-props-no-spreading  -- trigger requires to not pass attribute at all
        {...triggerRestProps}
      >
        {children}
      </Trigger>
    );
  }
}

Tooltip.defaultProps = {
  prefixCls: 'rc-tooltip',
  mouseEnterDelay: 0,
  destroyTooltipOnHide: false,
  mouseLeaveDelay: 0.1,
  align: {},
  placement: 'right',
  trigger: ['hover'],
  arrowContent: null,
};

Tooltip.propTypes = {
  trigger: PropTypes.any,
  children: PropTypes.any,
  defaultVisible: PropTypes.bool,
  popupVisible: PropTypes.bool,
  placement: PropTypes.string,
  customPlacements: PropTypes.object,
  transitionName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  animation: PropTypes.any,
  onVisibleChange: PropTypes.func,
  afterVisibleChange: PropTypes.func,
  overlay: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  overlayStyle: PropTypes.object,
  overlayClassName: PropTypes.string,
  prefixCls: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  getTooltipContainer: PropTypes.func,
  destroyTooltipOnHide: PropTypes.bool,
  align: PropTypes.object,
  arrowContent: PropTypes.any,
  id: PropTypes.string,
};

export default Tooltip;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withContentRect } from 'react-measure';
import { range } from 'lodash';
import { I18n } from 'react-redux-i18n';

import SimpleBar from './SimpleBar';
import AxisX from './AxisX';
import AxisY from './AxisY';
import { prepareAxisXScale } from '../../../../helpers/chartHelpers';

class SimpleBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphWasRendered: false,
    };
  }

  componentDidUpdate() {
    this.renderAxisXInPortalContainer();
  }

  setGraphAsRendered = () => {
    this.setState({
      graphWasRendered: true,
    });
  };

  renderAxisXInPortalContainer() {
    const { contentRect, portalId, mirrored } = this.props;
    const { width } = contentRect.bounds;
    const portalContainer = document.getElementById(portalId);

    ReactDOM.render(
      <AxisX
        guideLines={this.guideLines}
        maxAxisXItem={this.maxAxisXItem}
        cssKey={this.cssKey}
        singleAxisItem={this.singleAxisItem}
        width={width}
        mirrored={mirrored}
        type='value'
      />,
      portalContainer,
    );
  }

  renderBars() {
    const {
      mirrored,
      contentRect,
      labels,
      data,
      color,
      enableAnimation,
      dataLabel,
      total,
      tooltip,
      overwriteHeight,
    } = this.props;

    const { graphWasRendered } = this.state;

    const { width } = contentRect.bounds;

    return labels.map((label, index) => {
      const singleBarItem = data[label.id] || {};

      const { value = 0, percentageValue = 0 } = singleBarItem;

      const newOverwriteHeight = overwriteHeight
        ? overwriteHeight[label.id]
        : null;

      const translatedTooltipHeader = I18n.t(`demographicChart.${dataLabel}`);

      const renderedTooltip = tooltip.enabled
        ? {
            ...tooltip,
            data: {
              header: `${translatedTooltipHeader} (${label.label})`,
              valueLabel: `demographicChart.tooltipDemographicLabel.${dataLabel}`,
              value,
              percentageValue,
            },
          }
        : null;

      const setGraphAsRendered =
        index === labels.length - 1 ? this.setGraphAsRendered : null;

      return (
        <SimpleBar
          key={label.id}
          singleBarItem={singleBarItem}
          overwriteHeight={newOverwriteHeight}
          color={color}
          total={total}
          width={width}
          maxAxisXItem={this.maxAxisXItem}
          mirrored={mirrored}
          setGraphAsRendered={setGraphAsRendered}
          graphWasRendered={graphWasRendered}
          tooltip={renderedTooltip}
          enableAnimation={enableAnimation}
        />
      );
    });
  }

  render() {
    const {
      mirrored,
      contentRect,
      overwriteScale,
      maxSize,
      guideLines,
      measureRef,
    } = this.props;

    const SimpleBarChartClasses = classnames('simple-bar-chart', 'col-xs');

    const className = classnames('simple-bar-chart__bars row', {
      'reverse end-xs': mirrored,
    });

    const { width } = contentRect.bounds;

    this.maxAxisXItem =
      overwriteScale || prepareAxisXScale(maxSize, guideLines);

    this.cssKey = mirrored ? 'left' : 'right';

    this.singleAxisItem = this.maxAxisXItem / guideLines;

    this.guideLines = range(guideLines + 1);

    return (
      <div className={SimpleBarChartClasses} ref={measureRef}>
        <div className={className}>
          {this.renderBars()}
          <AxisY
            guideLines={this.guideLines}
            maxAxisXItem={this.maxAxisXItem}
            cssKey={this.cssKey}
            singleAxisItem={this.singleAxisItem}
            width={width}
            mirrored={mirrored}
          />
        </div>
      </div>
    );
  }
}

SimpleBarChart.defaultProps = {
  tooltip: {
    enabled: false,
    type: 'default',
  },
};

SimpleBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  maxSize: PropTypes.number.isRequired,
  guideLines: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  total: PropTypes.number,
  dataLabel: PropTypes.string,
  contentRect: PropTypes.shape({
    bounds: PropTypes.object.isRequired,
  }),
  tooltip: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  measureRef: PropTypes.func.isRequired,
  overwriteHeight: PropTypes.object,
  overwriteScale: PropTypes.number,
  mirrored: PropTypes.bool,
  enableAnimation: PropTypes.bool,
  portalId: PropTypes.string.isRequired,
};

export default withContentRect('bounds')(SimpleBarChart);

const map = {
  markers: {
    name: 'Name',
    address: 'Address',
    subSectorLabel: 'Subsector',
  },
  contextMenu: {
    copy: {
      text: 'Copy establishment information',
    },
  },
};

export default map;

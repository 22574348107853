import { createSelector } from '@reduxjs/toolkit';

export const getOnboarding = (state) => state.onboarding;

export const getOnboardingStep = createSelector(
  getOnboarding,
  ({ currentStep }) => currentStep,
);

export const getIsOnboardingActive = createSelector(
  getOnboarding,
  ({ onboardingActive }) => onboardingActive,
);

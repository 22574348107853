import React from 'react';

import NumberValueLabel from '../../../../components/NumberValueLabel';

import './SidebarValue.scss';

export interface SidebarValueProps {
  number: number | string;
  className?: string;
}

const SidebarValue = ({ number, className = '' }: SidebarValueProps) => (
  <p
    className={`SidebarValue sidebar-badge__number text text--extra-large text--highlighted ${className}`}
  >
    <NumberValueLabel number={number} />
  </p>
);

export default SidebarValue;

import React from 'react';
import PropTypes from 'prop-types';

import PercentageValue from '../../PercentageValue';

import './PanelItemPercentageValue.scss';

const PanelItemPercentageValue = ({ number, enableAnimation }) => (
  <span className='panel-item__value--percentage'>
    <PercentageValue number={number} enableAnimation={enableAnimation} />
  </span>
);

PanelItemPercentageValue.propTypes = {
  number: PropTypes.number.isRequired,
  enableAnimation: PropTypes.bool,
};

export default PanelItemPercentageValue;

import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import { pick } from 'lodash';

import DemographicBarChartComponent from '../components/DemographicBarChartComponent';
import DemographicActions from '../actions/DemographicActions';
import {
  getAreaShapes,
  getChosenRegion,
  getDemographicSpendingData,
  getMapAreas,
  getSidebarBreadcrumbs,
} from '../../../selectors/map.selector';
import {
  getDemographicDataLoader,
  getDemographicGraphData,
  getDemographicLegendHeights,
} from '../../../selectors/demographic.selector';
import {
  getDemographicIndicatorsQuery,
  getIncomeQuery,
} from '../../../graphql/queries/IncomeQueries';
import getDemographicSpendingDataQuery from '../../../graphql/queries/DemographicSpendingDataQuery';
import {
  easternRegionString,
  regionStringToIdMap,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const mapStateToProps = (state) => ({
  breadcrumbs: getSidebarBreadcrumbs(state),
  listLoading: getDemographicDataLoader(state),
  demographicGraphData: getDemographicGraphData(state),
  demographicLegendHeights: getDemographicLegendHeights(state),
  areaShapes: getAreaShapes(state),
  chosenRegion: getChosenRegion(state),
  spendingData: getDemographicSpendingData(state),
  areas: getMapAreas(state),
});

export const mapStateToPropsForDemographicBarChartComponent = mapStateToProps;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...DemographicActions,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getDemographicIndicatorsQuery, {
    props: ({ data }) => ({
      groups: pick(data, [
        'population_density',
        'population_growth_rate',
        'household_size',
        'household_growth_rate',
        'household_income',
        'household_savings',
        'household_spending',
      ]),
      loading: data.loading,
    }),
    skip: (props) =>
      !props.chosenRegion || props.chosenRegion === riyadhRegionString,
  }),
  graphql(getIncomeQuery, {
    options: (props) => ({
      variables: {
        areas: [regionStringToIdMap[props.chosenRegion]],
      },
    }),
    props: ({ data }) => ({
      groups: pick(data, [
        'all',
        'male',
        'female',
        'saudi',
        'nonSaudi',
        'saudiMale',
        'saudiFemale',
        'nonSaudiMale',
        'nonSaudiFemale',
      ]),
      loading: data.loading,
    }),
    skip: (props) =>
      !props.chosenRegion || props.chosenRegion === easternRegionString,
  }),
  graphql(getDemographicSpendingDataQuery, {
    options: (props) => ({
      variables: {
        areas: props.areas,
      },
    }),
    props: ({ data }) => ({
      spendingData: data.spendingData,
      loading: data.loading,
    }),
    skip: (props) =>
      !props.chosenRegion || props.chosenRegion === riyadhRegionString,
  }),
)(DemographicBarChartComponent);

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiPaths } from '../../../helpers/globals/Constans.ts';

import BusinessBarChartComponent from '../../Businesses/components/BusinessBarChartComponent';
import DemographicBarChartComponent, {
  DISPLAY_TYPE,
} from '../../Demographic/components/DemographicBarChartComponent';
import RenderPdfComponent from '../RenderPdfComponent/RenderPdfComponent';
import RenderPdfTopValuesTooltipContainer from '../RenderPdfTopValuesTooltipContainer/RenderPdfTopValuesTooltipContainer';
import RenderPdfDemographicTooltipContainer from '../RenderPdfDemographicTooltipContainer/RenderPdfDemographicTooltipContainer';
import {
  getBarChartComponentData,
  getDemographicBarChartComponentData,
  getDistricName,
} from '../../../helpers/charts/utils';

import './RenderPdfContainer.scss';

const RenderPdfContainer = () => {
  const [barChartComponentProps, setBarChartComponentProps] = useState();
  const [
    demographicBarChartComponentProps,
    setDemographicBarChartComponentProps,
  ] = useState();
  const [regionLabel, setRegionLabel] = useState();
  const [districtName, setDistrictName] = useState();

  const easternRegion = 'المنطقة الشرقية';
  const centralRegion = 'الرياض، المنطقة الوسطى';
  const regionName =
    regionLabel === 'breadcrumbs.centralRegionName'
      ? centralRegion
      : easternRegion;

  const totalNumberOfPages =
    regionName === centralRegion ||
    demographicBarChartComponentProps?.areas[0].length > 15
      ? '6'
      : '7';

  useEffect(() => {
    (async () => {
      const id = new URLSearchParams(window.location.search).get('id');

      const response = await axios.get(`${ApiPaths.DATA}/${id}`);

      const barChartComponentData = getBarChartComponentData(
        response.data.data.attributes.data.pointsOfInterestStoreData,
        10,
      );

      setBarChartComponentProps(barChartComponentData);

      const demographicBarChartComponentData =
        getDemographicBarChartComponentData(
          response.data.data.attributes.data.demographicStoreData,
        );

      setDemographicBarChartComponentProps(demographicBarChartComponentData);

      setRegionLabel(barChartComponentData.breadcrumbs.firstLine.label);

      const districName = getDistricName(barChartComponentData.breadcrumbs);

      if (districName) {
        setDistrictName(districName);
      }
    })();
  }, []);
  return (
    <div className='render-pdf-container'>
      {barChartComponentProps && demographicBarChartComponentProps ? (
        <>
          <RenderPdfComponent
            dataType='points'
            regionName={regionName}
            districtName={districtName}
            pageNumber='1'
            totalNumberOfPages={totalNumberOfPages}
          >
            <BusinessBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...barChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.PANEL}
            />
            <p className='render-pdf-component__content__info'>
              {regionLabel === 'breadcrumbs.centralRegionName'
                ? 'المصدر: الهيئة الملكية لمدينة الرياض، 2016'
                : 'المصدر: أمانة المنطقة الشرقية، 2020'}
            </p>
          </RenderPdfComponent>
          <RenderPdfComponent
            dataType='points'
            regionName={regionName}
            districtName={districtName}
            pageNumber='2'
            totalNumberOfPages={totalNumberOfPages}
          >
            <RenderPdfTopValuesTooltipContainer
              tooltipHeader
              tooltipData={
                barChartComponentProps.businessChartData.businessList
              }
              totalTooltipNumber={3}
            />
            <BusinessBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...barChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.CHART}
            />
          </RenderPdfComponent>
          <RenderPdfComponent
            dataType='demographic'
            regionName={regionName}
            districtName={districtName}
            pageNumber='3'
            totalNumberOfPages={totalNumberOfPages}
          >
            <DemographicBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...demographicBarChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.PANEL}
            />
            <p className='render-pdf-component__content__info'>
              {regionLabel === 'breadcrumbs.centralRegionName'
                ? 'المصدر: الهيئة الملكية لمدينة الرياض، 2016'
                : 'المصدر: أمانة المنطقة الشرقية، 2020'}
            </p>
          </RenderPdfComponent>
          <RenderPdfComponent
            dataType='demographic'
            regionName={regionName}
            districtName={districtName}
            pageNumber='4'
            totalNumberOfPages={totalNumberOfPages}
          >
            <RenderPdfDemographicTooltipContainer
              tooltipData={
                demographicBarChartComponentProps.demographicGraphData
                  .byGenderAndAgeGroupPopulation
              }
            />
            <DemographicBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...demographicBarChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.GENDER_CHART}
            />
          </RenderPdfComponent>
          <RenderPdfComponent
            dataType='demographic'
            regionName={regionName}
            districtName={districtName}
            pageNumber='5'
            totalNumberOfPages={totalNumberOfPages}
          >
            <RenderPdfDemographicTooltipContainer
              tooltipData={
                demographicBarChartComponentProps.demographicGraphData
                  .byNationalityAndAgeGroupPopulation
              }
            />
            <DemographicBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...demographicBarChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.NATIONALITY_CHART}
            />
          </RenderPdfComponent>
          <RenderPdfComponent
            dataType='demographic'
            regionName={regionName}
            districtName={districtName}
            pageNumber='6'
            totalNumberOfPages={totalNumberOfPages}
          >
            <DemographicBarChartComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...demographicBarChartComponentProps}
              listLoading={false}
              enableAnimation={false}
              displayType={DISPLAY_TYPE.INCOME_CHART}
            />
          </RenderPdfComponent>
          {regionName === easternRegion &&
          demographicBarChartComponentProps.areas[0].length < 15 ? (
            <RenderPdfComponent
              dataType='demographic'
              regionName={regionName}
              districtName={districtName}
              pageNumber='7'
              totalNumberOfPages={totalNumberOfPages}
            >
              <RenderPdfTopValuesTooltipContainer
                tooltipData={
                  demographicBarChartComponentProps.spendingData.facts
                }
                totalTooltipNumber={3}
              />
              <DemographicBarChartComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...demographicBarChartComponentProps}
                listLoading={false}
                enableAnimation={false}
                displayType={DISPLAY_TYPE.SPENDING_DATA}
              />
            </RenderPdfComponent>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default RenderPdfContainer;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { I18n } from 'react-redux-i18n';

import Selection from '../../../../helpers/SelectionHelper';
import { TOOLTIP_COLOR_MAPPINGS } from '../../constants/MapConstants.ts';
import getIconPath from './helpers/getIconPath';
import InfoWindowTooltipContent from './InfoWIndowTooltipContent';

const selection = new Selection();

const InfoWindowTooltipComponent = ({
  loading,
  updateContainerMargin,
  business,
  color,
  isAdmin,
  actions,
  chosenRegion,
}) => {
  const containerId = uniqueId('infoWindow-');

  const windowRef = useRef(containerId);

  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [isIconLoaded, setIsIconLoaded] = useState(false);
  const [tooltipIconPath, setTooltipIconPath] = useState(null);

  const isBusinessReady = business && !loading;

  const copyElementText = () => {
    const infoWindow = windowRef.current;

    infoWindow.classList.remove('contextmenu-opened');
    actions.copyBusinessDetailsIntoClipboard();
  };

  const bindEvents = () => {
    const infoWindow = windowRef.current;
    const infoWindowContent = infoWindow.getElementsByClassName(
      'info-window__content',
    )[0];

    const selectRange = infoWindowContent;

    selection.setRange(selectRange);

    infoWindow.addEventListener('contextmenu', (e) => {
      const { target } = e;
      if (target.classList.contains('context-menu__item')) {
        return;
      }

      infoWindow.classList.add('contextmenu-opened');

      // Select info-window__content text
      selection.setSelection();

      const text = infoWindowContent.innerText || infoWindowContent.textContent;

      actions.setInfoWindowContentText(text);

      const rect = infoWindowContent.getBoundingClientRect();
      const { clientX, clientY } = e;

      setPositionX(clientX - rect.left);
      setPositionY(clientY - rect.top);
    });

    infoWindow.addEventListener('click', (e) => {
      const { target } = e;
      selection.toggleSelection();

      e.stopPropagation();

      if (target.classList.contains('context-menu__item')) {
        copyElementText();
      } else {
        infoWindow.classList.remove('contextmenu-opened');
      }
    });
  };

  const setIconLoaded = () => setIsIconLoaded(true);

  useEffect(() => {
    if (isBusinessReady) {
      if (!isIconLoaded) {
        setTooltipIconPath(
          getIconPath(business.isic.iconId, TOOLTIP_COLOR_MAPPINGS[color]),
        );
      } else {
        bindEvents();
        updateContainerMargin();
      }
    }
  }, [business, isIconLoaded]);

  return isBusinessReady ? (
    <div ref={windowRef} className='info-window__container'>
      <div className='info-window__icon-container'>
        <img
          className='info-window__icon'
          alt='tooltip icon'
          src={tooltipIconPath}
          onLoad={setIconLoaded}
        />
        <div className='info-window__icon__bottom-background' />
      </div>
      <InfoWindowTooltipContent
        business={business}
        isAdmin={isAdmin}
        chosenRegion={chosenRegion}
        sectorColorName={TOOLTIP_COLOR_MAPPINGS[color]}
      />
      <div
        className='context-menu'
        style={{
          left: `${positionX}px`,
          top: `${positionY}px`,
        }}
      >
        <button
          type='button'
          className='context-menu__item'
          onClick={copyElementText}
        >
          {I18n.t('map.contextMenu.copy.text')}
        </button>
      </div>
      <div className='info-window__arrow-container'>
        <div className='info-window__arrow' />
        <div className='info-window__arrow info-window__arrow--hider' />
      </div>
    </div>
  ) : (
    <div ref={windowRef} />
  );
};

InfoWindowTooltipComponent.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    buildingNumber: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.number,
    ]),
    city: PropTypes.shape({
      name: PropTypes.string,
    }),
    district: PropTypes.shape({
      name: PropTypes.string,
    }),
    isic: PropTypes.shape({
      id: PropTypes.string,
      iconId: PropTypes.string,
      singularName: PropTypes.string,
      type: PropTypes.string,
      root: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    name: PropTypes.string,
    street: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
    zipCode: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool.isRequired,
  updateContainerMargin: PropTypes.func,
  actions: PropTypes.shape({
    setInfoWindowContentText: PropTypes.func,
    copyBusinessDetailsIntoClipboard: PropTypes.func,
  }),
  color: PropTypes.string,
  isAdmin: PropTypes.bool,
  chosenRegion: PropTypes.string.isRequired,
};

export default InfoWindowTooltipComponent;

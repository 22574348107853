import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderNav = ({ children, isActive }) => {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isActive]);
  return (
    <nav
      className={classNames('HeaderNav', {
        'header-menu': true,
        'HeaderNav--active': isActive,
      })}
    >
      {children}
    </nav>
  );
};

HeaderNav.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool.isRequired,
};

export default HeaderNav;

import GoogleAPI from './GoogleApi';
import MarkerConstructor from '../../../modules/Map/components/Markers/MarkerConstructor';
import InfoWindowConstructor from '../../../modules/Map/components/InfoWindow/InfoWindowConstructor';
import GoogleServices from './GoogleServices';

export default function setGoogleServices() {
  const { map, google } = GoogleAPI();
  const placesService = new google.maps.places.PlacesService(map);
  const markerConstructor = new MarkerConstructor(google.maps);
  const infoWindowConstructor = new InfoWindowConstructor(google.maps);

  GoogleServices.placesService = placesService;
  GoogleServices.markerConstructor = markerConstructor;
  GoogleServices.infoWindowConstructor = infoWindowConstructor;
}

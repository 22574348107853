import React from 'react';

import LogoImage from '../../assets/header/new-logo.svg';
import './Logo.scss';
import CONFIG from '../../config/config';

const Logo = () => (
  <a href={CONFIG.ninetenthsUrl} className='logo'>
    <img src={LogoImage} alt='910ths logo' />
  </a>
);

export default Logo;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { find } from 'lodash';

import Panel from '../../../components/Panel';
import MapLoader from '../../Map/components/LoadingMap/Loader';
import PyramidChart from '../../Charts/components/PyramidChart';
import TranslatedTitle from '../../../components/TranslatedTitleComponent';
import { StickyScrollWalker } from '../../../components/StickyScrollWalker/StickyScrollWalker';
import { isMobileDevice } from '../../../helpers/ResponsiveHelper';
import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';
import iconBreakdownGender from '../../../assets/demographic_icons/icon-breakdown-gender.svg';
import iconBreakdownNationality from '../../../assets/demographic_icons/icon-breakdown-nationality.svg';

import SpendingDataTreeMap from './SpendingDataTreeMap/SpendingDataTreeMap';
import IncomeChartEastern from './IncomeChartEastern';
import IncomeChart from './IncomeChart';
import './DemographicBarChart.scss';

export const DISPLAY_TYPE = {
  PANEL: 'panel',
  CHART: 'chart',
  GENDER_CHART: 'gender-chart',
  NATIONALITY_CHART: 'nationality-chart',
  INCOME_CHART: 'income-chart',
  SPENDING_DATA: 'spending-data',
};

function updatePanelItemsWithIncome(panelItems, groups, areaShapes) {
  const found = find(
    groups?.all.facts,
    (fact) => fact.area.id === areaShapes.id,
  );

  const modifiedPanelItems = panelItems;

  if (found) {
    modifiedPanelItems[3].value.params.size = found.value;
  } else {
    modifiedPanelItems[3] = null;
  }

  return modifiedPanelItems;
}

const DemographicBarChartComponent = ({
  listLoading,
  breadcrumbs,
  demographicGraphData,
  demographicLegendHeights,
  areaShapes,
  loading,
  groups,
  spendingData,
  actions,
  chosenRegion,
  displayType,
  enableAnimation,
}) => {
  if (loading || listLoading || !demographicGraphData) {
    return <MapLoader />;
  }

  const { panelItems } = demographicGraphData;

  const isMobile = isMobileDevice(/xs/);

  const value = 'demographicChart.demographicStatistics';

  const preparedActions = {
    updateLegendHeight: actions?.updateDemographicLegendHeight,
  };

  const genderTitle = demographicGraphData.byGenderAndAgeGroupPopulation.title;
  const nationalityTitle =
    demographicGraphData.byNationalityAndAgeGroupPopulation.title;

  const pyramidCharts = (
    <div>
      <div className='chart chart--pyramid'>
        <TranslatedTitle
          translation={{ value: genderTitle }}
          iconSrc={iconBreakdownGender}
          className='heading-6--white'
        />
        <PyramidChart
          overwriteHeight={demographicLegendHeights}
          actions={preparedActions}
          data={demographicGraphData.byGenderAndAgeGroupPopulation}
          dataKeys={['male', 'female']}
          isMobile={isMobile}
          tooltip={{ enabled: true, type: 'demographic' }}
          enableAnimation={enableAnimation}
        />
      </div>

      <div className='chart chart--pyramid'>
        <TranslatedTitle
          translation={{ value: nationalityTitle }}
          iconSrc={iconBreakdownNationality}
          className='heading-6--white'
        />
        <PyramidChart
          overwriteHeight={demographicLegendHeights}
          actions={preparedActions}
          data={demographicGraphData.byNationalityAndAgeGroupPopulation}
          dataKeys={['saudi', 'nonSaudi']}
          isMobile={isMobile}
          tooltip={{ enabled: true, type: 'demographic' }}
          enableAnimation={enableAnimation}
        />
      </div>
    </div>
  );

  const incomeChartMap = {
    [easternRegionString]: (
      <IncomeChartEastern
        areaId={areaShapes.id}
        groups={groups}
        loading={loading}
      />
    ),
    [riyadhRegionString]: (
      <IncomeChart areaId={areaShapes.id} groups={groups} loading={loading} />
    ),
  };

  const fixedPanelItems =
    chosenRegion === riyadhRegionString
      ? updatePanelItemsWithIncome(panelItems, groups, areaShapes)
      : panelItems;

  if (displayType === DISPLAY_TYPE.PANEL) {
    return (
      <Panel
        breadcrumbs={breadcrumbs}
        panelItems={fixedPanelItems}
        value={value}
        enableAnimation={enableAnimation}
      />
    );
  }

  if (displayType === DISPLAY_TYPE.GENDER_CHART) {
    return (
      <div className='gender-chart'>
        <TranslatedTitle
          translation={{ value: genderTitle }}
          iconSrc={iconBreakdownGender}
          className='heading-6--white'
        />
        <PyramidChart
          overwriteHeight={demographicLegendHeights}
          actions={preparedActions}
          data={demographicGraphData.byGenderAndAgeGroupPopulation}
          dataKeys={['male', 'female']}
          isMobile={isMobile}
          tooltip={{ enabled: true, type: 'demographic' }}
          enableAnimation={enableAnimation}
        />
      </div>
    );
  }

  if (displayType === DISPLAY_TYPE.NATIONALITY_CHART) {
    return (
      <div className='nationality-chart'>
        <TranslatedTitle
          translation={{ value: nationalityTitle }}
          iconSrc={iconBreakdownNationality}
          className='heading-6--white'
        />
        <PyramidChart
          overwriteHeight={demographicLegendHeights}
          actions={preparedActions}
          data={demographicGraphData.byNationalityAndAgeGroupPopulation}
          dataKeys={['saudi', 'nonSaudi']}
          isMobile={isMobile}
          tooltip={{ enabled: true, type: 'demographic' }}
          enableAnimation={enableAnimation}
        />
      </div>
    );
  }

  if (displayType === DISPLAY_TYPE.INCOME_CHART) {
    return chosenRegion === riyadhRegionString ? (
      <div className='income-chart'>
        <IncomeChart
          areaId={areaShapes.id}
          groups={groups}
          loading={loading}
          showAverageRiyadhCityValue
          fillWidth
        />
      </div>
    ) : (
      <div className='income-chart'>
        <IncomeChartEastern
          areaId={areaShapes.id}
          groups={groups}
          loading={loading}
          showAverageEasternProvinceValue
          fillWidth
        />
      </div>
    );
  }

  if (displayType === DISPLAY_TYPE.SPENDING_DATA) {
    return (
      <div className='spending-data'>
        <SpendingDataTreeMap spendingData={spendingData} />
      </div>
    );
  }

  return (
    <div className='demographic-section__content'>
      <div className='demographic-section__transform'>
        <Panel
          breadcrumbs={breadcrumbs}
          panelItems={fixedPanelItems}
          value={value}
          enableAnimation={enableAnimation}
        />
        {pyramidCharts}
        {incomeChartMap[chosenRegion]}
      </div>
      {chosenRegion === easternRegionString && (
        <SpendingDataTreeMap spendingData={spendingData} />
      )}
      <div className='Demographic__data-info'>
        <Translate value={`demographicTab.dataInfo.${chosenRegion}`} />
      </div>
    </div>
  );
};

const personDataShape = PropTypes.shape({
  color: PropTypes.string,
  data: PropTypes.object,
  dataLabel: PropTypes.string,
  maxSize: PropTypes.number,
  total: PropTypes.number,
});

DemographicBarChartComponent.propTypes = {
  breadcrumbs: PropTypes.object.isRequired,
  listLoading: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    updateDemographicLegendHeight: PropTypes.func,
  }),
  demographicData: PropTypes.shape({
    totalPopulation: PropTypes.number.isRequired,
    malePopulation: PropTypes.number.isRequired,
    saudiPopulation: PropTypes.number.isRequired,
  }),
  displayType: PropTypes.oneOf(Object.values(DISPLAY_TYPE)),
  demographicGraphData: PropTypes.shape({
    panelItems: PropTypes.array,
    byGenderAndAgeGroupPopulation: PropTypes.shape({
      male: personDataShape,
      female: personDataShape,
      title: PropTypes.string,
      labels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
    byNationalityAndAgeGroupPopulation: PropTypes.shape({
      saudi: personDataShape,
      nonSaudi: personDataShape,
      title: PropTypes.string,
      labels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
  }),
  demographicLegendHeights: PropTypes.object,
  loading: PropTypes.bool,
  groups: PropTypes.shape({}),
  spendingData: PropTypes.shape({}),
  areaShapes: PropTypes.shape({ id: PropTypes.string }),
  chosenRegion: PropTypes.string,
  enableAnimation: PropTypes.bool,
};

export default StickyScrollWalker(DemographicBarChartComponent, {
  cssClass:
    'demographic-section__scroll-container fancy-scroll--desktop--demographic',
});

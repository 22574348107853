import { partial } from 'lodash';
import React from 'react';
import IncomeChart from '../IncomeChart';
import {
  factToBubble,
  prepareIncomeChartData,
} from '../../constants/IncomeChartHelpers';
import IncomeTableEastern from '../IncomeTableEastern';

class IncomeChartEastern extends IncomeChart {
  render() {
    const {
      groups,
      loading,
      areaId,
      showAverageEasternProvinceValue,
      fillWidth,
    } = this.props;

    if (loading) {
      return null;
    }

    const data = prepareIncomeChartData({
      groups,
      areaId,
      convertFactToBubble: partial(factToBubble, areaId),
    });

    return (
      <div className='chart IncomeChartEastern'>
        {data && data.length > 0 && (
          <IncomeTableEastern
            data={data}
            showAverageValue={showAverageEasternProvinceValue}
            fillWidth={fillWidth}
          />
        )}
      </div>
    );
  }
}

export default IncomeChartEastern;

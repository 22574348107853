import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SimpleLegendItem = ({ additionalClass, legendItem }) => {
  const legendItemClasses = classnames('simple-legend', additionalClass);

  return (
    <div className={legendItemClasses}>
      <div
        className='simple-legend__color'
        style={{ background: legendItem.color }}
      />
      <div
        className='simple-legend__label paragraph-3--dark-gray'
        style={{ color: legendItem.color }}
      >
        {legendItem.label}
      </div>
    </div>
  );
};

SimpleLegendItem.propTypes = {
  additionalClass: PropTypes.string,
  legendItem: PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired,
  }).isRequired,
};

export default SimpleLegendItem;

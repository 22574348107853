import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';

const HeaderNavItem = ({ location, icon, title, isSubmenuItem }) => (
  <a
    href={location}
    className={classNames({
      [icon]: true,
      'header-submenu-position': isSubmenuItem,
      'header-menu-position': !isSubmenuItem,
      'header-menu-position--active': !isSubmenuItem && icon.includes('map'),
    })}
  >
    {I18n.t(title)}
  </a>
);

HeaderNavItem.defaultProps = {
  isSubmenuItem: false,
};

HeaderNavItem.propTypes = {
  location: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  isSubmenuItem: PropTypes.bool,
};

export default HeaderNavItem;

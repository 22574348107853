import React from 'react';
import PropTypes from 'prop-types';
import riyadhRegionActive from '../../assets/regions_icons/riyadh-region-active.svg';
import riyadhRegionDefault from '../../assets/regions_icons/riyadh-region-default.svg';
import easternRegionActive from '../../assets/regions_icons/eastern-region-active.svg';
import easternRegionDefault from '../../assets/regions_icons/eastern-region-default.svg';
import RegionButton from './RegionButton';
import {
  easternRegionString,
  riyadhRegionString,
} from '../../helpers/globals/Constans.ts';

const RegionDropdown = ({ handleChooseRegion, chosenRegion }) => (
  <div className='RegionDropdown'>
    <div className='RegionDropdown__regions-container'>
      <RegionButton
        iconDefault={riyadhRegionDefault}
        iconActive={riyadhRegionActive}
        region={riyadhRegionString}
        onClick={handleChooseRegion}
        chosenRegion={chosenRegion}
      />
      <RegionButton
        iconDefault={easternRegionDefault}
        iconActive={easternRegionActive}
        region={easternRegionString}
        onClick={handleChooseRegion}
        chosenRegion={chosenRegion}
      />
    </div>
  </div>
);

RegionDropdown.propTypes = {
  handleChooseRegion: PropTypes.func.isRequired,
  chosenRegion: PropTypes.string,
};

export default RegionDropdown;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Translate } from 'react-redux-i18n';
import { browserHistory } from 'react-router';
import riyadhRegionGreen from '../../assets/regions_icons/riyadh-region-default.svg';
import easternRegionGreen from '../../assets/regions_icons/eastern-region-default.svg';

import ButtonIcon from '../ButtonIcon';
import {
  disableScroll,
  enableScroll,
  getUrlSegments,
  isHomeUrl,
} from '../../helpers/AppHelper';
import './Popup.scss';
import {
  AppPaths,
  easternRegionString,
  regionStringToIdMap,
  riyadhRegionString,
} from '../../helpers/globals/Constans.ts';
import { doesFilterSearchParamsURLExist } from '../../modules/Sectors/components/filtersURLSearchParamsUtils';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    disableScroll();

    const region = getUrlSegments()[1];

    if (Object.keys(regionStringToIdMap).includes(region)) {
      this.setState({ isOpen: false });
      setTimeout(() => this.handleClosePopup(region, false), 300);
    } else {
      browserHistory.push(`${AppPaths.HOME}`);
    }

    this.removeListener = browserHistory.listen(() => {
      const { chosenRegion } = this.props;
      const urlRegion = getUrlSegments()[1];

      if (!urlRegion && chosenRegion) {
        window.location.assign('/');
      }
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }

  handleClosePopup = (chosenRegion, triggeredByUser = true) => {
    const {
      hasUserVisited,
      actions: { setChosenRegion, setOnboardingActive },
    } = this.props;

    this.setState({ isOpen: false });
    setChosenRegion(chosenRegion);

    if (triggeredByUser) {
      browserHistory.push(`${AppPaths.HOME}${chosenRegion}`);
    }

    const areFilters = doesFilterSearchParamsURLExist(
      browserHistory.getCurrentLocation().search,
    );

    if (!hasUserVisited && isHomeUrl() && !areFilters) {
      setOnboardingActive();
    } else {
      enableScroll();
    }
  };

  render() {
    const { isOpen } = this.state;

    return (
      <Modal
        className='Popup'
        overlayClassName='Popup__overlay'
        bodyOpenClassName='Popup__body--open'
        isOpen={isOpen}
        shouldFocusAfterRender={false}
      >
        <div className='Popup__header'>
          <Translate value='app.popup.heading' />
        </div>
        <div className='Popup__text'>
          <Translate value='app.popup.content' />
        </div>
        <div className='Popup__buttons'>
          <ButtonIcon
            text={`app.regions.${riyadhRegionString}`}
            icon={riyadhRegionGreen}
            onClick={() => this.handleClosePopup(riyadhRegionString)}
          />
          <ButtonIcon
            text={`app.regions.${easternRegionString}`}
            icon={easternRegionGreen}
            onClick={() => this.handleClosePopup(easternRegionString)}
          />
        </div>
      </Modal>
    );
  }
}

Popup.propTypes = {
  hasUserVisited: PropTypes.bool,
  chosenRegion: PropTypes.string,
  actions: PropTypes.shape({
    setChosenRegion: PropTypes.func.isRequired,
    setOnboardingActive: PropTypes.func.isRequired,
  }),
};

export default Popup;

import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import DownloadModal from '../DownloadModal';
import Button from '../Button';
import './DownloadReportButton.scss';

const DownloadReportButton = ({
  chosenRegion,
  modalButtonTranslations,
  buttonId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalName = chosenRegion ? chosenRegion.split('-')[0] : '';

  return (
    <>
      <div className='download-report-button'>
        <Button
          id={buttonId}
          text={I18n.t(modalButtonTranslations.buttonText)}
          onClick={() => setIsModalOpen(true)}
          modifier='turqoise'
          display='block'
          type='full'
        />
      </div>
      <DownloadModal
        modalName={modalName}
        isDownloadModalOpen={isModalOpen}
        setIsDownloadModalOpen={setIsModalOpen}
      />
    </>
  );
};

DownloadReportButton.propTypes = {
  chosenRegion: PropTypes.string,
  modalButtonTranslations: PropTypes.object,
  buttonId: PropTypes.string,
};

export default DownloadReportButton;

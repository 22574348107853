import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { getOnboarding } from '../../../selectors/onboarding.selector';
import OnboardingActions from '../actions/OnboardingActions';
import { setUserAsVisited } from '../../Map/actions/MapActions';

import OnboardingComponent from '../components/OnboardingComponent';

const mapStateToProps = (state) => ({
  ...getOnboarding(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { ...OnboardingActions, setUserAsVisited },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnboardingComponent);

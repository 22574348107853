import * as Sentry from '@sentry/react';
import createStore from './store/createStore';

const persistedState = {};

// Store Initialization
// ------------------------------------
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// export const store = createStore(window.__INITIAL_STATE__, sentryReduxEnhancer);
export default createStore(persistedState, sentryReduxEnhancer);

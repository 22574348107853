import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberValueLabel from '../../../components/NumberValueLabel';

class SectorsListItem extends React.Component {
  paragraphRef = React.createRef();

  render() {
    const {
      color: colorObj,
      handleCollapse,
      hasSubsectorsAfterSearch,
      isActive,
      isDisabled,
      isSubsector,
      name,
      onClick,
      getSectorListItemHoverColor,
      size,
    } = this.props;

    const color = colorObj && colorObj.value;

    const classes = classNames('paragraph-1 sectors-details__list-item', {
      disabled: size === 0,
      'sectors-details__list-item--with-dot': color,
      'paragraph-1--dark-gray': isSubsector,
    });

    const icon = (
      <i
        onClick={handleCollapse}
        className={classNames('sectors__icon geo-icon-angle-down', {
          'sectors__icon--active': isActive,
          'sectors__icon--disabled': isDisabled,
        })}
      />
    );

    return (
      <div className={classes} style={{ color }}>
        {/* eslint-disable-next-line */}
        <p
          ref={this.paragraphRef}
          onClick={onClick}
          onMouseEnter={() => {
            const thisElement = this.paragraphRef.current;
            if (!thisElement.parentNode.style.color) {
              thisElement.style.color = getSectorListItemHoverColor();
            }
          }}
          onMouseLeave={() => {
            this.paragraphRef.current.style.color = '';
          }}
          className='sectors-details__item-name'
          style={{
            color,
          }}
        >
          <span className='dot' style={{ background: color }} />
          {name}
          (<NumberValueLabel number={size} />)
        </p>
        {!isSubsector && hasSubsectorsAfterSearch && icon}
      </div>
    );
  }
}

SectorsListItem.propTypes = {
  handleCollapse: PropTypes.func,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    labelValue: PropTypes.string,
    transparentValue: PropTypes.string,
  }),
  hasSubsectorsAfterSearch: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  getSectorListItemHoverColor: PropTypes.func.isRequired,
  isSubsector: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SectorsListItem.defaultProps = {
  handleCollapse: null,
  isSubsector: false,
  isDisabled: false,
  hasSubsectorsAfterSearch: false,
};

export default SectorsListItem;

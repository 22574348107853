const demographicChart = {
  noDemographicDataFound: 'No Demograpic data Found in that Area',
  demographicStatistics: 'Demographic statistics in {{areaName}}',
  noDataProvided:
    'Population statistics in this region are not available. Please select another area.',
  population: 'Population',
  ageDistribution: 'Age distribution',
  nationality: 'Nationality',
  total: 'Total',
  male: 'Male',
  female: 'Female',
  saudi: 'Saudi',
  nonSaudi: 'Non Saudi',
  tooltipDemographicLabel: {
    male: 'Population (percentage from males)',
    female: 'Population (percentage from females)',
    saudi: 'Population (percentage from Saudis)',
    nonSaudi: 'Population (percentage from Non-Saudis)',
  },
  averageIncome: 'Average income for the district',
  population_density: 'Population density',
  population_growth_rate: 'Population growth rate',
  household_size: 'Household size',
  household_growth_rate: 'Household growth rate',
  household_income: 'Household income',
  household_savings: 'Household savings',
  household_spending: 'Household spending',
  averageSavings: 'Average savings',
  eastern: {
    groups: {
      people: 'Population by density and growth rate',
      household: 'Households by size and growth rate',
      sar: 'Average spendings and income',
    },
    suffix: {
      populationDensity: 'people / ha',
      populationGrowthRate: '%',
      householdSize: 'people / family',
      householdGrowthRate: '%',
    },
  },
};

export default demographicChart;

import React from 'react';
import PropTypes from 'prop-types';

import { createAxisStyles } from '../../../../helpers/BarChartHelper';

function createStyles(point) {
  if (!point) {
    return {};
  }

  const rtlStyles = ['left', 'right'];

  const value = point.position;

  return createAxisStyles(rtlStyles, value);
}

const AxisY = ({ points = [] }) => (
  <div className='axis-y'>
    {points.map((point, index) => (
      <div
        // eslint-disable-next-line
        key={index}
        className='axis-y__line'
        style={createStyles(point)}
      />
    ))}
  </div>
);

AxisY.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      width: PropTypes.number,
    }),
  ),
};

export default AxisY;

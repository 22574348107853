import gql from 'graphql-tag';
import CONFIG from '../../config/config';
import { buildStringifiedLocalesForGraphQL } from '../../helpers/AppHelper';

const {
  language,
  locales,
  searchOptions: { matchIds, matchNames },
} = CONFIG;

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getMapPlacesPredictions = gql`
  query getMapPlacesPredictions ($searchPhrase: String!, $areas: [String!]!, $elasticAreaTypes: [String!]) {
    globalSearchResults(
      searchInput: {
        locales: [${buildStringifiedLocalesForGraphQL(locales)}]
        searchPhrase: $searchPhrase,
        matchNames: ${matchNames},
        matchIds: ${matchIds}
      }
      elasticAreaTypes: $elasticAreaTypes,
      areas: $areas
      elasticEntityIndices: ["area"]
      googlePlaceAutocompleteTypes: []
      limit: 10
    ) {
      results: items {
        id
        name (language: "${language}")
        type
        source
        parentName (language: "${language}")
        boundingBox {
          minimalLatitude
          maximalLatitude
          minimalLongitude
          maximalLongitude
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

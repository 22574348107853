const onboarding = {
  complete: 'end tour',
  next: 'next',
  restart: 'start over',
  exit: 'skip',
  steps: {
    breadcrumbs:
      'You will find in this section the name of the site associated with all the information shown',
    business: 'You will be able to find the details of the selected sector',
    demographic:
      "You'll be able to find demographic details for the selected area",
    scroll: 'To complete the explanatory steps please click on the arrow below',
    search: "Use search to find an area you're interested in",
    sectors: 'Select the main or sub-sector that you are interested in',
    download: 'You can generate custom report based on your needs',
  },
};

export default onboarding;

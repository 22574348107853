import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const mapAutocomplete = {
  searchPlaceholder: {
    [riyadhRegionString]: 'يرجى إدخال اسم الحي',
    [easternRegionString]: 'الرجاء إدخال اسم المحافظة أو الحي',
  },
  noResultsMessage: 'لا توجد نتائج تتوافق مع بحثك',
  searchCoordinates: 'إحداثيات',
  name: '{{name}}، {{secondaryText}}',
  coordinates: '{{lat}},{{lng}}',
};

export default mapAutocomplete;

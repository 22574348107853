import { connect } from 'react-redux';

import BusinessBarChartComponent from '../components/BusinessBarChartComponent';
import { getSidebarBreadcrumbs } from '../../../selectors/map.selector';
import {
  isBusinessLoading,
  getAllBusisessesFromArea,
  getBusinessChartData,
} from '../../../selectors/business.selector';

const mapStateToProps = (state) => ({
  breadcrumbs: getSidebarBreadcrumbs(state),
  listLoading: isBusinessLoading(state),
  businessChartData: getBusinessChartData(state),
  businessesNumber: getAllBusisessesFromArea(state),
});

export const mapStateToPropsForBusinessBarChartComponent = mapStateToProps;

export default connect(mapStateToProps)(BusinessBarChartComponent);

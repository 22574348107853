import addHotjarScript from './addHotjarScript';

export default function middlewareFactory(settings) {
  const actionType = 'trigger';

  if (!settings) {
    // eslint-disable-next-line no-console
    console.error('Hotjar settings (hjid & hjsv) must be provided for Hotjar');

    return () => (next) => (action) => {
      next(action);
    };
  }

  if (!window.hj) {
    addHotjarScript(settings);
  }

  return () => (next) => (action) => {
    const hotjar = action && action.meta && action.meta.hotjar;

    if (
      window.hj &&
      window.hj instanceof Function &&
      hotjar &&
      hotjar[actionType]
    ) {
      window.hj(actionType, hotjar[actionType]);
    }

    return next(action);
  };
}

import {
  easternRegionString,
  riyadhRegionString,
} from '../../../helpers/globals/Constans.ts';

const app = {
  placeholder: 'الرجاء إدخال القيمة',
  backToMap: 'الرجوع إلى الخارطة',
  supportedBy: 'بدعم من:',
  mapTooltip: {
    district: '{{districtName}}، ',
    address: '{{areaName}} {{zipCode}}',
  },
  header: {
    homepage: 'الصفحة الرئيسية',
    map: 'الخارطة التفاعلية',
    reports: 'التقارير',
    allReports: {
      mobile: 'كل التقارير',
      desktop: 'كل الأقسام',
    },
    sector: {
      mobile: 'تقارير القطاعات',
      desktop: 'القطاعات',
    },
    product: {
      mobile: 'تقارير المنتجات',
      desktop: 'المنتجات',
    },
    area: {
      mobile: 'تقارير المناطق',
      desktop: 'المناطق',
    },
    services: {
      title: 'خدمات تسعة أعشار'
    },
    nineTenths: 'تسعة أعشار',
  },
  popup: {
    heading: 'الرجاء تحديد المنطقة',
    content:
      'نقوم حالياً بتوفير مواقع الأعمال التجارية والخصائص الديموغرافية في مدينة الرياض وبعض مدن المنطقة الشرقية. ستشمل الخارطة المعلومات على مستوى المملكة مستقبلاً.',
  },
  regions: {
    [riyadhRegionString]: 'المنطقة الوسطى',
    [easternRegionString]: 'المنطقة الشرقية',
  },
  easternRegionTooltip: 'باستثناء محافظتي الأحساء وحفر الباطن',
};

export default app;

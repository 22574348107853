export const BUSINESS_TABLE = {
  name: 'business',
  columns: [
    {
      nameTranslateKey: 'csvReport.name',
      valuePath: 'name',
    },
    {
      nameTranslateKey: 'csvReport.size',
      valuePath: 'size',
    },
  ],
};

export const GENDER_AGE_TABLE = {
  name: 'gender_age',
  columns: [
    {
      nameTranslateKey: 'csvReport.gender',
      valuePath: 'genderId',
    },
    {
      nameTranslateKey: 'csvReport.age_group',
      valuePath: 'key',
    },
    {
      nameTranslateKey: 'csvReport.size',
      valuePath: 'value',
    },
  ],
};

export const NATIONALITY_AGE_TABLE = {
  name: 'nationality_age',
  columns: [
    {
      nameTranslateKey: 'csvReport.nationality',
      valuePath: 'genderId',
    },
    {
      nameTranslateKey: 'csvReport.age_group',
      valuePath: 'key',
    },
    {
      nameTranslateKey: 'csvReport.size',
      valuePath: 'value',
    },
  ],
};

export const RIYADH_GROUPS_TABLE = {
  name: 'riyadh_group',
  columns: [
    {
      nameTranslateKey: 'csvReport.group',
      valuePath: 'key',
    },
    {
      nameTranslateKey: 'csvReport.area_name',
      valuePath: 'area.name',
    },
    {
      nameTranslateKey: 'csvReport.value',
      valuePath: 'value',
    },
  ],
};

export const getEasternGroupsTable = (key) => ({
  name: key,
  columns: [
    {
      nameTranslateKey: 'csvReport.area_name',
      valuePath: 'area.name',
    },
    {
      nameTranslateKey: `csvReport.${key}_value`,
      valuePath: 'value',
    },
  ],
});

export const SPENDING_DATA_TABLE = {
  name: 'spending_data',
  columns: [
    {
      nameTranslateKey: 'csvReport.indicator',
      valuePath: 'subIndicator',
    },
    {
      nameTranslateKey: 'csvReport.value',
      valuePath: 'value',
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, locationShape } from 'react-router';

const LinkWithPersistSearch = ({
  to,
  className,
  id,
  onClick,
  location,
  children,
}) => {
  const { search } = location;

  const toNormalized = typeof to === 'string' ? { pathname: to } : to;

  return (
    <Link
      className={className}
      id={id}
      onClick={onClick}
      to={{ ...toNormalized, search }}
    >
      {children}
    </Link>
  );
};

LinkWithPersistSearch.propTypes = {
  to: Link.propTypes.to,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.node,
  location: locationShape,
};

export default withRouter(LinkWithPersistSearch);

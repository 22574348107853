import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import './TranslatedTitleComponent.scss';

const TranslatedTitle = ({ className, translation, iconSrc }) => (
  <h4 className={classNames(`${className} translated-title`)}>
    <div className='translated-title__container'>
      <Translate value={translation.value} />
      <img className='translated-title__icon' src={iconSrc} alt='' />
    </div>
  </h4>
);

TranslatedTitle.propTypes = {
  className: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
  translation: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
};

export default TranslatedTitle;

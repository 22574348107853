import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

const PlainTooltipContent = ({ closeTooltip, breadcrumbLabel }) => (
  <div className='breadcrumbs__tooltip-content'>
    <Translate value={breadcrumbLabel} />
    <div className='geo-icon-asset-26white__background' />
    <button
      className='geo-icon-asset-26white'
      type='button'
      onClick={closeTooltip}
    />
  </div>
);

PlainTooltipContent.propTypes = {
  closeTooltip: PropTypes.func.isRequired,
  breadcrumbLabel: PropTypes.string.isRequired,
};

export default PlainTooltipContent;

const sectors = {
  sectorsHeader: 'Choose up to three sectors or subsectors',
  sectorTitle: 'Sectors [en]',
  subsectorTitle: 'Sub-sectors [en]',
  tagsRemaining: 'Remaining: {{number}}',
  searchPlaceholder: 'Type in sector/subsector',
  noSectorsFoundMessage: 'No results match your search in {{districtName}}',
  outsideOfDistrictNoSectorsFoundMessage:
    'No {{sectorTitle}} found matching your search',
};

export default sectors;

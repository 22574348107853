const incomeChart = {
  heading: {
    desktop: 'متوسط الدخل الشهري للفرد العامل',
    mobile: 'متوسط الدخل الشهري للفرد العامل',
  },
  position: 'الترتيب: {{numBubbles}}/{{position}}',
  notAvailable: 'غير متوفر',
  monetaryValue: '{{income}} ر.س',
  currencyRiyal: 'ر.س',
};

export default incomeChart;

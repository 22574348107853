import { sum } from 'lodash';

import { MapMarkerTypes } from '../types/index.ts';
import { clustersSizeObject } from './globals/Constans.ts';
import { getScreenBreakpoint } from './ResponsiveHelper';
import GoogleAPI from '../store/globalReducers/Google/GoogleApi';
import GoogleServices from '../store/globalReducers/Google/GoogleServices';
import { markersStore } from '../modules/Map/actions/mapStore';
import MarkerHelperTS from './MarkerHelperTS.ts';

export function getClusterPixelSize(highestClusterLabel) {
  const actualSize = getScreenBreakpoint();

  return (
    (clustersSizeObject[actualSize].max - clustersSizeObject[actualSize].min) /
    highestClusterLabel
  );
}

export function mapTagsColorById(tags) {
  const mappedTagsColors = {};

  tags.forEach(({ color, id }) => {
    if (color) {
      mappedTagsColors[id] = color;
    }
  });

  return mappedTagsColors;
}

function addMarkersToTheMap(
  sector,
  map,
  business,
  color,
  markersIndex,
  numberOfMarkersToDraw,
  store,
) {
  if (!business) return [];

  const googleMaps = GoogleAPI().google.maps;
  const { map: GoogleMap } = GoogleAPI();
  const { tags } = sector;
  const areClustersEnabled = map.clusters.enabled;
  const { highestClusterLabel } = map.clusters;
  const clusterPixelSize = getClusterPixelSize(highestClusterLabel);
  const Marker = GoogleServices.markerConstructor;

  const screenBreakpoint = getScreenBreakpoint();

  const actualSize = clustersSizeObject[screenBreakpoint].min;

  if (!Marker) {
    return [];
  }

  return business.map((marker) => {
    const clusterSize = actualSize + marker.label * clusterPixelSize;
    const markerPosition = new googleMaps.LatLng(
      marker.latitude,
      marker.longitude,
    );

    const clusterOptions = {
      size: clusterSize,
      label: marker.label,
      tagId: marker.tagId,
      tagsLength: tags.length,
      markersIndex,
    };

    const markerOnMap = new Marker(
      markerPosition,
      GoogleMap,
      color,
      areClustersEnabled,
      clusterOptions,
      tags,
      numberOfMarkersToDraw,
      marker.id,
      store,
    );

    return {
      id: marker.id,
      markerOnMap,
    };
  });
}

export function prepareAndDisplayMarkers(sector, map, businesses, store) {
  const markerIsicKeys = Object.keys(businesses.items);

  const { tags } = sector;

  let markers = markersStore.getAll();

  const mappedTagsColors = mapTagsColorById(tags);

  Object.values(MapMarkerTypes).forEach((elementClassName) => {
    MarkerHelperTS.removeElementsWithIdByClassName(elementClassName);
  });

  const numberOfMarkersToDraw = sum(
    markerIsicKeys.map((markerKey) => businesses.items[markerKey].length),
  );

  if (tags.length) {
    tags.forEach((tag, index) => {
      markers = markers.concat(
        addMarkersToTheMap(
          sector,
          map,
          businesses.items[tag.id],
          mappedTagsColors[tag.id],
          index,
          numberOfMarkersToDraw,
          store,
        ),
      );
    });
  } else {
    markerIsicKeys.forEach((markerKey, index) => {
      markers = addMarkersToTheMap(
        sector,
        map,
        businesses.items[markerKey],
        mappedTagsColors[markerKey],
        index,
        numberOfMarkersToDraw,
        store,
      );
    });
  }

  return markers;
}

import React from 'react';
import PropTypes from 'prop-types';

import PyramidLegend from './PyramidLegend';

import './FixedPyramidChartAxis.scss';

const FixedPyramidChartAxis = ({
  axisXMirroredUniqueId,
  axisXUniqueId,
  legend,
}) => (
  <div className='row top-xs sticky fixed-axis-portal axis-x--shadow'>
    <div className='col-xs-12'>
      <div className='row'>
        <div
          id={axisXMirroredUniqueId}
          className='col-xs-5 x-axis__container'
        />
        <div className='fixed-axis-portal__divider col-xs-2' />
        <div id={axisXUniqueId} className='col-xs-5 x-axis__container' />
      </div>
    </div>
    <div className='col-xs-12'>
      <PyramidLegend items={legend} />
    </div>
  </div>
);

FixedPyramidChartAxis.propTypes = {
  axisXMirroredUniqueId: PropTypes.string.isRequired,
  axisXUniqueId: PropTypes.string.isRequired,
  legend: PropTypes.array.isRequired,
};

export default FixedPyramidChartAxis;

import React from 'react';
import PropTypes from 'prop-types';

import IncomeTableItem from './IncomeTableItem';

import './IncomeTable.scss';

const IncomeTable = ({ data }) => (
  <div className='IncomeTable'>
    {data.map((group) => (
      // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
      <IncomeTableItem {...group} />
    ))}
  </div>
);

IncomeTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default IncomeTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import './NoDataWrapper.scss';

const Index = ({ text, className }) => (
  <div className={`no-data-wrapper ${className}`}>
    <div className='no-data-wrapper__message'>
      <div className='flash-modal flash-modal--data-wrapper'>
        <Translate value={text} />
      </div>
    </div>
  </div>
);

Index.defaultProps = {
  className: '',
};

Index.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Index;

import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import IncomeTableGroup from '../../../Demographic/components/IncomeTableGroup';
import iconPeople from '../../../../assets/demographic_icons/icon-people.svg';
import iconHousehold from '../../../../assets/demographic_icons/icon-household.svg';
import iconSar from '../../../../assets/demographic_icons/icon-sar.svg';
import Item from './Item';
import { findByLabel } from '../../../utils';

const BubbleChartEastern = ({ data, showAverageValue, fillWidth }) => (
  <div
    className={classNames('BubbleChart', { 'BubbleChart--fill': fillWidth })}
  >
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.people'
      iconSrc={iconPeople}
    >
      <div className='BubbleChart__group'>
        <Item
          hideRanking
          key='population_density'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'population_density')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
        <Item
          hideRanking
          key='population_growth_rate'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'population_growth_rate')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
      </div>
    </IncomeTableGroup>
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.household'
      iconSrc={iconHousehold}
    >
      <div className='BubbleChart__group'>
        <Item
          hideRanking
          key='household_size'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'household_size')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
        <Item
          hideRanking
          key='household_growth_rate'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'household_growth_rate')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
      </div>
    </IncomeTableGroup>
    <IncomeTableGroup
      titleKey='demographicChart.eastern.groups.sar'
      iconSrc={iconSar}
    >
      <div className='BubbleChart__group'>
        <Item
          hideRanking
          key='household_income'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'household_income')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
        <Item
          hideRanking
          key='household_spending'
          grouped
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...findByLabel(data, 'household_spending')}
          extraData={data.find((item) => item.label === 'household_savings')}
          showValue={showAverageValue}
          fillWidth={fillWidth}
        />
      </div>
    </IncomeTableGroup>
  </div>
);

BubbleChartEastern.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showAverageValue: PropTypes.bool,
  fillWidth: PropTypes.bool,
};

export default BubbleChartEastern;

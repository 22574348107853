import gql from 'graphql-tag';
import CONFIG from '../../config/config';

export const getBusinessFromAreaQuery = gql`
  query getBusinessFromAreaQuery($areas: [String]!) {
    businesses {
      size(areas: $areas)
    }
  }
`;

export const getBusinessFromAreaAndIsicsQuery = gql`
  query getBusinessFromAreaAndIsicsQuery($areas: [String]!, $isics: [String]!) {
    businesses {
      size(areas: $areas, isics: $isics)
    }
  }
`;

export const getBusinessesByBoundingBox = gql`
  query getBusinessesByBoundingBox(
    $minLat: Float!
    $maxLat: Float!
    $minLng: Float!
    $maxLng: Float!
  ) {
    businesses {
      size(
        locationBoundingBox: {
          minimalLatitude: $minLat
          maximalLatitude: $maxLat
          minimalLongitude: $minLng
          maximalLongitude: $maxLng
        }
      )
    }
  }
`;

export const getBusinessesByBoundingBoxWithTags = gql`
  query getBusinessesByBoundingBoxWithTags(
    $minLat: Float!
    $maxLat: Float!
    $minLng: Float!
    $maxLng: Float!
    $isics: [String]!
  ) {
    businesses {
      size(
        locationBoundingBox: {
          minimalLatitude: $minLat
          maximalLatitude: $maxLat
          minimalLongitude: $minLng
          maximalLongitude: $maxLng
        }
        isics: $isics
      )
    }
  }
`;

export const getSingleBusinessQuery = gql`
  query getSingleBusinessQuery ($businessId: String!) {
    business(id: $businessId) {
      id
      location {
        latitude
        longitude
      }
      name: name(language: "${CONFIG.language}")
      city {
        name(language: "${CONFIG.language}")
      }
      governorate {
        name(language: "${CONFIG.language}")
      }
      district {
        name(language: "${CONFIG.language}")
      }
      zipCode {
        code
      }
      isic {
        id
        iconId
        singularName(language: "${CONFIG.language}")
        type
        root {
          id
        }
      }
    }
  }
`;

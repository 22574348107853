import { I18n } from 'react-redux-i18n';

// Globals
import {
  allDefaultAreas,
  AppLanguages,
  areaTypesMap,
  AutocompletePlaceTypes,
  businessMaxSizeInBounds,
  Colors,
  maxZoomLevel,
  regionStringToIdMap,
  riyadhCityId,
  riyadhRegionString,
  RouteName,
  searchAreaTypes,
  shapeEpsilonSimplifyValue,
} from '../../../helpers/globals/Constans.ts';
import apolloClient from '../../../apolloClient';
import CONFIG from '../../../config/config';
import GoogleAPI from '../../../store/globalReducers/Google/GoogleApi';

// Helpers
import {
  extendBreadcrumbs,
  getBreadcrumbConnectedAndSidebarData,
} from '../../../helpers/BreadcrumbsHelper';
import {
  getChosenRegionData,
  getMinZoomLevel,
  isOutsideChosenRegion,
  setMapCenterToBounds,
} from '../../../helpers/MapHelper';
import { getClusterShowType } from '../../../helpers/BusinessHelper.ts';
import { prepareAndDisplayMarkers } from '../../../helpers/MarkerHelper';
import { getAppLanguage, isArabicDirection } from '../../../helpers/AppHelper';
import {
  getElasticAreaTypes,
  truncateResultTypePhrase,
} from '../../../helpers/SearchHelper';

// Queries
import { getBreadcrumbs } from '../../../graphql/queries/MapQuery';
import { getUser } from '../../../graphql/queries/UserQuery';
import { getAreaShape } from '../../../graphql/queries/AreaQueries';
import { getMapPlacesPredictions } from '../../../graphql/queries/GlobalSearchQueries';
import {
  getBusinessesByBoundingBox,
  getBusinessesByBoundingBoxWithTags,
  getBusinessFromAreaQuery,
} from '../../../graphql/queries/BusinessQuery';
import {
  getDemographicIndicatorsQuery,
  getIncomeQuery,
} from '../../../graphql/queries/IncomeQueries';

// Actions
import { setDemographicData } from '../../Demographic/actions/DemographicActions';
import { getBusinesses } from '../../Businesses/actions/BusinessActions';
import { getBusinessMarkerBackground } from '../components/Markers/utils';
import {
  getChosenRegion,
  getMapAreas,
  getMapMarkers,
  getMapRange,
} from '../../../selectors/map.selector';

import { isAdmin } from '../../../helpers/UserHelper';

// ------------------------------------
// Constants
// ------------------------------------
import {
  RUN_MAP_EXTENSIONS,
  SET_AREA_SHAPE,
  SET_AREAS,
  SET_BREADCRUMB_CONNECTED_DATA,
  SET_BREADCRUMB_ITEMS,
  SET_BREADCRUMBS_IN_SIDEBAR,
  SET_BUSINESSES_ON_MAP,
  SET_CHOSEN_REGION,
  SET_CLUSTER_LEVEL,
  SET_CURRENT_LOCATION,
  SET_MAP_OPTIONS,
  SET_MAP_RANGE,
  SET_MAP_SEARCH,
  SET_MAP_TYPE,
  SET_MAP_ZOOM,
  SET_MODAL_CONTENT,
  SET_MODAL_VISIBILITY,
  SET_USER,
  SET_USER_AS_VISITED,
  SET_USER_POSITION,
  UPDATE_VIEWPORT,
} from '../constants/MapActionsConstants';
import { saveAreaBusinesses } from '../../Businesses/reducers/BusinessesSlice';
import {
  SAVE_GROUPS,
  SET_DEMOGRAPHIC_SPENDING_DATA,
} from '../../Demographic/constants/DemographicConstants';
import getDemographicSpendingDataQuery from '../../../graphql/queries/DemographicSpendingDataQuery';

import setGoogleServices from '../../../store/globalReducers/Google/setGoogleService';
import redrawCurrentMarkers from './redrawCurrentMarkersAction';
import setInfoWindowOnMap from './setInfoWindowOnMapAction';
import { markersStore, shapesStore } from './mapStore';
import getOutlineColor from './utils.ts';

const Coordinates = require('coordinate-parser');

// ------------------------------------
// Actions
// ------------------------------------
export function getMapElements(searchPhrase) {
  return (dispatch, getState) => {
    const state = getState();
    const chosenRegion = getChosenRegion(state);

    const elasticAreaTypes = getElasticAreaTypes(searchPhrase);
    const parsedSearchPhrase = truncateResultTypePhrase(searchPhrase);

    if (parsedSearchPhrase && parsedSearchPhrase.length) {
      apolloClient
        .query({
          query: getMapPlacesPredictions,
          variables: {
            searchPhrase: parsedSearchPhrase,
            areas: [regionStringToIdMap[chosenRegion]],
            elasticAreaTypes,
          },
        })
        .then(({ data }) => {
          const { results } = data.globalSearchResults;

          const predictions = results.map((result) => {
            const isAreaTypeResult = !!searchAreaTypes[result.type];
            const isRouteResult = result.type === AutocompletePlaceTypes.route;
            const routePossibleLabels = RouteName[CONFIG.language];
            const [streetFound] = routePossibleLabels.filter((routeLabel) => {
              const resultNameInLowerCase = result.name.toLowerCase();

              return resultNameInLowerCase.search(routeLabel) !== -1;
            });

            const isRouteLabelInResult = !!(
              isRouteResult &&
              streetFound &&
              streetFound.length
            );

            let { name, parentName } = result;

            if (isAreaTypeResult || (isRouteResult && !isRouteLabelInResult)) {
              name = I18n.t(`breadcrumbs.areaTypes.${result.type}`, {
                areaName: name,
              });
            }

            if (
              isRouteResult &&
              isRouteLabelInResult &&
              RouteName.en.indexOf(streetFound) !== -1 &&
              isArabicDirection() &&
              getAppLanguage() === AppLanguages.ar
            ) {
              const whereStreetNameEnds = streetFound.length;
              const currentResultNameLength = name.length;
              const restOfTheResultName = name.substring(
                whereStreetNameEnds,
                currentResultNameLength,
              );

              name = I18n.t(`breadcrumbs.areaTypes.${result.type}`, {
                areaName: restOfTheResultName,
              });
            }

            parentName = I18n.t('breadcrumbs.areaTypes.governorate', {
              areaName: parentName,
            });
            const showParentName =
              result.type === areaTypesMap.district &&
              chosenRegion !== riyadhRegionString;

            return {
              ...result,
              mainText: name,
              secondaryText: showParentName ? parentName : null,
            };
          });

          let noResults = !predictions.length;

          if (noResults && isAdmin(state)) {
            try {
              const coordinates = new Coordinates(parsedSearchPhrase);
              const lat = coordinates.getLatitude();
              const lng = coordinates.getLongitude();
              noResults = false;
              predictions.push({
                mainText: I18n.t('mapAutocomplete.coordinates', {
                  lat: lat.toPrecision(8),
                  lng: lng.toPrecision(8),
                }),
                secondaryText: I18n.t('mapAutocomplete.searchCoordinates'),
                location: { latitude: lat, longitude: lng },
              });
            } catch (error) {
              predictions.push({});
            }
          } else {
            predictions.push({});
          }

          dispatch({
            type: SET_MAP_SEARCH,
            payload: {
              list: predictions,
              noResults,
            },
          });
        });
    } else {
      dispatch({
        type: SET_MAP_SEARCH,
        payload: {
          list: [],
          noResults: false,
        },
      });
    }
  };
}

export function setUser() {
  return (dispatch) => {
    apolloClient
      .query({
        query: getUser,
        variables: {},
      })
      .then(({ data }) => {
        dispatch({
          type: SET_USER,
          payload: {
            user: data.user,
          },
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console -- temporary solution @TODO handle for users
        console.error(`error: ${error}`);
      });
  };
}

export function setCurrentLocation(location) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_LOCATION,
      mapOptions: {
        center: {
          lat: location.lat,
          lng: location.lng,
        },
      },
    });
  };
}

export function setMapZoom(zoomValue) {
  return (dispatch) => {
    dispatch({
      type: SET_MAP_ZOOM,
      mapOptions: {
        zoom: zoomValue,
      },
    });
  };
}

export function setMapRange(mapRange) {
  return (dispatch) => {
    dispatch({
      type: SET_MAP_RANGE,
      mapRange,
    });
  };
}

export function setUserPosition(userPosition) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_POSITION,
      payload: userPosition,
    });
  };
}

export function runMapExtensions() {
  return (dispatch) => {
    dispatch({
      type: RUN_MAP_EXTENSIONS,
      payload: true,
    });
  };
}

export function toggleModalVisibility(isOpen = false) {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_VISIBILITY,
      payload: {
        isOpen,
      },
    });
  };
}

export function setModalMessage({
  errorMessage = null,
  isErrorMessage = true,
}) {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_CONTENT,
      payload: {
        text: I18n.t(errorMessage),
        isError: isErrorMessage,
      },
    });

    dispatch(toggleModalVisibility(true));
  };
}

function getAreaShapeColor(areaType) {
  const { map } = GoogleAPI();

  const mapType = map.getMapTypeId();

  return getOutlineColor(mapType, areaType) || Colors.havelockBlue.value;
}

function setMapToShapes(shapes, map) {
  shapes.forEach((shape) => {
    shape.setMap(map);
  });
}

function removeShapeFromMap() {
  if (shapesStore.has()) {
    const shapes = shapesStore.get();
    setMapToShapes(shapes, null);
    shapesStore.remove();
  }
}

function createPolylinesFromAreashapes(area, shapeColor) {
  const { google } = GoogleAPI();

  const polylines = [];
  area.shape.forEach((singleShape) => {
    singleShape.forEach((vertices) => {
      const polyline = new google.maps.Polyline({
        path: vertices.map(([lat, lng]) => ({
          lat,
          lng,
        })),
        strokeColor: shapeColor,
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: Colors.havelockBlue.transparentValue,
        fillOpacity: 0.9,
        zIndex: 100,
      });

      polylines.push(polyline);
    });
  });

  return polylines;
}

export function setMapShape(areaShapes) {
  const { map } = GoogleAPI();

  removeShapeFromMap();

  if (!areaShapes) return;

  const shapeColor = getAreaShapeColor(areaShapes.type);
  const polylines = createPolylinesFromAreashapes(areaShapes, shapeColor);

  setMapToShapes(polylines, map);

  shapesStore.set(polylines);
}

export function updateViewport(viewport) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VIEWPORT,
      payload: viewport,
    });
  };
}

function resetBounds(mapRange, googleMaps, map) {
  setMapCenterToBounds(mapRange, googleMaps, map);
}

export function setRegionShape() {
  return (dispatch, getState) => {
    const state = getState();
    const { areas } = state.map;
    const anyAreaDetectedByAPI = areas.length === 1;
    const { appDataLevels } = state.map;

    if (!CONFIG.enableShapes || !anyAreaDetectedByAPI) {
      dispatch({
        type: SET_AREA_SHAPE,
        payload: null,
      });

      setMapShape();

      return;
    }

    const [areaId] = areas;
    const { areaType } = appDataLevels;

    apolloClient
      .query({
        query: getAreaShape,
        variables: {
          areaId,
          epsilon: shapeEpsilonSimplifyValue[areaType],
        },
      })
      .then(({ data: { area } }) => {
        const { id, name, type, simplifiedShape: shape } = area;

        dispatch({
          type: SET_AREA_SHAPE,
          payload: {
            id,
            name,
            type,
            shape,
          },
        });

        setMapShape({ ...area, shape });
      });
  };
}

export function getAllBusinessesFromArea() {
  return (dispatch, getState) => {
    const state = getState();

    const areas = getMapAreas(state);

    apolloClient
      .query({
        query: getBusinessFromAreaQuery,
        variables: {
          areas,
        },
      })
      .then(
        ({
          data: {
            businesses: { size },
          },
        }) => {
          dispatch(saveAreaBusinesses(size));
        },
      );
  };
}

export function getDemographicSpendingData(areas) {
  return (dispatch) => {
    apolloClient
      .query({
        query: getDemographicSpendingDataQuery,
        variables: {
          areas,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: SET_DEMOGRAPHIC_SPENDING_DATA,
          payload: data,
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export function setCurrentAreaAndUpdateAppData(areas) {
  return (dispatch) => {
    dispatch({
      type: SET_AREAS,
      payload: areas,
    });

    dispatch(setRegionShape());
    dispatch(setDemographicData());
    dispatch(getBusinesses());
    dispatch(getAllBusinessesFromArea());
    dispatch(getDemographicSpendingData(areas));
  };
}

export function setBreadcrumbConnectedData() {
  return (dispatch, getState) => {
    const state = getState();
    const { breadcrumbItems, mapRange } = state.map;
    const { zoom } = state.map.mapOptions;
    const { tags } = state.sector;
    const chosenRegion = getChosenRegion(state);
    const isics = tags.map((tag) => tag.id);

    const query = isics.length
      ? getBusinessesByBoundingBoxWithTags
      : getBusinessesByBoundingBox;

    apolloClient
      .query({
        query,
        variables: {
          ...mapRange,
          isics,
        },
      })
      .then(({ data }) => {
        const { size: businessesInBounds } = data.businesses;
        const isDefaultZoomReached = zoom === getMinZoomLevel(chosenRegion);

        const isMaxZoomReached = zoom === maxZoomLevel;

        const breadcrumbData = getBreadcrumbConnectedAndSidebarData(
          breadcrumbItems,
          isDefaultZoomReached,
          businessesInBounds,
          chosenRegion,
        );

        const { breadcrumbItem, clusterLevel } =
          breadcrumbData.breadcrumbConnectedData;

        const isALotOfBusinesses =
          businessesInBounds > businessMaxSizeInBounds ||
          clusterLevel !== 'single';

        const clusterOptions = {
          enabled: !isMaxZoomReached && isALotOfBusinesses,
          areaType: clusterLevel,
          isDefaultZoomReached,
          highestClusterLabel: state.map.clusters.highestClusterLabel,
        };

        const clusterShowType = getClusterShowType(
          clusterOptions,
          tags.length > 0,
        );

        const areas = breadcrumbItem
          ? [breadcrumbItem.area.id]
          : allDefaultAreas;

        dispatch({
          type: SET_BREADCRUMB_CONNECTED_DATA,
          payload: breadcrumbData.breadcrumbConnectedData,
        });

        dispatch({
          type: SET_BREADCRUMBS_IN_SIDEBAR,
          payload: breadcrumbData.breadcrumbsInSidebar,
        });

        dispatch({
          type: SET_CLUSTER_LEVEL,
          clusters: {
            ...clusterOptions,
            showType: clusterShowType,
            businessesInBounds,
          },
        });

        dispatch(setCurrentAreaAndUpdateAppData(areas));
      });
  };
}

export function getBreadcrumbData(mapRange, areaCenter, zoom) {
  return (dispatch, getState) => {
    const state = getState();
    const { minZoom } = state.map.mapOptions;
    const chosenRegion = getChosenRegion(state);
    const googleMaps = GoogleAPI().google.maps;
    const { map } = GoogleAPI();
    const isMinZoom = zoom === minZoom;
    const { chosenRegionBounds } = getChosenRegionData(chosenRegion);

    dispatch(setMapZoom(zoom));
    dispatch(
      setCurrentLocation({
        lat: areaCenter.latitude,
        lng: areaCenter.longitude,
      }),
    );

    async function setAppDataBasedOnBreadcrumb({
      emirate,
      governorate,
      city,
      district,
    }) {
      const isOutsideChosenRegionData = await isOutsideChosenRegion(
        chosenRegion,
        areaCenter,
      );

      if (isOutsideChosenRegionData) {
        const prevMapRange = isMinZoom
          ? chosenRegionBounds
          : getMapRange(state);

        resetBounds(prevMapRange, googleMaps, map);
        dispatch(setBreadcrumbConnectedData());

        return;
      }

      if (isMinZoom) {
        resetBounds(chosenRegionBounds, googleMaps, map);
        dispatch(setMapRange(chosenRegionBounds));
      } else {
        dispatch(setMapRange(mapRange));
      }

      dispatch({
        type: SET_BREADCRUMB_ITEMS,
        payload: {
          emirate,
          governorate,
          city,
          district,
        },
      });

      dispatch(setBreadcrumbConnectedData());
    }

    apolloClient
      .query({
        query: getBreadcrumbs,
        variables: {
          ...mapRange,
          zoom,
        },
      })
      .then(({ data }) => {
        const { breadcrumb } = data;
        const mapBounds = map.getBounds();
        const extendedBreadcrumbs = extendBreadcrumbs(breadcrumb, mapBounds);
        const { emirate, governorate, city, district } = extendedBreadcrumbs;
        const breadcrumbs = { emirate, governorate, city, district };

        setAppDataBasedOnBreadcrumb(breadcrumbs);
      });
  };
}

export function drawMarkers(store) {
  return (dispatch, getState) => {
    const { sector, map, business } = getState();
    const { businesses } = business;
    const markers = prepareAndDisplayMarkers(sector, map, businesses, store);

    markersStore.setMultiple(markers);

    dispatch({
      type: SET_BUSINESSES_ON_MAP,
      payload: markers.map(({ id }) => id),
    });

    dispatch(redrawCurrentMarkers());

    setTimeout(() => {
      for (let i = 0; i < markers.length; i++) {
        const clustersWithSameId = document.querySelectorAll(
          `[id='${markers[i].id}']`,
        );
        if (clustersWithSameId.length > 1) {
          clustersWithSameId[0].parentNode.removeChild(clustersWithSameId[0]);
        }
      }

      const riyadhCityMarker = document.getElementById(riyadhCityId);
      if (riyadhCityMarker) {
        const clusters = document.getElementsByClassName('cluster');
        if (clusters.length > 1) {
          const riyadhCityMarkerParent = riyadhCityMarker.parentNode;

          for (let i = 0; i < clusters.length; i++) {
            clusters[i].parentNode.removeChild(clusters[i]);
          }
          riyadhCityMarkerParent.appendChild(riyadhCityMarker);
        }
      }
    }, 0);
  };
}

export function setMapType(mapTypeId) {
  return {
    type: SET_MAP_TYPE,
    payload: mapTypeId,
  };
}

export function redrawMarkersAfterMapTypeIdChange() {
  return (dispatch, getState) => {
    const state = getState();

    const markersIds = getMapMarkers(state);
    const markers = markersStore.getMultiple(markersIds);

    const {
      map,
      google: { maps: googleMaps },
    } = GoogleAPI();

    const mapTypeId = map.getMapTypeId();

    const { ROADMAP } = googleMaps.MapTypeId;

    const isRoadmapType = mapTypeId === ROADMAP;

    markers.forEach((marker) => {
      const { isCluster, businessMarker, color } = marker.markerOnMap;

      if (!businessMarker) {
        return;
      }

      businessMarker.className = `${
        isCluster ? 'cluster' : 'marker'
      } ${mapTypeId}`;

      if (!color) {
        return;
      }

      businessMarker.style.backgroundColor = getBusinessMarkerBackground(
        isCluster,
        isRoadmapType,
        color,
      );
    });
  };
}

export function setMapOptions(mapOptions) {
  return (dispatch) => {
    dispatch({
      type: SET_MAP_OPTIONS,
      payload: mapOptions,
    });
  };
}

export function setMap(map) {
  return (dispatch) => {
    setGoogleServices();

    const googleMaps = GoogleAPI().google.maps;

    googleMaps.event.addListenerOnce(map, 'tilesloaded', () => {
      dispatch(runMapExtensions());
    });
  };
}

export function setUserAsVisited() {
  return (dispatch) => {
    dispatch({
      type: SET_USER_AS_VISITED,
      payload: true,
    });
  };
}

export function setChosenRegion(regionChosen) {
  return (dispatch) => {
    dispatch({
      type: SET_CHOSEN_REGION,
      payload: regionChosen,
    });
  };
}

export function getGroups() {
  return (dispatch, getState) => {
    const state = getState();
    const chosenRegion = getChosenRegion(state);

    apolloClient
      .query({
        query:
          chosenRegion === riyadhRegionString
            ? getIncomeQuery
            : getDemographicIndicatorsQuery,
        variables: {
          areas: ['city-3', 'emirate-1'],
        },
      })
      .then(({ data }) => {
        dispatch({
          type: SAVE_GROUPS,
          payload: data,
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
}

const MapActions = {
  setCurrentLocation,
  setMapZoom,
  setMapRange,
  setMap,
  setMapShape,
  updateViewport,
  getBreadcrumbData,
  setGoogleServices,
  drawMarkers,
  runMapExtensions,
  setInfoWindowOnMap,
  setMapOptions,
  setModalMessage,
  toggleModalVisibility,
  setUserPosition,
  redrawMarkersAfterMapTypeIdChange,
  setUser,
  setChosenRegion,
  getGroups,
  getDemographicSpendingData,
  setMapType,
};

export default MapActions;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import BaseTooltipContent from '../BaseTooltip/BaseTooltipContent';
import PercentageValue from '../../PercentageValue';
import ChartTooltipNumberValueLabel from './ChartTooltipNumberValueLabel';
import FormattedValue from '../../FormattedValue';

const ChartTooltipOverlay = ({
  color,
  header,
  value,
  percentageValue,
  valueLabel,
  income,
  position,
  numBubbles,
  hideRanking,
  valueSuffix,
  children,
  arrowLeft,
}) => {
  const styles = {};

  if (color) {
    styles.color = color;
  }

  return (
    <BaseTooltipContent
      styles={styles}
      arrowStyles={arrowLeft ? { left: `${arrowLeft}px` } : {}}
    >
      <div className='chart-tooltip__content'>
        <div className='chart-tooltip-header'>
          <h4 className='chart-tooltip-header__title'>{header}</h4>
        </div>
        <div className='chart-tooltip__data'>
          <div className='chart-tooltip-values'>
            <div className='chart-tooltip-value'>
              {income ? (
                <span className='chart-tooltip-value__container'>
                  <span className='number-value-label'>
                    <span className='number-value-label__number'>
                      {valueSuffix ? (
                        <span className='valueSuffix__formatted'>
                          <FormattedValue
                            value={+parseFloat(income).toFixed(1)}
                          />
                        </span>
                      ) : (
                        <ChartTooltipNumberValueLabel value={income} />
                      )}
                    </span>
                    <span className='number-value-label__format'>
                      <Translate
                        value={valueSuffix || 'incomeChart.currencyRiyal'}
                      />
                    </span>
                  </span>
                </span>
              ) : (
                <ChartTooltipNumberValueLabel value={value} />
              )}
            </div>
            {percentageValue >= 0 && !income && (
              <div className='chart-tooltip-value--percentage'>
                (<PercentageValue number={percentageValue} />)
              </div>
            )}
            <div className='chart-tooltip__border-pointer'>
              <div className='chart-tooltip__border' />
              <div className='chart-tooltip__pointer' />
            </div>
          </div>
          <div className='chart-tooltip-value__label'>
            {hideRanking || (
              <Translate
                position={position}
                numBubbles={numBubbles}
                value={valueLabel}
              />
            )}
          </div>
          <div className='chart-tooltip-children'>{children}</div>
        </div>
      </div>
    </BaseTooltipContent>
  );
};

ChartTooltipOverlay.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.number,
  valueLabel: PropTypes.string.isRequired,
  valueSuffix: PropTypes.string,
  percentageValue: PropTypes.number,
  color: PropTypes.string,
  income: PropTypes.number,
  position: PropTypes.number,
  numBubbles: PropTypes.number,
  hideRanking: PropTypes.bool,
  children: PropTypes.node,
  arrowLeft: PropTypes.number,
};

ChartTooltipOverlay.defaultProps = {
  percentageValue: null,
  income: null,
};

export default ChartTooltipOverlay;

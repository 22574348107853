import {
  getBarChartComponentData,
  getDemographicBarChartComponentData,
} from '../charts/utils';

const selectDataFromChartsForCSV = ({ data, chosenRegion }) => {
  const barChartComponentData = getBarChartComponentData(
    data.pointsOfInterestStoreData,
  );

  const demographicBarChartComponentData = getDemographicBarChartComponentData(
    data.demographicStoreData,
  );

  return {
    chosenRegion,
    barChartComponentData,
    demographicBarChartComponentData,
  };
};

export default selectDataFromChartsForCSV;

import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import Popup from './Popup';
import { getChosenRegion, hasUserVisited } from '../../selectors/map.selector';
import { setChosenRegion } from '../../modules/Map/actions/MapActions';
import { setOnboardingActive } from '../../modules/Onboarding/actions/OnboardingActions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setChosenRegion, setOnboardingActive },
    dispatch,
  ),
});

// TODO: fill with state & Add selectors
const mapStateToProps = (state) => ({
  hasUserVisited: hasUserVisited(state),
  chosenRegion: getChosenRegion(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);

import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';

const SectorsTab = ({ tags = [], removeSector }) => {
  const sectorSidebarTranslations = {
    sector: 'sectorsSidebar.sector',
    selectedSectors: 'sectorsSidebar.selectedSectors',
    allSectors: 'sectorsSidebar.allSectors',
  };

  const sectorSidebar = {
    sector: I18n.t(sectorSidebarTranslations.sector),
    selectedSector: I18n.t(sectorSidebarTranslations.selectedSectors),
  };

  const title = !tags.length
    ? sectorSidebar.sector
    : sectorSidebar.selectedSector;

  const selectedTags = tags.map((tag, index) => (
    <span key={tag.name} className='sidebar-badge__tag'>
      <span
        className='sidebar-badge__tag-label'
        style={{ background: tag.color.value }}
      >
        {tag.name}
      </span>
      <button
        type='button'
        id={`tag-close-${index}`}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          removeSector(tag, tags);
        }}
        className='sidebar-badge__tag-icon geo-icon-cancel'
        style={{ background: tag.color.value }}
      />
    </span>
  ));

  const noSelectedSectors = tags.length === 0;

  return (
    <div>
      <h3 className='text sidebar__space-bottom--s3'>{title}</h3>
      <div>
        {noSelectedSectors ? (
          <span className='sidebar-badge__tag sidebar-badge__tag--neutral'>
            <Translate
              className='sidebar-badge__tag-label'
              value={sectorSidebarTranslations.allSectors}
            />
          </span>
        ) : (
          <div>{selectedTags}</div>
        )}
      </div>
    </div>
  );
};

SectorsTab.propTypes = {
  tags: PropTypes.array.isRequired,
  removeSector: PropTypes.func.isRequired,
};

export default SectorsTab;

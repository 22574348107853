import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Item from './Item';

import './BubbleChart.scss';

const BubbleChart = ({ data, hideRanking, fillWidth, showAverageValue }) => (
  <div
    className={classNames('BubbleChart', { 'BubbleChart--fill': fillWidth })}
  >
    <div className='BubbleChart__items'>
      {data.map((item) => (
        <Item
          hideRanking={hideRanking}
          key={item.label}
          showValue={showAverageValue}
          fillWidth={fillWidth}
          // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
          {...item}
        />
      ))}
    </div>
  </div>
);

BubbleChart.propTypes = {
  hideRanking: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fillWidth: PropTypes.bool,
  showAverageValue: PropTypes.bool,
};

export default BubbleChart;

import {
  SET_ONBOARDING,
  NEXT_STEP,
  END_ONBOARDING,
  RESET_STEPS,
} from '../constants/OnboardingConstants';

export function setOnboardingActive() {
  return {
    type: SET_ONBOARDING,
  };
}

function setNextStep(nextStep) {
  return {
    type: NEXT_STEP,
    payload: {
      nextStep,
    },
  };
}

function endOnboarding() {
  return {
    type: END_ONBOARDING,
  };
}

function resetOnboarding() {
  return {
    type: RESET_STEPS,
  };
}

export default {
  setOnboardingActive,
  setNextStep,
  endOnboarding,
  resetOnboarding,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './HeaderToggle.scss';

const HeaderToggle = ({ isActive, handleToggle }) => (
  <div onClick={handleToggle} className='HeaderToggle__wrapper'>
    <i
      className={classNames('HeaderToggle', {
        'HeaderToggle--active': isActive,
      })}
    />
  </div>
);

HeaderToggle.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default HeaderToggle;

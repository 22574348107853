import React from 'react';
import PropTypes from 'prop-types';
import NumberValueLabel from '../../NumberValueLabel';

import './ClusterNumberValueLabel.scss';

const ClusterNumberValueLabel = ({ number, clusterSize }) => {
  const style = {
    fontSize: clusterSize / 6,
  };

  return (
    <div className='number-value-label--cluster'>
      <NumberValueLabel number={number} style={style} />
    </div>
  );
};

ClusterNumberValueLabel.propTypes = {
  number: PropTypes.number.isRequired,
  clusterSize: PropTypes.number.isRequired,
};

export default ClusterNumberValueLabel;

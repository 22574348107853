const getThresholdsWithTotalValue = (data) => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;
  let totalValue = 0;

  data.forEach((item) => {
    const value = item.value ? item.value : 0;

    totalValue += value;

    if (value > max) {
      max = value;
    }
    if (value < min && value !== 0) {
      min = value;
    }
  });

  const thresholds = [min, 0.25 * max, 0.4 * max, 0.6 * max, 0.8 * max, max];

  return { thresholds, totalValue };
};

export default getThresholdsWithTotalValue;

import {
  BUBBLE_MARGIN,
  DEMOGRAPHIC_TOOLTIP_WIDTH,
} from '../../../Demographic/constants/IncomeChartHelpers';

const BUBBLE_OFFSET = 10;

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getTooltipPositionValues = ({ bubblePosition, endRange }) => {
  if (bubblePosition <= DEMOGRAPHIC_TOOLTIP_WIDTH / 2) {
    return {
      arrow: bubblePosition - BUBBLE_OFFSET,
      content: 0,
    };
  }
  if (bubblePosition >= endRange - DEMOGRAPHIC_TOOLTIP_WIDTH / 2) {
    return {
      arrow:
        DEMOGRAPHIC_TOOLTIP_WIDTH -
        (endRange + BUBBLE_MARGIN - bubblePosition + BUBBLE_OFFSET),
      content: endRange - DEMOGRAPHIC_TOOLTIP_WIDTH + BUBBLE_MARGIN,
    };
  }
  return {
    arrow: null,
    content: bubblePosition - DEMOGRAPHIC_TOOLTIP_WIDTH / 2,
  };
};

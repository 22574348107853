export const clustersMargins = (clusterSize) => ({
  // 0: Place cluster on location
  0: {
    0: `-${clusterSize / 2}px 0 0 -${clusterSize / 2}px`,
  },
  // 1: Place cluster on location
  1: {
    0: `-${clusterSize / 2}px 0 0 -${clusterSize / 2}px`,
  },
  // 2: Place clusters next to each other, location in the center
  2: {
    0: `-${clusterSize / 2}px 0 0 0`,
    1: `-${clusterSize / 2}px 0 0 -${clusterSize * 0.75}px`,
  },
  // 3: Place clusters in 3-star, location in center
  3: {
    0: '0 0 0 0',
    1: `0 0 0 -${clusterSize * 0.7}px`,
    2: `-${clusterSize * 0.75}px 0 0 -${clusterSize * 0.35}px`,
  },
});

/* eslint-disable no-param-reassign -- @refactor to pure function */
export const updateMarkerView = (
  businessMarker,
  clusterSize,
  tagsLength,
  tagIndex,
) => {
  businessMarker.style.width = `${clusterSize}px`;
  businessMarker.style.height = `${clusterSize}px`;

  businessMarker.style.margin = `-${clusterSize / 2}px 0 0 -${
    clusterSize / 2
  }px`;

  businessMarker.style.lineHeight = `${clusterSize}px`;
  businessMarker.style.fontSize = `${clusterSize / 4}px`;

  const clusterMarginsObject = clustersMargins(clusterSize);

  businessMarker.style.margin = clusterMarginsObject[tagsLength][tagIndex];

  return businessMarker;
};

export const getBusinessMarkerBackground = (
  isCluster,
  isRoadmapType,
  color,
) => {
  if (isCluster) {
    const clusterColor = isRoadmapType
      ? color.transparentValue
      : color.satelliteTransparentValue;

    return clusterColor;
  }

  return color.transparentValue;
};

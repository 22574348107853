import gql from 'graphql-tag';

import CONFIG from '../../config/config';

export const getDemographicIndicatorsQuery = gql`
   query getDemographicIndicatorsQuery {
      population_density: demographicIndicators(
        filters: {
          indicator: "population_density"
        }
        orders: {
          id: "value"
          direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      population_growth_rate: demographicIndicators(
        filters: {
          indicator: "population_growth_rate"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      household_size: demographicIndicators(
        filters: {
          indicator: "household_size"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      household_growth_rate: demographicIndicators(
        filters: {
          indicator: "household_growth_rate"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      household_income: demographicIndicators(
        filters: {
          indicator: "household_income"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      household_savings: demographicIndicators(
        filters: {
          indicator: "household_savings"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      household_spending: demographicIndicators(
        filters: {
          indicator: "household_spending"
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }
    }
`;

export const getIncomeQuery = gql`
    query getIncomeQuery ($areas: [String]!) {
      all: averageIncome(
        filters: {
          male: null
          saudi: null,
          parentAreas: $areas
        }
        orders: {
          id: "value"
          direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      male: averageIncome(
        filters: {
          male: true
          saudi: null,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      female: averageIncome(
        filters: {
          male: false,
          saudi: null,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      saudi: averageIncome(
        filters: {
          male: null
          saudi: true,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      nonSaudi: averageIncome(
        filters: {
          male: null,
          saudi: false,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      saudiMale: averageIncome(
        filters: {
          male: true,
          saudi: true,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      saudiFemale: averageIncome(
        filters: {
          male: false,
          saudi: true,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      nonSaudiMale: averageIncome(
        filters: {
          male: true,
          saudi: false,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }

      nonSaudiFemale: averageIncome(
        filters: {
          male: false,
          saudi: false,
          parentAreas: $areas
        }
        orders: {
            id: "value"
            direction: "desc"
        }
      ) {
        facts {
          area {
            id
            name(language: "${CONFIG.language}")
          }
          value
        }
      }
    }
  `;

import React from 'react';
import PropTypes from 'prop-types';
import chevron from '../../assets/chevron.svg';

const RegionDropdownToggle = ({ toggleDropdown, dropdownLabel }) => (
  <button
    type='button'
    className='RegionDropdownToggle'
    onClick={toggleDropdown}
  >
    <span className='RegionDropdownToggle__label'>{dropdownLabel}</span>
    <img src={chevron} alt='chevron' />
  </button>
);

RegionDropdownToggle.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  dropdownLabel: PropTypes.string.isRequired,
};

export default RegionDropdownToggle;

import gql from 'graphql-tag';
import CONFIG from '../../config/config';

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getDemographicDataQuery = gql`
  query getDemographicData ($areas: [String]!, $regionIdArr: [String]!) {
    # Total
    totalPopulation: population(
      filters: {
        areas: $areas
      }
      splits: []
    ) {
      facts {
        value
      }
    }

    # For percentages.
    malePopulation: population(
      filters: {
        areas: $areas
        sexes: ["male"]
      }
      splits: []
    ) {
      facts {
        value
      }
    }
    femalePopulation: population(
      filters: {
        areas: $areas
        sexes: ["female"]
      }
      splits: []
    ) {
      facts {
        value
      }
    }
    saudiPopulation: population(
      filters: {
        areas: $areas
        nationalities: ["saudi"]
      }
      splits: []
    ) {
      facts {
        value
      }
    }
    nonSaudiPopulation: population(
      filters: {
        areas: $areas
        nationalities: ["nonSaudi"]
      }
      splits: []
    ) {
      facts {
        value
      }
    }

    # For chart by sex.
    byGenderAndAgeGroupPopulation: population(
      filters: {
        areas: $areas
      }
      splits: [
        "sex",
        "ageGroup"
      ]
    ) {
      facts {
        splits {
          id
          label(language: "${CONFIG.language}")
        }
        value
      }
    }

    # For chart by Saudi/non-Saudi nationality.
    byNationalityAndAgeGroupPopulation: population(
      filters: {
        areas: $areas
      }
      splits: [
        "nationality",
        "ageGroup"
      ]
    ) {
      facts {
        splits {
          id
          label(language: "${CONFIG.language}")
        }
        value
      }
    }

    # Age group labels
    ageGroupLabels: population(
      filters: {
        areas: $regionIdArr
      }
      splits: [
        "ageGroup"
      ]
    ) {
      facts {
        splits {
          id
          label(language: "${CONFIG.language}")
        }
      }
    }
  }
`;

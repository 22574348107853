import React from 'react';
import PropTypes from 'prop-types';
import ViewportUnitsBuggyfillHacks from 'viewport-units-buggyfill/viewport-units-buggyfill.hacks';
import ViewportUnitsBuggyfill from 'viewport-units-buggyfill';

import MapContainer from '../modules/Map/containers/MapContainer';
import SidebarContainer from '../modules/Sidebar/containers/SidebarContainer';
import MobileIndicator from '../components/MobileIndicator';
import Popup from '../components/Popup';
import DebugTree from '../components/DebugTree';
import CONFIG from '../config/config';

ViewportUnitsBuggyfill.init({
  hacks: ViewportUnitsBuggyfillHacks,
});

export const PageLayout = ({ children }) => (
  <div className='container'>
    {CONFIG.debugTree && <DebugTree />}
    <div id='fullscreenViewport' className='page-layout__viewport'>
      <MapContainer>{children}</MapContainer>
      <div id='mobile-scrolled-section' className='sidebar'>
        <MobileIndicator sectionId='#mobile-scrolled-section' />
        <SidebarContainer>{children}</SidebarContainer>
      </div>
      <Popup />
    </div>
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: {},
};

export default PageLayout;

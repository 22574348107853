import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MapControls.scss';
import {
  mapRiyadhCentralDistrict,
  riyadhRegionString,
} from '../../../../helpers/globals/Constans.ts';

class ZoomComponent extends React.Component {
  componentDidMount() {
    const zoomRef = this.refs.zoom;
    const { map, google } = this.props;

    zoomRef.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomRef);
  }

  getZoomToUpdate = (zoomIn) => {
    const {
      map,
      mapOptions: { minZoom },
    } = this.props;

    const currentZoom = map.getZoom();

    if (currentZoom < minZoom) {
      return minZoom;
    }

    return zoomIn ? currentZoom + 1 : currentZoom - 1;
  };

  changeZoom = (zoomIn) => {
    const {
      zoomHandler,
      mapOptions: { minZoom },
      chosenRegion,
    } = this.props;
    const zoomToUpdate = this.getZoomToUpdate(zoomIn);

    if (zoomToUpdate >= minZoom) {
      zoomHandler(zoomToUpdate);

      if (
        zoomIn &&
        zoomToUpdate === minZoom + 1 &&
        chosenRegion === riyadhRegionString
      ) {
        this.moveToRiyadhCentralDistrict();
      }
    }
  };

  moveToRiyadhCentralDistrict = () => {
    const { map } = this.props;

    const { lat, lng } = mapRiyadhCentralDistrict;
    map.panTo({ lat, lng });
  };

  shouldZoomOutBeDisabled = () => {
    const {
      map,
      mapOptions: { minZoom },
    } = this.props;
    return map.getZoom() <= minZoom;
  };

  render() {
    const classes = classNames('map-controls__icon geo-icon-minus', {
      'map-controls__icon--disabled': this.shouldZoomOutBeDisabled(),
    });

    return (
      <div className='map-controls' ref='zoom'>
        <div
          className='map-controls__button'
          onClick={() => this.changeZoom(true)}
        >
          <div className='map-controls__icon geo-icon-plus' />
        </div>
        <div
          className='map-controls__button'
          onClick={() => this.changeZoom(false)}
        >
          <div className={classes} />
        </div>
      </div>
    );
  }
}

ZoomComponent.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  zoomHandler: PropTypes.func.isRequired,
  mapOptions: PropTypes.shape({
    zoom: PropTypes.number.isRequired,
    minZoom: PropTypes.number.isRequired,
  }),
  chosenRegion: PropTypes.string,
};

export default ZoomComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import CONFIG from '../../../../config/config';
import { EASTERN_REGION_NAME } from '../../constants/MapConstants.ts';

const InfoWindowTooltipContent = ({
  business,
  chosenRegion,
  isAdmin,
  sectorColorName,
}) => {
  const {
    isic: { singularName: subsectorName },
    name: businessName,
    governorate,
    city,
    district,
    zipCode: { code },
    id,
  } = business;
  const editBusinessLink = `${CONFIG.api}/admin/edit-business/${id}`;

  const shouldDisplayBusinessName =
    businessName !== '-' && chosenRegion === EASTERN_REGION_NAME;

  const areaNameBasedOnRegion =
    chosenRegion === EASTERN_REGION_NAME ? governorate.name : city.name;

  return (
    <div className='info-window__content'>
      <p className={`info-window__sector-name ${sectorColorName}`}>
        {subsectorName}
      </p>
      {shouldDisplayBusinessName && (
        <p className='info-window__business-name'>{businessName}</p>
      )}
      <p className='info-window__business-address'>
        {district && (
          <Translate
            value='app.mapTooltip.district'
            districtName={district.name}
          />
        )}
        <Translate
          value='app.mapTooltip.address'
          areaName={areaNameBasedOnRegion}
          zipCode={code}
        />
      </p>
      {isAdmin && (
        <p className='info-window__edit-button'>
          <a href={editBusinessLink} target='_blank' rel='noopener noreferrer'>
            Edit in admin panel
          </a>
        </p>
      )}
    </div>
  );
};

InfoWindowTooltipContent.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    buildingNumber: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.number,
    ]),
    governorate: PropTypes.shape({
      name: PropTypes.string,
    }),
    city: PropTypes.shape({
      name: PropTypes.string,
    }),
    district: PropTypes.shape({
      name: PropTypes.string,
    }),
    isic: PropTypes.shape({
      id: PropTypes.string,
      singularName: PropTypes.string,
      type: PropTypes.string,
      root: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    name: PropTypes.string,
    street: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
    zipCode: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  isAdmin: PropTypes.bool,
  chosenRegion: PropTypes.string.isRequired,
  sectorColorName: PropTypes.string.isRequired,
};

export default InfoWindowTooltipContent;

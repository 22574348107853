import CONFIG from '../../config/config';
import { buildStringifiedLocalesForGraphQL } from '../../helpers/AppHelper';
import { SectorTypes } from '../../types/index.ts';

const {
  language,
  locales,
  searchOptions: { matchIds, matchNames },
} = CONFIG;

const { sector, subsector } = SectorTypes;

export const getFilteredSectorsQuery = `
query getFilteredSectorsQuery ($searchPhrase: String!, $ids: [String]!) {
  isics {
    sectors: items (
      ids: $ids,
      types: ["${sector}", "${subsector}"]
      searchInput: {
        locales: [${buildStringifiedLocalesForGraphQL(locales)}]
        searchPhrase: $searchPhrase
        matchNames: ${matchNames},
        matchIds: ${matchIds}
      }
      orders: [
        {id:"name_ar" direction:"asc"}
        {id:"id" direction:"asc"}
      ]
    ) {
      id
      name (language: "${language}")
      type
      root {
        id
        name (language: "${language}")
        type
      }
      children {
        items(limit: 150) {
          id
          name (language: "${language}")
          type
          root {
            id
            name (language: "${language}")
          }
        }
      }
    }
  }
}`;

export const getSectorsQuery = `
  query getSectorsQuery {
    isics {
      sectors: items (
        types: ["${sector}"]
        orders: [
          {id:"name_ar" direction:"asc"}
          {id:"id" direction:"asc"}
        ]
      ) {
        id
        name (language: "${language}")
        type
        children {
          items(limit: 150) {
            id
            name (language: "${language}")
            type
            root {
              id
              name (language: "${language}")
            }
          }
        }
      }
    }
  }
`;

export const getSectorsSizeQuery = `
query getSectorsSizeQuery ($areas: [String]) {
  businesses {
    aggregationByIsic(
      areas: $areas
    ) {
      isicBuckets {
        isic {
          id
        }
        size
      }
    }
    size(
      areas: $areas
    )
  }
}`;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ChartTooltip from '../../../../components/Tooltip/ChartTooltip';

const MIN_BAR_SIZE = 10;

const calculateBarWidth = (value, maxAxisXItem, width) =>
  (value / maxAxisXItem) * width;

class SimpleBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barWidth: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      width,
      setGraphAsRendered,
      graphWasRendered,
      singleBarItem,
      maxAxisXItem,
    } = this.props;

    if (prevProps.width !== width && setGraphAsRendered) {
      setGraphAsRendered();
    }

    if (prevProps.graphWasRendered !== graphWasRendered) {
      this.timeout = setTimeout(() => {
        const value = singleBarItem ? singleBarItem.value : 0;
        const barWidth = value
          ? calculateBarWidth(value, maxAxisXItem, width)
          : 0;

        this.updateBarWidth(barWidth);
      }, 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  computeBarWidth() {
    const { barWidth } = this.state;

    return (MIN_BAR_SIZE > barWidth && barWidth) > 0 ? MIN_BAR_SIZE : barWidth;
  }

  updateBarWidth(barWidth) {
    this.setState({
      barWidth,
    });
  }

  render() {
    const {
      width,
      overwriteHeight,
      color,
      mirrored,
      enableAnimation,
      tooltip,
    } = this.props;

    if (!width) {
      return null;
    }

    const height = overwriteHeight || 'inherit';

    const className = classnames('simple-bar__line-container row top-xs', {
      reverse: mirrored,
    });

    const barLineClassName = classnames('simple-bar__line col-xs', {
      'simple-bar__line--animated': enableAnimation,
    });

    const barWidth = this.computeBarWidth();

    const backgroundColor = !barWidth ? 'transparent' : color;

    const background = backgroundColor || 'inherit';

    const barLine = (
      <div
        className={barLineClassName}
        style={{ maxWidth: barWidth, background }}
      />
    );

    const bar = tooltip.enabled ? (
      <ChartTooltip data={tooltip.data} color={color}>
        {barLine}
      </ChartTooltip>
    ) : (
      barLine
    );

    return (
      <div className='simple-bar col-xs-12' style={{ height }}>
        <div className={className}>{bar}</div>
      </div>
    );
  }
}

SimpleBar.propTypes = {
  singleBarItem: PropTypes.shape({
    percentageValue: PropTypes.number,
    value: PropTypes.number,
  }),
  tooltip: PropTypes.shape({
    enabled: PropTypes.bool,
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  color: PropTypes.string,
  maxAxisXItem: PropTypes.number.isRequired,
  width: PropTypes.number,
  mirrored: PropTypes.bool,
  overwriteHeight: PropTypes.number,
  setGraphAsRendered: PropTypes.func,
  graphWasRendered: PropTypes.bool,
  enableAnimation: PropTypes.bool,
};

SimpleBar.defaultProps = {
  tooltip: {
    enabled: false,
    type: 'default',
  },
};

export default SimpleBar;

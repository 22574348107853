import React from 'react';
import classNames from 'classnames'
import { I18n } from 'react-redux-i18n'
import PropTypes from 'prop-types';

import HeaderNav from '../HeaderNav/HeaderNav';
import HeaderNavItem from '../HeaderNavItem/HeaderNavItem';
import HeaderSubmenu from '../HeaderSubmenu/HeaderSubmenu';
import HeaderToggle from '../HeaderToggle';
import Logo from '../../Logo/Logo';
import ServicesMenuIcon from '../../../assets/header/services-menu-icon.svg';

import './Header.scss';
import { isMobileScreen } from '../../../helpers/ResponsiveHelper'
import HeaderServicesMenu, { HeaderServices } from '../HeaderServicesMenu/HeaderServicesMenu'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedSubmenu: null,
      isMobileMenuOpened: false,
      isServicesMenuOpened: false,
    };
  }

  componentDidMount() {
    this.bindListeners();
  }

  componentWillUnmount() {
    this.unbindListeners();
  }

  closeSubmenu = (event) => {
    const { submenuItems } = this.props;

    const { target } = event;

    const elementsWithContainedTarget = submenuItems.filter(({ ref }) =>
      ref.current.contains(target),
    );

    const shouldNotCloseSubmenu =
      elementsWithContainedTarget.length > 0 ||
      target.classList.contains('header-toggle-link');

    if (shouldNotCloseSubmenu) {
      return;
    }

    this.setState({
      openedSubmenu: null,
    });
  };

  closeServicesMenu = (event) => {
    const { target } = event;

    const targetMatchesServicesButton = target.classList.contains('Header__services') || target.tagName === 'IMG';

    if (targetMatchesServicesButton) {
      return;
    }

    const targetMatchesServicesMenu =
      target.classList.contains('HeaderServicesMenu')
      || target.classList.contains('HeaderServicesMenu__service')
      || target.classList.contains('HeaderServicesMenu__head')
      || target.classList.contains('service-img');

    if (targetMatchesServicesMenu) {
      return;
    }

    this.setState({
      isServicesMenuOpened: false,
    });
  };

  toggleSubmenu = (event, ref) => {
    event.preventDefault();

    this.setState((prevState) => ({
      openedSubmenu: prevState.openedSubmenu ? null : ref,
    }));
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpened: !prevState.isMobileMenuOpened,
    }));

    document.querySelector('main').classList.toggle('no-opacity');
    document.querySelector('.a2i-geo-footer').classList.toggle('no-opacity');
  };

  toggleServicesMenu = (event) => {
    event.stopPropagation();

    this.setState((prevState) => ({
      isServicesMenuOpened: !prevState.isServicesMenuOpened,
    }));
  };

  unbindListeners() {
    document.removeEventListener('click', this.closeSubmenu, false);
    document.removeEventListener('click', this.closeServicesMenu, false);
  }

  bindListeners() {
    document.addEventListener('click', this.closeSubmenu, false);
    document.addEventListener('click', this.closeServicesMenu, false);
  }

  render() {
    const { menuItems, submenuItems } = this.props;

    const { openedSubmenu, isMobileMenuOpened, isServicesMenuOpened } = this.state;

    const isMobile = isMobileScreen();

    return (
      <header className='Header'>
        <div className='Header__container'>
          <div className='Header__menu'>
            {isMobile ? (
              <div className='Header__services'>
                <HeaderToggle
                  isActive={isMobileMenuOpened}
                  handleToggle={this.toggleMobileMenu}
              />
              </div>
            ) : (
              <div className='Header__services' onClick={this.toggleServicesMenu}>
                <div className='Header__services__content'>
                  <img className='service-img' src={ServicesMenuIcon} alt='910ths services' />
                  <HeaderServicesMenu active={isServicesMenuOpened} />
                </div>
              </div>
            )}

            <div className='Header__divider'/>

            <div className='Header__logo'>
              <div className='Header__logo-image'>
                <Logo />
              </div>
            </div>
          </div>

          <HeaderNav isActive={isMobileMenuOpened}>
            {menuItems.map(({ id, location, icon, title }) => (
              <HeaderNavItem
                key={id}
                icon={icon}
                location={location}
                title={title}
              />
            ))}

            {submenuItems.map(({ id, ref, submenuTitle, items }) => (
              <HeaderSubmenu
                key={id}
                forwardedRef={ref}
                forwardedId={id}
                submenuTitle={submenuTitle}
                clickedItem={openedSubmenu}
                items={items}
                onClick={this.toggleSubmenu}
              >
                {items.map(({ id: subId, location, icon, title }) => (
                  <HeaderNavItem
                    key={subId}
                    title={title}
                    location={location}
                    icon={icon}
                    isSubmenuItem
                  />
                ))}
              </HeaderSubmenu>
            ))}

            <div className={classNames('HeaderServices', {
              'HeaderServices--active': isMobileMenuOpened,
            })}>
              <span className='HeaderServices__head'>{I18n.t('app.header.services.title')}</span>
              <HeaderServices />
            </div>
          </HeaderNav>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  menuItems: PropTypes.array,
  submenuItems: PropTypes.array,
};

export { HeaderNav, HeaderNavItem, HeaderSubmenu };

export default Header;

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { ApiPaths } from '../../helpers/globals/Constans';

import SimpleModal from '../SimpleModal/SimpleModal';
import DownloadModalButton from '../DownloadModalButton/index';
import DownloadModalStatus from './DownloadModalStatus';
import {
  downloadFile,
  downloadPdf,
  downloadStatuses,
  usePreloadStatusIconsForIOS,
} from './utils';

import pdfIcon from '../../assets/download/pdf-icon.svg';
import csvIcon from '../../assets/download/csv-icon.svg';
import './DownloadModal.scss';

import getChartsData from '../../helpers/charts/getChartsData';
import selectDataFromChartsForCSV from '../../helpers/csv/selectDataFromChartsForCSV';
import getCSVData from '../../helpers/csv/getCSVData';
import { DownloadStatusType } from './types.d';

interface DownloadModalProps {
  modalName: string;
  isDownloadModalOpen: boolean;
  setIsDownloadModalOpen: (value: boolean) => void;
}

const DownloadModal = ({
  modalName,
  isDownloadModalOpen,
  setIsDownloadModalOpen,
}: DownloadModalProps) => {
  const [pdfId, setPdfId] = useState('');
  const [isDownloadModalStatusOpen, setIsDownloadModalStatusOpen] =
    useState(false);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatusType>(
    downloadStatuses.IDLE,
  );
  usePreloadStatusIconsForIOS();

  // token to cancel axios request when user close modal
  const cancelToken = useRef(axios.CancelToken.source());
  const pdfStatusInterval = useRef<NodeJS.Timeout | null>(null);

  const modalText = {
    modalHeader: `downloadModal.${modalName}Header`,
    modalMessage: 'downloadModal.downloadMessage',
    modalCommentHeader: 'downloadModal.downloadCommentHeader',
    modalCommentItemOne: 'downloadModal.downloadCommentItemOne',
    modalCommentItemTwo: 'downloadModal.downloadCommentItemTwo',
    modalFooter: 'downloadModal.downloadFooter',
  };

  const abortPdfStatusInterval: () => void = () => {
    if (pdfStatusInterval.current) {
      clearInterval(pdfStatusInterval.current);
      pdfStatusInterval.current = null;
    }
  };

  const closeAllModals = (isDownloadCancelled: boolean = false) => {
    if (isDownloadCancelled) {
      abortPdfStatusInterval();
      cancelToken.current.cancel('Download modal closed');
    }
    setIsDownloadModalStatusOpen(false);
    setIsDownloadModalOpen(false);
  };

  const getPdfStatus = () => {
    axios
      .get(`${ApiPaths.STATUS}/${pdfId}`, {
        cancelToken: cancelToken.current.token,
      })
      .then((response) => {
        if (response.data.data.attributes.status === true) {
          setDownloadStatus(downloadStatuses.SUCCESS);
          if (pdfStatusInterval.current) {
            clearInterval(pdfStatusInterval.current);
            pdfStatusInterval.current = null;
          }
          downloadPdf(pdfId);
        }
      })
      .catch((error) => {
        abortPdfStatusInterval();
        if (axios.isCancel(error)) {
          cancelToken.current = axios.CancelToken.source();
          return;
        }
        setDownloadStatus(downloadStatuses.ERROR);
        throw new Error(error);
      });
  };

  const generatePdfId = () => {
    setIsDownloadModalOpen(false);
    setDownloadStatus(downloadStatuses.DOWNLOADING);
    setIsDownloadModalStatusOpen(true);
    axios
      .post(ApiPaths.GENERATE, getChartsData())
      .then((response) => {
        setPdfId(response.data.data.id);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const generateCsv = () => {
    const chartsData = getChartsData();
    const selectedData = selectDataFromChartsForCSV(chartsData);

    axios
      .post(
        ApiPaths.GENERATECSV,
        { data: getCSVData(selectedData) },
        { responseType: 'blob' },
      )
      .then((response) => {
        downloadFile(response.data, 'application/zip', 'reports.zip');
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (pdfId !== '') {
      cancelToken.current = axios.CancelToken.source();
      pdfStatusInterval.current = setInterval(() => {
        getPdfStatus();
      }, 3000);
    }
  }, [pdfId]);

  return (
    <>
      <SimpleModal
        isOpen={isDownloadModalOpen}
        shouldFocusAfterRender={false}
        onRequestClose={() => setIsDownloadModalOpen(false)}
      >
        <h4 className='DownloadModal__header'>
          <Translate value={modalText.modalHeader} />
        </h4>
        <p className='DownloadModal__message'>
          <Translate value={modalText.modalMessage} />
        </p>
        <p className='DownloadModal__comment__header'>
          <Translate value={modalText.modalCommentHeader} />
        </p>
        <ul className='DownloadModal__comment__list'>
          <li className='DownloadModal__comment__item'>
            <Translate value={modalText.modalCommentItemOne} />
          </li>
          <li className='DownloadModal__comment__item'>
            <Translate value={modalText.modalCommentItemTwo} />
          </li>
        </ul>
        <p className='DownloadModal__footer'>
          <Translate value={modalText.modalFooter} />
        </p>
        <div className='DownloadModal__buttons'>
          <DownloadModalButton
            id='pdf-download-button'
            buttonText='PDF'
            buttonIcon={pdfIcon}
            onClick={() => generatePdfId()}
          />
          <DownloadModalButton
            id='csv-download-button'
            buttonText='CSV'
            buttonIcon={csvIcon}
            onClick={() => generateCsv()}
          />
        </div>
      </SimpleModal>
      <DownloadModalStatus
        isDownloadModalStatusOpen={isDownloadModalStatusOpen}
        downloadStatus={downloadStatus}
        closeAllModals={closeAllModals}
      />
    </>
  );
};

export default DownloadModal;

DownloadModal.propTypes = {
  modalName: PropTypes.string,
  isDownloadModalOpen: PropTypes.bool,
  setIsDownloadModalOpen: PropTypes.func,
};

import { I18n } from 'react-redux-i18n';

import { SectorTypes } from '../types/index.ts';

const errorMessageTranslations = {
  noBusinessesRegistered: 'errorMessage.noBusinessesRegistered',
  limitForSelectedSectors: 'errorMessage.limitForSelectedSectors',
};

export function handleTagsErrors(tag, tags) {
  const errors = [];

  // Translations
  const infoMessage = {
    noBusinessesRegistered: I18n.t(
      errorMessageTranslations.noBusinessesRegistered,
    ),
    limitForSelectedSectors: I18n.t(
      errorMessageTranslations.limitForSelectedSectors,
    ),
  };

  if (!tag.size) {
    errors.push({
      message: infoMessage.noBusinessesRegistered,
    });

    return errors;
  }

  if (tags.length > 2) {
    errors.push({
      message: infoMessage.limitForSelectedSectors,
    });

    return errors;
  }

  return errors;
}

function sortByDescendingSize(a, b) {
  return b.size - a.size;
}

export function sortSectorsBySize(isics) {
  if (!isics.length) return isics;

  const sortedIsics = isics
    .slice()
    .sort(sortByDescendingSize)
    .map((sector) => {
      const { items: subsectors } = sector.children;

      const sortedSubsectors = subsectors.slice().sort(sortByDescendingSize);

      return {
        ...sector,
        children: {
          items: sortedSubsectors,
        },
      };
    });

  return sortedIsics;
}

export function preparefilteredIsicsList(
  sectors,
  graphSubsectorsAggregated = false,
) {
  const { sector: sectionType } = SectorTypes;

  const subsectorsList = sectors.filter(
    (element) => element.type !== sectionType,
  );

  const sectorsList = sectors
    .filter((element) => element.type === sectionType)
    .map((sector) => {
      const root =
        sector.root && sector.id === sector.root.id
          ? { id: null }
          : sector.root;

      if (graphSubsectorsAggregated) {
        return {
          ...sector,
          root,
          children: {},
        };
      }

      return {
        ...sector,
        root,
        children: {
          items: sector.children.items.filter((child) =>
            subsectorsList.some((subsector) => subsector.id === child.id),
          ),
        },
      };
    });

  const subsectorsWithRoots = [];

  subsectorsList.forEach((subsector) => {
    const sectorIndex = subsectorsWithRoots.findIndex(
      (root) => subsector.root.id === root.id,
    );

    if (sectorIndex === -1) {
      subsectorsWithRoots.push({
        id: subsector.root.id,
        name: subsector.root.name,
        size: subsector.size,
        color: subsector.color,
        tooltip: subsector.tooltip,
        type: subsector.root.type,
        children: {
          items: [subsector],
        },
      });
    } else {
      subsectorsWithRoots[sectorIndex].children.items.push(subsector);
    }
  });

  return sectorsList.concat(subsectorsWithRoots);
}

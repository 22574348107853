import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

import {
  Colors,
  PanelItemTypes,
  SubtitleTypes,
} from '../../../helpers/globals/Constans.ts';
import PanelItemNumberValueLabel from './PanelItemNumberValueLabel';
import PanelItemPercentageValue from './PanelItemPercentageValue';
import businessChart from '../../../translations/ar/translations/businessChart';

// eslint-disable-next-line consistent-return
function prepareLabelValue(type, params, enableAnimation = true) {
  // eslint-disable-next-line default-case
  switch (type) {
    case PanelItemTypes.percentageValue:
      return (
        <PanelItemPercentageValue
          number={params.percentageValue}
          enableAnimation={enableAnimation}
        />
      );
    case PanelItemTypes.demographicValue:
      return (
        <PanelItemNumberValueLabel
          number={params.demographicValue}
          enableAnimation={enableAnimation}
        />
      );
    case PanelItemTypes.number:
      return (
        <PanelItemNumberValueLabel
          number={params.size}
          enableAnimation={enableAnimation}
        />
      );
  }
}

function prepareSubtitle(type, params) {
  switch (type) {
    case SubtitleTypes.demographicValue:
      return (
        <div className='panel-item__text panel-item__text--subtitle paragraph-2--dark-gray'>
          <PanelItemNumberValueLabel number={params.demographicValue} />
        </div>
      );

    default:
      return null;
  }
}

const PanelItemComponent = ({
  iconContent,
  valueType,
  params,
  title,
  changedColor,
  subtitle,
  isicName,
  pdfSectorTitles,
  enableAnimation,
  index,
}) => {
  const panelItemClasses = classNames(
    'panel-item col-xs center-xs start-sm nopadding',
    {
      'panel-item--with-icon': iconContent,
    },
  );
  const preparedSubtitle = subtitle
    ? prepareSubtitle(subtitle.type, subtitle.params)
    : null;
  const value = prepareLabelValue(valueType, params, enableAnimation);
  const color =
    changedColor ||
    (index === 0 ? Colors.cyan.value : Colors.havelockBlue.value);

  const iconClasses = classNames(`panel-item__icon ${iconContent}`, {
    hide: !iconContent,
  });

  const panelItemTitle = (panelTitle, isicTitle, description) => {
    if (panelTitle.value.includes('businessNumber')) {
      // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
      return <Translate value={panelTitle.value} {...panelTitle.params} />;
    }
    return `${description} ${isicTitle}`;
  };

  return (
    <div className={panelItemClasses}>
      <i className={iconClasses} style={{ color }} />
      <div className='panel-item__value heading-3 bold' style={{ color }}>
        {value}
      </div>
      <p className='panel-item__text panel-item__text--light paragraph-2 paragraph-2--white '>
        {pdfSectorTitles ? (
          panelItemTitle(
            title,
            isicName,
            businessChart.description.pdf.panelItemDescription,
          )
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading -- x-files
          <Translate value={title.value} {...title.params} />
        )}
      </p>
      {preparedSubtitle}
    </div>
  );
};

PanelItemComponent.propTypes = {
  iconContent: PropTypes.string,
  params: PropTypes.object.isRequired,
  valueType: PropTypes.string.isRequired,
  changedColor: PropTypes.string,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
    params: PropTypes.object,
  }),
  subtitle: PropTypes.shape({
    type: PropTypes.string,
    params: PropTypes.object,
  }),
  demographicPath: PropTypes.bool,
  pdfSectorTitles: PropTypes.bool,
  isicName: PropTypes.string,
  enableAnimation: PropTypes.bool,
  index: PropTypes.number,
};

export default PanelItemComponent;

import { SET_INFOWINDOW_ON_MAP } from '../constants/MapActionsConstants';

export default function setInfoWindowOnMap(infoWindowInstance) {
  return (dispatch, getState) => {
    const state = getState();
    const infoWindowStateInstance = state.map.infoWindowOnMap;

    if (infoWindowStateInstance) {
      infoWindowStateInstance.remove();
    }

    dispatch({
      type: SET_INFOWINDOW_ON_MAP,
      payload: infoWindowInstance,
    });
  };
}

const onboarding = {
  complete: 'إنهاء',
  next: 'التالي',
  restart: 'البدء من جديد',
  exit: 'تخطي',
  steps: {
    breadcrumbs:
      'تستطيع معرفة اسم الموقع الظاهر على الخريطة وتغيير المنطقة المحددة',
    business: 'ستظهر لك تفاصيل القطاعات المحددة على شكل رسوم بيانية',
    demographic: 'يوضح لك هذا القسم التفاصيل الديموغرافية للموقع المحدد',
    scroll: 'لاستكمال الخطوات التوضيحية الرجاء الضغط على السهم أدناه',
    search: 'يمكنك العثور على الموقع المهتم به باستخدام خانة البحث',
    sectors: 'تحديدك للقطاعات يمكنك من استعراض المعلومات الخاصة بها',
    download: 'يمكنك إنشاء تقرير مخصص بناءً على احتياجاتك',
  },
};

export default onboarding;

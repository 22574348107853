import { round } from 'lodash';

import { roundUp } from './AppHelper';
import { GraphUpperscale, numberBreakCases } from './globals/Constans.ts';

function getHighestNumberBreakCase(number) {
  if (number >= numberBreakCases.billions.size) {
    return numberBreakCases.billions.size;
  }

  if (number >= numberBreakCases.millions.size) {
    return numberBreakCases.millions.size;
  }

  if (number >= numberBreakCases.thousands.size) {
    return numberBreakCases.thousands.size;
  }

  return 1;
}

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export function prepareAxisXScale(maxSize, guideLines, isMobile = false) {
  const scale = isMobile ? GraphUpperscale.mobile : GraphUpperscale.desktop;
  const highestNumberBreakCase = getHighestNumberBreakCase(maxSize);
  const precision = -1 * Math.log10(highestNumberBreakCase) + 1;
  let smallestCommonXAxisValueMultiple =
    highestNumberBreakCase * guideLines * 5;

  if ((maxSize * scale) / highestNumberBreakCase >= 1000) {
    smallestCommonXAxisValueMultiple *= 100;
  } else if ((maxSize * scale) / highestNumberBreakCase >= 100) {
    smallestCommonXAxisValueMultiple *= 10;
  } else if ((maxSize * scale) / highestNumberBreakCase < 10) {
    smallestCommonXAxisValueMultiple /= 10;
  }

  const maxAxisXItem = roundUp(
    round(maxSize * scale, precision),
    smallestCommonXAxisValueMultiple,
  );

  return maxAxisXItem;
}

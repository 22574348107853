import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import OnboardingTooltip from '../../../components/Tooltip/OnboardingTooltip/OnboardingTooltip';
import { screenMode, isMobileDevice } from '../../../helpers/ResponsiveHelper';
import {
  isTooltipTargetAvailable,
  getTooltipPositioning,
  initStylesHandler,
  initEventsHandler,
} from './helpers';
import { enableScroll, scrollToMap } from '../../../helpers/AppHelper';

import './Onboarding.scss';

const SEARCH = 0;
const SCROLL = 1;
const BREADCRUMBS = 2;
const SECTORS = 3;
const BUSINESS = 4;
const DEMOGRAPHIC = 5;
const DOWNLOAD = 6;

const ONBOARDING_STEPS_IDS = {
  map: {
    [SEARCH]: 'search',
  },
  mobileIndicator: {
    [SCROLL]: 'scroll',
  },
  sidebar: {
    [BREADCRUMBS]: 'breadcrumbs',
    [SECTORS]: 'sectors',
    [BUSINESS]: 'business',
    [DEMOGRAPHIC]: 'demographic',
    [DOWNLOAD]: 'download',
  },
};

const AVAILABLE_STEPS = {
  desktop: [SEARCH, BREADCRUMBS, SECTORS, BUSINESS, DEMOGRAPHIC, DOWNLOAD],
  mobile: [
    SEARCH,
    SCROLL,
    BREADCRUMBS,
    SECTORS,
    BUSINESS,
    DEMOGRAPHIC,
    DOWNLOAD,
  ],
};

class OnboardingComponent extends React.Component {
  device = isMobileDevice(/xs/) ? 'mobile' : 'desktop';

  stylesHandler = initStylesHandler();

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      positioning: {},
    };
  }

  handleResize = debounce(50, () => {
    const target = this.getTooltipTargetNode();
    const positioning = getTooltipPositioning(target);

    this.setState({ positioning });
  });

  componentDidMount() {
    const { actions } = this.props;

    this.extendedActions = {
      ...actions,
      setNextStep: this.handleSetNextStep,
      endOnboarding: this.handleEndOnboarding,
    };
    this.eventsHandler = initEventsHandler(this.extendedActions);
    if (screenMode === 'desktop')
      this.eventsHandler.addEvent(window, 'resize', this.handleResize);
  }

  componentDidUpdate(
    { currentStep: prevCurrentStep },
    { showTooltip: prevShowTooltip },
  ) {
    const { isMapLoaded, currentStep } = this.props;
    const { showTooltip } = this.state;

    if (isMapLoaded && !showTooltip && !prevShowTooltip) {
      this.showTooltip();
    }

    if (currentStep !== prevCurrentStep) {
      this.resetTooltip();
      this.showTooltip();
    }
  }

  componentWillUnmount() {
    enableScroll();
    this.stylesHandler.resetStyles();
    this.eventsHandler.resetEvents();
  }

  getOnboardingStep = () => {
    const { section, currentStep } = this.props;

    return ONBOARDING_STEPS_IDS[section][currentStep];
  };

  getTooltipTargetNode = () => {
    const id = `onboarding-step-${this.getOnboardingStep()}`;

    return document.getElementById(id);
  };

  getNextStep = () => {
    const { currentStep } = this.props;
    const nextStepIndex = AVAILABLE_STEPS[this.device].indexOf(currentStep) + 1;
    return AVAILABLE_STEPS[this.device][nextStepIndex];
  };

  handleSetNextStep = () => {
    const {
      actions: { setNextStep },
    } = this.props;
    const nextStep = this.getNextStep();

    if (!nextStep) {
      this.handleEndOnboarding();
    } else {
      setNextStep(nextStep);
    }
  };

  handleEndOnboarding = () => {
    const {
      actions: { endOnboarding, setUserAsVisited },
    } = this.props;

    window.localStorage.setItem('hasUserVisited', true);
    this.resetTooltip();
    endOnboarding();
    setUserAsVisited();
    enableScroll();
    scrollToMap();
  };

  showTooltip = () => {
    const target = this.getTooltipTargetNode();
    const sidebarContainer =
      document.getElementsByClassName('sidebar__container')[0];
    const step = this.getOnboardingStep();

    if (isTooltipTargetAvailable(target)) {
      this.setState(
        {
          showTooltip: true,
          positioning: getTooltipPositioning(target),
        },
        () => {
          this.stylesHandler.addClass(sidebarContainer, 'position-relative');
          this.stylesHandler.addClass(target, 'onboarding__target');
          this.stylesHandler.addStyles(target, step);
          this.eventsHandler.addStepEvents(target, step);
        },
      );
    }
  };

  resetTooltip() {
    this.stylesHandler.resetStyles();
    this.eventsHandler.resetEvents();
    this.setState({ showTooltip: false });
  }

  render() {
    const { onboardingActive, currentStep } = this.props;
    const { showTooltip, positioning } = this.state;
    const tourSteps = {
      step: this.getOnboardingStep(),
      stepNumber: AVAILABLE_STEPS[this.device].indexOf(currentStep) + 1,
      maxSteps: AVAILABLE_STEPS[this.device].length,
    };

    return onboardingActive ? (
      <div className='onboarding__bg'>
        {showTooltip && (
          <OnboardingTooltip
            tourSteps={tourSteps}
            positioning={positioning}
            actions={this.extendedActions}
          />
        )}
      </div>
    ) : null;
  }
}

OnboardingComponent.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  onboardingActive: PropTypes.bool,
  currentStep: PropTypes.number,
  isMapLoaded: PropTypes.bool,
  section: PropTypes.string.isRequired,
};

export default OnboardingComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import LinkWithPersistSearch from '../../../../components/LinkWithPersistSearch/LinkWithPersistSearch';

import './CloseSidebarButton.scss';

const CloseSidebarButton = ({ sidebarControlCallback, region }) => (
  <LinkWithPersistSearch
    className='CloseSidebarButton sidebar__close-btn btn btn--turqoise btn--shadow'
    onClick={sidebarControlCallback}
    to={{ pathname: `/${region}` }}
  >
    <span className='btn-label'>
      <Translate value='app.backToMap' />
    </span>
  </LinkWithPersistSearch>
);

CloseSidebarButton.propTypes = {
  sidebarControlCallback: PropTypes.func.isRequired,
  region: PropTypes.string,
};

export default CloseSidebarButton;

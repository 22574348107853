const removeElementsWithIdByClassName = (className: string) => {
  const elementsIds = Array.from(
    document.getElementsByClassName(className),
  ).map((element) => element.id);

  elementsIds.forEach((elementId) => {
    document.getElementById(elementId)?.remove();
  });
};

const MarkerHelperTS = {
  removeElementsWithIdByClassName,
};

export default MarkerHelperTS;

import steakGreen from '../../../../../assets/businesses_icons/Food/steak-green.svg';
import steakYellow from '../../../../../assets/businesses_icons/Food/steak-yellow.svg';
import steakBlue from '../../../../../assets/businesses_icons/Food/steak-blue.svg';
import steakPurple from '../../../../../assets/businesses_icons/Food/steak-purple.svg';
import milkGreen from '../../../../../assets/businesses_icons/Food/milk-green.svg';
import milkYellow from '../../../../../assets/businesses_icons/Food/milk-yellow.svg';
import milkBlue from '../../../../../assets/businesses_icons/Food/milk-blue.svg';
import milkPurple from '../../../../../assets/businesses_icons/Food/milk-purple.svg';
import appleGreen from '../../../../../assets/businesses_icons/Food/apple-green.svg';
import appleYellow from '../../../../../assets/businesses_icons/Food/apple-yellow.svg';
import appleBlue from '../../../../../assets/businesses_icons/Food/apple-blue.svg';
import applePurple from '../../../../../assets/businesses_icons/Food/apple-purple.svg';
import factoryGreen from '../../../../../assets/businesses_icons/Business_Finance/factory-green.svg';
import factoryYellow from '../../../../../assets/businesses_icons/Business_Finance/factory-yellow.svg';
import factoryBlue from '../../../../../assets/businesses_icons/Business_Finance/factory-blue.svg';
import factoryPurple from '../../../../../assets/businesses_icons/Business_Finance/factory-purple.svg';
import bakeryGreen from '../../../../../assets/businesses_icons/Food/bakery-green.svg';
import bakeryYellow from '../../../../../assets/businesses_icons/Food/bakery-yellow.svg';
import bakeryBlue from '../../../../../assets/businesses_icons/Food/bakery-blue.svg';
import bakeryPurple from '../../../../../assets/businesses_icons/Food/bakery-purple.svg';
import candyGreen from '../../../../../assets/businesses_icons/Food/candy-green.svg';
import candyYellow from '../../../../../assets/businesses_icons/Food/candy-yellow.svg';
import candyBlue from '../../../../../assets/businesses_icons/Food/candy-blue.svg';
import candyPurple from '../../../../../assets/businesses_icons/Food/candy-purple.svg';
import softDrinkGreen from '../../../../../assets/businesses_icons/Food/soft-drink-green.svg';
import softDrinkYellow from '../../../../../assets/businesses_icons/Food/soft-drink-yellow.svg';
import softDrinkBlue from '../../../../../assets/businesses_icons/Food/soft-drink-blue.svg';
import softDrinkPurple from '../../../../../assets/businesses_icons/Food/soft-drink-purple.svg';
import iceCreamGreen from '../../../../../assets/businesses_icons/Food/ice-cream-green.svg';
import iceCreamYellow from '../../../../../assets/businesses_icons/Food/ice-cream-yellow.svg';
import iceCreamBlue from '../../../../../assets/businesses_icons/Food/ice-cream-blue.svg';
import iceCreamPurple from '../../../../../assets/businesses_icons/Food/ice-cream-purple.svg';
import beverageGreen from '../../../../../assets/businesses_icons/Food/beverage-green.svg';
import beverageYellow from '../../../../../assets/businesses_icons/Food/beverage-yellow.svg';
import beverageBlue from '../../../../../assets/businesses_icons/Food/beverage-blue.svg';
import beveragePurple from '../../../../../assets/businesses_icons/Food/beverage-purple.svg';
import snowGreen from '../../../../../assets/businesses_icons/Holidays/snow-green.svg';
import snowYellow from '../../../../../assets/businesses_icons/Holidays/snow-yellow.svg';
import snowBlue from '../../../../../assets/businesses_icons/Holidays/snow-blue.svg';
import snowPurple from '../../../../../assets/businesses_icons/Holidays/snow-purple.svg';
import dressWomanGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-woman-green.svg';
import dressWomanYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-woman-yellow.svg';
import dressWomanBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-woman-blue.svg';
import dressWomanPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-woman-purple.svg';
import tableGreen from '../../../../../assets/businesses_icons/Home_Buildings/table-green.svg';
import tableYellow from '../../../../../assets/businesses_icons/Home_Buildings/table-yellow.svg';
import tableBlue from '../../../../../assets/businesses_icons/Home_Buildings/table-blue.svg';
import tablePurple from '../../../../../assets/businesses_icons/Home_Buildings/table-purple.svg';
import furnishedPropertyGreen from '../../../../../assets/businesses_icons/Home_Buildings/furnished-property-green.svg';
import furnishedPropertyYellow from '../../../../../assets/businesses_icons/Home_Buildings/furnished-property-yellow.svg';
import furnishedPropertyBlue from '../../../../../assets/businesses_icons/Home_Buildings/furnished-property-blue.svg';
import furnishedPropertyPurple from '../../../../../assets/businesses_icons/Home_Buildings/furnished-property-purple.svg';
import cableGreen from '../../../../../assets/businesses_icons/Technology/cable-green.svg';
import cableYellow from '../../../../../assets/businesses_icons/Technology/cable-yellow.svg';
import cableBlue from '../../../../../assets/businesses_icons/Technology/cable-blue.svg';
import cablePurple from '../../../../../assets/businesses_icons/Technology/cable-purple.svg';
import airConditionerGreen from '../../../../../assets/businesses_icons/Home_Buildings/air-conditioner-green.svg';
import airConditionerYellow from '../../../../../assets/businesses_icons/Home_Buildings/air-conditioner-yellow.svg';
import airConditionerBlue from '../../../../../assets/businesses_icons/Home_Buildings/air-conditioner-blue.svg';
import airConditionerPurple from '../../../../../assets/businesses_icons/Home_Buildings/air-conditioner-purple.svg';
import paperGreen from '../../../../../assets/businesses_icons/File_Folders/paper-green.svg';
import paperYellow from '../../../../../assets/businesses_icons/File_Folders/paper-yellow.svg';
import paperBlue from '../../../../../assets/businesses_icons/File_Folders/paper-blue.svg';
import paperPurple from '../../../../../assets/businesses_icons/File_Folders/paper-purple.svg';
import box3dGreen from '../../../../../assets/businesses_icons/Shopping/box-3d-green.svg';
import box3dYellow from '../../../../../assets/businesses_icons/Shopping/box-3d-yellow.svg';
import box3dBlue from '../../../../../assets/businesses_icons/Shopping/box-3d-blue.svg';
import box3dPurple from '../../../../../assets/businesses_icons/Shopping/box-3d-purple.svg';
import booksGreen from '../../../../../assets/businesses_icons/Business_Finance/books-green.svg';
import booksYellow from '../../../../../assets/businesses_icons/Business_Finance/books-yellow.svg';
import booksBlue from '../../../../../assets/businesses_icons/Business_Finance/books-blue.svg';
import booksPurple from '../../../../../assets/businesses_icons/Business_Finance/books-purple.svg';
import printerGreen from '../../../../../assets/businesses_icons/Technology/printer-green.svg';
import printerYellow from '../../../../../assets/businesses_icons/Technology/printer-yellow.svg';
import printerBlue from '../../../../../assets/businesses_icons/Technology/printer-blue.svg';
import printerPurple from '../../../../../assets/businesses_icons/Technology/printer-purple.svg';
import paintBrushGreen from '../../../../../assets/businesses_icons/Design_Development/paint-brush-green.svg';
import paintBrushYellow from '../../../../../assets/businesses_icons/Design_Development/paint-brush-yellow.svg';
import paintBrushBlue from '../../../../../assets/businesses_icons/Design_Development/paint-brush-blue.svg';
import paintBrushPurple from '../../../../../assets/businesses_icons/Design_Development/paint-brush-purple.svg';
import towelGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/towel-green.svg';
import towelYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/towel-yellow.svg';
import towelBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/towel-blue.svg';
import towelPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/towel-purple.svg';
import sprayGreen from '../../../../../assets/businesses_icons/Home_Buildings/spray-green.svg';
import sprayYellow from '../../../../../assets/businesses_icons/Home_Buildings/spray-yellow.svg';
import sprayBlue from '../../../../../assets/businesses_icons/Home_Buildings/spray-blue.svg';
import sprayPurple from '../../../../../assets/businesses_icons/Home_Buildings/spray-purple.svg';
import pillGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/pill-green.svg';
import pillYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/pill-yellow.svg';
import pillBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/pill-blue.svg';
import pillPurple from '../../../../../assets/businesses_icons/Healthcare_Medical/pill-purple.svg';
import wasteDangerGreen from '../../../../../assets/businesses_icons/Energy_Environment/waste-danger-green.svg';
import wasteDangerYellow from '../../../../../assets/businesses_icons/Energy_Environment/waste-danger-yellow.svg';
import wasteDangerBlue from '../../../../../assets/businesses_icons/Energy_Environment/waste-danger-blue.svg';
import wasteDangerPurple from '../../../../../assets/businesses_icons/Energy_Environment/waste-danger-purple.svg';
import fuelGreen from '../../../../../assets/businesses_icons/Energy_Environment/fuel-green.svg';
import fuelYellow from '../../../../../assets/businesses_icons/Energy_Environment/fuel-yellow.svg';
import fuelBlue from '../../../../../assets/businesses_icons/Energy_Environment/fuel-blue.svg';
import fuelPurple from '../../../../../assets/businesses_icons/Energy_Environment/fuel-purple.svg';
import carTaxiGreen from '../../../../../assets/businesses_icons/Transportation/car-taxi-green.svg';
import carTaxiYellow from '../../../../../assets/businesses_icons/Transportation/car-taxi-yellow.svg';
import carTaxiBlue from '../../../../../assets/businesses_icons/Transportation/car-taxi-blue.svg';
import carTaxiPurple from '../../../../../assets/businesses_icons/Transportation/car-taxi-purple.svg';
import goldGreen from '../../../../../assets/businesses_icons/Business_Finance/gold-green.svg';
import goldYellow from '../../../../../assets/businesses_icons/Business_Finance/gold-yellow.svg';
import goldBlue from '../../../../../assets/businesses_icons/Business_Finance/gold-blue.svg';
import goldPurple from '../../../../../assets/businesses_icons/Business_Finance/gold-purple.svg';
import trainGreen from '../../../../../assets/businesses_icons/Transportation/train-green.svg';
import trainYellow from '../../../../../assets/businesses_icons/Transportation/train-yellow.svg';
import trainBlue from '../../../../../assets/businesses_icons/Transportation/train-blue.svg';
import trainPurple from '../../../../../assets/businesses_icons/Transportation/train-purple.svg';
import carSimpleGreen from '../../../../../assets/businesses_icons/Transportation/car-simple-green.svg';
import carSimpleYellow from '../../../../../assets/businesses_icons/Transportation/car-simple-yellow.svg';
import carSimpleBlue from '../../../../../assets/businesses_icons/Transportation/car-simple-blue.svg';
import carSimplePurple from '../../../../../assets/businesses_icons/Transportation/car-simple-purple.svg';
import planeGreen from '../../../../../assets/businesses_icons/Transportation/plane-green.svg';
import planeYellow from '../../../../../assets/businesses_icons/Transportation/plane-yellow.svg';
import planeBlue from '../../../../../assets/businesses_icons/Transportation/plane-blue.svg';
import planePurple from '../../../../../assets/businesses_icons/Transportation/plane-purple.svg';
import signalGreen from '../../../../../assets/businesses_icons/Technology/signal-green.svg';
import signalYellow from '../../../../../assets/businesses_icons/Technology/signal-yellow.svg';
import signalBlue from '../../../../../assets/businesses_icons/Technology/signal-blue.svg';
import signalPurple from '../../../../../assets/businesses_icons/Technology/signal-purple.svg';
import mobileButtonGreen from '../../../../../assets/businesses_icons/Technology/mobile-button-green.svg';
import mobileButtonYellow from '../../../../../assets/businesses_icons/Technology/mobile-button-yellow.svg';
import mobileButtonBlue from '../../../../../assets/businesses_icons/Technology/mobile-button-blue.svg';
import mobileButtonPurple from '../../../../../assets/businesses_icons/Technology/mobile-button-purple.svg';
import tvOldGreen from '../../../../../assets/businesses_icons/Technology/tv-old-green.svg';
import tvOldYellow from '../../../../../assets/businesses_icons/Technology/tv-old-yellow.svg';
import tvOldBlue from '../../../../../assets/businesses_icons/Technology/tv-old-blue.svg';
import tvOldPurple from '../../../../../assets/businesses_icons/Technology/tv-old-purple.svg';
import waterSinkGreen from '../../../../../assets/businesses_icons/Energy_Environment/water-sink-green.svg';
import waterSinkYellow from '../../../../../assets/businesses_icons/Energy_Environment/water-sink-yellow.svg';
import waterSinkBlue from '../../../../../assets/businesses_icons/Energy_Environment/water-sink-blue.svg';
import waterSinkPurple from '../../../../../assets/businesses_icons/Energy_Environment/water-sink-purple.svg';
import toiletGreen from '../../../../../assets/businesses_icons/Home_Buildings/toilet-green.svg';
import toiletYellow from '../../../../../assets/businesses_icons/Home_Buildings/toilet-yellow.svg';
import toiletBlue from '../../../../../assets/businesses_icons/Home_Buildings/toilet-blue.svg';
import toiletPurple from '../../../../../assets/businesses_icons/Home_Buildings/toilet-purple.svg';
import wasteRecyclingGreen from '../../../../../assets/businesses_icons/Energy_Environment/waste-recycling-green.svg';
import wasteRecyclingYellow from '../../../../../assets/businesses_icons/Energy_Environment/waste-recycling-yellow.svg';
import wasteRecyclingBlue from '../../../../../assets/businesses_icons/Energy_Environment/waste-recycling-blue.svg';
import wasteRecyclingPurple from '../../../../../assets/businesses_icons/Energy_Environment/waste-recycling-purple.svg';
import deliveryGreen from '../../../../../assets/businesses_icons/Shopping/delivery-green.svg';
import deliveryYellow from '../../../../../assets/businesses_icons/Shopping/delivery-yellow.svg';
import deliveryBlue from '../../../../../assets/businesses_icons/Shopping/delivery-blue.svg';
import deliveryPurple from '../../../../../assets/businesses_icons/Shopping/delivery-purple.svg';
import roastChickenGreen from '../../../../../assets/businesses_icons/Food/roast-chicken-green.svg';
import roastChickenYellow from '../../../../../assets/businesses_icons/Food/roast-chicken-yellow.svg';
import roastChickenBlue from '../../../../../assets/businesses_icons/Food/roast-chicken-blue.svg';
import roastChickenPurple from '../../../../../assets/businesses_icons/Food/roast-chicken-purple.svg';
import plantGroundGreen from '../../../../../assets/businesses_icons/Animals&Nature/plant-ground-green.svg';
import plantGroundYellow from '../../../../../assets/businesses_icons/Animals&Nature/plant-ground-yellow.svg';
import plantGroundBlue from '../../../../../assets/businesses_icons/Animals&Nature/plant-ground-blue.svg';
import plantGroundPurple from '../../../../../assets/businesses_icons/Animals&Nature/plant-ground-purple.svg';
import newConstructionGreen from '../../../../../assets/businesses_icons/Business_Finance/new-construction-green.svg';
import newConstructionYellow from '../../../../../assets/businesses_icons/Business_Finance/new-construction-yellow.svg';
import newConstructionBlue from '../../../../../assets/businesses_icons/Business_Finance/new-construction-blue.svg';
import newConstructionPurple from '../../../../../assets/businesses_icons/Business_Finance/new-construction-purple.svg';
import paintBucketGreen from '../../../../../assets/businesses_icons/Design_Development/paint-bucket-green.svg';
import paintBucketYellow from '../../../../../assets/businesses_icons/Design_Development/paint-bucket-yellow.svg';
import paintBucketBlue from '../../../../../assets/businesses_icons/Design_Development/paint-bucket-blue.svg';
import paintBucketPurple from '../../../../../assets/businesses_icons/Design_Development/paint-bucket-purple.svg';
import keyGreen from '../../../../../assets/businesses_icons/User_Interface/key-green.svg';
import keyYellow from '../../../../../assets/businesses_icons/User_Interface/key-yellow.svg';
import keyBlue from '../../../../../assets/businesses_icons/User_Interface/key-blue.svg';
import keyPurple from '../../../../../assets/businesses_icons/User_Interface/key-purple.svg';
import storageUnitGreen from '../../../../../assets/businesses_icons/Shopping/storage-unit-green.svg';
import storageUnitYellow from '../../../../../assets/businesses_icons/Shopping/storage-unit-yellow.svg';
import storageUnitBlue from '../../../../../assets/businesses_icons/Shopping/storage-unit-blue.svg';
import storageUnitPurple from '../../../../../assets/businesses_icons/Shopping/storage-unit-purple.svg';
import moneyCoinsGreen from '../../../../../assets/businesses_icons/Business_Finance/money-coins-green.svg';
import moneyCoinsYellow from '../../../../../assets/businesses_icons/Business_Finance/money-coins-yellow.svg';
import moneyCoinsBlue from '../../../../../assets/businesses_icons/Business_Finance/money-coins-blue.svg';
import moneyCoinsPurple from '../../../../../assets/businesses_icons/Business_Finance/money-coins-purple.svg';
import storeGreen from '../../../../../assets/businesses_icons/Food/store-green.svg';
import storeYellow from '../../../../../assets/businesses_icons/Food/store-yellow.svg';
import storeBlue from '../../../../../assets/businesses_icons/Food/store-blue.svg';
import storePurple from '../../../../../assets/businesses_icons/Food/store-purple.svg';
import potatoGreen from '../../../../../assets/businesses_icons/Food/potato-green.svg';
import potatoYellow from '../../../../../assets/businesses_icons/Food/potato-yellow.svg';
import potatoBlue from '../../../../../assets/businesses_icons/Food/potato-blue.svg';
import potatoPurple from '../../../../../assets/businesses_icons/Food/potato-purple.svg';
import cakeGreen from '../../../../../assets/businesses_icons/Food/cake-green.svg';
import cakeYellow from '../../../../../assets/businesses_icons/Food/cake-yellow.svg';
import cakeBlue from '../../../../../assets/businesses_icons/Food/cake-blue.svg';
import cakePurple from '../../../../../assets/businesses_icons/Food/cake-purple.svg';
import hazelnutGreen from '../../../../../assets/businesses_icons/Food/hazelnut-green.svg';
import hazelnutYellow from '../../../../../assets/businesses_icons/Food/hazelnut-yellow.svg';
import hazelnutBlue from '../../../../../assets/businesses_icons/Food/hazelnut-blue.svg';
import hazelnutPurple from '../../../../../assets/businesses_icons/Food/hazelnut-purple.svg';
import ovenGreen from '../../../../../assets/businesses_icons/Food/oven-green.svg';
import ovenYellow from '../../../../../assets/businesses_icons/Food/oven-yellow.svg';
import ovenBlue from '../../../../../assets/businesses_icons/Food/oven-blue.svg';
import ovenPurple from '../../../../../assets/businesses_icons/Food/oven-purple.svg';
import dressManGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-man-green.svg';
import dressManYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-man-yellow.svg';
import dressManBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-man-blue.svg';
import dressManPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/dress-man-purple.svg';
import babyGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/baby-green.svg';
import babyYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/baby-yellow.svg';
import babyBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/baby-blue.svg';
import babyPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/baby-purple.svg';
import shoeManGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/shoe-man-green.svg';
import shoeManYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/shoe-man-yellow.svg';
import shoeManBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/shoe-man-blue.svg';
import shoeManPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/shoe-man-purple.svg';
import scissorsGreen from '../../../../../assets/businesses_icons/Design_Development/scissors-green.svg';
import scissorsYellow from '../../../../../assets/businesses_icons/Design_Development/scissors-yellow.svg';
import scissorsBlue from '../../../../../assets/businesses_icons/Design_Development/scissors-blue.svg';
import scissorsPurple from '../../../../../assets/businesses_icons/Design_Development/scissors-purple.svg';
import needleGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/needle-green.svg';
import needleYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/needle-yellow.svg';
import needleBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/needle-blue.svg';
import needlePurple from '../../../../../assets/businesses_icons/Clothes_Accessories/needle-purple.svg';
import apartmentGreen from '../../../../../assets/businesses_icons/Home_Buildings/apartment-green.svg';
import apartmentYellow from '../../../../../assets/businesses_icons/Home_Buildings/apartment-yellow.svg';
import apartmentBlue from '../../../../../assets/businesses_icons/Home_Buildings/apartment-blue.svg';
import apartmentPurple from '../../../../../assets/businesses_icons/Home_Buildings/apartment-purple.svg';
import curtainGreen from '../../../../../assets/businesses_icons/Home_Buildings/curtain-green.svg';
import curtainYellow from '../../../../../assets/businesses_icons/Home_Buildings/curtain-yellow.svg';
import curtainBlue from '../../../../../assets/businesses_icons/Home_Buildings/curtain-blue.svg';
import curtainPurple from '../../../../../assets/businesses_icons/Home_Buildings/curtain-purple.svg';
import homeGreen from '../../../../../assets/businesses_icons/User_Interface/home-green.svg';
import homeYellow from '../../../../../assets/businesses_icons/User_Interface/home-yellow.svg';
import homeBlue from '../../../../../assets/businesses_icons/User_Interface/home-blue.svg';
import homePurple from '../../../../../assets/businesses_icons/User_Interface/home-purple.svg';
import radioGreen from '../../../../../assets/businesses_icons/Multimedia/radio-green.svg';
import radioYellow from '../../../../../assets/businesses_icons/Multimedia/radio-yellow.svg';
import radioBlue from '../../../../../assets/businesses_icons/Multimedia/radio-blue.svg';
import radioPurple from '../../../../../assets/businesses_icons/Multimedia/radio-purple.svg';
import musicGreen from '../../../../../assets/businesses_icons/File_Folders/music-green.svg';
import musicYellow from '../../../../../assets/businesses_icons/File_Folders/music-yellow.svg';
import musicBlue from '../../../../../assets/businesses_icons/File_Folders/music-blue.svg';
import musicPurple from '../../../../../assets/businesses_icons/File_Folders/music-purple.svg';
import plateGreen from '../../../../../assets/businesses_icons/Food/plate-green.svg';
import plateYellow from '../../../../../assets/businesses_icons/Food/plate-yellow.svg';
import plateBlue from '../../../../../assets/businesses_icons/Food/plate-blue.svg';
import platePurple from '../../../../../assets/businesses_icons/Food/plate-purple.svg';
import restaurantMenuGreen from '../../../../../assets/businesses_icons/Food/restaurant-menu-green.svg';
import restaurantMenuYellow from '../../../../../assets/businesses_icons/Food/restaurant-menu-yellow.svg';
import restaurantMenuBlue from '../../../../../assets/businesses_icons/Food/restaurant-menu-blue.svg';
import restaurantMenuPurple from '../../../../../assets/businesses_icons/Food/restaurant-menu-purple.svg';
import coffeeGreen from '../../../../../assets/businesses_icons/Food/coffee-green.svg';
import coffeeYellow from '../../../../../assets/businesses_icons/Food/coffee-yellow.svg';
import coffeeBlue from '../../../../../assets/businesses_icons/Food/coffee-blue.svg';
import coffeePurple from '../../../../../assets/businesses_icons/Food/coffee-purple.svg';
import toothGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/tooth-green.svg';
import toothYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/tooth-yellow.svg';
import toothBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/tooth-blue.svg';
import toothPurple from '../../../../../assets/businesses_icons/Healthcare_Medical/tooth-purple.svg';
import honeyGreen from '../../../../../assets/businesses_icons/Food/honey-green.svg';
import honeyYellow from '../../../../../assets/businesses_icons/Food/honey-yellow.svg';
import honeyBlue from '../../../../../assets/businesses_icons/Food/honey-blue.svg';
import honeyPurple from '../../../../../assets/businesses_icons/Food/honey-purple.svg';
import bookmarkGreen from '../../../../../assets/businesses_icons/User_Interface/bookmark-green.svg';
import bookmarkYellow from '../../../../../assets/businesses_icons/User_Interface/bookmark-yellow.svg';
import bookmarkBlue from '../../../../../assets/businesses_icons/User_Interface/bookmark-blue.svg';
import bookmarkPurple from '../../../../../assets/businesses_icons/User_Interface/bookmark-purple.svg';
import basketballPlayerGreen from '../../../../../assets/businesses_icons/Sport/basketball-player-green.svg';
import basketballPlayerYellow from '../../../../../assets/businesses_icons/Sport/basketball-player-yellow.svg';
import basketballPlayerBlue from '../../../../../assets/businesses_icons/Sport/basketball-player-blue.svg';
import basketballPlayerPurple from '../../../../../assets/businesses_icons/Sport/basketball-player-purple.svg';
import parkGreen from '../../../../../assets/businesses_icons/Animals&Nature/park-green.svg';
import parkYellow from '../../../../../assets/businesses_icons/Animals&Nature/park-yellow.svg';
import parkBlue from '../../../../../assets/businesses_icons/Animals&Nature/park-blue.svg';
import parkPurple from '../../../../../assets/businesses_icons/Animals&Nature/park-purple.svg';
import diamondGreen from '../../../../../assets/businesses_icons/Clothes_Accessories/diamond-green.svg';
import diamondYellow from '../../../../../assets/businesses_icons/Clothes_Accessories/diamond-yellow.svg';
import diamondBlue from '../../../../../assets/businesses_icons/Clothes_Accessories/diamond-blue.svg';
import diamondPurple from '../../../../../assets/businesses_icons/Clothes_Accessories/diamond-purple.svg';
import clockGreen from '../../../../../assets/businesses_icons/User_Interface/clock-green.svg';
import clockYellow from '../../../../../assets/businesses_icons/User_Interface/clock-yellow.svg';
import clockBlue from '../../../../../assets/businesses_icons/User_Interface/clock-blue.svg';
import clockPurple from '../../../../../assets/businesses_icons/User_Interface/clock-purple.svg';
import flowerGreen from '../../../../../assets/businesses_icons/Animals&Nature/flower-green.svg';
import flowerYellow from '../../../../../assets/businesses_icons/Animals&Nature/flower-yellow.svg';
import flowerBlue from '../../../../../assets/businesses_icons/Animals&Nature/flower-blue.svg';
import flowerPurple from '../../../../../assets/businesses_icons/Animals&Nature/flower-purple.svg';
import tapeGreen from '../../../../../assets/businesses_icons/Holidays/tape-green.svg';
import tapeYellow from '../../../../../assets/businesses_icons/Holidays/tape-yellow.svg';
import tapeBlue from '../../../../../assets/businesses_icons/Holidays/tape-blue.svg';
import tapePurple from '../../../../../assets/businesses_icons/Holidays/tape-purple.svg';
import videoGreen from '../../../../../assets/businesses_icons/Multimedia/video-green.svg';
import videoYellow from '../../../../../assets/businesses_icons/Multimedia/video-yellow.svg';
import videoBlue from '../../../../../assets/businesses_icons/Multimedia/video-blue.svg';
import videoPurple from '../../../../../assets/businesses_icons/Multimedia/video-purple.svg';
import glassesGreen from '../../../../../assets/businesses_icons/School_Education/glasses-green.svg';
import glassesYellow from '../../../../../assets/businesses_icons/School_Education/glasses-yellow.svg';
import glassesBlue from '../../../../../assets/businesses_icons/School_Education/glasses-blue.svg';
import glassesPurple from '../../../../../assets/businesses_icons/School_Education/glasses-purple.svg';
import computerOldGreen from '../../../../../assets/businesses_icons/Technology/computer-old-green.svg';
import computerOldYellow from '../../../../../assets/businesses_icons/Technology/computer-old-yellow.svg';
import computerOldBlue from '../../../../../assets/businesses_icons/Technology/computer-old-blue.svg';
import computerOldPurple from '../../../../../assets/businesses_icons/Technology/computer-old-purple.svg';
import officeGreen from '../../../../../assets/businesses_icons/Business_Finance/office-green.svg';
import officeYellow from '../../../../../assets/businesses_icons/Business_Finance/office-yellow.svg';
import officeBlue from '../../../../../assets/businesses_icons/Business_Finance/office-blue.svg';
import officePurple from '../../../../../assets/businesses_icons/Business_Finance/office-purple.svg';
import giftGreen from '../../../../../assets/businesses_icons/Holidays/gift-green.svg';
import giftYellow from '../../../../../assets/businesses_icons/Holidays/gift-yellow.svg';
import giftBlue from '../../../../../assets/businesses_icons/Holidays/gift-blue.svg';
import giftPurple from '../../../../../assets/businesses_icons/Holidays/gift-purple.svg';
import hotelGreen from '../../../../../assets/businesses_icons/Travel/hotel-green.svg';
import hotelYellow from '../../../../../assets/businesses_icons/Travel/hotel-yellow.svg';
import hotelBlue from '../../../../../assets/businesses_icons/Travel/hotel-blue.svg';
import hotelPurple from '../../../../../assets/businesses_icons/Travel/hotel-purple.svg';
import bankGreen from '../../../../../assets/businesses_icons/Business_Finance/bank-green.svg';
import bankYellow from '../../../../../assets/businesses_icons/Business_Finance/bank-yellow.svg';
import bankBlue from '../../../../../assets/businesses_icons/Business_Finance/bank-blue.svg';
import bankPurple from '../../../../../assets/businesses_icons/Business_Finance/bank-purple.svg';
import paymentGreen from '../../../../../assets/businesses_icons/Business_Finance/payment-green.svg';
import paymentYellow from '../../../../../assets/businesses_icons/Business_Finance/payment-yellow.svg';
import paymentBlue from '../../../../../assets/businesses_icons/Business_Finance/payment-blue.svg';
import paymentPurple from '../../../../../assets/businesses_icons/Business_Finance/payment-purple.svg';
import washGreen from '../../../../../assets/businesses_icons/Home_Buildings/wash-green.svg';
import washYellow from '../../../../../assets/businesses_icons/Home_Buildings/wash-yellow.svg';
import washBlue from '../../../../../assets/businesses_icons/Home_Buildings/wash-blue.svg';
import washPurple from '../../../../../assets/businesses_icons/Home_Buildings/wash-purple.svg';
import baloonGreen from '../../../../../assets/businesses_icons/Holidays/baloon-green.svg';
import baloonYellow from '../../../../../assets/businesses_icons/Holidays/baloon-yellow.svg';
import baloonBlue from '../../../../../assets/businesses_icons/Holidays/baloon-blue.svg';
import baloonPurple from '../../../../../assets/businesses_icons/Holidays/baloon-purple.svg';
import customerSupportGreen from '../../../../../assets/businesses_icons/Shopping/customer-support-green.svg';
import customerSupportYellow from '../../../../../assets/businesses_icons/Shopping/customer-support-yellow.svg';
import customerSupportBlue from '../../../../../assets/businesses_icons/Shopping/customer-support-blue.svg';
import customerSupportPurple from '../../../../../assets/businesses_icons/Shopping/customer-support-purple.svg';
import broomGreen from '../../../../../assets/businesses_icons/Home_Buildings/broom-green.svg';
import broomYellow from '../../../../../assets/businesses_icons/Home_Buildings/broom-yellow.svg';
import broomBlue from '../../../../../assets/businesses_icons/Home_Buildings/broom-blue.svg';
import broomPurple from '../../../../../assets/businesses_icons/Home_Buildings/broom-purple.svg';
import carWashGreen from '../../../../../assets/businesses_icons/Transportation/car-wash-green.svg';
import carWashYellow from '../../../../../assets/businesses_icons/Transportation/car-wash-yellow.svg';
import carWashBlue from '../../../../../assets/businesses_icons/Transportation/car-wash-blue.svg';
import carWashPurple from '../../../../../assets/businesses_icons/Transportation/car-wash-purple.svg';
import carAccidentGreen from '../../../../../assets/businesses_icons/Transportation/car-accident-green.svg';
import carAccidentYellow from '../../../../../assets/businesses_icons/Transportation/car-accident-yellow.svg';
import carAccidentBlue from '../../../../../assets/businesses_icons/Transportation/car-accident-blue.svg';
import carAccidentPurple from '../../../../../assets/businesses_icons/Transportation/car-accident-purple.svg';
import cowYellow from '../../../../../assets/businesses_icons/Animals&Nature/cow-yellow.svg';
import cowGreen from '../../../../../assets/businesses_icons/Animals&Nature/cow-green.svg';
import cowBlue from '../../../../../assets/businesses_icons/Animals&Nature/cow-blue.svg';
import cowPurple from '../../../../../assets/businesses_icons/Animals&Nature/cow-purple.svg';
import lawGreen from '../../../../../assets/businesses_icons/Business_Finance/law-green.svg';
import lawYellow from '../../../../../assets/businesses_icons/Business_Finance/law-yellow.svg';
import lawBlue from '../../../../../assets/businesses_icons/Business_Finance/law-blue.svg';
import lawPurple from '../../../../../assets/businesses_icons/Business_Finance/law-purple.svg';
import calculatorGreen from '../../../../../assets/businesses_icons/Business_Finance/calculator-green.svg';
import calculatorYellow from '../../../../../assets/businesses_icons/Business_Finance/calculator-yellow.svg';
import calculatorBlue from '../../../../../assets/businesses_icons/Business_Finance/calculator-blue.svg';
import calculatorPurple from '../../../../../assets/businesses_icons/Business_Finance/calculator-purple.svg';
import securityGreen from '../../../../../assets/businesses_icons/User_Interface/security-green.svg';
import securityYellow from '../../../../../assets/businesses_icons/User_Interface/security-yellow.svg';
import securityBlue from '../../../../../assets/businesses_icons/User_Interface/security-blue.svg';
import securityPurple from '../../../../../assets/businesses_icons/User_Interface/security-purple.svg';
import policeGreen from '../../../../../assets/businesses_icons/Users/police-green.svg';
import policeYellow from '../../../../../assets/businesses_icons/Users/police-yellow.svg';
import policeBlue from '../../../../../assets/businesses_icons/Users/police-blue.svg';
import policePurple from '../../../../../assets/businesses_icons/Users/police-purple.svg';
import mailGreen from '../../../../../assets/businesses_icons/User_Interface/mail-green.svg';
import mailYellow from '../../../../../assets/businesses_icons/User_Interface/mail-yellow.svg';
import mailBlue from '../../../../../assets/businesses_icons/User_Interface/mail-blue.svg';
import mailPurple from '../../../../../assets/businesses_icons/User_Interface/mail-purple.svg';
import handoutGreen from '../../../../../assets/businesses_icons/Business_Finance/handout-green.svg';
import handoutYellow from '../../../../../assets/businesses_icons/Business_Finance/handout-yellow.svg';
import handoutBlue from '../../../../../assets/businesses_icons/Business_Finance/handout-blue.svg';
import handoutPurple from '../../../../../assets/businesses_icons/Business_Finance/handout-purple.svg';
import doctorGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/doctor-green.svg';
import doctorYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/doctor-yellow.svg';
import doctorBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/doctor-blue.svg';
import doctorPurple from '../../../../../assets/businesses_icons/Healthcare_Medical/doctor-purple.svg';
import hospitalGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/hospital-green.svg';
import hospitalYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/hospital-yellow.svg';
import hospitalBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/hospital-blue.svg';
import hospitalPurple from '../../../../../assets/businesses_icons/Healthcare_Medical/hospital-purple.svg';
import labGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/lab-green.svg';
import labYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/lab-yellow.svg';
import labBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/lab-blue.svg';
import labPurple from '../../../../../assets/businesses_icons/Healthcare_Medical/lab-purple.svg';
import ambulanceGreen from '../../../../../assets/businesses_icons/Healthcare_Medical/ambulance-green.svg';
import ambulanceYellow from '../../../../../assets/businesses_icons/Healthcare_Medical/ambulance-yellow.svg';
import ambulanceBlue from '../../../../../assets/businesses_icons/Healthcare_Medical/ambulance-blue.svg';
import ambulancePurple from '../../../../../assets/businesses_icons/Healthcare_Medical/ambulance-purple.svg';
import playgroundGreen from '../../../../../assets/businesses_icons/Real_Estate/playground-green.svg';
import playgroundYellow from '../../../../../assets/businesses_icons/Real_Estate/playground-yellow.svg';
import playgroundBlue from '../../../../../assets/businesses_icons/Real_Estate/playground-blue.svg';
import playgroundPurple from '../../../../../assets/businesses_icons/Real_Estate/playground-purple.svg';
import schoolGreen from '../../../../../assets/businesses_icons/School_Education/school-green.svg';
import schoolYellow from '../../../../../assets/businesses_icons/School_Education/school-yellow.svg';
import schoolBlue from '../../../../../assets/businesses_icons/School_Education/school-blue.svg';
import schoolPurple from '../../../../../assets/businesses_icons/School_Education/school-purple.svg';
import disabledGreen from '../../../../../assets/businesses_icons/Users/disabled-green.svg';
import disabledYellow from '../../../../../assets/businesses_icons/Users/disabled-yellow.svg';
import disabledBlue from '../../../../../assets/businesses_icons/Users/disabled-blue.svg';
import disabledPurple from '../../../../../assets/businesses_icons/Users/disabled-purple.svg';
import libraryGreen from '../../../../../assets/businesses_icons/Home_Buildings/library-green.svg';
import libraryYellow from '../../../../../assets/businesses_icons/Home_Buildings/library-yellow.svg';
import libraryBlue from '../../../../../assets/businesses_icons/Home_Buildings/library-blue.svg';
import libraryPurple from '../../../../../assets/businesses_icons/Home_Buildings/library-purple.svg';
import castleGreen from '../../../../../assets/businesses_icons/Travel/castle-green.svg';
import castleYellow from '../../../../../assets/businesses_icons/Travel/castle-yellow.svg';
import castleBlue from '../../../../../assets/businesses_icons/Travel/castle-blue.svg';
import castlePurple from '../../../../../assets/businesses_icons/Travel/castle-purple.svg';
import certificateGreen from '../../../../../assets/businesses_icons/Business_Finance/certificate-green.svg';
import certificateYellow from '../../../../../assets/businesses_icons/Business_Finance/certificate-yellow.svg';
import certificateBlue from '../../../../../assets/businesses_icons/Business_Finance/certificate-blue.svg';
import certificatePurple from '../../../../../assets/businesses_icons/Business_Finance/certificate-purple.svg';
import stadiumGreen from '../../../../../assets/businesses_icons/Sport/stadium-green.svg';
import stadiumYellow from '../../../../../assets/businesses_icons/Sport/stadium-yellow.svg';
import stadiumBlue from '../../../../../assets/businesses_icons/Sport/stadium-blue.svg';
import stadiumPurple from '../../../../../assets/businesses_icons/Sport/stadium-purple.svg';
import racingBikeGreen from '../../../../../assets/businesses_icons/Sport/racing-bike-green.svg';
import racingBikeYellow from '../../../../../assets/businesses_icons/Sport/racing-bike-yellow.svg';
import racingBikeBlue from '../../../../../assets/businesses_icons/Sport/racing-bike-blue.svg';
import racingBikePurple from '../../../../../assets/businesses_icons/Sport/racing-bike-purple.svg';
import athleticsGreen from '../../../../../assets/businesses_icons/Sport/athletics-green.svg';
import athleticsYellow from '../../../../../assets/businesses_icons/Sport/athletics-yellow.svg';
import athleticsBlue from '../../../../../assets/businesses_icons/Sport/athletics-blue.svg';
import athleticsPurple from '../../../../../assets/businesses_icons/Sport/athletics-purple.svg';
import presentationGreen from '../../../../../assets/businesses_icons/Business_Finance/presentation-green.svg';
import presentationYellow from '../../../../../assets/businesses_icons/Business_Finance/presentation-yellow.svg';
import presentationBlue from '../../../../../assets/businesses_icons/Business_Finance/presentation-blue.svg';
import presentationPurple from '../../../../../assets/businesses_icons/Business_Finance/presentation-purple.svg';
import frameGreen from '../../../../../assets/businesses_icons/Home_Buildings/frame-green.svg';
import frameYellow from '../../../../../assets/businesses_icons/Home_Buildings/frame-yellow.svg';
import frameBlue from '../../../../../assets/businesses_icons/Home_Buildings/frame-blue.svg';
import framePurple from '../../../../../assets/businesses_icons/Home_Buildings/frame-purple.svg';
import golfClubGreen from '../../../../../assets/businesses_icons/Sport/golf-club-green.svg';
import golfClubYellow from '../../../../../assets/businesses_icons/Sport/golf-club-yellow.svg';
import golfClubBlue from '../../../../../assets/businesses_icons/Sport/golf-club-blue.svg';
import golfClubPurple from '../../../../../assets/businesses_icons/Sport/golf-club-purple.svg';
import volleyballGreen from '../../../../../assets/businesses_icons/Sport/volleyball-green.svg';
import volleyballYellow from '../../../../../assets/businesses_icons/Sport/volleyball-yellow.svg';
import volleyballBlue from '../../../../../assets/businesses_icons/Sport/volleyball-blue.svg';
import volleyballPurple from '../../../../../assets/businesses_icons/Sport/volleyball-purple.svg';
import tennisRacketGreen from '../../../../../assets/businesses_icons/Sport/tennis-racket-green.svg';
import tennisRacketYellow from '../../../../../assets/businesses_icons/Sport/tennis-racket-yellow.svg';
import tennisRacketBlue from '../../../../../assets/businesses_icons/Sport/tennis-racket-blue.svg';
import tennisRacketPurple from '../../../../../assets/businesses_icons/Sport/tennis-racket-purple.svg';
import skatingGreen from '../../../../../assets/businesses_icons/Sport/skating-green.svg';
import skatingYellow from '../../../../../assets/businesses_icons/Sport/skating-yellow.svg';
import skatingBlue from '../../../../../assets/businesses_icons/Sport/skating-blue.svg';
import skatingPurple from '../../../../../assets/businesses_icons/Sport/skating-purple.svg';
import equestrianHelmetGreen from '../../../../../assets/businesses_icons/Sport/equestrian-helmet-green.svg';
import equestrianHelmetYellow from '../../../../../assets/businesses_icons/Sport/equestrian-helmet-yellow.svg';
import equestrianHelmetBlue from '../../../../../assets/businesses_icons/Sport/equestrian-helmet-blue.svg';
import equestrianHelmetPurple from '../../../../../assets/businesses_icons/Sport/equestrian-helmet-purple.svg';
import bowlingBallGreen from '../../../../../assets/businesses_icons/Sport/bowling-ball-green.svg';
import bowlingBallYellow from '../../../../../assets/businesses_icons/Sport/bowling-ball-yellow.svg';
import bowlingBallBlue from '../../../../../assets/businesses_icons/Sport/bowling-ball-blue.svg';
import bowlingBallPurple from '../../../../../assets/businesses_icons/Sport/bowling-ball-purple.svg';
import basketballRingGreen from '../../../../../assets/businesses_icons/Sport/basketball-ring-green.svg';
import basketballRingYellow from '../../../../../assets/businesses_icons/Sport/basketball-ring-yellow.svg';
import basketballRingBlue from '../../../../../assets/businesses_icons/Sport/basketball-ring-blue.svg';
import basketballRingPurple from '../../../../../assets/businesses_icons/Sport/basketball-ring-purple.svg';
import soccerFieldGreen from '../../../../../assets/businesses_icons/Sport/soccer-field-green.svg';
import soccerFieldYellow from '../../../../../assets/businesses_icons/Sport/soccer-field-yellow.svg';
import soccerFieldBlue from '../../../../../assets/businesses_icons/Sport/soccer-field-blue.svg';
import soccerFieldPurple from '../../../../../assets/businesses_icons/Sport/soccer-field-purple.svg';
import dumbbellGreen from '../../../../../assets/businesses_icons/Sport/dumbbell-green.svg';
import dumbbellYellow from '../../../../../assets/businesses_icons/Sport/dumbbell-yellow.svg';
import dumbbellBlue from '../../../../../assets/businesses_icons/Sport/dumbbell-blue.svg';
import dumbbellPurple from '../../../../../assets/businesses_icons/Sport/dumbbell-purple.svg';
import swimmingPoolGreen from '../../../../../assets/businesses_icons/Sport/swimming-pool-green.svg';
import swimmingPoolYellow from '../../../../../assets/businesses_icons/Sport/swimming-pool-yellow.svg';
import swimmingPoolBlue from '../../../../../assets/businesses_icons/Sport/swimming-pool-blue.svg';
import swimmingPoolPurple from '../../../../../assets/businesses_icons/Sport/swimming-pool-purple.svg';
import movieGreen from '../../../../../assets/businesses_icons/Multimedia/movie-green.svg';
import movieYellow from '../../../../../assets/businesses_icons/Multimedia/movie-yellow.svg';
import movieBlue from '../../../../../assets/businesses_icons/Multimedia/movie-blue.svg';
import moviePurple from '../../../../../assets/businesses_icons/Multimedia/movie-purple.svg';
import hammerGreen from '../../../../../assets/businesses_icons/Business_Finance/hammer-green.svg';
import hammerYellow from '../../../../../assets/businesses_icons/Business_Finance/hammer-yellow.svg';
import hammerBlue from '../../../../../assets/businesses_icons/Business_Finance/hammer-blue.svg';
import hammerPurple from '../../../../../assets/businesses_icons/Business_Finance/hammer-purple.svg';
import mosqueGreen from '../../../../../assets/businesses_icons/Travel/mosque-green.svg';
import mosqueYellow from '../../../../../assets/businesses_icons/Travel/mosque-yellow.svg';
import mosqueBlue from '../../../../../assets/businesses_icons/Travel/mosque-blue.svg';
import mosquePurple from '../../../../../assets/businesses_icons/Travel/mosque-purple.svg';
import cemeteryGreen from '../../../../../assets/businesses_icons/Travel/cemetery-green.svg';
import cemeteryYellow from '../../../../../assets/businesses_icons/Travel/cemetery-yellow.svg';
import cemeteryBlue from '../../../../../assets/businesses_icons/Travel/cemetery-blue.svg';
import cemeteryPurple from '../../../../../assets/businesses_icons/Travel/cemetery-purple.svg';
import gasGreen from '../../../../../assets/businesses_icons/Energy_Environment/gas-green.svg';
import gasYellow from '../../../../../assets/businesses_icons/Energy_Environment/gas-yellow.svg';
import gasBlue from '../../../../../assets/businesses_icons/Energy_Environment/gas-blue.svg';
import gasPurple from '../../../../../assets/businesses_icons/Energy_Environment/gas-purple.svg';
import parkingGreen from '../../../../../assets/businesses_icons/Transportation/parking-green.svg';
import parkingYellow from '../../../../../assets/businesses_icons/Transportation/parking-yellow.svg';
import parkingBlue from '../../../../../assets/businesses_icons/Transportation/parking-blue.svg';
import parkingPurple from '../../../../../assets/businesses_icons/Transportation/parking-purple.svg';

const cow = {
  blue: cowBlue,
  green: cowGreen,
  purple: cowPurple,
  yellow: cowYellow,
};

const flower = {
  blue: flowerBlue,
  green: flowerGreen,
  purple: flowerPurple,
  yellow: flowerYellow,
};

const park = {
  blue: parkBlue,
  green: parkGreen,
  purple: parkPurple,
  yellow: parkYellow,
};

const plantGround = {
  blue: plantGroundBlue,
  green: plantGroundGreen,
  purple: plantGroundPurple,
  yellow: plantGroundYellow,
};

const bank = {
  blue: bankBlue,
  green: bankGreen,
  purple: bankPurple,
  yellow: bankYellow,
};

const books = {
  blue: booksBlue,
  green: booksGreen,
  purple: booksPurple,
  yellow: booksYellow,
};

const calculator = {
  blue: calculatorBlue,
  green: calculatorGreen,
  purple: calculatorPurple,
  yellow: calculatorYellow,
};

const certificate = {
  blue: certificateBlue,
  green: certificateGreen,
  purple: certificatePurple,
  yellow: certificateYellow,
};

const factory = {
  blue: factoryBlue,
  green: factoryGreen,
  purple: factoryPurple,
  yellow: factoryYellow,
};

const gold = {
  blue: goldBlue,
  green: goldGreen,
  purple: goldPurple,
  yellow: goldYellow,
};

const hammer = {
  blue: hammerBlue,
  green: hammerGreen,
  purple: hammerPurple,
  yellow: hammerYellow,
};

const handout = {
  blue: handoutBlue,
  green: handoutGreen,
  purple: handoutPurple,
  yellow: handoutYellow,
};

const law = {
  blue: lawBlue,
  green: lawGreen,
  purple: lawPurple,
  yellow: lawYellow,
};

const moneyCoins = {
  blue: moneyCoinsBlue,
  green: moneyCoinsGreen,
  purple: moneyCoinsPurple,
  yellow: moneyCoinsYellow,
};

const newConstruction = {
  blue: newConstructionBlue,
  green: newConstructionGreen,
  purple: newConstructionPurple,
  yellow: newConstructionYellow,
};

const office = {
  blue: officeBlue,
  green: officeGreen,
  purple: officePurple,
  yellow: officeYellow,
};

const payment = {
  blue: paymentBlue,
  green: paymentGreen,
  purple: paymentPurple,
  yellow: paymentYellow,
};

const presentation = {
  blue: presentationBlue,
  green: presentationGreen,
  purple: presentationPurple,
  yellow: presentationYellow,
};

const baby = {
  blue: babyBlue,
  green: babyGreen,
  purple: babyPurple,
  yellow: babyYellow,
};

const diamond = {
  blue: diamondBlue,
  green: diamondGreen,
  purple: diamondPurple,
  yellow: diamondYellow,
};

const dressMan = {
  blue: dressManBlue,
  green: dressManGreen,
  purple: dressManPurple,
  yellow: dressManYellow,
};

const dressWoman = {
  blue: dressWomanBlue,
  green: dressWomanGreen,
  purple: dressWomanPurple,
  yellow: dressWomanYellow,
};

const needle = {
  blue: needleBlue,
  green: needleGreen,
  purple: needlePurple,
  yellow: needleYellow,
};

const shoeMan = {
  blue: shoeManBlue,
  green: shoeManGreen,
  purple: shoeManPurple,
  yellow: shoeManYellow,
};

const towel = {
  blue: towelBlue,
  green: towelGreen,
  purple: towelPurple,
  yellow: towelYellow,
};

const paintBrush = {
  blue: paintBrushBlue,
  green: paintBrushGreen,
  purple: paintBrushPurple,
  yellow: paintBrushYellow,
};

const paintBucket = {
  blue: paintBucketBlue,
  green: paintBucketGreen,
  purple: paintBucketPurple,
  yellow: paintBucketYellow,
};

const scissors = {
  blue: scissorsBlue,
  green: scissorsGreen,
  purple: scissorsPurple,
  yellow: scissorsYellow,
};

const spray = {
  blue: sprayBlue,
  green: sprayGreen,
  purple: sprayPurple,
  yellow: sprayYellow,
};

const fuel = {
  blue: fuelBlue,
  green: fuelGreen,
  purple: fuelPurple,
  yellow: fuelYellow,
};

const wasteDanger = {
  blue: wasteDangerBlue,
  green: wasteDangerGreen,
  purple: wasteDangerPurple,
  yellow: wasteDangerYellow,
};

const wasteRecycling = {
  blue: wasteRecyclingBlue,
  green: wasteRecyclingGreen,
  purple: wasteRecyclingPurple,
  yellow: wasteRecyclingYellow,
};

const waterSink = {
  blue: waterSinkBlue,
  green: waterSinkGreen,
  purple: waterSinkPurple,
  yellow: waterSinkYellow,
};

const music = {
  blue: musicBlue,
  green: musicGreen,
  purple: musicPurple,
  yellow: musicYellow,
};

const paper = {
  blue: paperBlue,
  green: paperGreen,
  purple: paperPurple,
  yellow: paperYellow,
};

const apple = {
  blue: appleBlue,
  green: appleGreen,
  purple: applePurple,
  yellow: appleYellow,
};

const bakery = {
  blue: bakeryBlue,
  green: bakeryGreen,
  purple: bakeryPurple,
  yellow: bakeryYellow,
};

const beverage = {
  blue: beverageBlue,
  green: beverageGreen,
  purple: beveragePurple,
  yellow: beverageYellow,
};

const cake = {
  blue: cakeBlue,
  green: cakeGreen,
  purple: cakePurple,
  yellow: cakeYellow,
};

const candy = {
  blue: candyBlue,
  green: candyGreen,
  purple: candyPurple,
  yellow: candyYellow,
};

const coffee = {
  blue: coffeeBlue,
  green: coffeeGreen,
  purple: coffeePurple,
  yellow: coffeeYellow,
};

const hazelnut = {
  blue: hazelnutBlue,
  green: hazelnutGreen,
  purple: hazelnutPurple,
  yellow: hazelnutYellow,
};

const iceCream = {
  blue: iceCreamBlue,
  green: iceCreamGreen,
  purple: iceCreamPurple,
  yellow: iceCreamYellow,
};

const milk = {
  blue: milkBlue,
  green: milkGreen,
  purple: milkPurple,
  yellow: milkYellow,
};

const oven = {
  blue: ovenBlue,
  green: ovenGreen,
  purple: ovenPurple,
  yellow: ovenYellow,
};

const plate = {
  blue: plateBlue,
  green: plateGreen,
  purple: platePurple,
  yellow: plateYellow,
};

const potato = {
  blue: potatoBlue,
  green: potatoGreen,
  purple: potatoPurple,
  yellow: potatoYellow,
};

const restaurantMenu = {
  blue: restaurantMenuBlue,
  green: restaurantMenuGreen,
  purple: restaurantMenuPurple,
  yellow: restaurantMenuYellow,
};

const roastChicken = {
  blue: roastChickenBlue,
  green: roastChickenGreen,
  purple: roastChickenPurple,
  yellow: roastChickenYellow,
};

const softDrink = {
  blue: softDrinkBlue,
  green: softDrinkGreen,
  purple: softDrinkPurple,
  yellow: softDrinkYellow,
};

const steak = {
  blue: steakBlue,
  green: steakGreen,
  purple: steakPurple,
  yellow: steakYellow,
};

const store = {
  blue: storeBlue,
  green: storeGreen,
  purple: storePurple,
  yellow: storeYellow,
};

const ambulance = {
  blue: ambulanceBlue,
  green: ambulanceGreen,
  purple: ambulancePurple,
  yellow: ambulanceYellow,
};

const doctor = {
  blue: doctorBlue,
  green: doctorGreen,
  purple: doctorPurple,
  yellow: doctorYellow,
};

const hospital = {
  blue: hospitalBlue,
  green: hospitalGreen,
  purple: hospitalPurple,
  yellow: hospitalYellow,
};

const lab = {
  blue: labBlue,
  green: labGreen,
  purple: labPurple,
  yellow: labYellow,
};

const pill = {
  blue: pillBlue,
  green: pillGreen,
  purple: pillPurple,
  yellow: pillYellow,
};

const tooth = {
  blue: toothBlue,
  green: toothGreen,
  purple: toothPurple,
  yellow: toothYellow,
};

const baloon = {
  blue: baloonBlue,
  green: baloonGreen,
  purple: baloonPurple,
  yellow: baloonYellow,
};

const gift = {
  blue: giftBlue,
  green: giftGreen,
  purple: giftPurple,
  yellow: giftYellow,
};

const snow = {
  blue: snowBlue,
  green: snowGreen,
  purple: snowPurple,
  yellow: snowYellow,
};

const tape = {
  blue: tapeBlue,
  green: tapeGreen,
  purple: tapePurple,
  yellow: tapeYellow,
};

const airConditioner = {
  blue: airConditionerBlue,
  green: airConditionerGreen,
  purple: airConditionerPurple,
  yellow: airConditionerYellow,
};

const apartment = {
  blue: apartmentBlue,
  green: apartmentGreen,
  purple: apartmentPurple,
  yellow: apartmentYellow,
};

const broom = {
  blue: broomBlue,
  green: broomGreen,
  purple: broomPurple,
  yellow: broomYellow,
};

const curtain = {
  blue: curtainBlue,
  green: curtainGreen,
  purple: curtainPurple,
  yellow: curtainYellow,
};

const frame = {
  blue: frameBlue,
  green: frameGreen,
  purple: framePurple,
  yellow: frameYellow,
};

const furnishedProperty = {
  blue: furnishedPropertyBlue,
  green: furnishedPropertyGreen,
  purple: furnishedPropertyPurple,
  yellow: furnishedPropertyYellow,
};

const library = {
  blue: libraryBlue,
  green: libraryGreen,
  purple: libraryPurple,
  yellow: libraryYellow,
};

const table = {
  blue: tableBlue,
  green: tableGreen,
  purple: tablePurple,
  yellow: tableYellow,
};

const toilet = {
  blue: toiletBlue,
  green: toiletGreen,
  purple: toiletPurple,
  yellow: toiletYellow,
};

const wash = {
  blue: washBlue,
  green: washGreen,
  purple: washPurple,
  yellow: washYellow,
};

const movie = {
  blue: movieBlue,
  green: movieGreen,
  purple: moviePurple,
  yellow: movieYellow,
};

const radio = {
  blue: radioBlue,
  green: radioGreen,
  purple: radioPurple,
  yellow: radioYellow,
};

const video = {
  blue: videoBlue,
  green: videoGreen,
  purple: videoPurple,
  yellow: videoYellow,
};

const playground = {
  blue: playgroundBlue,
  green: playgroundGreen,
  purple: playgroundPurple,
  yellow: playgroundYellow,
};

const glasses = {
  blue: glassesBlue,
  green: glassesGreen,
  purple: glassesPurple,
  yellow: glassesYellow,
};

const school = {
  blue: schoolBlue,
  green: schoolGreen,
  purple: schoolPurple,
  yellow: schoolYellow,
};

const box3d = {
  blue: box3dBlue,
  green: box3dGreen,
  purple: box3dPurple,
  yellow: box3dYellow,
};

const customerSupport = {
  blue: customerSupportBlue,
  green: customerSupportGreen,
  purple: customerSupportPurple,
  yellow: customerSupportYellow,
};

const delivery = {
  blue: deliveryBlue,
  green: deliveryGreen,
  purple: deliveryPurple,
  yellow: deliveryYellow,
};

const storageUnit = {
  blue: storageUnitBlue,
  green: storageUnitGreen,
  purple: storageUnitPurple,
  yellow: storageUnitYellow,
};

const athletics = {
  blue: athleticsBlue,
  green: athleticsGreen,
  purple: athleticsPurple,
  yellow: athleticsYellow,
};

const basketballRing = {
  blue: basketballRingBlue,
  green: basketballRingGreen,
  purple: basketballRingPurple,
  yellow: basketballRingYellow,
};

const bowlingBall = {
  blue: bowlingBallBlue,
  green: bowlingBallGreen,
  purple: bowlingBallPurple,
  yellow: bowlingBallYellow,
};

const dumbbell = {
  blue: dumbbellBlue,
  green: dumbbellGreen,
  purple: dumbbellPurple,
  yellow: dumbbellYellow,
};

const equestrianHelmet = {
  blue: equestrianHelmetBlue,
  green: equestrianHelmetGreen,
  purple: equestrianHelmetPurple,
  yellow: equestrianHelmetYellow,
};

const golfClub = {
  blue: golfClubBlue,
  green: golfClubGreen,
  purple: golfClubPurple,
  yellow: golfClubYellow,
};

const racingBike = {
  blue: racingBikeBlue,
  green: racingBikeGreen,
  purple: racingBikePurple,
  yellow: racingBikeYellow,
};

const skating = {
  blue: skatingBlue,
  green: skatingGreen,
  purple: skatingPurple,
  yellow: skatingYellow,
};

const soccerField = {
  blue: soccerFieldBlue,
  green: soccerFieldGreen,
  purple: soccerFieldPurple,
  yellow: soccerFieldYellow,
};

const stadium = {
  blue: stadiumBlue,
  green: stadiumGreen,
  purple: stadiumPurple,
  yellow: stadiumYellow,
};

const swimmingPool = {
  blue: swimmingPoolBlue,
  green: swimmingPoolGreen,
  purple: swimmingPoolPurple,
  yellow: swimmingPoolYellow,
};

const tennisRacket = {
  blue: tennisRacketBlue,
  green: tennisRacketGreen,
  purple: tennisRacketPurple,
  yellow: tennisRacketYellow,
};

const volleyball = {
  blue: volleyballBlue,
  green: volleyballGreen,
  purple: volleyballPurple,
  yellow: volleyballYellow,
};

const cable = {
  blue: cableBlue,
  green: cableGreen,
  purple: cablePurple,
  yellow: cableYellow,
};

const computerOld = {
  blue: computerOldBlue,
  green: computerOldGreen,
  purple: computerOldPurple,
  yellow: computerOldYellow,
};

const mobileButton = {
  blue: mobileButtonBlue,
  green: mobileButtonGreen,
  purple: mobileButtonPurple,
  yellow: mobileButtonYellow,
};

const printer = {
  blue: printerBlue,
  green: printerGreen,
  purple: printerPurple,
  yellow: printerYellow,
};

const signal = {
  blue: signalBlue,
  green: signalGreen,
  purple: signalPurple,
  yellow: signalYellow,
};

const tvOld = {
  blue: tvOldBlue,
  green: tvOldGreen,
  purple: tvOldPurple,
  yellow: tvOldYellow,
};

const carAccident = {
  blue: carAccidentBlue,
  green: carAccidentGreen,
  purple: carAccidentPurple,
  yellow: carAccidentYellow,
};

const carSimple = {
  blue: carSimpleBlue,
  green: carSimpleGreen,
  purple: carSimplePurple,
  yellow: carSimpleYellow,
};

const carTaxi = {
  blue: carTaxiBlue,
  green: carTaxiGreen,
  purple: carTaxiPurple,
  yellow: carTaxiYellow,
};

const carWash = {
  blue: carWashBlue,
  green: carWashGreen,
  purple: carWashPurple,
  yellow: carWashYellow,
};

const plane = {
  blue: planeBlue,
  green: planeGreen,
  purple: planePurple,
  yellow: planeYellow,
};

const train = {
  blue: trainBlue,
  green: trainGreen,
  purple: trainPurple,
  yellow: trainYellow,
};

const castle = {
  blue: castleBlue,
  green: castleGreen,
  purple: castlePurple,
  yellow: castleYellow,
};

const hotel = {
  blue: hotelBlue,
  green: hotelGreen,
  purple: hotelPurple,
  yellow: hotelYellow,
};

const mosque = {
  blue: mosqueBlue,
  green: mosqueGreen,
  purple: mosquePurple,
  yellow: mosqueYellow,
};

const clock = {
  blue: clockBlue,
  green: clockGreen,
  purple: clockPurple,
  yellow: clockYellow,
};

const home = {
  blue: homeBlue,
  green: homeGreen,
  purple: homePurple,
  yellow: homeYellow,
};

const key = {
  blue: keyBlue,
  green: keyGreen,
  purple: keyPurple,
  yellow: keyYellow,
};

const mail = {
  blue: mailBlue,
  green: mailGreen,
  purple: mailPurple,
  yellow: mailYellow,
};

const security = {
  blue: securityBlue,
  green: securityGreen,
  purple: securityPurple,
  yellow: securityYellow,
};

const disabled = {
  blue: disabledBlue,
  green: disabledGreen,
  purple: disabledPurple,
  yellow: disabledYellow,
};

const police = {
  blue: policeBlue,
  green: policeGreen,
  purple: policePurple,
  yellow: policeYellow,
};

const basketballPlayer = {
  blue: basketballPlayerBlue,
  green: basketballPlayerGreen,
  purple: basketballPlayerPurple,
  yellow: basketballPlayerYellow,
};

const bookmark = {
  blue: bookmarkBlue,
  green: bookmarkGreen,
  purple: bookmarkPurple,
  yellow: bookmarkYellow,
};

const honey = {
  blue: honeyBlue,
  green: honeyGreen,
  purple: honeyPurple,
  yellow: honeyYellow,
};

const gas = {
  blue: gasBlue,
  green: gasGreen,
  purple: gasPurple,
  yellow: gasYellow,
};

const parking = {
  blue: parkingBlue,
  green: parkingGreen,
  purple: parkingPurple,
  yellow: parkingYellow,
};

const cemetery = {
  blue: cemeteryBlue,
  green: cemeteryGreen,
  purple: cemeteryPurple,
  yellow: cemeteryYellow,
};

const IDS_TO_PATH = {
  default: office,
  // central region
  2110: steak,
  2120: milk,
  2131: apple,
  2140: factory,
  2150: bakery,
  2170: candy,
  2181: softDrink,
  2182: iceCream,
  2183: beverage,
  2184: snow,
  2190: factory,
  2210: factory,
  2240: factory,
  2290: factory,
  2310: dressWoman,
  2360: factory,
  2361: factory,
  2390: factory,
  2411: table,
  2510: furnishedProperty,
  2560: cable,
  2579: factory,
  2580: factory,
  2582: airConditioner,
  2589: factory,
  2610: paper,
  2621: box3d,
  2640: factory,
  2690: factory,
  2730: books,
  2740: printer,
  2790: printer,
  2810: paintBrush,
  2830: towel,
  2840: spray,
  2860: pill,
  2870: factory,
  2880: wasteDanger,
  2921: factory,
  2990: fuel,
  3121: factory,
  3129: factory,
  3130: factory,
  3220: factory,
  3230: factory,
  3231: factory,
  3232: factory,
  3242: factory,
  3260: factory,
  3261: factory,
  3270: factory,
  3291: carTaxi,
  3310: factory,
  3390: factory,
  3391: factory,
  3393: gold,
  3394: factory,
  3440: factory,
  3459: factory,
  4113: train,
  4213: train,
  4219: train,
  4292: carTaxi,
  4293: carTaxi,
  4299: carSimple,
  4312: plane,
  4319: plane,
  4713: signal,
  4719: mobileButton,
  4752: signal,
  4759: tvOld,
  4832: waterSink,
  4841: toilet,
  4849: toilet,
  4855: wasteRecycling,
  4858: carSimple,
  4859: wasteRecycling,
  4869: fuel,
  4921: delivery,
  4923: plane,
  4929: train,
  5110: carSimple,
  5120: wasteDanger,
  5130: dressWoman,
  5140: roastChicken,
  5150: plantGround,
  5160: cable,
  5170: airConditioner,
  5180: newConstruction,
  5190: newConstruction,
  5210: table,
  5220: airConditioner,
  5230: paintBucket,
  5231: newConstruction,
  5240: cable,
  5250: newConstruction,
  5251: key,
  5260: plantGround,
  5270: books,
  5280: storageUnit,
  5290: newConstruction,
  5310: newConstruction,
  5330: storageUnit,
  5340: moneyCoins,
  5390: storageUnit,
  5411: store,
  5412: store,
  5413: store,
  5419: store,
  5420: steak,
  5430: potato,
  5441: cake,
  5442: hazelnut,
  5452: iceCream,
  5460: bakery,
  5461: bakery,
  5470: factory,
  5480: oven,
  5490: store,
  5510: carSimple,
  5520: carSimple,
  5530: fuel,
  5550: carSimple,
  5590: carSimple,
  5610: dressMan,
  5620: dressWoman,
  5640: baby,
  5660: shoeMan,
  5671: scissors,
  5672: needle,
  5673: needle,
  5674: needle,
  5681: dressMan,
  5682: store,
  5690: apartment,
  5711: furnishedProperty,
  5712: apartment,
  5713: curtain,
  5714: apartment,
  5717: apartment,
  5719: furnishedProperty,
  5720: home,
  5731: radio,
  5732: mobileButton,
  5733: tvOld,
  5734: music,
  5740: plate,
  5750: furnishedProperty,
  5790: furnishedProperty,
  5811: restaurantMenu,
  5820: restaurantMenu,
  5830: restaurantMenu,
  5840: restaurantMenu,
  5882: coffee,
  5890: restaurantMenu,
  5912: tooth,
  5913: pill,
  5921: honey,
  5930: store,
  5931: furnishedProperty,
  5940: bookmark,
  5950: basketballPlayer,
  5960: park,
  5971: diamond,
  5972: clock,
  5973: gold,
  5979: diamond,
  5983: oven,
  5989: fuel,
  5991: flower,
  5992: tape,
  5993: tape,
  5994: video,
  5995: store,
  5996: glasses,
  5997: spray,
  5998: computerOld,
  5999: office,
  6270: gift,
  6271: office,
  1311: apartment,
  1313: apartment,
  1314: plane,
  1510: hotel,
  6110: bank,
  6130: payment,
  6150: office,
  6190: moneyCoins,
  6210: wash,
  6220: video,
  6230: scissors,
  6240: baloon,
  6253: shoeMan,
  6259: dressWoman,
  6290: office,
  6311: office,
  6312: office,
  6319: office,
  6320: customerSupport,
  6330: printer,
  6331: printer,
  6341: broom,
  6342: spray,
  6343: office,
  6349: apartment,
  6360: office,
  6372: storageUnit,
  6373: storageUnit,
  6374: storageUnit,
  6375: storageUnit,
  6379: storageUnit,
  6380: office,
  6390: office,
  6412: carWash,
  6414: carSimple,
  6415: office,
  6416: carAccident,
  6419: carSimple,
  6491: cable,
  6492: tvOld,
  6493: factory,
  6494: factory,
  6495: factory,
  6496: cow,
  6497: airConditioner,
  6499: office,
  6520: law,
  6591: office,
  6593: calculator,
  6594: office,
  6595: security,
  6599: office,
  6626: office,
  6629: office,
  6630: office,
  6690: office,
  6691: office,
  6930: office,
  6940: office,
  6710: office,
  6711: office,
  6713: office,
  6719: office,
  6721: police,
  6723: apartment,
  6724: police,
  6729: security,
  6731: mail,
  6732: mail,
  6739: mail,
  6749: police,
  6799: office,
  6920: handout,
  6511: doctor,
  6512: tooth,
  6513: hospital,
  6514: hospital,
  6516: hospital,
  6517: hospital,
  6761: doctor,
  6762: tooth,
  6763: hospital,
  6764: lab,
  6767: hospital,
  6768: ambulance,
  6769: hospital,
  6810: playground,
  6821: school,
  6822: school,
  6823: school,
  6824: school,
  6825: school,
  6826: school,
  6829: school,
  6831: school,
  6832: school,
  6833: school,
  6834: school,
  6835: school,
  6836: school,
  6839: school,
  6841: school,
  6842: school,
  6843: school,
  6844: school,
  6845: school,
  6846: school,
  6867: school,
  6868: school,
  6849: school,
  6851: school,
  6852: school,
  6853: school,
  6854: school,
  6861: school,
  6862: school,
  6863: school,
  6864: school,
  6865: school,
  6871: school,
  6872: school,
  6873: school,
  6889: disabled,
  6890: school,
  7111: library,
  7112: bank,
  7114: baloon,
  7115: school,
  7124: cow,
  7192: castle,
  7199: certificate,
  7221: stadium,
  7223: racingBike,
  7229: athletics,
  7231: presentation,
  7232: frame,
  7233: hotel,
  7234: stadium,
  7239: stadium,
  7411: golfClub,
  7412: volleyball,
  7413: tennisRacket,
  7414: skating,
  7416: equestrianHelmet,
  7417: bowlingBall,
  7418: basketballRing,
  7419: athletics,
  7421: soccerField,
  7424: stadium,
  7425: dumbbell,
  7430: dumbbell,
  7426: dumbbell,
  7433: swimmingPool,
  7499: baloon,
  7611: playground,
  7612: park,
  7613: park,
  7614: playground,
  7619: park,
  7620: movie,
  7900: baloon,
  9712: home,
  9713: baloon,
  8110: plantGround,
  8130: plantGround,
  8140: plantGround,
  8160: plantGround,
  8170: plantGround,
  8180: plantGround,
  8194: equestrianHelmet,
  8197: plantGround,
  8290: plantGround,
  8320: plantGround,
  8543: hammer,
  8590: hammer,
  6912: mosque,
  6913: mosque,
  6914: mosque,
  6915: mosque,
  // eastern region
  100: office,
  10001: store,
  10002: frame,
  10003: store,
  10004: store,
  10005: apple,
  10007: moneyCoins,
  10008: carSimple,
  10009: newConstruction,
  10010: fuel,
  10011: payment,
  10013: hammer,
  10015: hammer,
  10016: restaurantMenu,
  10018: bank,
  10019: pill,
  10021: restaurantMenu,
  10022: hotel,
  10023: hammer,
  10024: hammer,
  10025: newConstruction,
  10026: waterSink,
  10027: gas,
  200: athletics,
  20001: dumbbell,
  20003: stadium,
  300: office,
  30001: castle,
  30002: police,
  30003: apartment,
  30004: office,
  30006: office,
  30007: office,
  30010: office,
  30011: waterSink,
  30012: mail,
  30013: office,
  30014: law,
  400: school,
  40001: school,
  40002: school,
  40003: school,
  40004: school,
  40005: school,
  40006: school,
  40007: school,
  40008: school,
  40009: school,
  40014: school,
  40015: school,
  40016: school,
  40017: playground,
  40018: school,
  40019: school,
  40020: school,
  40021: school,
  40022: school,
  500: stadium,
  50001: stadium,
  50002: hotel,
  50004: park,
  50005: park,
  600: mosque,
  60001: mosque,
  60002: mosque,
  60004: mosque,
  60005: cemetery,
  700: hospital,
  70001: hospital,
  70003: hospital,
  70006: hospital,
  70007: hospital,
  70010: tooth,
  70013: hospital,
  70017: ambulance,
  70019: hospital,
  800: certificate,
  80002: library,
  900: handout,
  90001: parking,
};

const getIconPath = (id, color) =>
  (IDS_TO_PATH[id] && IDS_TO_PATH[id][color]) || IDS_TO_PATH.default[color];

export default getIconPath;

import React, { Component } from 'react';
import { geoContains } from 'd3-geo';

import GoogleAPI, {
  InitializeGoogleAPI,
} from '../store/globalReducers/Google/GoogleApi';
import { getScreenBreakpoint, isMobileDevice } from './ResponsiveHelper';

import {
  EasternRegionBounds,
  easternRegionString,
  mapDefaultBounds,
  mapDefaultCenter,
  mapDefaultZoomEasternRegion,
  mapDefaultZoomKSA,
  mapDefaultZoomRiyadhRegion,
  mapEasternRegionCenter,
  MapElementTypes,
  mapRiyadhCenter,
  RiyadhBounds,
  riyadhRegionString,
} from './globals/Constans.ts';

export function getMapBounds(state) {
  return state.map.mapRange;
}

export function getBoundsFromMap(map) {
  const mapBounds = map.getBounds().toJSON();
  return {
    minLat: mapBounds.south,
    maxLat: mapBounds.north,
    minLng: mapBounds.west,
    maxLng: mapBounds.east,
  };
}

export function setMapCenterToBounds(bounds, googleMaps, map) {
  const latLngBounds = new googleMaps.LatLngBounds(
    new googleMaps.LatLng(bounds.minLat, bounds.minLng),
    new googleMaps.LatLng(bounds.maxLat, bounds.maxLng),
  );

  const areaCoords = {
    latLngBounds,
  };

  const areaCenter = areaCoords.latLngBounds.getCenter();

  const areaRestriction = {
    centerLng: areaCenter.lng(),
    centerLat: areaCenter.lat(),
  };

  map.panTo(
    new googleMaps.LatLng(areaRestriction.centerLat, areaRestriction.centerLng),
  );
}

export const WithMap = (EnchancedComponent) =>
  class WithMapComponent extends Component {
    constructor() {
      super();
      this.state = {
        google: null,
        map: null,
      };
    }

    componentDidMount() {
      if (GoogleAPI() && GoogleAPI().google) {
        const { google } = GoogleAPI();

        this.setState((state) => ({ ...state, google }));

        return;
      }

      InitializeGoogleAPI().then((google) =>
        this.setState((state) => ({ ...state, google })),
      );
    }

    setMap = (map) => {
      GoogleAPI().map = map;
      this.setState((state) => ({ ...state, map }));
    };

    render() {
      const { google, map } = this.state;

      // eslint-disable-next-line react/jsx-props-no-spreading -- high-order component
      return (
        <EnchancedComponent
          {...this.props}
          google={google}
          setMap={this.setMap}
          map={map}
        />
      );
    }
  };

export const isOutsideKSA = async ({ latitude, longitude }) => {
  const sageoJSON = await import('../assets/sa.geo.json');
  return !geoContains(sageoJSON, [longitude, latitude]);
};

export const isOutsideRiyadh = async ({ latitude, longitude }) => {
  const riyadhGeoJSON = await import('../assets/riyadh.geo.json');
  return !geoContains(riyadhGeoJSON, [longitude, latitude]);
};

export const isOutsideEasternRegion = async ({ latitude, longitude }) => {
  const easternRegionGeoJSON = await import(
    '../assets/eastern-region.geo.json'
  );
  return !geoContains(easternRegionGeoJSON, [longitude, latitude]);
};

export const isOutsideChosenRegion = (chosenRegion, areaCenter) => {
  switch (chosenRegion) {
    case easternRegionString:
      return isOutsideEasternRegion(areaCenter);
    case riyadhRegionString:
      return isOutsideRiyadh(areaCenter);
    default:
      return isOutsideKSA(areaCenter);
  }
};

export const getChosenRegionData = (chosenRegion) => {
  const isMobileOrDesktop = isMobileDevice() ? 'mobile' : 'desktop';

  switch (chosenRegion) {
    case easternRegionString:
      return {
        chosenRegionBounds: EasternRegionBounds[isMobileOrDesktop],
        chosenRegionCenter: mapEasternRegionCenter[isMobileOrDesktop],
        mapDefaultZoom: mapDefaultZoomEasternRegion,
      };
    case riyadhRegionString:
      return {
        chosenRegionBounds: RiyadhBounds[isMobileOrDesktop],
        chosenRegionCenter: mapRiyadhCenter[isMobileOrDesktop],
        mapDefaultZoom: mapDefaultZoomRiyadhRegion,
      };
    default:
      return {
        chosenRegionBounds: mapDefaultBounds[isMobileOrDesktop],
        chosenRegionCenter: mapDefaultCenter[isMobileOrDesktop],
        mapDefaultZoom: mapDefaultZoomKSA,
      };
  }
};

export function getMinZoomLevel(chosenRegion) {
  const deviceWidth = getScreenBreakpoint();
  const { mapDefaultZoom } = getChosenRegionData(chosenRegion);

  switch (deviceWidth) {
    case 'xs':
      return mapDefaultZoom.mobile;
    case 'xl':
    case 'xxxl':
      return mapDefaultZoom.bigDesktop;
    default:
      return mapDefaultZoom.other;
  }
}

const calculateMargin = (max, min, ration = 0.1) => ration * (max - min);

export function prepareViewport(type, data, google) {
  if (type === MapElementTypes.boundingBox) {
    const {
      minimalLatitude,
      minimalLongitude,
      maximalLatitude,
      maximalLongitude,
    } = data;

    const latitudeMargin = calculateMargin(maximalLatitude, minimalLatitude);

    const longitudeMargin = calculateMargin(maximalLongitude, minimalLongitude);

    return new google.maps.LatLngBounds(
      {
        lat: minimalLatitude + latitudeMargin,
        lng: minimalLongitude + longitudeMargin,
      },
      {
        lat: maximalLatitude - latitudeMargin,
        lng: maximalLongitude - longitudeMargin,
      },
    );
  }

  if (type === MapElementTypes.location) {
    const { latitude: lat, longitude: lng } = data;

    return { lat, lng };
  }

  return null;
}

import React from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import FormattedValue from '../../../../components/FormattedValue';
import MonetaryValue from '../../../../components/MonetaryValue';

const BubbleChartValue = ({ income, valueSuffix, color }) =>
  valueSuffix ? (
    <div className='IncomeTableItem__income' style={{ color }}>
      <FormattedValue value={+parseFloat(income).toFixed(1)} />{' '}
      {I18n.t(valueSuffix)}
    </div>
  ) : (
    <MonetaryValue
      className='IncomeTableItem__income'
      value={income}
      fractionDigits={1}
      style={{ color }}
    />
  );

export default BubbleChartValue;

BubbleChartValue.propTypes = {
  income: PropTypes.number,
  color: PropTypes.string,
  valueSuffix: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';
import CountTo from './CountTo';

import FormattedValue from '../FormattedValue';

function calculateNumberOfDigits(value) {
  const normalizedDigits = {
    1: 1,
    3: 1,
    4: 2,
    5: 3,
  };

  const decimals = round(value % 1, 3).toString().length;

  return normalizedDigits[decimals];
}

class AnimatedValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      to: 0,
      speed: 800,
      timer: null,
      digits: undefined,
    };
  }

  componentDidMount() {
    const { value } = this.props;

    this.prepareValue(value);
  }

  componentWillReceiveProps(newProps) {
    const { value } = this.props;

    if (newProps.value !== value) {
      this.prepareValue(newProps.value);
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;

    clearTimeout(timer);
  }

  prepareValue(value) {
    const timer = setTimeout(() => {
      this.setState({
        to: value,
        digits: calculateNumberOfDigits(value),
      });
    }, 500);

    this.setState({ timer });
  }

  render() {
    const { to, speed, timer, digits } = this.state;
    const { bottomThousandRange } = this.props;

    if (to < 0.1 || to === 0) {
      return <span className='animated-value'>{to}</span>;
    }

    return (
      <span className='animated-value'>
        <CountTo to={to} speed={speed} timer={timer} digits={digits}>
          {(value) => (
            <FormattedValue
              value={Number(value)}
              bottomThousandRange={bottomThousandRange}
            />
          )}
        </CountTo>
      </span>
    );
  }
}

AnimatedValue.propTypes = {
  value: PropTypes.number.isRequired,
  bottomThousandRange: PropTypes.number,
};

export default AnimatedValue;

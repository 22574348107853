import React, { useState } from 'react';

import SaveModal from '../../components/SaveModal/SaveModal';
import Button from '../../components/Button';

const iconButtonText = 'saveResults.saveResultsButton';
const saveResultsId = 'save-results-icon-button';

const SaveModalWrapper = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopyingBlocked, setIsCopyingBlocked] = useState(false);

  const resetModal = () => {
    setIsModalOpen(false);
    setIsCopyingBlocked(false);
  };

  return (
    <>
      <Button
        id={saveResultsId}
        text={iconButtonText}
        onClick={() => setIsModalOpen(true)}
        modifier='turqoise'
        display='block'
      />
      <SaveModal
        isOpen={isModalOpen}
        isCopyingBlocked={isCopyingBlocked}
        setIsCopyingBlocked={setIsCopyingBlocked}
        onClose={resetModal}
      />
    </>
  );
};

export default SaveModalWrapper;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n } from 'react-redux-i18n';

const REGION_TRANSLATION_PATH = 'app.regions.';

const RegionButton = ({
  iconDefault,
  iconActive,
  region,
  onClick,
  chosenRegion,
}) => {
  const onClickCallback = useCallback(() => onClick(region), [region]);
  const [isHover, setIsHover] = useState(false);
  const isActive = chosenRegion === region || isHover;

  return (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      type='button'
      className={classNames('RegionButton', isActive && 'active')}
      onClick={onClickCallback}
    >
      <img src={isActive ? iconActive : iconDefault} alt='' />
      <span className='RegionButton__label'>
        {I18n.t(`${REGION_TRANSLATION_PATH}${region}`)}
      </span>
    </button>
  );
};

RegionButton.propTypes = {
  iconDefault: PropTypes.string.isRequired,
  iconActive: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  chosenRegion: PropTypes.string.isRequired,
};

export default RegionButton;

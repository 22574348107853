import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';

import apolloClient from '../../../../apolloClient';
import InfoWindowTooltipContainer from './InfoWindowTooltipContainer';
import { SET_INFOWINDOW_ON_MAP_PENDING } from '../../constants/MapActionsConstants';

/* eslint-disable */

const updateInfoWindowMargin = (infoWindow) => {
  const markerSize = 40;
  const offset = 12;
  const marginTopProperty = -infoWindow.offsetHeight - markerSize - offset;
  infoWindow.style.marginTop = `${marginTopProperty}px`;
};

function InfoWindowConstructor(googleMaps) {
  function InfoWindow(map, infoWindow, id, borderColor, store) {
    store.dispatch({
      type: SET_INFOWINDOW_ON_MAP_PENDING,
      payload: true,
    });

    this.id = id;
    this.infoWindowOptions = infoWindow;
    this.setMap(map);
    this.borderColor = borderColor;
    this.store = store;
  }

  InfoWindow.prototype = new googleMaps.OverlayView();
  InfoWindow.prototype.draw = function () {
    let infoWindow = this.infoWindow;
    let infoWindowOptions = this.infoWindowOptions;

    if (!this.infoWindow) {
      infoWindow = this.infoWindow = document.createElement('div');
      infoWindow.style.borderColor = this.borderColor;
      infoWindow.style.display = 'none';

      infoWindow.tabIndex = 0;
      infoWindow.className =
        infoWindowOptions.sector && infoWindowOptions.sector.length > 25
          ? 'infoWindow infoWindow--big'
          : 'infoWindow';

      ReactDOM.render(
        <ApolloProvider store={this.store} client={apolloClient}>
          <Provider store={this.store}>
            <InfoWindowTooltipContainer
              subsectorName={infoWindowOptions.sector}
              businessName={infoWindowOptions.name}
              address={infoWindowOptions.address}
              businessId={this.id}
              isAdmin={infoWindowOptions.isAdmin}
              color={this.borderColor}
              updateContainerMargin={() => {
                infoWindow.style.display = 'block';
                updateInfoWindowMargin(infoWindow);
              }}
            />
          </Provider>
        </ApolloProvider>,
        infoWindow,
      );

      let panes = this.getPanes();
      panes.overlayImage.appendChild(infoWindow);
    }

    let convertLatLangIntoPixel = this.getProjection().fromLatLngToDivPixel(
      infoWindowOptions.coords,
    );

    if (convertLatLangIntoPixel) {
      infoWindow.style.left = `${convertLatLangIntoPixel.x}px`;
      infoWindow.style.top = `${convertLatLangIntoPixel.y}px`;
    }

    this.store.dispatch({
      type: SET_INFOWINDOW_ON_MAP_PENDING,
      payload: false,
    });
  };

  InfoWindow.prototype.remove = function () {
    if (this.infoWindow) {
      // TODO check why no parentNode - AG-936
      this.infoWindow.parentNode.removeChild(this.infoWindow);
      this.infoWindow = null;
      this.setMap(null);
    }
  };

  return InfoWindow;
}

export default InfoWindowConstructor;

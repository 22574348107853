import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';

import Sidebar from '../../../layouts/Sidebar/SidebarLayout.tsx';
import { WithMap } from '../../../helpers/MapHelper';
import {
  getChosenRegion,
  getMapAreas,
  isRegionLimitationEnabled,
} from '../../../selectors/map.selector';
import { getDemographicGlobalData } from '../../../selectors/demographic.selector';
import {
  getBusinessFromAreaAndIsicsQuery,
  getBusinessFromAreaQuery,
} from '../../../graphql/queries/BusinessQuery';
import { getChosenSectorsIds } from '../../../selectors/sector.selector';

import SidebarCumulatedActions from './actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(SidebarCumulatedActions, dispatch),
});

const mapStateToProps = (state) => ({
  breadcrumbs: state.map.breadcrumbsInSidebar,
  demographicData: getDemographicGlobalData(state),
  tags: state.sector.tags,
  tagIds: getChosenSectorsIds(state),
  areas: getMapAreas(state),
  isRegionLimitationEnabled: isRegionLimitationEnabled(state),
  chosenRegion: getChosenRegion(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getBusinessFromAreaQuery, {
    options: (props) => ({
      variables: {
        areas: props.areas,
      },
    }),
    skip: (props) => props.tags.length,
    props: ({ data: { businesses = { size: 0 } } }) => ({
      businessesNumber: businesses.size,
    }),
  }),
  graphql(getBusinessFromAreaAndIsicsQuery, {
    options: (props) => ({
      variables: {
        areas: props.areas,
        isics: props.tagIds,
      },
    }),
    skip: (props) => !props.tags.length,
    props: ({ data: { businesses = { size: 0 } } }) => ({
      businessesNumber: businesses.size,
    }),
  }),
)(WithMap(Sidebar));

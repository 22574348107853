import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { I18n, Translate } from 'react-redux-i18n';

import getThresholdsWithTotalValue from './helpers';
import { colors, colorBlank } from './constants';
import './SpendingDataTreeMap.scss';
import TreemapTooltip from '../../../../components/Tooltip/TreemapTooltip/TreemapTooltip';
import { isMobileDevice } from '../../../../helpers/ResponsiveHelper';
import { prepareLocaleString } from '../../../../components/FormattedValue/FormattedValue';
import iconSpendingHouseHold from '../../../../assets/demographic_icons/icon-spending-household.svg';

// it is ugly workaround problem with parcel tree-shaking of d3.
// https://github.com/parcel-bundler/parcel/issues/7274
Object.keys(d3);
// The issue is parcel wrongly tree-shaking () and it causes removing of d3 parts and importing
// this library as empty object. This strange line makes d3 noticeable for parcel.
// As you can read, offical parcel issue is closed, but this is the same problem probably caused by
// older version of d3. Possible better solutions:
// - update d3
// - use only specific part of d3
// - report offical issue to parcel-bulder/parcel

const SpendingDataTreeMap = ({ spendingData }) => {
  const treeMapContainerRef = useRef(null);

  const [tooltipData, setTooltipData] = useState();
  const [totalSpending, setTotalSpending] = useState();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const draw = () => {
    const svg = d3
      .select('.treemap-container')
      .append('svg')
      .attr('id', 'treemap-svg');

    const { thresholds, totalValue } = getThresholdsWithTotalValue(
      spendingData.facts,
    );

    setTotalSpending(totalValue);

    svg.selectAll('g').remove();

    svg.attr('width', width).attr('height', height);

    const root = d3
      .hierarchy({
        name: 'Spending',
        children: spendingData.facts,
      })
      .sum((d) => d.value)
      .sort((a, b) => a.value - b.value);

    const treemapRoot = d3.treemap().size([width, height]).padding(1)(root);

    const nodes = svg
      .selectAll('g')
      .data(treemapRoot.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${height - d.y1})`);

    // handle tooltip
    if (isMobileDevice(/xs|sm|md/)) {
      nodes.on('click', (e, d) => {
        setTooltipData({ ...d.data, positionX: e.pageX, positionY: e.pageY });
      });
    } else {
      nodes
        .on('mousemove', (e, d) => {
          setTooltipData({ ...d.data, positionX: e.pageX, positionY: e.pageY });
        })
        .on('mouseout', (e) => {
          const { id } = e.toElement;
          if (
            treeMapContainerRef.current &&
            (!treeMapContainerRef.current.contains(e.toElement) ||
              id === 'treemap-svg')
          ) {
            setTooltipData();
          }
        });
    }

    const colorScale = d3
      .scaleThreshold()
      .domain(thresholds)
      .range([colorBlank, ...colors]);

    nodes
      .append('rect')
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => colorScale(d.data.value));

    const foreignObject = nodes
      .append('foreignObject')
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0);

    const labelContainer = foreignObject
      .append('xhtml:div')
      .attr('class', 'treemap-label-container');

    labelContainer
      .append('xhtml:div')
      .attr('class', 'treemap-label')
      .text((d) => I18n.t(`spendingChart.categories.${d.data.subIndicator}`));

    labelContainer
      .append('xhtml:div')
      .attr('class', 'treemap-label-text')
      .text(
        (d) =>
          `${prepareLocaleString(d.data.value)} ${I18n.t(
            'spendingChart.currency',
          )}`,
      );

    labelContainer.each((node, i, element) => {
      const el = d3.select(element[i]);
      const elHeight = el.node().offsetHeight;
      const nodeWidth = Math.ceil(node.x1 - node.x0);
      const nodeHeight = Math.ceil(node.y1 - node.y0);

      if (nodeWidth >= 45 && elHeight <= nodeHeight) {
        return;
      }

      el.remove();
    });
  };

  const handleOutsideClick = (event) => {
    if (
      treeMapContainerRef.current &&
      !treeMapContainerRef.current.contains(event.target)
    ) {
      setTooltipData();
    }
  };

  const resetTooltip = () => {
    setTooltipData();
  };

  useEffect(() => {
    if (isMobileDevice(/xs|sm|md/)) {
      window.addEventListener('click', handleOutsideClick);
      window.addEventListener('touchstart', resetTooltip);
    } else {
      window.addEventListener('scroll', resetTooltip);
      window.addEventListener('wheel', resetTooltip);
    }

    return () => {
      if (isMobileDevice(/xs|sm|md/)) {
        window.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('touchstart', resetTooltip);
      } else {
        window.removeEventListener('scroll', resetTooltip);
        window.addEventListener('wheel', resetTooltip);
      }
    };
  }, []);

  useEffect(() => {
    let resizedFn;
    window.addEventListener('resize', () => {
      clearTimeout(resizedFn);
      resizedFn = setTimeout(() => {
        if (treeMapContainerRef && treeMapContainerRef.current) {
          setWidth(treeMapContainerRef.current.offsetWidth);
          setHeight(treeMapContainerRef.current.offsetHeight);
        }
      }, 200);
    });
  }, []);

  useEffect(() => {
    if (treeMapContainerRef && treeMapContainerRef.current) {
      setTimeout(() => {
        setWidth(treeMapContainerRef.current.offsetWidth);
        setHeight(treeMapContainerRef.current.offsetHeight);
      }, 300);
    }
  }, [treeMapContainerRef]);

  useEffect(() => {
    d3.select('.treemap-container svg').remove();

    if (spendingData) {
      draw();
    }
  }, [width, height, spendingData]);

  return (
    spendingData &&
    spendingData.facts.length > 0 && (
      <>
        <h3 className='treemap-name'>
          <div className='treemap-name__container'>
            <Translate value='spendingChart.name' />
            <img
              className='treemap-name__icon'
              src={iconSpendingHouseHold}
              alt=''
            />
          </div>
        </h3>
        <div className='treemap-wrapper'>
          <div ref={treeMapContainerRef} className='treemap-container'>
            {tooltipData && (
              <TreemapTooltip
                header={tooltipData.subIndicator}
                value={tooltipData.value}
                positionX={tooltipData.positionX}
                positionY={tooltipData.positionY}
                percentageValue={(
                  (tooltipData.value / totalSpending) *
                  100
                ).toFixed(1)}
              />
            )}
          </div>
        </div>
      </>
    )
  );
};

SpendingDataTreeMap.propTypes = {
  spendingData: PropTypes.shape({
    facts: PropTypes.arrayOf(PropTypes.shape),
  }),
};
export default SpendingDataTreeMap;

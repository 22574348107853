const spendingChart = {
  name: 'Distribution of monthly household spending by item',
  categories: {
    food_and_purchases: 'Food and purchases',
    education: 'Education',
    rent_housing: 'Rent housing',
    health_and_treatment: 'Health and treatment',
    house_maintenance_and_repairs: 'House maintenance and reports',
    household_water: 'Household water',
    dewatering: 'Dewatering',
    electricity_and_gas: 'Electricity and gas',
    transport_and_petrol: 'Transport and petrol',
    internet_and_tv: 'Internet and TV',
    personal_needs: 'Personal needs',
    electrical_appliances_and_furniture: 'Electrical appliances and furniture',
    tourism_and_restaurants: 'Tourism and restaurants',
    domestic_workers: 'Domestic workers',
    loan_or_purchase_installments: 'Loan or purchase installments',
    social_compliments: 'Social compliments',
  },
  currency: 'SAR',
};

export default spendingChart;

import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

import './Button.scss';

interface ButtonProps {
  text: string;
  modifier?: string | null;
  extendedClasses?: string | null;
  className?: string | null;
  onClick?: () => void;
  type?: 'outline' | 'full';
  display?: 'inline' | 'block';
  id?: string;
}

const Button = ({
  text,
  modifier = null,
  extendedClasses = null,
  className = extendedClasses,
  onClick = () => ({}),
  type = 'outline',
  display = 'inline',
  id,
}: ButtonProps) => (
  <button
    type='button'
    id={id}
    onClick={onClick}
    className={classNames(`Button btn ${className}`, {
      [`btn--${type}-${modifier}`]: modifier,
      'btn--block': display === 'block',
    })}
  >
    <Translate value={text} />
  </button>
);

export default Button;

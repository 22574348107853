import React from 'react';
import PropTypes from 'prop-types';

import NumberValueLabel from '../../NumberValueLabel';

import './PanelItemNumberValueLabel.scss';

const PanelItemNumberValueLabel = ({ number, enableAnimation }) => (
  <p className='panel-item__value--with-label'>
    <NumberValueLabel
      number={number}
      enableAnimation={enableAnimation}
      isInPanelItem
    />
  </p>
);

PanelItemNumberValueLabel.propTypes = {
  number: PropTypes.number.isRequired,
  enableAnimation: PropTypes.bool,
};

export default PanelItemNumberValueLabel;

import { configureStore } from '@reduxjs/toolkit';
import {
  i18nReducer,
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { createLogger } from 'redux-logger/src';
import { browserHistory } from 'react-router';

import CONFIG from '../config/config';
import translationsObject from '../translations';
import locationReducer, { updateLocation } from './globalReducers/location';
import { InitializeGoogleAPI } from './globalReducers/Google/GoogleApi';
import businessReducer from '../modules/Businesses/reducers/BusinessesSlice';
import demographicReducer from '../modules/Demographic/reducers/DemographicReducer';
import mapReducer from '../modules/Map/reducers/MapReducer';
import sectorReducer from '../modules/Sectors/reducers/SectorSlice';
import onboardingReducer from '../modules/Onboarding/reducers/onboardingReducer';
import hotjarMiddleware from '../helpers/hotjarScript/hotjarMiddleware';
import { listenerMiddleware } from '../listenerMiddleware';

function initTranslations(store) {
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  store.dispatch(setLocale(CONFIG.language));
}

function addHotjarScript() {
  return hotjarMiddleware({
    hjid: CONFIG.hotjar.hjid,
    hjsv: CONFIG.hotjar.hjsv,
  });
}

const createStore = (initialState = {}) => {
  // ======================================================
  // Custom Middleware Configuration
  // ======================================================
  const customMiddleware = [];

  if (CONFIG.hotjar.enabled) {
    customMiddleware.push(addHotjarScript());
  }
  if (CONFIG.enableReduxLogger) {
    customMiddleware.push(createLogger());
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = configureStore({
    preloadedState: initialState,
    reducer: {
      business: businessReducer,
      demographic: demographicReducer,
      map: mapReducer,
      i18n: i18nReducer,
      location: locationReducer,
      sector: sectorReducer,
      onboarding: onboardingReducer,
    },
    // disable serializableCheck and immutableCheck to avoid errors with current state shape
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .prepend(listenerMiddleware.middleware)
        .concat(customMiddleware),
  });

  // Get Google API
  InitializeGoogleAPI();

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store));

  initTranslations(store);

  return store;
};

export default createStore;

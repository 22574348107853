const breadcrumbs = {
  KSA: 'KSA',
  selectRegion: 'Select Region',
  selectGovernorate: 'Please select a governorate',
  selectCity: 'Select City',
  selectDistrict: 'Select District',
  areaTypes: {
    district: 'District {{areaName}}',
    governorate: 'Governorate {{areaName}}',
    emirate: '{{areaName}}',
    city: 'city {{areaName}}',
    locality: 'city {{areaName}}',
    route: 'street {{areaName}}',
  },
  centralRegionName: 'Central Region',
  defaultBreadcrumb: {
    firstLine: {
      label: 'Kingdom of Saudi Arabia',
    },
    secondLine: {
      label: 'Select Region',
    },
  },
  tooltipInfoDistrict:
    'You can select the neighborhood by using the search box or by navigating the map.',
  tooltipInfoGovernorate:
    'You can select a governorate by using the search box or navigating through the map.',
};

export default breadcrumbs;

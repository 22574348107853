import { createSelector } from '@reduxjs/toolkit';
import CONFIG from '../config/config';
import { getAreaShapes, getChosenRegion, getMapAreas } from './map.selector';
import {
  areaMapClassification,
  Colors,
  DemographicCharts,
  DemographicChartsDataLabels,
  easternRegionString,
  PanelItemTypes,
  SubtitleTypes,
} from '../helpers/globals/Constans.ts';
import { preparePanelItem } from '../helpers/PanelItemHelper';
import { convertToPercentage } from '../helpers/BarChartHelper';

export const shouldResetData = (areas, zeroAreaCase) => {
  const { enabled: zeroCaseEnabled, areaId: zeroCaseAreaId } = zeroAreaCase;

  const [areaId] = areas;

  return zeroCaseEnabled && zeroCaseAreaId === areaId;
};

export const prepareDemographicData = (
  demographicRawData,
  areas,
  zeroAreaCase,
  isDefaultData,
) => {
  if (!demographicRawData) return null;

  const demogaphicDataProperties = [
    'totalPopulation',
    'saudiPopulation',
    'malePopulation',
  ];

  const demographicData = demogaphicDataProperties.reduce(
    (aggregated, population) => {
      const [fact] = demographicRawData[population].facts;
      const { value: populationValue } = fact;

      return {
        ...aggregated,
        [population]: populationValue,
      };
    },
    {},
  );

  // Overwrite area to have one without people
  if (shouldResetData(areas, zeroAreaCase) && !isDefaultData) {
    Object.keys(demographicData).forEach((key) => {
      demographicData[key] = 0;
    });
  }

  return demographicData;
};

export const getDemographicSum = (data) =>
  Object.keys(data)
    .map((key) => data[key].value)
    .reduce((current, sum) => current + sum, 0);

export const temporaryZeroCaseHandling = (
  graphData,
  dataLabels = ['title', 'labels', 'placeholderData'],
) =>
  Object.keys(graphData).reduce((aggregated, dataLabel) => {
    if (dataLabels.includes(dataLabel)) {
      return aggregated;
    }

    /* eslint-disable no-param-reassign -- @refactor */
    aggregated[dataLabel].total = 0;

    Object.keys(aggregated[dataLabel].data).forEach((ageLabel) => {
      aggregated[dataLabel].data[ageLabel].value = 0;
    });
    /* eslint-enable no-param-reassign -- @refactor */

    return aggregated;
  }, graphData);

export const parseLabel = (label) => parseInt(label.substr(0, 2), 10);

export const preparePopulationPyramidLabels = (labels) =>
  labels.facts
    .map(({ splits: [label] }) => label)
    .sort((a, b) => parseLabel(b.label) - parseLabel(a.label));

export const divideGraphData = ({ graphData, labelId, colors, dataLabels }) => {
  const [primaryDataLabel, secondaryDataLabel] = dataLabels;

  const items = graphData.facts.reduce(
    (aggregated, fact) => {
      const [type, ageGroup] = fact.splits;
      const { value } = fact;

      const itemData = {
        genderId: type.id,
        value,
      };

      /* eslint-disable no-param-reassign -- @refactor */
      if (labelId === type.id) {
        aggregated[primaryDataLabel][ageGroup.id] = itemData;
      } else {
        aggregated[secondaryDataLabel][ageGroup.id] = itemData;
      }
      /* eslint-enable no-param-reassign -- @refactor */

      return aggregated;
    },
    {
      [primaryDataLabel]: {},
      [secondaryDataLabel]: {},
    },
  );

  const preparedData = Object.entries(items).reduce(
    (aggregated, [label, item], index) => {
      let maximumValue = 0;

      const population = getDemographicSum(item);

      const updatedItem = { ...item };
      Object.keys(item).forEach((key) => {
        const percentageValue = convertToPercentage(
          item[key].value,
          population,
        );

        if (maximumValue < item[key].value) {
          maximumValue = item[key].value;
        }

        updatedItem[key] = Object.assign(item[key], {
          percentageValue,
        });
      });

      aggregated.push({
        data: item,
        total: population,
        color: colors[index],
        maxSize: maximumValue,
        dataLabel: label,
      });

      return aggregated;
    },
    [],
  );

  const [primaryData, secondaryData] = preparedData;

  return { primaryData, secondaryData };
};

export const prepareDemographicSubtitle = (population) =>
  population > 0
    ? {
        subtitle: {
          type: SubtitleTypes.demographicValue,
          params: {
            demographicValue: population,
          },
        },
      }
    : {};

const prepareBaseDataForDemographicPanelItems = (
  {
    totalPopulation,
    malePopulation,
    saudiPopulation,
    // Mocked income value
    incomeValue = 20456,
  },
  chosenRegion,
) => [
  {
    type: PanelItemTypes.demographicValue,
    value: {
      demographicValue: totalPopulation,
    },
    translation: {
      value: 'demographicChart.total',
    },
    otherProperties: {
      changedColor: Colors.cyan.value,
      iconContent: 'geo-icon-people',
    },
  },
  {
    type: PanelItemTypes.percentageValue,
    value: {
      size: malePopulation,
      totalNumber: totalPopulation,
    },
    translation: {
      value: 'demographicChart.male',
      ...prepareDemographicSubtitle(malePopulation),
    },
    otherProperties: {
      changedColor: Colors.havelockBlue.value,
      iconContent: 'geo-icon-male',
    },
  },
  {
    type: PanelItemTypes.percentageValue,
    value: {
      size: saudiPopulation,
      totalNumber: totalPopulation,
    },
    translation: {
      value: 'demographicChart.saudi',
      ...prepareDemographicSubtitle(saudiPopulation),
    },
    otherProperties: {
      changedColor: Colors.mountainMeadow.value,
      iconContent: 'geo-icon-saudi',
    },
  },
  {
    type: PanelItemTypes.number,
    value: {
      size: incomeValue,
    },
    translation: {
      value:
        chosenRegion === easternRegionString
          ? 'demographicChart.averageIncomeEastern'
          : 'demographicChart.averageIncome',
    },
    otherProperties: {
      changedColor: Colors.blueRibbon.value,
      iconContent: 'geo-icon-money',
    },
  },
];

export const prepareDemographicPanelItems = (populationData, chosenRegion) =>
  prepareBaseDataForDemographicPanelItems(populationData, chosenRegion).map(
    (params) => preparePanelItem(params, params.type),
  );

export const preparePopulationPyramid = (graphDataOptions) => {
  const preparedData = divideGraphData(graphDataOptions);
  const { dataLabels } = graphDataOptions;

  const [primaryDataLabel, secondaryDataLabel] = dataLabels;
  const { primaryData, secondaryData } = preparedData;

  return {
    [primaryDataLabel]: primaryData,
    [secondaryDataLabel]: secondaryData,
  };
};

export const prepareSingleDemographicGraphData = (
  graphDataOptions,
  pyramidGraphLabels,
  title,
) =>
  Object.assign(preparePopulationPyramid(graphDataOptions), {
    labels: pyramidGraphLabels,
    title,
  });

export const prepareDemographicGraphsData = (
  demographicDefaultData,
  demographicGlobalData,
) => {
  if (!demographicDefaultData) return null;

  const { totalPopulation } = demographicGlobalData;

  const {
    byGenderAndAgeGroupPopulation: genderData,
    byNationalityAndAgeGroupPopulation: nationalityData,
    ageGroupLabels,
  } = demographicDefaultData;

  const pyramidGraphLabels = preparePopulationPyramidLabels(ageGroupLabels);
  const genderDataOptions = {
    graphData: genderData,
    totalPopulation,
    labelId: DemographicCharts.ageDistribution.male,
    colors: [Colors.havelockBlue.value, Colors.pink.value],
    mainLabel: DemographicCharts.ageDistribution.male,
    dataLabels: [...Object.values(DemographicChartsDataLabels.byGender)],
  };

  const byGenderAndAgeGroupPopulation = prepareSingleDemographicGraphData(
    genderDataOptions,
    pyramidGraphLabels,
    'demographicChart.ageDistribution',
  );

  const nationalityDataOptions = {
    graphData: nationalityData,
    totalPopulation,
    labelId: DemographicCharts.nationality.saudi,
    colors: [Colors.mountainMeadow.value, Colors.yellow.value],
    mainLabel: DemographicCharts.nationality.saudi,
    dataLabels: [...Object.values(DemographicChartsDataLabels.byNationality)],
  };

  const byNationalityAndAgeGroupPopulation = prepareSingleDemographicGraphData(
    nationalityDataOptions,
    pyramidGraphLabels,
    'demographicChart.nationality',
  );

  return {
    byGenderAndAgeGroupPopulation,
    byNationalityAndAgeGroupPopulation,
  };
};

export const selectDemographicGraphsData = (
  demographicData,
  areas,
  areaShapes,
  demographicGlobalData,
  demographicDefaultGraphData,
  zeroAreaCase,
  chosenRegion,
) => {
  if (!demographicDefaultGraphData || !areaShapes) return null;

  const { totalPopulation } = demographicGlobalData;

  const { type } = areaShapes;

  // Temporary keep it & wait for API data
  // eslint-disable-next-line no-unused-vars
  const isDistrict = type === areaMapClassification.district;

  const panelItems = prepareDemographicPanelItems(
    demographicGlobalData,
    chosenRegion,
  );

  const {
    byGenderAndAgeGroupPopulation: ByGenderData,
    byNationalityAndAgeGroupPopulation: byNationalityData,
  } = prepareDemographicGraphsData(demographicData, { totalPopulation });

  if (!ByGenderData && !byNationalityData) {
    return {
      panelItems,
      byGenderAndAgeGroupPopulation: { facts: [] },
      byNationalityAndAgeGroupPopulation: { facts: [] },
    };
  }

  const {
    byGenderAndAgeGroupPopulation: byGenderPlaceholder,
    byNationalityAndAgeGroupPopulation: byNationalityPlaceholder,
  } = demographicDefaultGraphData;

  const byGenderAndAgeGroupPopulation = Object.assign(ByGenderData, {
    placeholderData: byGenderPlaceholder,
  });

  const byNationalityAndAgeGroupPopulation = Object.assign(byNationalityData, {
    placeholderData: byNationalityPlaceholder,
  });

  if (shouldResetData(areas, zeroAreaCase)) {
    return {
      panelItems,
      byGenderAndAgeGroupPopulation: temporaryZeroCaseHandling(
        byGenderAndAgeGroupPopulation,
      ),
      byNationalityAndAgeGroupPopulation: temporaryZeroCaseHandling(
        byNationalityAndAgeGroupPopulation,
      ),
    };
  }

  return {
    panelItems,
    byGenderAndAgeGroupPopulation,
    byNationalityAndAgeGroupPopulation,
  };
};

const isDefaultData = () => true;
const isNotDefaulData = () => false;
const getZeroAreaCase = () => CONFIG.zeroAreaCase;

const getDemographic = (state) => state.demographic;

export const getDemographicDataLoader = createSelector(
  getDemographic,
  (demographic) => demographic.listLoading,
);
export const getDemographicData = createSelector(
  getDemographic,
  (demographic) => demographic.demographicData,
);
export const getDemographicGlobalData = createSelector(
  getDemographicData,
  getMapAreas,
  getZeroAreaCase,
  isNotDefaulData,
  prepareDemographicData,
);
export const getDemographicDefaultData = createSelector(
  getDemographic,
  (demographic) => demographic.demographicDefaultData,
);
export const getDemographicDefaultGlobalData = createSelector(
  getDemographicDefaultData,
  getMapAreas,
  getZeroAreaCase,
  isDefaultData,
  prepareDemographicData,
);
export const getDemographicPlaceholderGraphData = createSelector(
  getDemographicDefaultData,
  getDemographicDefaultGlobalData,
  prepareDemographicGraphsData,
);
export const getDemographicGraphData = createSelector(
  getDemographicData,
  getMapAreas,
  getAreaShapes,
  getDemographicGlobalData,
  getDemographicPlaceholderGraphData,
  getZeroAreaCase,
  getChosenRegion,
  selectDemographicGraphsData,
);
export const getDemographicLegendHeights = createSelector(
  getDemographic,
  (demographic) => demographic.demographicLegendHeights,
);

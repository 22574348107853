import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import NumberValueLabel from '../../../../components/NumberValueLabel';

const PyramidLabelItem = ({
  actions,
  itemIndex,
  lastItemIndex,
  singleLabelItem,
  sum,
}) => (
  <Measure
    bounds
    onResize={(contentRect) => {
      if (actions?.updateLegendHeight) {
        const updateInStore = itemIndex === lastItemIndex;

        actions.updateLegendHeight(
          {
            [singleLabelItem.id]: contentRect.bounds.height,
          },
          updateInStore,
        );
      }
    }}
  >
    {({ measureRef }) => (
      <div
        ref={measureRef}
        className='pyramid-label-item row center-xs middle-xs'
      >
        <p className='pyramid-label-item__label paragraph-4--dark-gray col-xs-12'>
          {singleLabelItem.label.replaceAll('-', ' - ')}
        </p>
        <div className='pyramid-label-value paragraph-4 col-xs-12'>
          <div className='pyramid-label-sum'>
            <NumberValueLabel number={sum} />
          </div>
        </div>
      </div>
    )}
  </Measure>
);

PyramidLabelItem.propTypes = {
  singleLabelItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  actions: PropTypes.shape({
    updateLegendHeight: PropTypes.func,
  }),
  itemIndex: PropTypes.number.isRequired,
  lastItemIndex: PropTypes.number.isRequired,
  sum: PropTypes.number.isRequired,
};

export default PyramidLabelItem;

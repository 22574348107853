import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  useFiltersURL,
  useUpdatingURLWithMapOptions,
  useUpdatingURLWithSectorsFilter,
  useUpdatingURLWithMapTypeId,
} from './hooks';
import useApplyingFiltersBasedOnURL from './hooks/useApplyingFiltersBasedOnURL';

const FilterSearchURLProvider = ({ children }) => {
  const [isReady, setReady] = useState(false);
  const filtersURL = useFiltersURL();

  useApplyingFiltersBasedOnURL(!isReady, () => setReady(true), filtersURL);

  useUpdatingURLWithSectorsFilter(isReady);
  useUpdatingURLWithMapOptions(isReady);
  useUpdatingURLWithMapTypeId(isReady);

  return children;
};

FilterSearchURLProvider.propTypes = {
  children: PropTypes.node,
};

export default FilterSearchURLProvider;

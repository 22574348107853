import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from '../RoundButton/RoundButton';

const FlashModalCloseButton = ({ onClickFn }) => (
  <div className='flash-modal__round-button'>
    <RoundButton iconClass='geo-icon-cancel' onClickFn={onClickFn} />
  </div>
);

FlashModalCloseButton.propTypes = {
  onClickFn: PropTypes.func.isRequired,
};

export default FlashModalCloseButton;

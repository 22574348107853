import React from 'react';
import { Translate } from 'react-redux-i18n';

import './IncomeTableGroup.scss';
import PropTypes from 'prop-types';

const IncomeTableGroup = ({ children, titleKey, iconSrc }) => (
  <div className='IncomeTableGroup'>
    <div className='IncomeTableGroup__title'>
      <img className='IncomeTableGroup__icon' src={iconSrc} alt='' />
      <Translate value={titleKey} />
    </div>
    {children}
  </div>
);

export default IncomeTableGroup;

IncomeTableGroup.propTypes = {
  children: PropTypes.node.isRequired,
  titleKey: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
};

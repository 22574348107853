import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { partial } from 'lodash';

import IncomeTable from '../IncomeTable';

import {
  factToBubble,
  prepareIncomeChartData,
} from '../../constants/IncomeChartHelpers';

import { riyadhCityId } from '../../../../helpers/globals/Constans.ts';

import './IncomeChart.scss';
import iconSar from '../../../../assets/demographic_icons/icon-sar.svg';

class IncomeChart extends React.PureComponent {
  render() {
    const { groups, loading, areaId, fillWidth, showAverageRiyadhCityValue } =
      this.props;

    if (loading) {
      return null;
    }

    const data = prepareIncomeChartData({
      groups,
      areaId,
      convertFactToBubble: partial(factToBubble, areaId),
    });

    const isCityLevel = areaId === riyadhCityId;

    return (
      <div className='chart IncomeChart'>
        <h4 className='IncomeChart__heading heading-6--white'>
          <div className='IncomeChart__heading-container'>
            <Translate value='incomeChart.heading.desktop' />
            <img className='IncomeChart__heading-icon' src={iconSar} alt='' />
          </div>
        </h4>
        <h4 className='IncomeChart__heading--mobile heading-6--white'>
          <div className='IncomeChart__heading-container'>
            <Translate value='incomeChart.heading.mobile' />
            <img className='IncomeChart__heading-icon' src={iconSar} alt='' />
          </div>
        </h4>
        <IncomeTable
          hideRanking={isCityLevel}
          data={data}
          showAverageValue={showAverageRiyadhCityValue}
          fillWidth={fillWidth}
        />
      </div>
    );
  }
}

IncomeChart.propTypes = {
  areaId: PropTypes.string.isRequired,
  groups: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  fillWidth: PropTypes.bool,
  showAverageRiyadhCityValue: PropTypes.bool,
};

export default IncomeChart;

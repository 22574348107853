import apolloClient from '../../../apolloClient';
import { getDemographicDataQuery } from '../../../graphql/queries/DemographicQueries';
import {
  SET_DEMOGRAPHIC_DATA,
  SET_DEMOGRAPHIC_DEFAULT_DATA,
  SET_DEMOGRAPHIC_LOADING,
  UPDATE_DEMOGRAPHIC_ITEM_HEIGHT,
  UPDATE_PEOPLE_NUMBER,
} from '../constants/DemographicConstants';
import { regionStringToIdMap } from '../../../helpers/globals/Constans.ts';

export function updatePeopleNumber(peopleNumber) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PEOPLE_NUMBER,
      peopleNumber,
    });
  };
}

export function setDemographicData() {
  return (dispatch, getState) => {
    const state = getState();
    const { areas, chosenRegion } = state.map;
    const { demographicDefaultData } = state.demographic;

    const regionIdArr = [regionStringToIdMap[chosenRegion]];

    dispatch({
      type: SET_DEMOGRAPHIC_LOADING,
      payload: true,
    });

    apolloClient
      .query({
        query: getDemographicDataQuery,
        variables: {
          areas,
          regionIdArr,
        },
      })
      .then((res) => {
        const { data } = res;

        dispatch({
          type: SET_DEMOGRAPHIC_DATA,
          payload: data,
        });

        if (demographicDefaultData === null) {
          dispatch({
            type: SET_DEMOGRAPHIC_DEFAULT_DATA,
            payload: data,
          });
        }

        dispatch({
          type: SET_DEMOGRAPHIC_LOADING,
          payload: false,
        });
      });
  };
}

export function updateDemographicLegendHeight(
  demographicItemWidth,
  updateInStore,
) {
  return (dispatch, getState) => {
    const state = getState();
    let { demographicLegendHeights } = state.demographic;

    if (updateInStore) {
      // Prevent this.setState error
      demographicLegendHeights = {
        ...demographicLegendHeights,
        ...demographicItemWidth,
      };
    } else {
      demographicLegendHeights = Object.assign(
        demographicLegendHeights,
        demographicItemWidth,
      );
    }

    dispatch({
      type: UPDATE_DEMOGRAPHIC_ITEM_HEIGHT,
      payload: demographicLegendHeights,
    });
  };
}

const DemographicActions = {
  updatePeopleNumber,
  updateDemographicLegendHeight,
};

export default DemographicActions;

import React from 'react';
import { browserHistory, Redirect, Route, Router } from 'react-router';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import appRoutes from '../routes/appRoutes';
import FilterSearchURLProvider from './FilterSearchURLProvider';

function hashLinkScroll() {
  const { hash } = browserHistory.getCurrentLocation();

  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }, 0);
  }
}

class App extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { store } = this.props;
    const { Main, Businesses, Sectors, Demographic, RenderPdf } = appRoutes;

    return (
      <Provider store={store}>
        <FilterSearchURLProvider>
          <div style={{ height: '100%' }}>
            <Router history={browserHistory} onUpdate={hashLinkScroll}>
              <Route path={RenderPdf.path} component={RenderPdf.component} />
              <Route path='/(:region)' component={Main.component}>
                <Route path={Sectors.path} component={Sectors.component} />
                <Route
                  path={Businesses.path}
                  component={Businesses.component}
                />
                <Route
                  path={Demographic.path}
                  component={Demographic.component}
                />

                <Redirect from='*' to='/' />
              </Route>
            </Router>
          </div>
        </FilterSearchURLProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;

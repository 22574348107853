import { mapValues } from 'lodash';
import { objectsListToTable } from './utils';
import {
  ListItem,
  ItemsCollection,
  Table,
  RequiredFields,
  ConditionalType,
  TransformConfig,
} from './types';

function transformDataToTables<
  DataItem extends ListItem,
  Data extends ItemsCollection<DataItem>,
>(
  data: Data,
  transformConfig: TransformConfig<Data, Data, DataItem>,
): (Table<DataItem> | null)[];
function transformDataToTables<Data, DataItem extends ListItem, NormalizedData>(
  data: Data,
  transformConfig: RequiredFields<
    TransformConfig<Data, NormalizedData, DataItem>,
    'normalize'
  >,
): (Table<DataItem> | null)[];
function transformDataToTables<Data, DataItem extends ListItem, NormalizedData>(
  data: Data,
  {
    tableConfig,
    normalize = (v) => v as ConditionalType<Data, NormalizedData>,
    process = (v) =>
      v as ConditionalType<NormalizedData, ItemsCollection<DataItem>>,
    translate = (v) => v,
  }: TransformConfig<Data, NormalizedData, DataItem>,
): (Table<DataItem> | null)[] {
  const getTableConfig =
    typeof tableConfig === 'function' ? tableConfig : () => tableConfig;

  const normalizedData = normalize(data);
  const processedData = process(normalizedData);

  const dataObj = Array.isArray(processedData)
    ? { data: processedData }
    : processedData;

  const translatedData = mapValues(dataObj, (dataArray) =>
    dataArray.map(translate),
  );

  return Object.keys(translatedData).map((key) =>
    objectsListToTable(translatedData[key], getTableConfig(key)),
  );
}

export default transformDataToTables;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import RenderPdfTooltip from '../RenderPdfTooltip/RenderPdfTooltip';
import NumberValueLabel from '../../../components/NumberValueLabel';
import PercentageValue from '../../../components/PercentageValue';

import './RenderPdfPointsTooltip.scss';

const tooltipText = {
  tooltipFooter: 'businessChart.businessTooltipLabel',
};

const RenderPdfPointsTooltip = ({ item }) => (
  <RenderPdfTooltip>
    <div className='render-pdf-points-tooltip' key={item.id}>
      <h3 className='render-pdf-points-tooltip__header'>
        {item.tooltip.header}
      </h3>
      <div className='render-pdf-points-tooltip__underline' />
      <p className='render-pdf-points-tooltip__value'>
        <NumberValueLabel number={item.tooltip.value} />
        <span className='render-pdf-points-tooltip__percentage'>
          <PercentageValue number={item.tooltip.percentageValue} />
        </span>
      </p>
      <Translate
        className='render-pdf-points-tooltip__footer'
        value={tooltipText.tooltipFooter}
      />
    </div>
  </RenderPdfTooltip>
);

export default RenderPdfPointsTooltip;

RenderPdfPointsTooltip.propTypes = {
  item: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import { isSafari } from '../../helpers/ResponsiveHelper';
import { searchInputMaxLength } from '../../helpers/globals/Constans.ts';

const AutocompleteSearch = ({
  placeholder,
  searchId,
  query,
  chosenItem,
  onKeyDown,
  onFocus,
  onBlur,
  children,
  onChange,
  resetQuery,
  toggleSearch,
  isSectorSearch,
}) => {
  const translatedPlaceholder = I18n.t(placeholder);

  return (
    <div className='autocomplete__input-container'>
      {isSectorSearch && <i className='geo-icon-search' />}
      <input
        id={searchId}
        className={classNames(
          'autocomplete__input data-hj-whitelist',
          {
            // hotjar whitelisting inputs: https://help.hotjar.com/hc/en-us/articles/115015563287-Whitelisting-input-fields
            'autocomplete__input--safari': isSafari(),
          },
          isSectorSearch ? 'sector-search' : 'neighbourhoods-search',
        )}
        type='text'
        value={query}
        placeholder={translatedPlaceholder}
        onKeyDown={(e) => onKeyDown(e, chosenItem)}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        autoComplete='off'
        maxLength={searchInputMaxLength}
      />
      {!isSectorSearch && (
        <i className='geo-icon-search' onClick={toggleSearch} />
      )}
      <div className='autocomplete__children-elements'>
        {children}
        <i className='geo-icon-cancel' onClick={() => resetQuery()} />
      </div>
    </div>
  );
};

AutocompleteSearch.propTypes = {
  resetQuery: PropTypes.func,
  toggleSearch: PropTypes.func,
  isSectorSearch: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchId: PropTypes.string,
  query: PropTypes.string,
  chosenItem: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
      districtName: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.string,
      ]),
      boundingBox: PropTypes.shape({
        minimalLatitude: PropTypes.number,
        maximalLatitude: PropTypes.number,
        minimalLongitude: PropTypes.number,
        maximalLongitude: PropTypes.number,
      }),
      location: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
      ]),
      mainText: PropTypes.string,
      secondaryText: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.string,
      ]),
    }),
  ]),
  children: PropTypes.object,
};

AutocompleteSearch.defaultProps = {
  resetQuery: () => {},
  toggleSearch: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  placeholder: 'Autocomplete placeholder',
};

export default AutocompleteSearch;

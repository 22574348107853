import { orderBy } from 'lodash';

import { preparefilteredIsicsList } from './SectorHelper';
import {
  convertToPercentage,
  roundPanelItemToGetProperSum,
  roundTooltipValueToGetProperSum,
} from './BarChartHelper';
import { BusinessChartTypes, PanelItemTypes } from './globals/Constans.ts';
import { preparePanelItem } from './PanelItemHelper';

const filterZeroValues = (businessList) => {
  const filteredBusinessList = businessList.filter(
    (businessElement) => businessElement.size > 0,
  );

  return filteredBusinessList.length === 0
    ? businessList
    : filteredBusinessList;
};

const isChildSubsector = (isics, rootId) =>
  isics && !!isics.find((item) => item && item.id === rootId);

const extendItemsByTooltip = (items, tooltipParams) =>
  items
    .filter((item) => item)
    .map((item) => ({
      ...item,
      tooltip: {
        header: item.name,
        value: item.size,
        percentageValue: convertToPercentage(
          item.size,
          tooltipParams.totalSize,
        ),
        valueLabel: tooltipParams.valueLabel,
      },
      isChildSubsector: item.root && isChildSubsector(items, item.root.id),
    }));

export class BusinessChartData {
  constructor(
    sectorList,
    nonZeroSectorList,
    taggedSectors,
    taggedSubsectors,
    businessesNumber,
    businessesFromSA,
    businessesFromArea,
  ) {
    this.sectorList = sectorList;
    this.nonZeroSectorList = nonZeroSectorList;
    this.taggedSectors = taggedSectors;
    this.taggedSubsectors = taggedSubsectors;
    this.businessesNumber = businessesNumber;
    this.businessesFromSA = businessesFromSA;
    this.businessesFromArea = businessesFromArea;
  }

  getIsicItem(id) {
    return this.sectorList.filter((isic) => isic.id === id);
  }

  getDefault() {
    /**
     * Base data
     */
    const type = BusinessChartTypes.noSectors;

    /**
     * Tooltips preparation
     */
    const tooltipParams = {
      totalSize: this.businessesNumber,
      valueLabel: 'businessChart.businessTooltipLabel',
    };

    let defaultBusinessList = extendItemsByTooltip(
      this.sectorList,
      tooltipParams,
    );

    if (this.businessesNumber > 0) {
      defaultBusinessList = filterZeroValues(defaultBusinessList);
    }

    /**
     * Prepare panel items
     */
    let panelItems = this.nonZeroSectorList.slice(0, 3);

    panelItems = panelItems.map((item) => {
      const panelParams = {
        value: {
          size: item.size,
          totalNumber: this.businessesFromArea,
        },
        translation: {
          value: `businessChart.description.${type}.panelItem`,
          params: {
            isicName: item.name,
          },
        },
        otherProperties: {
          showIcon: false,
        },
      };

      return preparePanelItem(panelParams, PanelItemTypes.percentageValue);
    });

    /**
     * Prepare total sum panel item
     */
    const totalPanelParams = {
      value: {
        size: this.businessesFromArea,
      },
      translation: {
        value: `businessChart.description.${type}.businessNumber`,
      },
      otherProperties: {
        changedColor: null,
        iconContent: 'geo-icon-business',
      },
    };

    const panelItemWithTotalSum = preparePanelItem(
      totalPanelParams,
      PanelItemTypes.number,
    );

    panelItems.unshift(panelItemWithTotalSum);

    /**
     * Response
     */
    return {
      businessChartType: type,
      businessList: defaultBusinessList,
      mainSector: {},
      panelItems: roundPanelItemToGetProperSum(panelItems),
    };
  }

  getOneSectorData() {
    /**
     * Base data
     */
    const type = BusinessChartTypes.oneSector;
    const [sector = {}] = this.taggedSectors;
    const isicList = sector && sector.children ? sector.children.items : [];
    let businessList;

    /**
     * Tooltips preparation
     */
    const tooltipParams = {
      totalSize: sector.size,
      valueLabel: 'businessChart.businessTooltipLabel',
    };

    businessList = extendItemsByTooltip(isicList, tooltipParams);

    if (this.businessesNumber > 0) {
      businessList = filterZeroValues(businessList);
    }

    /**
     * Prepare panel items
     */
    const otherProperties = {
      changedColor: sector.color ? sector.color.value : null,
      iconContent: null,
    };

    const percentagePanelItemParams = {
      value: {
        size: sector.size,
        totalNumber: this.businessesFromArea,
      },
      translation: {
        value: 'businessChart.description.oneSector.panelItem',
        params: {
          isicName: sector.name,
        },
      },
      otherProperties,
    };

    const panelItems = [
      preparePanelItem(
        percentagePanelItemParams,
        PanelItemTypes.percentageValue,
      ),
    ];

    /**
     * Prepare total sum panel item
     */
    const totalPanelParams = {
      value: {
        size: sector.size,
      },
      translation: {
        value: `businessChart.description.${type}.businessNumber`,
      },
      otherProperties: {
        changedColor: sector.color ? sector.color.value : null,
        iconContent: 'geo-icon-business',
      },
    };

    const panelItemWithTotalSum = preparePanelItem(
      totalPanelParams,
      PanelItemTypes.number,
    );

    panelItems.unshift(panelItemWithTotalSum);

    /**
     * Response
     */
    return {
      businessChartType: type,
      businessList,
      mainSector: sector,
      panelItems,
    };
  }

  getOneSubsectorData() {
    /**
     * Base data
     */
    const [subsector = {}] = this.taggedSubsectors;
    const sectorId = subsector && subsector.root ? subsector.root.id : null;
    const [sector = {}] = this.getIsicItem(sectorId);
    const subsectorsOfSectors =
      sector && sector.children ? sector.children.items : [];

    const type = BusinessChartTypes.oneSubsector;

    /**
     * Tooltips preparation
     */
    const tooltipParams = {
      totalSize: sector.size,
      valueLabel: 'businessChart.businessTooltipLabel',
    };

    let businessList = extendItemsByTooltip(subsectorsOfSectors, tooltipParams);

    if (this.businessesNumber > 0) {
      businessList = filterZeroValues(businessList);
    }

    /**
     * Prepare panel items
     */

    const subsectorColor = subsector.color ? subsector.color.value : null;

    const otherProperties = {
      changedColor: subsectorColor,
      iconContent: null,
    };

    const percentagePanelItemParams = {
      value: {
        size: subsector.size,
        totalNumber: sector.size,
      },
      translation: {
        value: 'businessChart.description.oneSubsector.panelItem',
        params: {
          parentIsicName: sector.name,
          isicName: subsector.name,
        },
      },
      otherProperties,
    };

    const panelItems = [
      preparePanelItem(
        percentagePanelItemParams,
        PanelItemTypes.percentageValue,
      ),
    ];

    /**
     * Prepare total sum panel item
     */
    const totalPanelParams = {
      value: {
        size: subsector.size,
      },
      translation: {
        value: `businessChart.description.${type}.businessNumber`,
      },
      otherProperties: {
        changedColor: subsectorColor,
        iconContent: 'geo-icon-business',
      },
    };

    const panelItemWithTotalSum = preparePanelItem(
      totalPanelParams,
      PanelItemTypes.number,
    );

    panelItems.unshift(panelItemWithTotalSum);

    /**
     * Response
     */
    return {
      businessChartType: type,
      businessList,
      mainSector: sector,
      overwrittenSize: subsector.size,
      panelItems,
    };
  }

  getMoreThanOneTagData() {
    /**
     * Base data
     */
    const pulledSectors = this.taggedSectors;
    const pulledSubsectors = this.taggedSubsectors;

    const subsectorsWithinSectorChecked = pulledSubsectors
      .filter((subsector) => subsector)
      .map((subsector) => ({
        ...subsector,
        skipInSum: !pulledSectors.length
          ? false
          : pulledSectors.filter((sector) => subsector.root.id === sector.id)
              .length > 0,
      }));

    const pulledSubsectorsFiltered = subsectorsWithinSectorChecked.filter(
      (subsector) => !subsector.skipInSum,
    );

    const pulledIsicsFiltered = pulledSectors.concat(pulledSubsectorsFiltered);
    const pulledIsics = pulledSectors.concat(pulledSubsectors);
    const sumToShow = pulledIsicsFiltered
      .filter((item) => item)
      .reduce((currentSum, isicItem) => currentSum + isicItem.size, 0);

    const type = BusinessChartTypes.moreThanOneTag;

    /**
     * Tooltips preparation
     */
    const tooltipParams = {
      totalSize: sumToShow,
      valueLabel: 'businessChart.businessTooltipLabel',
    };

    let businessList = roundTooltipValueToGetProperSum(
      extendItemsByTooltip(pulledIsics, tooltipParams),
    );

    /**
     * Prepare panel items
     */

    const panelItems = pulledIsics
      .filter((isic) => isic)
      .map((isic) => {
        let panelItemTranslation;

        if (!isic.root && pulledIsics.length === 2) {
          panelItemTranslation =
            'businessChart.description.moreThanOneTag.panelItem.twoSectors';
        } else if (!isic.root && pulledIsics.length === 3) {
          panelItemTranslation =
            'businessChart.description.moreThanOneTag.panelItem.threeSectors';
        } else if (pulledIsics.length === 2) {
          panelItemTranslation =
            'businessChart.description.moreThanOneTag.panelItem.twoSubsector';
        } else {
          panelItemTranslation =
            'businessChart.description.moreThanOneTag.panelItem.threeSubsector';
        }

        const panelParams = {
          value: {
            size: isic.size,
            totalNumber: sumToShow,
            subsectorWithinSectorCase: isic.skipInSum,
          },
          translation: {
            value: panelItemTranslation,
            params: {
              isicName: isic.name,
            },
          },
          otherProperties: {
            isChildSubsector:
              isic.root && isChildSubsector(pulledIsics, isic.root.id),
            isic: {
              id: isic.id,
              root: isic.root,
            },
            changedColor: isic.color ? isic.color.value : null,
            iconContent: null,
          },
        };

        return preparePanelItem(panelParams, PanelItemTypes.percentageValue);
      });

    /**
     * Prepare total sum panel item
     */
    const totalPanelParams = {
      value: {
        size: sumToShow,
      },
      translation: {
        value: `businessChart.description.${type}.businessNumber`,
      },
      otherProperties: {
        changedColor: null,
        iconContent: 'geo-icon-business',
      },
    };

    const panelItemWithTotalSum = preparePanelItem(
      totalPanelParams,
      PanelItemTypes.number,
    );

    panelItems.unshift(panelItemWithTotalSum);

    /**
     * Prepare business list for graph
     */
    businessList = preparefilteredIsicsList(businessList, true);

    businessList = orderBy(businessList, ['size'], ['desc']);

    /**
     * Response
     */
    return {
      businessChartType: BusinessChartTypes.moreThanOneTag,
      businessList,
      overwrittenSize: sumToShow,
      mainSector: {},
      panelItems: roundPanelItemToGetProperSum(panelItems),
    };
  }
}

export default BusinessChartData;

import React from 'react';
import PropTypes from 'prop-types';

import logoZadd from '../../../assets/pdf_icons/zadd.svg';
import './RenderPdfComponent.scss';

const RenderPdfComponent = ({
  children,
  dataType,
  regionName,
  districtName,
  pageNumber,
  totalNumberOfPages,
}) => (
  <div className='render-pdf-component'>
    <div className='render-pdf-component__header'>
      <div className='render-pdf-component__column'>
        <h2 className='render-pdf-component__header__title'>
          {dataType === 'points' ? 'نقاط الاهتمام' : 'المعلومات الديموغرافية'}
        </h2>
        <h1 className='render-pdf-component__header__region'>
          {regionName}
          <span>{districtName}</span>
        </h1>
      </div>
      <div className='render-pdf-component__column'>
        <img
          src={logoZadd}
          alt='logo-zadd'
          className='render-pdf-component__header__image'
        />
      </div>
    </div>
    <div className='render-pdf-component__content'>{children}</div>
    <div className='render-pdf-component__footer'>
      <div className='render-pdf-component__column'>
        <p className='render-pdf-component__footer__page'>
          {pageNumber}/{totalNumberOfPages}
        </p>
      </div>
    </div>
  </div>
);

export default RenderPdfComponent;

RenderPdfComponent.propTypes = {
  children: PropTypes.element,
  dataType: PropTypes.string,
  regionName: PropTypes.string,
  districtName: PropTypes.string,
  pageNumber: PropTypes.string,
  totalNumberOfPages: PropTypes.string,
};

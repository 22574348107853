class Selection {
  constructor() {
    this.range = null;
    this.selectionToggle = false;
  }

  setRange(range) {
    this.range = range;
  }

  setSelection() {
    window.getSelection().selectAllChildren(this.range);

    this.selectionToggle = true;
  }

  toggleSelection() {
    if (!this.selectionToggle && this.range) {
      this.setSelection(this.range);
    } else {
      this.removeSelection();
    }
  }

  removeSelection() {
    window.getSelection().removeAllRanges();

    this.selectionToggle = false;
  }
}

export default Selection;

const errorMessage = {
  limitForSelectedSectors:
    'You can select up to only 3 sectors and/or sub-sectors.',
  noBusinessesRegistered:
    'No businesses registered under the selected Sector/Sub-Sector',
  outOfRegion:
    'Location not found within the currently selected region. Please make sure to select the correct region if available in order to use this feature.',
  geolocationOff:
    'We are not able to locate you. Allow browser to use location or use HTTPS protocol',
};

export default errorMessage;

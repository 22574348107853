import CoreLayout from '../layouts/PageLayout';
import Businesses from './paths/businesses';
import Demographic from './paths/demographic';
import Sectors from './paths/sectors';
import RenderPdf from './paths/renderPdf';

const Main = {
  path: '/',
  component: CoreLayout,
};

export default {
  Main,
  Sectors,
  Businesses,
  Demographic,
  RenderPdf,
};

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

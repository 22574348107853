const builtinPlacements = {
  breadcrumbsLightRight: {
    points: ['cl', 'cr'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [40, 10],
    targetOffset: [0, 0],
  },
  breadcrumbsRight: {
    points: ['cl', 'cr'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [70, 10],
    targetOffset: [0, 0],
  },
  breadcrumbsBottomRight: {
    points: ['tr', 'br'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [0, 35],
    targetOffset: [0, 0],
  },
  easternRight: {
    points: ['cl', 'cr'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [15, 0],
    targetOffset: [0, 0],
  },
  easternBottomRight: {
    points: ['tr', 'br'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [0, 15],
    targetOffset: [0, 0],
  },
  downloadLabelRight: {
    points: ['cl', 'cr'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [25, 0],
    targetOffset: [0, 0],
  },
  downloadLabelBottomRight: {
    points: ['tr', 'br'],
    overflow: {
      adjustX: 1,
      adjustY: 1,
    },
    offset: [0, 20],
    targetOffset: [0, 0],
  },
};

export default builtinPlacements;

const footer = {
  navigation: {
    mainPage: 'الصفحة الرئيسية',
    interactiveMap: 'الخارطة التفاعلية',
    reports: 'التقارير',
  },
  pages: {
    termsAndConditions: 'الشروط و الأحكام',
    privacyPolicy: 'الخصوصية',
  },
  initiativeBy: 'مبادرة من',
}

export default footer;

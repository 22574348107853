import React from 'react';
import { Translate } from 'react-redux-i18n';

import PropTypes from 'prop-types';
import EasternRegionLogo from '../../assets/founder_icons/eastern-region_logo.svg';
import RcaLogo from '../../assets/founder_icons/rca_logo.svg';
import './FounderLogo.scss';
import {
  easternRegionString,
  riyadhRegionString,
} from '../../helpers/globals/Constans.ts';

const regionToLogoMap = {
  [riyadhRegionString]: RcaLogo,
  [easternRegionString]: EasternRegionLogo,
};

const regionToLinkMap = {
  [riyadhRegionString]: 'https://www.rcrc.gov.sa',
  [easternRegionString]: 'https://www.eamana.gov.sa',
};

const FounderLogo = ({ chosenRegion, hideOnSmallLaptop }) => (
  <div className={`founder-logo ${hideOnSmallLaptop ? 'hide-sm' : ''}`}>
    <div className='founder-logo__container'>
      <a
        className='clear-link'
        target='_blank'
        rel='noopener noreferrer'
        href={regionToLinkMap[chosenRegion]}
      >
        <div className={`founder-logo__box ${chosenRegion}`}>
          <Translate className='founder-logo__note' value='app.supportedBy' />
          <img
            className='founder-logo__image'
            alt='founder logo'
            src={regionToLogoMap[chosenRegion]}
          />
        </div>
      </a>
    </div>
  </div>
);

export default FounderLogo;

FounderLogo.propTypes = {
  chosenRegion: PropTypes.string,
  hideOnSmallLaptop: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import AnimatedValue from '../AnimatedValue';
import FormattedValue from '../FormattedValue';
import ShortcutValueLabel from '../ShortcutValueLabel';
import { numberBreakCases } from '../../helpers/globals/Constans.ts';

const NumberValueLabel = ({
  number,
  style,
  enableAnimation,
  isInPanelItem,
}) => {
  const bottomThousandRange = isInPanelItem
    ? numberBreakCases.oneThousand.size
    : numberBreakCases.thousands.size;

  const ValueComponent = enableAnimation ? AnimatedValue : FormattedValue;
  let numberValue = number;
  let shortcutValueLabel = null;

  if (number !== '-') {
    numberValue = (
      <ValueComponent
        value={number}
        bottomThousandRange={bottomThousandRange}
      />
    );
    shortcutValueLabel = (
      <ShortcutValueLabel
        value={number}
        bottomThousandRange={bottomThousandRange}
        style={style}
      />
    );
  }

  return (
    <span className='number-value-label'>
      <span className='number-value-label__number'>{numberValue}</span>
      {shortcutValueLabel}
    </span>
  );
};

NumberValueLabel.defaultProps = {
  enableAnimation: false,
};

NumberValueLabel.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  enableAnimation: PropTypes.bool,
  isInPanelItem: PropTypes.bool,
};

export default NumberValueLabel;

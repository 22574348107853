import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';

import Bar from './Bar';
import BarLabel from './BarLabel';
import { isMobileScreen } from '../../../../helpers/ResponsiveHelper';
import { isArabicDirection } from '../../../../helpers/AppHelper';

const getFifthPosition = (fifth, index) => fifth * index;

function computeTooltipPosition(
  mouseEventPositionX,
  barWidth,
  width,
  placements = ['topRight', 'topLightRight', 'top', 'topLightLeft', 'topLeft'],
) {
  const mousePositionX = isArabicDirection()
    ? width - mouseEventPositionX
    : mouseEventPositionX;

  const placementsCount = placements.length;

  const lastPlacement = last(placements);

  const fifth = width / placementsCount;

  const [defaultPosition] = placements;

  if (barWidth === 0) {
    for (let i = 1; i < placementsCount; ++i) {
      if (mousePositionX < getFifthPosition(fifth, i)) {
        return placements[i - 1];
      }
    }

    return lastPlacement;
  }

  if (mousePositionX === 0) {
    return defaultPosition;
  }

  const filtered = placements.filter((placement, index) => {
    const normalizationValue = 50;
    const position =
      index === 0
        ? getFifthPosition(fifth, index)
        : getFifthPosition(fifth, index) + normalizationValue;

    return position < mousePositionX && position < barWidth;
  });

  return last(filtered) || placements[filtered.length - 1];
}

class BarItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mousePositionX: 0,
    };
  }

  handleChangeMousePosition = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const mousePositionX = event.clientX - rect.left;

    this.setState({ mousePositionX });
  };

  render() {
    const {
      item,
      barWidth,
      guides,
      barChartDataType,
      isMoreThanOneTag,
      width,
      enableAnimation,
    } = this.props;

    // eslint-disable-next-line
    const isSubsector = !!item.root;

    const { mousePositionX } = this.state;

    const placement = isMobileScreen()
      ? 'top'
      : computeTooltipPosition(mousePositionX, barWidth, width);

    return (
      <div className='bar-item' onMouseMove={this.handleChangeMousePosition}>
        {!isMoreThanOneTag && (
          <BarLabel name={item.name} isSubsector={isSubsector} />
        )}
        <Bar
          barWidth={barWidth}
          item={item}
          guides={guides}
          placement={placement}
          barChartDataType={barChartDataType}
          enableAnimation={enableAnimation}
        />
      </div>
    );
  }
}

BarItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    root: PropTypes.object,
  }),
  width: PropTypes.number.isRequired,
  barWidth: PropTypes.number,
  barChartDataType: PropTypes.string,
  guides: PropTypes.number.isRequired,
  isMoreThanOneTag: PropTypes.bool,
  enableAnimation: PropTypes.bool,
};

export default BarItem;

import React from 'react';
import PropTypes from 'prop-types';
import { isOutsideChosenRegion } from '../../../../helpers/MapHelper';

import './MapControls.scss';

class LocateMe extends React.Component {
  constructor(props) {
    super(props);
    this.LocateMeRef = React.createRef();
  }

  componentDidMount() {
    const { map, google } = this.props;

    this.LocateMeRef.current.index = 2;

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      this.LocateMeRef.current,
    );
  }

  setCurrentPosition = async (pos) => {
    const coords = {
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
    };

    const { setModalMessageFn, map, chosenRegion } = this.props;

    if (await isOutsideChosenRegion(chosenRegion, coords)) {
      setModalMessageFn({ errorMessage: 'errorMessage.outOfRegion' });
      return;
    }

    map.setCenter({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    map.setZoom(16);
  };

  handleUserPosition = () => {
    const { isModalOpened, setModalMessageFn } = this.props;

    if (isModalOpened) {
      return;
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setCurrentPosition(position);
        },
        () => {
          setModalMessageFn({
            errorMessage: 'errorMessage.geolocationOff',
          });
        },
        {
          enableHighAccuracy: true,
          maximumAge: 60000,
        },
      );
    }
  };

  render() {
    return (
      <div
        ref={this.LocateMeRef}
        className='map-controls__single-btn-container'
      >
        <div
          className='map-controls__single-btn'
          onClick={this.handleUserPosition}
        >
          <i className='geo-icon-geolocation' />
        </div>
      </div>
    );
  }
}

LocateMe.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  setModalMessageFn: PropTypes.func,
  isModalOpened: PropTypes.bool,
  chosenRegion: PropTypes.string,
};

export default LocateMe;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

import './ButtonIcon.scss';

const ButtonIcon = ({ text, icon, onClick = () => ({}) }) => (
  <button
    type='button'
    onClick={onClick}
    className={classNames('ButtonIcon btnIcon')}
  >
    <img className='btnIcon__icon' src={icon} alt='' />
    <Translate value={text} />
  </button>
);

ButtonIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ButtonIcon;

import { debounce, pick } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apolloClient from '../../../apolloClient';

import {
  getBusinessQuery,
  getBusinessResponse,
  getHighestClusterLabel,
} from '../../../helpers/BusinessHelper.ts';
import {
  getMapAreas,
  getMapBounds,
  getMapClustersOptions,
  getMapOptions,
} from '../../../selectors/map.selector';
import { SAVE_HIGHEST_CLUSTER_LABEL } from '../../Map/constants/MapActionsConstants';
import { getSectorLists } from '../../Sectors/reducers/SectorSlice';
import { BusinessDataShowWays } from '../../../helpers/globals/Constans.ts';
import { getChosenSectorsIds } from '../../../selectors/sector.selector';

const getClustersPrecision = (zoom, numberOfTags) => {
  if (numberOfTags > 1) {
    return zoom - 2;
  }

  return zoom;
};

const prepareDefaultVariables = (state) => {
  const mapRange = getMapBounds(state);
  const clusters = getMapClustersOptions(state);
  const areas = getMapAreas(state);
  const { zoom } = getMapOptions(state);
  const tagsIds = getChosenSectorsIds(state);
  const { areaType, isDefaultZoomReached } = clusters;

  const wasAnyAreaFound = areas.length > 0 && !isDefaultZoomReached;
  const precision = getClustersPrecision(zoom, tagsIds.length);
  const highlightedAreas = wasAnyAreaFound ? areas : null;

  return {
    mapRange,
    clusterType: areaType,
    areas,
    isics: tagsIds,
    highlightedAreas,
    precision,
  };
};

const getVariables = (state, clusterShowType) => {
  const defaultVariables = prepareDefaultVariables(state);
  const { queryVariables: expectedVariables } =
    BusinessDataShowWays[clusterShowType];

  const { mapRange, ...otherVariables } = pick(
    defaultVariables,
    expectedVariables,
  );

  return {
    ...mapRange,
    ...otherVariables,
  };
};

export const getBusinesses = createAsyncThunk(
  'businesses/getBusinesses',
  debounce(
    async (arg, thunkAPI) => {
      const state = thunkAPI.getState();
      const { showType: clusterShowType } = getMapClustersOptions(state);

      const variables = getVariables(state, clusterShowType);

      const query = getBusinessQuery(state);

      const response = await apolloClient.query({
        query,
        variables,
      });

      const businesses = getBusinessResponse(state, response.data.businesses);
      const highestClusterLabel = getHighestClusterLabel(businesses);

      // TODO: move to dedicated slices
      thunkAPI.dispatch({
        type: SAVE_HIGHEST_CLUSTER_LABEL,
        payload: highestClusterLabel,
      });
      thunkAPI.dispatch(getSectorLists(true));

      return businesses;
    },
    50,
    { leading: true, trailing: false },
  ),
);

// ------------------------------------
// Actions
// ------------------------------------

const BusinessActions = {
  getBusinesses,
};

export default BusinessActions;

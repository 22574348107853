import { SET_INFOWINDOW_CONTENT_TEXT } from '../constants/MapActionsConstants';

export default function setInfoWindowContentText(text) {
  return (dispatch) => {
    dispatch({
      type: SET_INFOWINDOW_CONTENT_TEXT,
      payload: {
        text,
      },
    });
  };
}

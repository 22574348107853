import React from 'react';
import { Translate } from 'react-redux-i18n';

import SidebarValue from '../SidebarValue';

export interface TabProps {
  value: number | string;
  labelTranslation: string;
  iconName: string;
  className?: string;
}

const Tab = ({
  value,
  labelTranslation,
  iconName,
  className = '',
}: TabProps) => (
  <div className={`sidebar__badge ${className}`}>
    <div className='sidebar__badge__wrapper'>
      <i className={`sidebar__badge__icon ${iconName}`} />
      <SidebarValue number={value} className='sidebar__badge__number' />
    </div>
    <p className='sidebar__badge__link sidebar__space-top--s1'>
      <Translate value={labelTranslation} />
    </p>
  </div>
);

export default Tab;

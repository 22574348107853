import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { getMapElements } from '../../actions/MapActions';
import MapAutocomplete from './MapAutocomplete';
import {
  getChosenRegion,
  getMapPredictions,
} from '../../../../selectors/map.selector';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getMapElements }, dispatch),
});

const mapStateToProps = (state) => ({
  predictions: getMapPredictions(state),
  chosenRegion: getChosenRegion(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapAutocomplete);

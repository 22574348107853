/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { withContentRect } from 'react-measure';

import PercentageValue from '../../../../components/PercentageValue';
import NumberValueLabel from '../../../../components/NumberValueLabel';

const getAxisXItemValue = (type, value) =>
  type === 'percentageValue' ? (
    <PercentageValue number={value} />
  ) : (
    <NumberValueLabel number={value} />
  );

const AxisXItem = ({
  contentRect,
  value,
  maxAxisXItem,
  width,
  type,
  cssKey,
  measureRef,
}) => {
  const { width: AxisXItemComponentWidth } = contentRect.bounds;
  const computedPosition =
    (value / maxAxisXItem) * width - AxisXItemComponentWidth / 2;
  const cssValue = !Number.isNaN(computedPosition) ? computedPosition : 0;

  return (
    <div
      className='simple-axis-x__item'
      style={{ [cssKey]: cssValue }}
      ref={measureRef}
    >
      {getAxisXItemValue(type, value)}
    </div>
  );
};

AxisXItem.propTypes = {
  contentRect: PropTypes.shape({
    bounds: PropTypes.object,
  }),
};

export default withContentRect('bounds')(AxisXItem);

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { browserHistory } from 'react-router';

import { getChosenSectors } from '../../../selectors/sector.selector';
import { getMapOptions } from '../../../selectors/map.selector';

import { MAP_TYPE_ID } from '../../../modules/Map/constants/MapConstants.ts';
import {
  buildFilterSearchParamsURL,
  parseFilterSearchParamsURL,
} from '../../../modules/Sectors/components/filtersURLSearchParamsUtils';

import { generateFilterSearchParamsURLBasedOnTags } from '../utils';

const SEARCH_PUSHING_DELAY = 500;
const LAT_LNG_FIXED_NUMBER = 6;

export const useFiltersURL = () => {
  const [filtersURL, setFiltersURL] = useState(
    () => browserHistory.getCurrentLocation().search,
  );

  useEffect(() => {
    const removeListener = browserHistory.listen(() => {
      const { search } = browserHistory.getCurrentLocation();
      setFiltersURL(search);
    });

    return () => {
      removeListener();
    };
  }, []);

  return filtersURL;
};

const useSearchPushing = () => {
  const [search, setSearch] = useState(null);

  const [, cancel] = useDebounce(
    () => {
      const { pathname, search: currentSearch } =
        browserHistory.getCurrentLocation();

      if (search !== null && search !== currentSearch) {
        browserHistory.push({
          pathname,
          search,
        });
      }
    },
    SEARCH_PUSHING_DELAY,
    [search],
  );

  useEffect(() => () => cancel(), []);

  return setSearch;
};

export const useUpdatingURLWithSectorsFilter = (canUpdate) => {
  const tags = useSelector(getChosenSectors);

  const pushSearch = useSearchPushing();

  useEffect(() => {
    if (canUpdate) {
      const newFilterSearchParamsURL =
        generateFilterSearchParamsURLBasedOnTags(tags);

      pushSearch(newFilterSearchParamsURL);
    }
  }, [tags]);
};

export const useUpdatingURLWithMapOptions = (canUpdate) => {
  const {
    zoom: currentZoom,
    center: { lat: currentCenterLat, lng: currentCenterLng },
    minZoom,
  } = useSelector(getMapOptions);

  const pushSearch = useSearchPushing();

  useEffect(() => {
    const { search } = browserHistory.getCurrentLocation();
    const parsedSearchParamsURL = parseFilterSearchParamsURL(search);

    if (canUpdate) {
      const newSearchParamsURL = {
        ...parsedSearchParamsURL,
        zoom: currentZoom,
        centerLat: currentCenterLat.toFixed(LAT_LNG_FIXED_NUMBER),
        centerLng: currentCenterLng.toFixed(LAT_LNG_FIXED_NUMBER),
      };

      const newFilterSearchParamsURL =
        buildFilterSearchParamsURL(newSearchParamsURL);

      if (currentZoom > minZoom) {
        pushSearch(newFilterSearchParamsURL);
      }
    }
  }, [currentZoom, currentCenterLat, currentCenterLng]);
};

export const useUpdatingURLWithMapTypeId = (canUpdate) => {
  const { mapTypeId: currentMapTypeId } = useSelector(getMapOptions);

  const pushSearch = useSearchPushing();

  useEffect(() => {
    const { search } = browserHistory.getCurrentLocation();
    const parsedSearchParamsURL = parseFilterSearchParamsURL(search);

    if (canUpdate) {
      const newSearchParamsURL = {
        ...parsedSearchParamsURL,
        mapTypeId: currentMapTypeId,
      };

      const newFilterSearchParamsURL =
        buildFilterSearchParamsURL(newSearchParamsURL);

      const isDefaultState =
        parsedSearchParamsURL.mapTypeId === undefined &&
        currentMapTypeId === MAP_TYPE_ID.HYBRID;

      if (!isDefaultState) {
        pushSearch(newFilterSearchParamsURL);
      }
    }
  }, [currentMapTypeId]);
};

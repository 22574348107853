export function trimNode(node) {
  const stripNode = {};

  const { child, type, stateNode, memoizedProps } = node;

  if (typeof type === 'function') {
    stripNode.name = type.displayName || type.name;

    const { children, ...rest } = memoizedProps;

    stripNode.props = rest;
  }

  if (
    (typeof type === 'string' || !type) &&
    !child &&
    stateNode.textContent?.trim() !== ''
  ) {
    stripNode.text = stateNode.textContent;
  }

  if (!stripNode.name && !stripNode.text) {
    stripNode.element = node.stateNode;
  }

  return stripNode;
}

function isNodeNotNeeded(stripNode, sibling) {
  return (
    !stripNode.text &&
    Object.keys(stripNode.props || {}).length === 0 &&
    !sibling
  );
}

export function trimReactTree(node) {
  if (!node) {
    return [];
  }

  const { child, sibling } = node;

  const stripNode = trimNode(node);

  const isNotNeeded = isNodeNotNeeded(stripNode, sibling);

  if (isNotNeeded) {
    return trimReactTree(child);
  }

  const children = trimReactTree(child);
  const siblings = trimReactTree(sibling);

  const theNode = {
    ...stripNode,
    children,
  };

  return [theNode, ...siblings];
}

export function searchInTree(trimedTree, search) {
  const { text } = trimedTree;

  const isText = text !== undefined && text !== null;

  if (isText && text !== search) {
    return null;
  }

  const children = trimedTree.children
    ?.map((child) => searchInTree(child, search))
    .filter((child) => child);

  if (!isText && children?.length === 0) {
    return null;
  }

  return {
    ...trimedTree,
    children,
  };
}

function startTreeFromName(trimedTree, startName) {
  if (!trimedTree) {
    return null;
  }

  if (trimedTree.name !== startName) {
    const children =
      trimedTree.children
        ?.map((child) => startTreeFromName(child, startName))
        .filter((child) => child) || [];

    if (children.length === 0) {
      return null;
    }

    return children.length === 1 ? children[0] : children;
  }

  return trimedTree;
}

export function mapTree(trimedTree, fn) {
  if (!trimedTree) {
    return null;
  }

  const childrenResult = trimedTree.children?.map((child) =>
    mapTree(child, fn),
  );

  return fn(trimedTree, childrenResult);
}

function clearNode(obj) {
  const clearedObj = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (
      !obj[key] ||
      (typeof obj[key] !== 'number' &&
        typeof obj[key] !== 'object' &&
        !key.startsWith('__'))
    ) {
      delete clearedObj[key];
    }
  });

  return clearedObj;
}

export function searchInTreeFor(root, startName, search) {
  const trimedTree = trimReactTree(root);

  const searchedTree = searchInTree(trimedTree[0], search);
  const cutTree = startTreeFromName(searchedTree, startName);

  return mapTree(cutTree, ({ props, name, text }, childrenResult) =>
    [
      clearNode({ __TEXT: text, __NAME: name, ...props }),
      ...childrenResult,
    ].flat(),
  );
}

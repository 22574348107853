/* eslint-disable no-underscore-dangle */

// Snippet from https://gist.github.com/auser/1d55aa3897f15d17caf21dc39b85b663

import CONFIG from '../../../config/config';
import cache from './ScriptCache';

window.mapCallback = function mapCallback() {
  // eslint-disable-next-line no-console
  console.log('GoogleMap API is ready');
};

class Google {
  constructor(opts = {}) {
    this._map = null;
    this._google = null;
    this._URL = 'https://maps.googleapis.com/maps/api/js';

    const {
      apiKey,
      client,
      libraries = [],
      language = null,
      region = null,
    } = opts;

    const channel = null;

    this.params = {
      key: apiKey,
      libraries: libraries.join(','),
      callback: 'mapCallback',
      v: '3.53',
      client,
      channel,
      language,
      region,
    };
  }

  get url() {
    const paramStr = Object.keys(this.params)
      .filter((k) => !!this.params[k])
      .map((k) => `${k}=${this.params[k]}`)
      .join('&');

    return `${this._URL}?${paramStr}`;
  }

  set map(newMap) {
    this._map = newMap;
  }

  get map() {
    return this._map;
  }

  set google(google) {
    this._google = google;
  }

  get google() {
    return this._google;
  }
}

let GoogleAPI = null;

export const InitializeGoogleAPI = () =>
  new Promise((resolve, reject) => {
    const config = {
      apiKey: CONFIG.GoogleMapsKey,
      language: CONFIG.language,
      libraries: ['places'],
    };

    if (!GoogleAPI) {
      GoogleAPI = new Google({ ...config, region: 'SA' });
    }

    const { url } = GoogleAPI;

    const scriptCache = cache({
      google: url,
    });

    scriptCache.google.onLoad((err) => {
      if (err) {
        reject(err);
      }

      if (GoogleAPI) {
        GoogleAPI.google = window.google;
      }

      resolve(window.google);
    });
  });

export default () => GoogleAPI;

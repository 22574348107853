import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n'
import { uniqueId } from 'lodash'
import classNames from 'classnames';

import BahrLogo from '../../../assets/header/bahr-logo-textless.svg';
import TojjarLogo from '../../../assets/header/tojjar-logo-textless.svg';
import ForsahLogo from '../../../assets/header/forsah-logo-textless.svg';
import ZaddLogo from '../../../assets/header/zadd-logo-textless.svg';

import './HeaderServicesMenu.scss';

export const HeaderServices = () => {
  const services = [
    {
      id: uniqueId('service-'),
      logo: BahrLogo,
      name: 'Bahr',
      href: 'https://bahr.sa/',
      isServiceActive: () => false,
    },
    {
      id: uniqueId('service-'),
      logo: TojjarLogo,
      name: 'Tojjar',
      href: 'https://tojjar.sa/',
      isServiceActive: () => false,
    },
    {
      id: uniqueId('service-'),
      logo: ForsahLogo,
      name: 'Forsah',
      href: 'https://forsah.sa/',
      isServiceActive: () => false,
    },
    {
      id: uniqueId('service-'),
      logo: ZaddLogo,
      name: 'Zadd',
      href: '.',
      isServiceActive: () => true,
    },
  ];

  return (
    <div className='HeaderServicesMenu__content'>
      {services.map(({ id, logo, name, isServiceActive, href }) => (
        <a href={href} key={id} className={classNames('HeaderServicesMenu__service', {
          'HeaderServicesMenu__service--active': isServiceActive(),
        })}>
          <img className='service-img' src={logo} alt={`${name}-service-logo`} />
        </a>
      ))}
    </div>
  )
}

const HeaderServicesMenu = ({ active }) => (
  <div className={classNames('HeaderServicesMenu', {
    'HeaderServicesMenu--active': active,
  })}>
    <span className='HeaderServicesMenu__head'>{I18n.t('app.header.services.title')}</span>
    <HeaderServices />
  </div>
)

HeaderServicesMenu.propTypes = {
  active: PropTypes.bool,
};

export default HeaderServicesMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, graphql } from 'react-apollo';

import setInfoWindowContentText from '../../actions/setInfoWindowContentTextAction';
import copyBusinessDetailsIntoClipboard from '../../actions/copyBusinessDetailsIntoClipboardAction';
import InfoWindowTooltipComponent from './InfoWindowTooltipComponent';
import { getSingleBusinessQuery } from '../../../../graphql/queries/BusinessQuery';
import { getChosenRegion } from '../../../../selectors/map.selector';

const cumulatedActions = {
  setInfoWindowContentText,
  copyBusinessDetailsIntoClipboard,
};

// eslint-disable-next-line react/jsx-props-no-spreading -- adapter component
const InfoWindowTooltipContainer = (props) => (
  <InfoWindowTooltipComponent {...props} />
);

InfoWindowTooltipContainer.propTypes = {
  subsectorName: PropTypes.string,
  businessName: PropTypes.string,
  businessId: PropTypes.string,
  isAdmin: PropTypes.bool,
  chosenRegion: PropTypes.string.isRequired,
  address: PropTypes.shape({
    firstRow: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
    secondRow: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  }),
  actions: PropTypes.shape({
    setInfoWindowContentText: PropTypes.func,
    copyBusinessDetailsIntoClipboard: PropTypes.func,
  }),
};

const mapStateToProps = (state) => ({
  chosenRegion: getChosenRegion(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(cumulatedActions, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getSingleBusinessQuery, {
    options: ({ isAdmin }) => ({
      fetchPolicy: isAdmin ? 'no-cache' : 'cache-first',
    }),
    variables: ({ businessId }) => ({
      businessId,
    }),
    props: ({ data: { business, loading } }) => ({ business, loading }),
  }),
)(InfoWindowTooltipContainer);

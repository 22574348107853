import { copyTextToClipboard } from '../../../helpers/AppHelper';

export default function copyBusinessDetailsIntoClipboard() {
  return (dispatch, getState) => {
    const state = getState();
    const { text } = state.map.infoWindowContent;

    copyTextToClipboard(text);
  };
}

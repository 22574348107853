import { createSelector } from '@reduxjs/toolkit';
import { sortSectorsBySize } from '../helpers/SectorHelper';

export const getSector = (state) => state.sector;
export const getSectorsSizesById = createSelector(
  getSector,
  (sector) => sector.sectorsSizes,
);
export const getSectorsSizesBySectorId = createSelector(
  getSector,
  (sector) => sector.sectorSizesBySectorId,
);
export const isSectorsSizesLoading = createSelector(
  getSector,
  (sector) => sector.sectorSizesLoading,
);
export const isSectorsListLoading = createSelector(
  getSector,
  (sector) => sector.listLoading,
);
export const getSectorSearchPhrase = createSelector(
  getSector,
  (sector) => sector.sectorSearchPhrase,
);
export const getChosenSectors = createSelector(
  getSector,
  (sector) => sector.tags,
);
export const getChosenSectorsColorsNames = createSelector(
  getChosenSectors,
  (tags) => tags.map(({ color }) => color.name),
);
export const getChosenSectorsIds = createSelector(getChosenSectors, (tags) =>
  tags.map(({ id }) => id),
);
export const getChosenSectorsById = createSelector(
  getChosenSectors,
  (tags) => new Map(tags.map((tag) => [tag.id, tag])),
);
export const getSectorErrors = createSelector(
  getSector,
  (sector) => sector.errors,
);
export const getClickedSector = createSelector(
  getSector,
  (sector) => sector.clickedSector,
);

// ------------------------------------
// Sector list helpers
// ------------------------------------
function addBusinessNumberToIsics({ sectors }, sectorSizes) {
  const mappedSector = sectors.map((sector) => {
    const sectorBusinessSize = sectorSizes[sector.id] || 0;

    const items = sector.children.items.map((subsector) => {
      const subsectorBusinessSize = sectorSizes[subsector.id] || 0;

      return {
        ...subsector,
        size: subsectorBusinessSize,
      };
    });

    return {
      ...sector,
      size: sectorBusinessSize,
      children: {
        items,
      },
    };
  });

  return mappedSector;
}

function removeIsicsWithZeroBusinesses(sectors) {
  return sectors
    .map((sector) => ({
      ...sector,
      children: {
        items: sector.children.items.filter((subsector) => subsector.size > 0),
      },
    }))
    .filter((sector) => sector.size > 0);
}

function mapSectorsList(sectors) {
  return new Map(
    sectors.map((sector) => {
      const subsectors = new Map(
        sector.children.items.map((subsector) => [subsector.id, subsector]),
      );

      return [
        sector.id,
        {
          ...sector,
          children: {
            items: subsectors,
          },
        },
      ];
    }),
  );
}

function flatSectorsMap(sectors) {
  return new Map(
    sectors.flatMap((sector) => {
      const subsectors = sector.children.items.map((subsector) => [
        subsector.id,
        subsector,
      ]);

      return [[sector.id, sector], ...subsectors];
    }),
  );
}

function colorizeSectorList(sectors, tags) {
  tags.forEach((tag, tagId) => {
    const { color } = tag;
    const { id: sectorId } = tag.root;

    if (sectorId && sectors.has(sectorId)) {
      const sector = sectors.get(sectorId);
      const subsectors = sector.children.items;
      const subsector = subsectors.get(tagId);

      if (subsector) {
        subsectors.set(tagId, { ...subsector, color });
      }
    }

    if (sectors.has(tagId)) {
      const sector = sectors.get(tagId);

      if (sector) {
        sectors.set(tagId, { ...sector, color });
      }
    }
  });

  return sectors;
}

function prepareSectorListDataToShow(sectors) {
  const coloredList = [];

  sectors.forEach((sector) => {
    const unmappedSector = sector;
    const unmappedSubsectors = [];

    unmappedSector.children.items.forEach((unmappedSubsector) => {
      unmappedSubsectors.push(unmappedSubsector);
    });

    coloredList.push({
      ...unmappedSector,
      children: {
        items: unmappedSubsectors,
      },
    });
  });

  return coloredList;
}

const getSectorIds = (sectorList) =>
  sectorList.reduce((cumulated, sector) => {
    const subsectors = sector.children;

    const subsectorIds =
      subsectors && !subsectors.items
        ? []
        : subsectors.items.map((subsector) => subsector.id);

    return cumulated.concat([sector.id, ...subsectorIds]);
  }, []);

// ------------------------------------
// Default sector list
// ------------------------------------
export const getDefaultSectorList = createSelector(
  getSector,
  (sector) => sector.sectorLists.default,
);

export const getDefaultSectorListSectors = createSelector(
  getDefaultSectorList,
  (sectorList) => sectorList.sectors,
);

export const getDefaultSectorListFlatMap = createSelector(
  getDefaultSectorListSectors,
  flatSectorsMap,
);

export const getSectorListWithSizes = createSelector(
  getDefaultSectorList,
  getSectorsSizesBySectorId,
  addBusinessNumberToIsics,
);
export const getNonZeroDefaultSectorList = createSelector(
  getSectorListWithSizes,
  removeIsicsWithZeroBusinesses,
);
export const getNonZeroDefaultSectorListIds = createSelector(
  getNonZeroDefaultSectorList,
  getSectorIds,
);
export const getSortedNonZeroSectorList = createSelector(
  getNonZeroDefaultSectorList,
  (nonZeroSectorList) => sortSectorsBySize(nonZeroSectorList),
);
export const getSortedSectorList = createSelector(
  getSectorListWithSizes,
  (sectorList) => sortSectorsBySize(sectorList),
);
export const getDefaultSectorListByDesc = createSelector(
  getDefaultSectorList,
  (sectorList) => sortSectorsBySize(sectorList).sectors,
);
export const getSortedNonZeroSectorListById = createSelector(
  getSortedNonZeroSectorList,
  mapSectorsList,
);
export const getSortedSectorListById = createSelector(
  getSortedSectorList,
  mapSectorsList,
);
export const getMappedColoredSectorListById = createSelector(
  getSortedSectorListById,
  getChosenSectorsById,
  colorizeSectorList,
);
export const getMappedColoredNonZeroSectorListById = createSelector(
  getSortedNonZeroSectorListById,
  getChosenSectorsById,
  colorizeSectorList,
);
export const getColoredSectorList = createSelector(
  getMappedColoredSectorListById,
  prepareSectorListDataToShow,
);
export const getColoredNonZeroSectorList = createSelector(
  getMappedColoredNonZeroSectorListById,
  prepareSectorListDataToShow,
);

// ------------------------------------
// Filtered sector list
// ------------------------------------
export const getFilteredSectorList = createSelector(
  getSector,
  (sector) => sector.sectorLists.filtered,
);
export const getFilteredSectorListWithSizes = createSelector(
  getFilteredSectorList,
  getSectorsSizesBySectorId,
  addBusinessNumberToIsics,
);
export const getNonZeroFilteredSectorList = createSelector(
  getFilteredSectorListWithSizes,
  removeIsicsWithZeroBusinesses,
);
export const getSortedNonZeroFilteredSectorList = createSelector(
  getNonZeroFilteredSectorList,
  (nonZeroSectorList) => sortSectorsBySize(nonZeroSectorList),
);
export const getSortedNonZeroFilteredSectorListById = createSelector(
  getSortedNonZeroFilteredSectorList,
  mapSectorsList,
);
export const getMappedColoredFilteredSectorListById = createSelector(
  getSortedNonZeroFilteredSectorListById,
  getChosenSectorsById,
  colorizeSectorList,
);
export const getColoredFilteredSectorList = createSelector(
  getMappedColoredFilteredSectorListById,
  prepareSectorListDataToShow,
);

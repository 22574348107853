import React from 'react';
import PropTypes from 'prop-types';

import { PercentageSign } from '../../helpers/globals/Constans.ts';
import AnimatedValue from '../AnimatedValue';
import FormattedValue from '../FormattedValue';

const PercentageValue = ({ number, enableAnimation }) => {
  const percentageSign = PercentageSign;

  const renderedNumber = enableAnimation ? (
    <AnimatedValue value={number} />
  ) : (
    <FormattedValue value={Number(number)} />
  );

  return (
    <span className='percentage-value'>
      <span className='percentage-value__format'>{percentageSign}</span>
      <span className='percentage-value__number'>{renderedNumber}</span>
    </span>
  );
};

PercentageValue.defaultProps = {
  enableAnimation: false,
};

PercentageValue.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  enableAnimation: PropTypes.bool,
};

export default PercentageValue;

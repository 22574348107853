import hexToRgba from 'hex-rgba';
import { getResponsiveConstantData } from '../ResponsiveHelper';
import CONFIG from '../../config/config';
import { BusinessDataShowTypes, Color } from '../../types';

// ------------------------------------
// Global Constants
// ------------------------------------

export const AppPaths = {
  HOME: '/',
  SECTORS: '/sectors',
  BUSINESSES: '/businesses',
  DEMOGRAPHIC: '/demographic',
  NOT_FOUND: '*',
  RENDER_PDF: '/render-pdf',
};

export const AppLanguages = {
  en: 'en',
  ar: 'ar',
};

const ReportsApiBase = `${CONFIG.api}/api/reports`;

export const ApiPaths = {
  GENERATE: `${ReportsApiBase}/generate`,
  GENERATECSV: `${ReportsApiBase}/generate-csv`,
  STATUS: `${ReportsApiBase}/status`,
  DATA: `${ReportsApiBase}/data`,
  DOWNLOAD: `${ReportsApiBase}/download`,
};

export const ColorsByPriority = ['havelockBlue', 'yellow', 'purple'];

export const Colors: Record<string, Partial<Color>> = {
  blue: {
    name: 'blue',
    value: '#00F6C9',
    labelValue: '#849db3',
    transparentValue: hexToRgba('#00F6C9', 50),
    satelliteTransparentValue: hexToRgba('#00F6C9', 80),
  },
  blueRibbon: {
    name: 'blueRibbon',
    value: '#0060ff',
  },
  yellow: {
    name: 'yellow',
    value: '#DFDF6B',
    labelValue: '#849db3',
    transparentValue: hexToRgba('#DFDF6B', 50),
    satelliteTransparentValue: hexToRgba('#DFDF6B', 80),
  },
  purple: {
    name: 'purple',
    value: '#C37DC5',
    labelValue: '#849db3',
    transparentValue: hexToRgba('#C37DC5', 50),
    satelliteTransparentValue: hexToRgba('#C37DC5', 80),
  },
  havelockBlue: {
    name: 'havelockBlue',
    value: '#4a90e2',
    labelValue: '#849db3',
    transparentValue: hexToRgba('#4a90e2', 50),
    satelliteTransparentValue: hexToRgba('#4a90e2', 80),
  },
  cyan: {
    value: '#00DDFF',
  },
  green: {
    value: '#53d17a',
  },
  mountainMeadow: {
    value: '#29e57b',
  },
  libertyGreen: {
    value: '#139F50',
  },
  pink: {
    value: '#FF7296',
  },
  brilliantRose: {
    value: '#FE4EA4',
  },
  yellowChenin: {
    value: '#E2E36C',
  },
  beigeRed: {
    value: '#D5920B',
  },
  gray: {
    value: '#48556B',
    transparentValue: hexToRgba('#48556B', 90),
  },
};

export const Placement = {
  top: 'top',
  right: 'right',
  bottom: 'bottom',
  left: 'left',
  topRight: 'topRight',
};

export const DemographicCharts = {
  ageDistribution: {
    male: 'male',
    female: 'female',
  },
  nationality: {
    saudi: 'saudi',
    nonSaudi: 'nonSaudi',
  },
};

export const DemographicChartsDataLabels = {
  byGender: {
    male: 'male',
    female: 'female',
  },
  byNationality: {
    saudi: 'saudi',
    nonSaudi: 'nonSaudi',
  },
};

export const numberBreakCases = {
  billions: {
    size: 100000000,
    translation: 'demographicTab.billion',
  },
  millions: {
    size: 1000000,
    translation: 'demographicTab.million',
  },
  thousands: {
    size: 10000,
    translation: 'demographicTab.thousand',
  },
  oneThousand: {
    size: 1000,
  },
};

// ------------------------------------
// Map Constants
// ------------------------------------

export const googleMapEvents = {
  ZOOM_CHANGED: 'zoom_changed',
  CENTER_CHANGED: 'center_changed',
  IDLE: 'idle',
  MAP_TYPE_ID_CHANGED: 'maptypeid_changed',
  BOUNDS_CHANGED: 'bounds_changed',
};

export const mapEventState = {
  preventDefault: 'preventDefault',
  default: 'default',
};

export const areaTypesMap = {
  emirate: 'emirate',
  governorate: 'governorate',
  city: 'city',
  district: 'district',
};

export const areaTypes = Object.values(areaTypesMap);

export const areaMapClassification = {
  city: 'city',
  district: 'district',
};

export const searchAreaTypes = {
  emirate: true,
  governorate: true,
  locality: true,
  district: true,
};

export const maxZoomLevel = 22;

export const mapDefaultZoomKSA = {
  bigDesktop: 6,
  other: 5,
  mobile: 4,
};

export const mapDefaultZoomRiyadhRegion = {
  bigDesktop: 10,
  other: 9,
  mobile: 8,
};

export const mapDefaultZoomEasternRegion = {
  bigDesktop: 8,
  other: 7,
  mobile: 6,
};

export const riyadhCityId = 'city-3';
export const easterRegionId = 'emirate-1';

export const riyadhRegionString = 'riyadh-region';
export const easternRegionString = 'eastern-region';

export const regionStringToIdMap = {
  [riyadhRegionString]: riyadhCityId,
  [easternRegionString]: easterRegionId,
};

export const searchInputMaxLength = 64;

// TODO investigate allDefaultAreas and rename easter to eastern usage AG-932
export const allDefaultAreas = [riyadhCityId, easterRegionId];

export const mapKSACenter = {
  desktop: {
    lat: 24.752300814715696,
    lng: 46.54328583007816,
  },
  mobile: {
    lat: 24.745212424751614,
    lng: 46.53641937500004,
  },
};

export const mapRiyadhCenter = {
  desktop: {
    lat: 24.752300814715696,
    lng: 46.54328583007816,
  },
  mobile: {
    lat: 24.745212424751614,
    lng: 46.53641937500004,
  },
};

export const mapEasternRegionCenter = {
  desktop: {
    lat: 27.203659219,
    lng: 48.134426904,
  },
  mobile: {
    lat: 27.203659219,
    lng: 48.134426904,
  },
};

export const mapRiyadhCentralDistrict = {
  lat: 24.698059185241558,
  lng: 46.687781319071746,
};

export const mapDefaultCenter = mapKSACenter;

export const KSABounds = {
  desktop: {
    minLat: 16.19,
    maxLat: 32.2,
    minLng: 34.6,
    maxLng: 55.86,
  },
  mobile: {
    minLat: 12.99,
    maxLat: 35.61,
    minLng: 35.53,
    maxLng: 51.43,
  },
};

export const RiyadhBounds = {
  desktop: {
    minLat: 24.125639930566646,
    maxLat: 25.378961698864742,
    minLng: 45.626614077148474,
    maxLng: 47.45995758300785,
  },
  mobile: {
    minLat: 23.47322457575292,
    maxLat: 26.017200273750298,
    minLng: 45.40482757812504,
    maxLng: 47.66801117187504,
  },
};

export const EasternRegionBounds = {
  desktop: {
    minLat: 25.746904697384,
    maxLat: 28.660413741386,
    minLng: 46.028576003104,
    maxLng: 50.240277805436,
  },
  mobile: {
    minLat: 25.746904697384,
    maxLat: 28.660413741386,
    minLng: 46.028576003104,
    maxLng: 50.240277805436,
  },
};

export const mapDefaultBounds = KSABounds;

export const RiyadhCircularArea = {
  centerLat: 24.75671,
  centerLng: 46.62431,
  radius: 72810,
};

const businessMaxSizeInBoundsData = {
  mobile: 10,
  desktop: 50,
};
export const businessMaxSizeInBounds = getResponsiveConstantData(
  businessMaxSizeInBoundsData,
);

export const breadcrumbLevels = {
  ksa: 'ksa',
  emirate: 'emirate',
  governorate: 'governorate',
  city: 'city',
  district: 'district',
};

export const shapeEpsilonSimplifyValue = {
  emirate: 0.001,
  governorate: 0.0001,
  city: 0.00001,
  district: 0.0000025,
};

export const clustersSizeObject = {
  xs: {
    min: 30,
    max: 60,
  },
  sm: {
    min: 35,
    max: 70,
  },
  md: {
    min: 50,
    max: 100,
  },
  lg: {
    min: 50,
    max: 100,
  },
  xl: {
    min: 50,
    max: 100,
  },
  xxl: {
    min: 50,
    max: 100,
  },
  xxxl: {
    min: 50,
    max: 100,
  },
};

const BusinessQueryVariables = {
  mapRange: 'mapRange',
  clusterType: 'clusterType',
  areas: 'areas',
  highlightedAreas: 'highlightedAreas',
  isics: 'isics',
  precision: 'precision',
};

export const BusinessDataShowWays = (({
  mapRange,
  clusterType,
  areas,
  highlightedAreas,
  isics,
  precision,
}) => {
  const enhancedClusteringVariables = [mapRange, areas, precision];

  const simpleClusteringVariables = [
    mapRange,
    clusterType,
    areas,
    highlightedAreas,
  ];

  const notClusteredBusinessesVariables = [mapRange, areas];

  const {
    enhancedClustering,
    enhancedClusteringWithTags,
    simpleClustering,
    simpleClusteringWithTags,
    notClusteredBusinesses,
    notClusteredBusinessesWithTags,
  } = BusinessDataShowTypes;

  return {
    [enhancedClustering]: {
      queryVariables: enhancedClusteringVariables,
    },
    [enhancedClusteringWithTags]: {
      queryVariables: enhancedClusteringVariables.concat(isics),
    },
    [simpleClustering]: {
      queryVariables: simpleClusteringVariables,
    },
    [simpleClusteringWithTags]: {
      queryVariables: simpleClusteringVariables.concat(isics),
    },
    [notClusteredBusinesses]: {
      queryVariables: notClusteredBusinessesVariables,
    },
    [notClusteredBusinessesWithTags]: {
      queryVariables: notClusteredBusinessesVariables.concat(isics),
    },
  };
})(BusinessQueryVariables);

export const MapElementTypes = {
  boundingBox: 'boundingBox',
  location: 'location',
};

export const DefaultBusinessZoomLvl = 20;
export const BusinessMarkerRefreshInterval = 25;

// ------------------------------------
// Chart Constants
// ------------------------------------

export const BusinessChartTypes = {
  noSectors: 'noSectors',
  oneSector: 'oneSector',
  oneSubsector: 'oneSubsector',
  moreThanOneTag: 'moreThanOneTag',
};

export const PanelItemTypes = {
  number: 'number',
  percentageValue: 'percentageValue',
  demographicValue: 'demographicValue',
};

export const SubtitleTypes = {
  demographicValue: 'demographicValue',
};

export const PercentageSign = '%';

export const GraphUpperscale = {
  desktop: 1.3,
  mobile: 1.5,
};

export const RouteName = {
  ar: ['شارع', 'طريق', 'الطريق', 'street'],
  en: ['road', 'street'],
};

export const SearchResultTypePhrases = {
  ar: ['محافظة', 'حي'],
  en: ['district', 'governorate'],
};

export const ElasticAreaTypes = {
  district: ['district', 'حي'],
  governorate: ['محافظة', 'governorate'],
};

export const AutocompletePlaceTypes = {
  route: 'route',
  city: 'location',
  emirate: 'emirate',
  district: 'district',
  governorate: 'governorate',
};

// ------------------------------------
// Sector List Constants
// ------------------------------------

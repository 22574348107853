import React from 'react';
import PropTypes from 'prop-types';

import LocateMe from './MapControls/LocateMe';
import ZoomComponent from './MapControls/ZoomComponent';
import MapAutocomplete from './MapAutocomplete/MapAutocompleteContainer';
import FlashModal from '../../../components/FlashModal/FlashModal';

function getMapElement() {
  return document.querySelector('#map-wrapper');
}

const MapExtensions = ({
  google,
  map,
  modal,
  actions: { setModalMessage, toggleModalVisibility, setMapZoom },
  mapOptions,
  isRegionLimitationEnabled,
  chosenRegion,
}) => {
  const searchPlaceholderTranslation = chosenRegion
    ? `mapAutocomplete.searchPlaceholder.${chosenRegion}`
    : '';

  return (
    <div>
      <MapAutocomplete
        searchPlaceholderTranslation={searchPlaceholderTranslation}
        noResultsTranslation='mapAutocomplete.noResultsMessage'
        google={google}
        map={map}
      />
      <LocateMe
        google={google}
        map={map}
        modal={modal}
        setModalMessageFn={setModalMessage}
        isRegionLimitationEnabled={isRegionLimitationEnabled}
        chosenRegion={chosenRegion}
      />
      <ZoomComponent
        google={google}
        map={map}
        mapOptions={mapOptions}
        zoomHandler={setMapZoom}
        chosenRegion={chosenRegion}
      />
      <FlashModal
        isOpen={modal.isOpen}
        isError={modal.isError}
        parentSelector={getMapElement}
        closeModalFn={toggleModalVisibility}
      >
        {modal.text}
      </FlashModal>
    </div>
  );
};

MapExtensions.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  modal: PropTypes.object,
  mapOptions: PropTypes.object,
  isRegionLimitationEnabled: PropTypes.bool,
  chosenRegion: PropTypes.string,
  actions: PropTypes.shape({
    setUserPosition: PropTypes.func,
    setModalMessage: PropTypes.func,
    toggleModalVisibility: PropTypes.func,
    setMapZoom: PropTypes.func,
  }),
};

export default MapExtensions;

import React from 'react';
import PropTypes from 'prop-types';

import { MapElementTypes } from '../../../../helpers/globals/Constans.ts';
import { prepareViewport } from '../../../../helpers/MapHelper';
import BreadcrumbsItem from './BreadcrumbsItem.tsx';
import BreadcrumbsTooltip from '../../../../components/Tooltip/BreadcrumbsTooltip';
import { scrollToMap } from '../../../../helpers/AppHelper';

const Breadcrumbs = ({
  google,
  breadcrumbs,
  updateViewport,
  chosenRegion,
  resetToRegion,
}) => {
  const setViewport = (breadcrumb) => {
    if (!breadcrumb.clickable) {
      return;
    }
    const { boundingBox } = breadcrumb;

    scrollToMap();

    if (boundingBox) {
      const viewport = prepareViewport(
        MapElementTypes.boundingBox,
        boundingBox,
        google,
      );
      updateViewport(viewport);
    } else {
      resetToRegion(chosenRegion);
    }
  };

  return (
    <div className='sidebar__badge sidebar__badge--breadcrumbs'>
      <BreadcrumbsTooltip breadcrumb={breadcrumbs.secondLine.label}>
        <p className='sidebar__badge--breadcrumbs__text  text text--dimmed'>
          <BreadcrumbsItem
            breadcrumb={breadcrumbs.secondLine}
            setViewport={setViewport}
            isLastLine={!breadcrumbs.thirdLine}
          />
        </p>
      </BreadcrumbsTooltip>
      {breadcrumbs.thirdLine && (
        <BreadcrumbsTooltip breadcrumb={breadcrumbs.thirdLine.label}>
          <p>
            <BreadcrumbsItem
              breadcrumb={breadcrumbs.thirdLine}
              setViewport={setViewport}
              isLastLine
            />
          </p>
        </BreadcrumbsTooltip>
      )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  google: PropTypes.object,
  breadcrumbs: PropTypes.object,
  chosenRegion: PropTypes.string,
  updateViewport: PropTypes.func,
  resetToRegion: PropTypes.func.isRequired,
};

export default Breadcrumbs;

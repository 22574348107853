import PropTypes from 'prop-types';
import { round } from 'lodash';
import { numberBreakCases } from '../../helpers/globals/Constans.ts';

const prepareNumber = (numberToPrepare, divider) => {
  const dividedNumber = numberToPrepare / divider;

  return round(dividedNumber, 1);
};

export const formatNumberValue = (
  number,
  bottomThousandRange = numberBreakCases.thousands.size,
) => {
  if (number >= numberBreakCases.billions.size) {
    return prepareNumber(number, numberBreakCases.billions.size);
  }

  if (number >= numberBreakCases.millions.size) {
    return prepareNumber(number, numberBreakCases.millions.size);
  }

  if (bottomThousandRange <= number) {
    return prepareNumber(number, numberBreakCases.oneThousand.size);
  }

  return number;
};

export const prepareLocaleString = (value) =>
  Number(value).toLocaleString('en');

const FormattedValue = ({ value, bottomThousandRange }) => {
  const formattedNumberValue = formatNumberValue(value, bottomThousandRange);
  const numberFormattedAsLocaleString =
    prepareLocaleString(formattedNumberValue);

  return numberFormattedAsLocaleString;
};

FormattedValue.propTypes = {
  value: PropTypes.number.isRequired,
  bottomThousandRange: PropTypes.number,
};

export default FormattedValue;

import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

interface Breadcrumbs {
  clickable: boolean;
  label: string;
}

export interface BreadcrumbsItemProps {
  breadcrumb: Breadcrumbs;
  setViewport: (breadcrumb: Breadcrumbs) => void;
  isLastLine: boolean;
}

const BreadcrumbsItem = ({
  breadcrumb,
  setViewport,
  isLastLine,
}: BreadcrumbsItemProps) => {
  const classes = classNames({
    'text--clickable': breadcrumb.clickable,
    'text breadcrumbs__last-line': isLastLine,
  });

  const text = <Translate value={breadcrumb.label} />;

  if (breadcrumb.clickable) {
    return (
      <button
        type='button'
        className={classes}
        onClick={() => setViewport(breadcrumb)}
      >
        {text}
      </button>
    );
  }

  return <span className={classes}>{text}</span>;
};

export default BreadcrumbsItem;

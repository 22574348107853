import React from 'react';

import './Loader.scss';

const Loader = () => (
  <div className='loader__content'>
    <div className='loader__container'>
      <div className='loader' />
    </div>
  </div>
);

export default Loader;

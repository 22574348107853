import gql from 'graphql-tag';

const getDemographicSpendingDataQuery = gql`
  query getDemographicSpendingDataQuery($areas: [String]!) {
    spendingData(
      filters: { areas: $areas }
      orders: { id: "value", direction: "desc" }
    ) {
      facts {
        area {
          id
        }
        value
        subIndicator
      }
    }
  }
`;

export default getDemographicSpendingDataQuery;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import {
  drawMarkers,
  getBreadcrumbData,
  getDemographicSpendingData,
  getGroups,
  redrawMarkersAfterMapTypeIdChange,
  runMapExtensions,
  setCurrentLocation,
  setMap,
  setMapOptions,
  setMapRange,
  setMapType,
  setMapZoom,
  setUser,
} from '../actions/MapActions';
import setInfoWindowOnMap from '../actions/setInfoWindowOnMapAction';
import { getBusinesses } from '../../Businesses/actions/BusinessActions';
import { Map } from '../components/MapView/MapView';
import MapLoader from '../components/LoadingMap/Loader';
import { WithMap } from '../../../helpers/MapHelper';
import {
  getAreaShapes,
  getBreadcrumbsItems,
  getChosenRegion,
  getMapClustersOptions,
  getMapOptions,
  getMapRange,
  isMapExtensionsRan,
} from '../../../selectors/map.selector';
import { getBusinessItems } from '../../../selectors/business.selector';
import { getSectorsSizes } from '../../Sectors/reducers/SectorSlice';

const cumulatedActions = {
  setCurrentLocation,
  getBreadcrumbData,
  setMapZoom,
  setMapRange,
  getBusinesses,
  getSectorsSizes,
  drawMarkers,
  redrawMarkersAfterMapTypeIdChange,
  runMapExtensions,
  setInfoWindowOnMap,
  setMapOptions,
  setMap,
  setUser,
  getGroups,
  getDemographicSpendingData,
  setMapType,
};

const MapContainer = (props) => {
  const { google } = props;

  if (!google) {
    return (
      <div className='map__container'>
        <MapLoader />
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading -- proxy component
  return <Map {...props} />;
};

MapContainer.propTypes = {
  google: PropTypes.object,
  children: PropTypes.object,
  businesses: PropTypes.object,
  breadcrumbItems: PropTypes.shape({
    district: PropTypes.object,
    emirate: PropTypes.object,
    governorate: PropTypes.object,
  }),
  mapRange: PropTypes.object,
  areaShapes: PropTypes.object,
  mapOptions: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    zoom: PropTypes.number,
    minZoom: PropTypes.number,
    streetViewControl: PropTypes.bool,
    zoomControl: PropTypes.bool,
  }),
  clusters: PropTypes.shape({
    enabled: PropTypes.bool,
    areaType: PropTypes.string,
  }),
  modal: PropTypes.object,
  actions: PropTypes.shape({
    getBusinesses: PropTypes.func,
    getSectorsSizes: PropTypes.func,
    setMapZoom: PropTypes.func,
    setMapOptions: PropTypes.func,
    setMapRange: PropTypes.func,
    setCurrentLocation: PropTypes.func,
    setInfowindowOnMap: PropTypes.func,
  }),
  runMapExtensions: PropTypes.bool,
  chosenRegion: PropTypes.string,
};

const mapStateToProps = (state) => ({
  mapOptions: getMapOptions(state),
  breadcrumbItems: getBreadcrumbsItems(state),
  businesses: getBusinessItems(state),
  mapRange: getMapRange(state),
  clusters: getMapClustersOptions(state),
  areaShapes: getAreaShapes(state),
  runMapExtensions: isMapExtensionsRan(state),
  chosenRegion: getChosenRegion(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(cumulatedActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithMap(MapContainer));

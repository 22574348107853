let shapeDB = null;

export const shapesStore = {
  get() {
    return shapeDB;
  },

  has() {
    return shapeDB !== null;
  },

  set(newShape) {
    shapeDB = newShape;
  },

  remove() {
    shapeDB = null;
  },
};

let markersDB = new Map();

export const markersStore = {
  setMultiple(markers) {
    const newMarkerEntries = markers.map(({ id, markerOnMap }) => [
      id,
      markerOnMap,
    ]);
    markersDB = new Map(newMarkerEntries);
  },

  getAll() {
    return [...markersDB.entries()].map(([id, markerOnMap]) => ({
      id,
      markerOnMap,
    }));
  },

  getMultiple(ids) {
    return ids.map((id) => ({ id, markerOnMap: markersDB.get(id) }));
  },

  set(marker) {
    const { id, markerOnMap } = marker;

    return markersDB.set(id, markerOnMap);
  },

  remove(id) {
    return markersDB.remove(id);
  },

  has(id) {
    return markersDB.has(id);
  },

  get(id) {
    return markersDB.get(id);
  },
};

const downloadModal = {
  riyadhHeader: 'Download a custom report from the Central Region',
  easternHeader: 'Download a custom report from the Eastern Province',
  downloadMessage:
    'You are about to download the report based on currently selected filters.',
  downloadCommentHeader: 'What are the filters?',
  downloadCommentItemOne: 'selected district within the map',
  downloadCommentItemTwo: 'selected sectors',
  downloadFooter:
    'Downloading without filters selected generates general report for the region.',
  pendingHeader: 'We are working on your document',
  pendingMessage: 'This may take a few seconds. Please wait...',
  successHeader: 'Success',
  successMessage: 'You have successfully downloaded the file.',
  errorHeader: 'Error',
  errorMessage: 'Something went wrong. Please try again later.',
  backToMapsButtonText: 'Return to maps',
  cancel: 'Cancel',
};

export default downloadModal;

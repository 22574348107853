import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { numberBreakCases } from '../../helpers/globals/Constans.ts';

export const prepareNumberShortcutLabel = (
  number,
  bottomThousandRange = numberBreakCases.thousands.size,
) => {
  if (number >= numberBreakCases.billions.size) {
    return numberBreakCases.billions.translation;
  }

  if (number >= numberBreakCases.millions.size) {
    return numberBreakCases.millions.translation;
  }

  if (bottomThousandRange <= number) {
    return numberBreakCases.thousands.translation;
  }

  return null;
};

const ShortcutValueLabel = ({ value, style, bottomThousandRange }) => {
  const numberShortcutLabel = prepareNumberShortcutLabel(
    value,
    bottomThousandRange,
  );

  if (!numberShortcutLabel) {
    return null;
  }

  return (
    <span className='number-value-label__format' style={style}>
      {' '}
      <Translate value={numberShortcutLabel} />
    </span>
  );
};

ShortcutValueLabel.defaultProps = {
  style: {},
};

ShortcutValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
  bottomThousandRange: PropTypes.number,
  style: PropTypes.object,
};

export default ShortcutValueLabel;

const spendingChart = {
  name: 'توزيع الإنفاق الشهري للأسر حسب البند',
  categories: {
    food_and_purchases: 'الأطعمة وغيرها من المشتريات الأساسية',
    education: 'التعليم',
    rent_housing: 'الصحة والعلاج',
    health_and_treatment: 'إيجار السكن',
    house_maintenance_and_repairs: 'صيانة وإصلاحات السكن',
    household_water: 'مياه الشرب والمياه المنزلية',
    dewatering: 'نزح الصرف الصحي',
    electricity_and_gas: 'الكهرباء وغاز الطبخ',
    transport_and_petrol: 'المواصلات والبنزين وصيانة السيارات',
    internet_and_tv: 'الاتصالات والإنترنت والفضائيات',
    personal_needs: 'الملابس والأحذية والحُلي والعطور والاحتياجات الشخصية',
    electrical_appliances_and_furniture:
      'الأجهزة الكهربائية والهواتف والحواسيب والأثاث المنزلي',
    tourism_and_restaurants: 'الترفيه والسياحة والمطاعم',
    domestic_workers: 'العمالة المنزلية',
    loan_or_purchase_installments: 'تسديد أقساط القروض أو المشتريات',
    social_compliments: 'المجاملات الاجتماعية',
  },
  currency: 'ر.س',
};

export default spendingChart;

import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import iconClose from '../../assets/download/close.svg';
import './SimpleModal.scss';

interface SimpleModalProps {
  isOpen: boolean;
  shouldFocusAfterRender: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
}

const SimpleModal = ({
  isOpen,
  shouldFocusAfterRender,
  onRequestClose,
  children,
}: SimpleModalProps) => (
  <Modal
    className='SimpleModal'
    overlayClassName='SimpleModal__overlay'
    bodyOpenClassName='SimpleModal__body--open'
    isOpen={isOpen}
    shouldFocusAfterRender={shouldFocusAfterRender}
    shouldCloseOnOverlayClick
    onRequestClose={onRequestClose}
  >
    <button
      type='button'
      className='SimpleModal__close'
      onClick={onRequestClose}
    >
      <img src={iconClose} alt='يغلق' />
    </button>
    {children}
  </Modal>
);

export default SimpleModal as any;

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  shouldFocusAfterRender: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
};

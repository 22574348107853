export const isTooltipTargetAvailable = (node) => Boolean(node);

export const initStylesHandler = () => {
  const addedClasses = [];

  const resetStyles = () => {
    addedClasses.forEach(([node, addedClass]) => {
      node.classList.remove(addedClass);
    });
    addedClasses.length = 0;
  };

  const addClass = (node, newClass) => {
    addedClasses.push([node, newClass]);
    node.classList.add(newClass);
  };

  const addStyles = (node, step) => {
    /** add custom modifications per step if required */
    switch (step) {
      case 'search':
        // map need the additional class to highlight the searchbar
        addClass(document.getElementsByClassName('map')[0], 'map--onboarding');
        addClass(node.parentNode, 'onboarding__target-parent');
        break;
      case 'business':
        break;
      case 'scroll':
        addClass(node, 'onboarding__target--scroll');
        addClass(
          node.parentNode.children[0].childNodes[0],
          'onboarding-shadow-target--scroll',
        );
        break;
      default:
        break;
    }
  };

  return { addClass, addStyles, resetStyles };
};

export function initEventsHandler(actions) {
  const events = [];

  const addEvent = (target, eventType, callback) => {
    events.push([target, eventType, callback]);
    target.addEventListener(eventType, callback);
  };

  const resetEvents = () => {
    events.forEach(([target, eventType, callback]) => {
      target.removeEventListener(eventType, callback);
    });
    events.length = 0;
  };

  function addStepEvents(target, step) {
    /** add custom modifications per step if required */
    switch (step) {
      case 'scroll':
        addEvent(target, 'click', (e) => {
          e.stopPropagation();
          actions.setNextStep();
        });
        break;
      default:
        break;
    }
  }

  return { addEvent, addStepEvents, resetEvents };
}

export const getTooltipPositioning = (target) => {
  const boundaries = target && target.getBoundingClientRect();

  if (boundaries) {
    return {
      width: boundaries.width,
      height: boundaries.height,
      left: boundaries.left,
      top: boundaries.top,
      position: 'fixed',
    };
  }

  return {};
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import AutocompleteItemContent from './AutocompleteItemContent';

const AutocompleteItem = ({
  index,
  activeIndex,
  noResults,
  element,
  onClickItem,
  noResultsTranslation,
}) => {
  const setPlaceResult = () => {
    if (isEmpty(element)) {
      return;
    }

    onClickItem(element);
  };

  const classes = classNames('autocomplete__list-item', {
    active: index === activeIndex && !noResults,
  });

  return (
    <div
      id={`autocomplete-item-${index}`}
      className={classes}
      onClick={setPlaceResult}
    >
      <AutocompleteItemContent
        noResults={noResults}
        noResultsTranslation={noResultsTranslation}
        element={element}
      />
    </div>
  );
};

AutocompleteItem.propTypes = {
  index: PropTypes.number.isRequired,
  noResults: PropTypes.bool.isRequired,
  noResultsTranslation: PropTypes.string.isRequired,
  activeIndex: PropTypes.number.isRequired,
  element: PropTypes.object.isRequired,
  onClickItem: PropTypes.func,
};

export default AutocompleteItem;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { round } from 'lodash';

import AxisXItem from './AxisXItem';

const AxisX = ({
  mirrored,
  singleAxisItem,
  width,
  cssKey,
  maxAxisXItem,
  guideLines,
  type = 'percentageValue',
}) => {
  const axisXClasses = classnames('simple-axis-x row paragraph-3--dark-gray', {
    'end-xs reverse': mirrored,
  });

  const renderedGuideLines = guideLines
    .filter((line) => line !== guideLines.length - 1)
    .map((line) => {
      const value = round(singleAxisItem * line);

      return (
        <AxisXItem
          key={line}
          type={type}
          cssKey={cssKey}
          maxAxisXItem={maxAxisXItem}
          width={width}
          value={value}
        />
      );
    });

  return <div className={axisXClasses}>{renderedGuideLines}</div>;
};

AxisX.propTypes = {
  mirrored: PropTypes.bool,
  guideLines: PropTypes.array.isRequired,
  singleAxisItem: PropTypes.number.isRequired,
  maxAxisXItem: PropTypes.number.isRequired,
  cssKey: PropTypes.string.isRequired,
  width: PropTypes.number,
  type: PropTypes.string,
};

export default AxisX;

const breadcrumbs = {
  KSA: 'المملكة العربية السعودية',
  selectRegion: 'يرجى تحديد المنطقة الإدارية',
  selectGovernorate: 'يرجى تحديد المحافظة',
  selectCity: 'يرجى تحديد المحافظة',
  selectDistrict: 'يرجى تحديد الحي',
  noDistrictsToSelect: 'لا توجد معلومات على مستوى الأحياء',
  areaTypes: {
    district: 'حي {{areaName}}',
    governorate: 'محافظة {{areaName}}',
    emirate: '{{areaName}}',
    city: 'مدينة {{areaName}}',
    locality: 'مدينة {{areaName}}',
    route: 'شارع {{areaName}}',
  },
  centralRegionName: 'المنطقة الوسطى',
  defaultBreadcrumb: {
    firstLine: {
      label: 'المملكة العربية السعودية',
    },
    secondLine: {
      label: 'يرجى تحديد المنطقة',
    },
  },
  tooltipInfoDistrict:
    'يمكنك تحديد الحي باستخدام خانة البحث أو من خلال التنقل على الخريطة',
  tooltipInfoGovernorate:
    'يمكنك تحديد المحافظة باستخدام خانة البحث أو من خلال التنقل على الخريطة',
};

export default breadcrumbs;

import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import SimpleModal from '../SimpleModal/SimpleModal';
import './DownloadModalStatus.scss';
import { DownloadStatusType } from './types.d';
import { downloadStatuses } from './utils';
import LoadingModalContent from './LoadingModalContent';
import ResolvedModalContent from './ResolvedModalContent';

const modalText = {
  pendingHeader: 'downloadModal.pendingHeader',
  pendingMessage: 'downloadModal.pendingMessage',
  successHeader: 'downloadModal.successHeader',
  successMessage: 'downloadModal.successMessage',
  errorHeader: 'downloadModal.errorHeader',
  errorMessage: 'downloadModal.errorMessage',
  backToMapsButtonText: 'downloadModal.backToMapsButtonText',
  cancel: 'downloadModal.cancel',
};

interface DownloadModalStatusProps {
  isDownloadModalStatusOpen: boolean;
  downloadStatus: DownloadStatusType;
  closeAllModals: (isDownloadCancelled: boolean) => void;
}

const DownloadModalStatus = ({
  isDownloadModalStatusOpen,
  downloadStatus,
  closeAllModals,
}: DownloadModalStatusProps) => {
  const isLoading = downloadStatus === downloadStatuses.DOWNLOADING;

  return (
    <SimpleModal
      isOpen={isDownloadModalStatusOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => closeAllModals(false)}
    >
      {isLoading ? (
        <LoadingModalContent />
      ) : (
        <ResolvedModalContent downloadStatus={downloadStatus} />
      )}
      <button
        type='button'
        className='DownloadModalStatus__button'
        onClick={() => closeAllModals(true)}
      >
        <Translate
          className='DownloadModalStatus__button__text'
          value={isLoading ? modalText.cancel : modalText.backToMapsButtonText}
        />
      </button>
    </SimpleModal>
  );
};

export default DownloadModalStatus;

DownloadModalStatus.propTypes = {
  isDownloadModalStatusOpen: PropTypes.bool,
  closeAllModals: PropTypes.func,
  downloadStatus: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  BusinessChartTypes,
  Colors,
} from '../../../../helpers/globals/Constans.ts';
import ChartTooltip from '../../../../components/Tooltip/ChartTooltip';
import { startAnimation } from '../../../../helpers/AppHelper';

function colorizeSubsectorBar(item, type) {
  let background = Colors.havelockBlue.value;

  if (
    (type === BusinessChartTypes.oneSubsector ||
      type === BusinessChartTypes.moreThanOneTag) &&
    item.color
  ) {
    background = item.color.value;
  }

  return background;
}

const LOWER_TOOLTIP_WIDTH_LIMIT = 47;

class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barWidth: 0,
    };
  }

  componentDidMount() {
    this.updateBarWidth(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { barWidth: propsBarWidth, placement } = this.props;
    const { barWidth: stateBarWidth } = this.state;

    const { barWidth: nextPropsBarWidth } = nextProps;
    const { barWidth: nextStateBarWidth } = nextState;

    if (propsBarWidth !== nextPropsBarWidth) {
      this.setState((state) => ({ ...state, barWidth: 0 }));
    }

    return (
      propsBarWidth !== nextPropsBarWidth ||
      stateBarWidth !== nextStateBarWidth ||
      placement !== nextProps.placement
    );
  }

  componentDidUpdate(nextProps) {
    this.updateBarWidth(nextProps);
  }

  computeBarStyle() {
    const { item, barChartDataType } = this.props;

    const background = colorizeSubsectorBar(item, barChartDataType);

    const { barWidth } = this.state;

    const width = barWidth > 0 && barWidth < 1 ? 1 : barWidth;

    return { width, background };
  }

  updateBarWidth({ barWidth }) {
    startAnimation(() => {
      this.setState((state) => ({ ...state, barWidth }));
    });
  }

  render() {
    const { item, placement, enableAnimation = true } = this.props;

    const { width, background } = this.computeBarStyle();

    const overlayClassName =
      width < LOWER_TOOLTIP_WIDTH_LIMIT && width > 0
        ? 'chart chart--small-width'
        : 'chart';

    const className = cn('bar__visible-part', {
      'animation-disabled': !enableAnimation,
    });

    return (
      <div className='bar-chart-horizontal'>
        <div className='bar-container'>
          <ChartTooltip
            placement={placement}
            data={item.tooltip}
            color={background}
            overlayClassName={overlayClassName}
          >
            <div className='bar'>
              <div className={className} style={{ width, background }} />
            </div>
          </ChartTooltip>
        </div>
      </div>
    );
  }
}

Bar.propTypes = {
  barWidth: PropTypes.number,
  item: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    tooltip: PropTypes.object,
  }),
  placement: PropTypes.string,
  barChartDataType: PropTypes.string,
  enableAnimation: PropTypes.bool,
};

export default Bar;

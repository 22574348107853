import gql from 'graphql-tag';

import CONFIG from '../../config/config';
import { areaTypes } from '../../helpers/globals/Constans.ts';

function buildBreadcrumbLevelsQuery() {
  const query = areaTypes.map(
    (level) => `${level} {
    area {
      id
      type
      name(language: "${CONFIG.language}")
      boundingBox {
        minimalLatitude
        maximalLatitude
        minimalLongitude
        maximalLongitude
      }
    }
    score
  }\n`,
  );

  return query;
}

// eslint-disable-next-line import/prefer-default-export -- it could be expanded in future
export const getBreadcrumbs = gql`
  query getBreadcrumbs (
    $minLat: Float!,
    $maxLat: Float!,
    $minLng: Float!,
    $maxLng: Float!,
    $zoom: Int!
  ) {
    breadcrumb (
      minimalLatitude: $minLat
      maximalLatitude: $maxLat
      minimalLongitude: $minLng
      maximalLongitude: $maxLng
      zoom: $zoom
    ) {
      ${buildBreadcrumbLevelsQuery()}
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import { getBusinesses } from '../actions/BusinessActions';

// ------------------------------------
// Reducer
// ------------------------------------
// TODO: define Types
const initialState = {
  businessesNumber: 0,
  businessesFromSA: 0,
  businessesFromCurrentArea: 0,
  businesses: {
    items: {
      withoutTags: [],
    },
    size: 0,
  },
  listLoading: true,
};

const businessesSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    // TODO: Give case reducers meaningful past-tense "event"-style names
    // https://redux.js.org/usage/migrating-to-modern-redux#modernizing-redux-logic-with-redux-toolkit
    saveBusinesses(state, action) {
      state.businesses = action.payload;
    },
    saveAreaBusinesses(state, action) {
      state.businessesFromCurrentArea = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinesses.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getBusinesses.fulfilled, (state, action) => {
        state.businesses = action.payload;
        state.listLoading = false;
        state.businessesNumber = action.payload.size;
      });
  },
});

export const { saveBusinesses, saveAreaBusinesses } = businessesSlice.actions;

export default businessesSlice.reducer;

import { createSelector } from '@reduxjs/toolkit';

export const getMap = (state) => state.map;

export const getUserRoles = createSelector(getMap, (map) =>
  map.user && map.user.roles ? map.user.roles : [],
);
export const getSidebarBreadcrumbs = createSelector(
  getMap,
  (map) => map.breadcrumbsInSidebar,
);
export const getBreadcrumbsItems = createSelector(
  getMap,
  (map) => map.breadcrumbItems,
);
export const isRegionLimitationEnabled = createSelector(
  getMap,
  (map) => map.isRegionLimitationEnabled,
);
export const hasUserVisited = createSelector(
  getMap,
  (map) => map.hasUserVisited,
);
export const getChosenRegion = createSelector(
  getMap,
  (map) => map.chosenRegion,
);
export const getDemographicSpendingData = createSelector(
  getMap,
  (map) => map.spendingData,
);
export const getMapRange = createSelector(getMap, (map) => map.mapRange);
export const getMapOptions = createSelector(getMap, (map) => map.mapOptions);
export const getMapModal = createSelector(getMap, (map) => map.modal);
export const getMapMarkers = createSelector(getMap, (map) => map.markers);
export const getMapClustersOptions = createSelector(
  getMap,
  (map) => map.clusters,
);
export const getAreaShapes = createSelector(getMap, (map) => map.areaShapes);
export const isMapExtensionsRan = createSelector(
  getMap,
  (map) => map.runMapExtensions,
);
export const getUserPosition = createSelector(
  getMap,
  (map) => map.userPosition,
);
export const getMapPredictions = createSelector(
  getMap,
  (map) => map.predictions,
);
export const getMapAreas = createSelector(getMap, (map) => map.areas);
export const getMapBounds = createSelector(
  getMap,
  getMapClustersOptions,
  getMapRange,
  (map, clusters, mapRange) => mapRange,
);

import React from 'react';
import PropTypes from 'prop-types';

const BaseTooltipContent = ({ styles, arrowStyles, children }) => (
  <div className='tooltip' style={styles}>
    <div className='tooltip__content'>{children}</div>
    <div className='rc-tooltip-arrow' style={arrowStyles} />
  </div>
);

BaseTooltipContent.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  arrowStyles: PropTypes.object,
};

export default BaseTooltipContent;

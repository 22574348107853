import gql from 'graphql-tag';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import {
  getChosenSectorsColorsNames,
  getNonZeroDefaultSectorListIds,
  getSectorSearchPhrase,
} from '../../../selectors/sector.selector';
import { getMapBounds } from '../../../helpers/MapHelper';
import apolloClient from '../../../apolloClient';
import {
  getFilteredSectorsQuery,
  getSectorsQuery,
  getSectorsSizeQuery,
} from '../../../graphql/queries/SectorQueries';
import * as SectorHelper from '../../../helpers/SectorHelper';
import { startListening } from '../../../listenerMiddleware';
import { correctColorsInTags, getColorForNewTag } from '../actions/utils';
import { Colors, ColorsByPriority } from '../../../helpers/globals/Constans.ts';

const initialState = {
  tags: [],
  errors: [],
  clickedSector: null,
  sectorSearchPhrase: '',
  sectorSizesBySectorId: {},
  sectorsSizes: null,
  listLoading: false,
  sectorSizesLoading: false,
  sectorLists: {
    default: {
      sectors: [],
    },
    filtered: {
      sectors: [],
    },
  },
};

export const getSectorsSizes = createAsyncThunk(
  'sectors/getSectorsSizes',
  async (arg, { getState }) => {
    const state = getState();

    const mapRange = getMapBounds(state);
    const { areas } = state.map;

    const result = await apolloClient
      .query({
        query: gql`
          ${getSectorsSizeQuery}
        `,
        variables: {
          ...mapRange,
          areas,
        },
      })
      .then(({ data: { businesses } }) => {
        const sectorSizes = {};

        businesses.aggregationByIsic.isicBuckets.forEach(
          ({ isic: { id }, size }) => {
            sectorSizes[id] = size;
          },
        );

        return sectorSizes;
      });

    return result;
  },
);

const getFilteredSectorLists = createAsyncThunk(
  'sectors/getFilteredSectorLists',
  async (arg, { dispatch, getState }) => {
    const state = getState();

    const searchPhrase = getSectorSearchPhrase(state);

    const mapRange = getMapBounds(state);

    const variables = {
      searchPhrase,
      ...mapRange,
    };

    variables.ids = getNonZeroDefaultSectorListIds(state);

    const result = await apolloClient
      .query({
        query: gql`
          ${getFilteredSectorsQuery}
        `,
        variables,
      })
      .then(({ data: { isics } }) => isics);

    dispatch(getSectorsSizes());

    return result;
  },
);

const getSectorDefaultLists = createAsyncThunk(
  'sectors/getSectorDefaultLists',
  async (updateSectorsSizes = false, { dispatch, getState }) => {
    const state = getState();

    const searchPhrase = getSectorSearchPhrase(state);

    const mapRange = getMapBounds(state);

    const variables = {
      searchPhrase,
      ...mapRange,
    };

    const result = apolloClient
      .query({
        query: gql`
          ${getSectorsQuery}
        `,
        variables,
      })
      .then(({ data: { isics } }) => isics);

    if (updateSectorsSizes) {
      dispatch(getSectorsSizes());
    }

    return result;
  },
);

export const getSectorLists =
  (updateSectorsSizes = false) =>
  (dispatch, getState) => {
    const state = getState();
    const searchPhrase = getSectorSearchPhrase(state);

    if (searchPhrase.length) {
      getFilteredSectorLists()(dispatch, getState);
    } else {
      getSectorDefaultLists(updateSectorsSizes)(dispatch, getState);
    }
  };

// ------------------------------------
// Reducer
// ------------------------------------

const SectorsSlice = createSlice({
  name: 'sectors',
  initialState,
  reducers: {
    saveTags(state, action) {
      state.tags = action.payload;
      state.errors = [];
      state.clickedSector = null;
    },
    sectorError(state, action) {
      state.errors = action.payload.errors;
      state.clickedSector = action.payload.clickedSector;
    },
    filterSectors(state, action) {
      state.sectorSearchPhrase = action.payload;
    },
    saveSectorLists(state, action) {
      state.sectorLists.default.sectors = action.payload.sectors;
    },
    saveFilteredSectorLists(state, action) {
      state.sectorLists.filtered.sectors = action.payload.sectors;
    },
    saveSectorSizes(state, action) {
      state.sectorSizesBySectorId = { ...action.payload };
    },
    setSectorsLoading(state, action) {
      state.listLoading = action.payload;
    },
    sectorSizesLoading(state, action) {
      state.sectorSizesLoading = action.payload;
    },
    sectorClearError(state) {
      state.errors = [];
      state.clickedSector = null;
    },
    clearTags(state) {
      state.tags = [];
      state.errors = [];
      state.clickedSector = null;
    },
    saveMultipleSectors(state, action) {
      state.tags = correctColorsInTags(action.payload);
      state.errors = [];
      state.clickedSector = null;
    },
    removeSector(state, action) {
      const tags = [...state.tags];

      const chosenSectorIndex = tags.findIndex(
        (tag) => tag.id === action.payload.id,
      );

      tags.splice(chosenSectorIndex, 1);

      state.tags = tags;
      state.errors = [];
      state.clickedSector = null;
    },
    saveSector(state, action) {
      const tags = [...state.tags];
      const { id, name, root, type } = action.payload;
      const chosenSectorRoot = root || { id: null };
      const sectorAlreadyInTags = tags.findIndex(
        (tag) => tag.id === action.payload.id,
      );
      const errors = SectorHelper.handleTagsErrors(action.payload, tags);

      if (errors.length) {
        return;
      }

      if (sectorAlreadyInTags !== -1) {
        return;
      }

      state.tags = [
        ...tags,
        {
          id,
          name,
          root: chosenSectorRoot,
          color: getColorForNewTag(tags),
          type,
        },
      ];
      state.errors = [];
      state.clickedSector = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilteredSectorLists.pending, (state) => {
      state.listLoading = true;
    });
    builder.addCase(getFilteredSectorLists.fulfilled, (state, action) => {
      state.listLoading = false;
      state.sectorLists.filtered.sectors =
        SectorHelper.preparefilteredIsicsList(action.payload.sectors);
    });
    builder.addCase(getSectorDefaultLists.pending, (state) => {
      state.listLoading = true;
    });
    builder.addCase(getSectorDefaultLists.fulfilled, (state, action) => {
      state.listLoading = false;
      state.sectorLists.default.sectors = action.payload.sectors;
    });
    builder.addCase(getSectorsSizes.pending, (state) => {
      state.sectorSizesLoading = true;
    });
    builder.addCase(getSectorsSizes.fulfilled, (state, action) => {
      state.sectorSizesLoading = false;
      state.sectorSizesBySectorId = { ...action.payload };
    });
  },
});

export const {
  saveTags,
  clearTags,
  filterSectors,
  saveMultipleSectors,
  saveFilteredSectorLists,
  saveSectorLists,
  saveSectorSizes,
  sectorError,
  sectorSizesLoading,
  sectorClearError,
  setSectorsLoading,
  removeSector,
  saveSector,
} = SectorsSlice.actions;

export default SectorsSlice.reducer;

startListening({
  actionCreator: filterSectors,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(getSectorLists(true));
  },
});

startListening({
  actionCreator: saveMultipleSectors,
  effect: (action, { dispatch }) => {
    dispatch(getSectorLists(true));
  },
});

startListening({
  actionCreator: removeSector,
  effect: (action, { dispatch }) => {
    dispatch(getSectorLists(true));
  },
});

startListening({
  actionCreator: saveSector,
  effect: (action, { dispatch, getOriginalState }) => {
    const { tags } = getOriginalState().sector;
    const errors = SectorHelper.handleTagsErrors(action.payload, tags);

    if (errors.length) {
      dispatch(
        sectorError({
          errors,
          clickedSector: action.payload,
        }),
      );

      return;
    }

    dispatch(getSectorLists(true));
  },
});

// todo convert to selector
export const getSectorListItemHoverColor = () => (dispatch, getState) => {
  const usedColors = getChosenSectorsColorsNames(getState());
  const availableColors = difference(ColorsByPriority, usedColors);
  const colorName = availableColors.shift();
  return Colors[colorName]?.value;
};

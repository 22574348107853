const demographicChart = {
  noDemographicDataFound: 'لا توجد معلومات ديموغرافية لهذه المنطقة',
  demographicStatistics: 'المعلومات الديموغرافية في {{areaName}}',
  noDataProvided:
    'الإحصاءات السكانية في هذه المنطقة غير متوفرة. الرجاء اختيار منطقة أخرى.',
  population: 'عدد السكان',
  ageDistribution: 'السكان حسب فئات العمر والجنس',
  nationality: 'السكان حسب فئات العمر والجنسية',
  total: 'عدد السكان',
  all: 'الكل',
  male: 'ذكور',
  female: 'إناث',
  saudi: 'سعوديون',
  saudiMale: 'سعوديون ذكور',
  saudiFemale: 'سعوديون إناث',
  nonSaudi: 'غير سعوديين',
  nonSaudiMale: 'غير سعوديين ذكور',
  nonSaudiFemale: 'غير سعوديين إناث',
  tooltipDemographicLabel: {
    male: 'عدد السكان (النسبة من إجمالي الذكور)',
    female: ' عدد السكان (النسبة من إجمالي الإناث)',
    saudi: ' عدد السكان (النسبة من إجمالي السعوديين)',
    nonSaudi: ' عدد السكان (النسبة من إجمالي غير السعوديين)',
  },
  averageIncome: 'متوسط دخل الفرد',
  averageIncomeEastern: 'متوسط دخل الأسرة',
  population_density: 'الكثافة السكانية',
  population_growth_rate: 'المعدل السنوي لنمو السكان',
  household_size: 'متوسط حجم الأسرة المعيشية',
  household_growth_rate: 'المعدل السنوي لنمو الأسر المعيشية',
  household_income: 'متوسط الدخل الشهري للأسر',
  household_savings: 'متوسط الادخار الشهري للأسر',
  household_spending: 'متوسط الإنفاق الشهري للأسر',
  averageSavings: 'متوسط الادخار',
  eastern: {
    groups: {
      people: 'السكان حسب الكثافة ومعدل النمو',
      household: 'الأسر حسب الحجم ومعدل النمو',
      sar: 'متوسط الدخل والإنفاق',
    },
    suffix: {
      populationDensity: 'فرد / هكتار',
      populationGrowthRate: '%',
      householdSize: 'فرد / أسرة',
      householdGrowthRate: '%',
    },
  },
};

export default demographicChart;

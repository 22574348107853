import { findIndex, map, partial, reduce } from 'lodash';

export const IncomeTypesChartConfig = {
  all: {
    icon: 'income-all',
    color: '#0060ff',
  },
  male: {
    icon: 'income-male',
    color: '#4a90e2',
  },
  female: {
    icon: 'income-female',
    color: '#ff7296',
  },
  saudi: {
    icon: 'income-saudi',
    color: '#53d17a',
  },
  nonSaudi: {
    icon: 'income-non-saudi',
    color: '#e2e36c',
  },
  saudiMale: {
    icon: 'income-male-saudi',
    color: '#6d52ff',
    centerIcon: true,
  },
  saudiFemale: {
    icon: 'income-female-saudi',
    color: '#e86bde',
    centerIcon: true,
  },
  nonSaudiMale: {
    icon: 'income-male-non-saudi',
    color: '#928dc7',
    centerIcon: true,
  },
  nonSaudiFemale: {
    icon: 'income-female-non-saudi',
    color: '#b2e03d',
    centerIcon: true,
  },
  population_density: {
    color: '#4a90e2',
    valueSuffix: 'demographicChart.eastern.suffix.populationDensity',
  },
  population_growth_rate: {
    color: '#e64770',
    valueSuffix: 'demographicChart.eastern.suffix.populationGrowthRate',
  },
  household_size: {
    color: '#c65abd',
    valueSuffix: 'demographicChart.eastern.suffix.householdSize',
  },
  household_growth_rate: {
    color: '#6d52ff',
    valueSuffix: 'demographicChart.eastern.suffix.householdGrowthRate',
  },
  household_income: {
    color: '#e2e36c',
  },
  household_savings: {
    color: '#0060ff',
  },
  household_spending: {
    color: '#53d17a',
  },
};

export const sortBubbles = (bubble1, bubble2) => {
  if (bubble1.type === 'primary') {
    return 1;
  }
  if (bubble2.type === 'primary') {
    return -1;
  }

  return bubble2.value - bubble1.value;
};

export const prepareIncomeChartData = ({
  groups,
  areaId,
  convertFactToBubble,
}) =>
  reduce(
    groups,
    (result, value, key) => {
      const config = IncomeTypesChartConfig[key];

      const positionIndex = findIndex(
        value.facts,
        ({ area }) => area.id === areaId,
      );
      const position = 1 + positionIndex;

      const income = value.facts[positionIndex]
        ? value.facts[positionIndex].value
        : null;

      const isEmpty = positionIndex === -1 || income === null;

      const numBubbles = value.facts.length;
      const minIncome = value.facts[numBubbles - 1].value;
      const maxIncome = value.facts[0].value;

      result.push({
        label: key,
        position,
        income,
        isEmpty,
        bubbles: map(
          value.facts,
          partial(convertFactToBubble, minIncome, maxIncome, numBubbles),
        ).sort(sortBubbles),
        minIncome,
        maxIncome,
        ...config,
      });

      return result;
    },
    [],
  );

export const BUBBLE_MARGIN = 18;
export const DEMOGRAPHIC_TOOLTIP_WIDTH = 200;

export const factToBubble = (
  currentAreaId,
  minIncome,
  maxIncome,
  numBubbles,
  { area: { id, name }, value },
  index,
) => {
  const type = currentAreaId === id ? 'primary' : 'default';

  return {
    id,
    type,
    numBubbles,
    value,
    income: value,
    districtName: name,
    valueLabel: 'incomeChart.position',
    position: index + 1,
  };
};
